import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import DisputeDetails from './DisputeDisplay/DisputeDetails/DisputeDetails';
import { useState } from 'react';
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

export default function ReceivedRegisterDisputeDisplay({ activeDispute, setStep }) {
    const useStyles = makeStyles((theme) => ({
        body: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: "0",
            overflow: "hidden",
            width: "100%",
            textAlign: "center"
        },
    }));

    const classes = useStyles();
    const [dispute, setDispute] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        instance({
            method: "get",
            url: "/api/dispute/",
            params: {
                id: activeDispute.id,
            },
        }).then((response) => {
            setDispute(response.data.results);
        })
    }, []);

    React.useEffect(() => {
        if (dispute[0]) {
            console.log("Dispute::::", dispute[0]);
            setLoading(false);
        }
    }, [dispute]);

    return (
        // <React.Fragment>
        //     <Box className={classes.body}>
        //         <DisputeDetails activeDispute={activeDispute} step={2} setStep={setStep}/>
        //     </Box>
        // </React.Fragment>
        <React.Fragment>
            {
                !loading ? (
                    <Box className={classes.body}>
                        <DisputeDetails activeDispute={dispute[0]} step={2} setStep={setStep} />
                    </Box>
                ) : (
                    <CircularCenteredLoader />
                )}
        </React.Fragment>
    )
}
