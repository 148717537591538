import {
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
  Grid,
  Hidden,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DuoIcon from "@material-ui/icons/Duo";
import { ARBITRATION } from "app/constants";
import { containerContext } from "features/Chat/ChatContainer";
import Files from "features/Chat/Files";
import FilesModal from "features/Chat/FilesModal";
import Scheduler from "features/Chat/Scheduler";
import Search from "features/Chat/Search";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import Recordings from "features/Chat/Recordings";

export default function ChatDoc({ classes, setStep, smallScreen, selected }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const activeDispute = useSelector((state) => state.disputes.activeDispute);

  const { showVC, setShowVC } = useContext(containerContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterAudioFiles = (files) => {
    return files.filter((file) => file.typeGroup !== "audio");
  };
  console.log("in chat doc")
  const isArbitrator = activeDispute.flow_type === ARBITRATION;
  return (


    <Box className={classes.rightDoc} >
      <Box m="0 0 0 0" textAlign="center">
        <Box height="2rem"></Box>
        <Hidden mdUp>
          <IconButton
            onClick={() => {
              setStep(1);
            }}
          >
            <ArrowBackIosIcon /> Back 
          </IconButton>
        </Hidden>

        <Recordings />
        {/* <Divider /> */}
        {/* <Files handleOpen={handleOpen} filterAudioFiles={filterAudioFiles} />
        <FilesModal
          open={open}
          handleClose={handleClose}
          filterAudioFiles={filterAudioFiles}
        /> */}
      </Box>
    </Box>



  );
}
