import { Box, Button, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { Link } from "react-router-dom";
import refreshPng from "images/refresh.png"
import { useHistory } from "react-router-dom";

export default function Fallback() {
  let history = useHistory();
  return (
    <Box height="80vh" textAlign="center">
      <Box height="20vh"></Box>

      {/* <Typography variant="h1" color="primary">
        <FormattedMessage id="pages.fallback.heading1" />
      </Typography>
      <Box heigh="2rem"></Box>
      <Typography variant="h4" color="primary">
        <FormattedMessage id="pages.fallback.heading2" />
      </Typography>
      <Box height="1rem"></Box>
      <Link to="/" style={{ textDecoration: "None" }}>
        <Button variant="outlined" color="primary">
          <FormattedMessage id="buttons.goToHomepage" />
        </Button>
      </Link> */}
      
      <Typography variant="h4" color="primary" style={{color:"grey"}}>
      Please refresh or go back to the previous screen on your browser
      </Typography>
      <br />
      <img src = {refreshPng} onClick={() => {history.goBack();}}/>
    </Box>
  );
}
