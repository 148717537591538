import { Box, Button, Hidden, makeStyles, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DocGenerator } from "utils";

const useStyles = makeStyles((theme) => ({
  disputeName: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default function ArbitratorCommonContent({ activeDispute, step }) {
  const history = useHistory();
  const classes = useStyles();
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );
  const isArbitrator = profileType === "arbitrator";
  return (
    <Box className={classes.container}>
      <Box>
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="h2"
            className={classes.disputeName}
          >
            {activeDispute.name}
          </Typography>
          <Typography variant="h6" color="textSecondary" component="p">
            <FormattedMessage id="dispute.startDate" /> :{" "}
            {activeDispute.created.split("T")[0]}
          </Typography>
          <Box height="0.5rem"></Box>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => {
              DocGenerator(activeDispute.id, "claimant");
            }}
          >
            <FormattedMessage id="dispute.statementOfClaim" />
          </Button>
          <Box width="1rem"></Box>
          {/* {isArbitrator && (
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                DocGenerator(activeDispute.id, "respondent");
              }}
            >
              <FormattedMessage id="dispute.statementOfDefence" />
            </Button>
          )} */}
        </Box>
      </Box>
      <Box flexGrow={1}></Box>
      <Hidden mdUp>
        <Box height="3rem"></Box>
      </Hidden>
      <Box>
        {activeDispute.status !== "closed" ? (
          <>
            <Box width="1rem"></Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                history.push(`/disputes/${activeDispute.id}/chat`);
              }}
              disabled={step < 1}
            >
              <FormattedMessage id="common.goToHearingRooms" />
            </Button>
          </>
        ) : null}
      </Box>
    </Box>
  );
}
