import { Box, Grid, Button, Paper, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { getWindowDimension } from "utils/responsiveUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ReceivedRegisterDisputeDisplay from "./ReceivedRegisterDisputeDisplay";
import RegisteredDisputes from "./RegisteredDisputes/RegisteredDisputes";
import ReceivedDisputes from "./ReceivedDisputes/ReceivedDisputes";
import ClosedDisputes from "./ClosedDisputes/ClosedDisputes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    // height: "95vh",
    // height:"100%",
    // height:"auto",
    borderRadius: "1rem",
    // height: '-webkit-fill-available',
    maxHeight: '100%'
    // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  sidebar: {
    background: "white",
    marginTop: "2px",
    // width: "27rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // height:"100%",
  },
  sidebarItem: {
    fontSize: "16px",
    padding: "0.5rem",
  },
  selected: {
    background: "#F2F2F2",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  secretariatName: {
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
    },
  },
  btn: {
    fontSize: "20px",
    color: "#4F4F4F",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
}));

export default function Secretariat({
  user,
  disputes,
  cases,
  profile,
  claimaintDisputes,
  respondentDisputes,
  isRV,
}) {
  

  let t = localStorage.getItem("whichTabHomePage");

  const classes = useStyles();
  // const [selected, setSelected] = useState(isSmallScreen ? 0 :t?t:1);
  const [selected, setSelected] = useState(t ? t : 1);
  const [step, setStep] = useState(0);
  let history = useHistory();
  const [activeDispute, setActiveDispute] = useState();

  const MobileView = ({ step }) => {
    switch (step) {
      case 0:
        return <Sidebar />;
      case 1:
        return <Body />;
      case 2:
        return (
          <ReceivedRegisterDisputeDisplay
            activeDispute={activeDispute}
            setStep={setStep}
          />
        );
      default:
        return null;
    }
  };

  const RenderOptions = () => {
    // let { cases, disputes, respondent, claimant, loading } = useDisputes(isRV);
    if (selected == 1 || selected == 0) {
      return (
        <ReceivedDisputes
          setActiveDispute={setActiveDispute}
          setStep={setStep}
        />
      );
    }
    if (selected == 2) {
      return <RegisteredDisputes />;
    }
    if (selected == 3) {
      return <ClosedDisputes />;
    }
  };

  const Sidebar = () => {
    return (
      <Box className={classes.sidebar}>
        <Hidden smDown>
          <Box height="4rem" />
        </Hidden>
        <List style={{ paddingTop: "0" }}>
          <Divider />
          {selected == 1 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Grievances received
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Grievances received
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 2 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Grievances registered
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Grievances registered
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 3 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Closed Grievances
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Closed Grievances
              </Typography>
            </ListItem>
          )}
          <Divider />
        </List>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box className={classes.body2}>
        <Hidden mdUp>
          <Paper
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              display: "flex",
              padding: "20px 30px",
            }}
          >
            <Button
              onClick={() => {
                history.push("/disputes");
                setStep(0);
              }}
              size="large"
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
          </Paper>
        </Hidden>
        <Box className={classes.body}>
          <Box height="3rem"></Box>
          <Grid container justify="center" alignItems="center">
            <Grid item sm={12}>
              <Typography
                variant="h3"
                className={classes.secretariatName}
              >{`Hi, ${user.first_name}`}</Typography>
            </Grid>
          </Grid>
          <Box height="2.5rem"></Box>
          <Grid container justify="center" alignItems="center">
            <Grid item style={{padding:"10px"}}>
              <Button
                variant="outlined"
                color="myblack"
                className={classes.btn}
                onClick={() => {
                  history.push("/analytics");
                }}
              >
                <FormattedMessage id="buttons.analytics" />
              </Button>
            </Grid>
            <Grid item style={{padding:"10px"}}>
              <Button
                className={classes.btn}
                variant="outlined"
                color="myblack"
                onClick={() => {
                  history.push("/events");
                }}
              >
                <FormattedMessage id="buttons.calendar" />
              </Button>
            </Grid>
          </Grid>
          <Box height="2.5rem"></Box>
          <RenderOptions />
          <Box height="2.5rem"></Box>
          <Box height="2.5rem"></Box>
          <Box height="2.5rem"></Box>
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Hidden smDown>
          <>
          <Grid container>
            <Grid item  style={{background: "white",width:"22%",maxWidth:"22%"}}>
            <Sidebar />
            </Grid>
            <Grid item style={{width:"78%",maxWidth:"78%"}}>
            {step == 2 ? (
              <ReceivedRegisterDisputeDisplay
                activeDispute={activeDispute}
                setStep={setStep}
              />
            ) : (
              <Body />
            )}
            </Grid>
            </Grid>
          </>
        </Hidden>
        <Hidden mdUp>
          <MobileView step={step} />
        </Hidden>
      </Box>
    </React.Fragment>
  );
}
