import PropTypes from "prop-types";
import React from "react";
import IntroForm from "./IntroForm";
import VendorAsClaimantForm from "./VendorAsClaimantForm";

function Intro({ profile, user, companies, formStep }) {
  let props = { user, profile, companies };

  if (formStep === 1) return <IntroForm {...props} />;
  else return <VendorAsClaimantForm {...props} />;
}

Intro.propTypes = {
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default Intro;
