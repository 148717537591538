import {
  Box,
  Hidden,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import GroupDisplay from "features/Chat/GroupDisplay";
import InputBox from "features/Chat/InputBox";
import MembersDisplay from "features/Chat/MembersDisplay";
import Message from "features/Chat/Message";
import VoiceInputBox from "features/Chat/VoiceInputBox";
import { useAudioRecorder, useTimer } from "hooks";
import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { uploadFile } from "slices/groupSlice";
import { createGroupName } from "utils";
import HearingRoomSVG from "images/hearingroom.svg";
import { getCase } from "slices/casesSlice";
import { getCompanyFlows } from "slices/companyFlowsSlice";
import { getDispute } from "slices/disputeSlice";
import { useTheme } from "@material-ui/core";
import DuoIcon from "@material-ui/icons/Duo";
import { ARBITRATION } from "app/constants";
import { containerContext } from "features/Chat/ChatContainer";
import Files from "features/Chat/Files";
import FilesModal from "features/Chat/FilesModal";
import Scheduler from "features/Chat/Scheduler";
import Search from "features/Chat/Search";
import FormattedMessage from "features/common/TranslatedMessage";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getWindowDimension } from "utils/responsiveUtils";
import { useState } from "react";
import instance from "api/globalaxios";
import { useParams } from "react-router-dom";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      background: "#F2F2F2",
      margin: "2px",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    disputename: {
      fontSize: "36px",
      lineHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "18px",
      },
    },
    header: {
      borderRadius: "inherit",
      flexBasis: "5rem",
      minHeight: "5rem",
      width: "100%",
      padding: "0 3rem",
      borderBottom: `1px solid ${theme.palette.border}`,
      background: theme.palette.common.grey,
    },
    heading: {
      fontWeight: "bold",
    },
    messages: {
      overflow: "scroll",
      flexGrow: "1",
      borderBottom: `0.2rem solid ${theme.palette.border}`,
      padding: "0 2rem",
      boxSizing: "border-box",
    },
    footer: {
      padding: "0rem 2rem",
    },
    joinbtn: {
      fontSize: "20px",
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    vectorImage: {
      width: "40%",
      height: "40%",
      // marginLeft: "285px",
      // marginTop: "31px",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
        order: 1,
        marginBottom: "2rem",
      },
    },
  };
});

export default function ChatBody({
  disputeName,
  group,
  classes: parentClasses,
  messages,
  members,
  rightDocOpen,
  typing,
  messageContainer,
  smallScreen,
  setStep,
}) {
  const theme = useTheme();
  const { showVC, setShowVC, setVcType } = useContext(containerContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isVoiceInput, setIsVoiceInput] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const arr = location.pathname.split("/");

  const {
    profile: { profile, loading: profileLoading },
    disputes: { loading: disputeLoading, activeDispute: activeDispute },
    cases: { loading: caseLoading, activeCase: activeCase },
    companyFlows: { loading: companyFlowsLoading, companyFlows },
  } = useSelector((state) => state, shallowEqual);

  React.useEffect(() => {
    // dispatch(getDispute(disputeId));
    // dispatch(getCase(disputeId));
    dispatch(getCompanyFlows());
  }, []);

  // console.log("companyFlows:", companyFlows);

  const [isRecording, blobURL, audioFile, startRecording, stopRecording] =
    useAudioRecorder();

  const [toggle, reset, seconds] = useTimer();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartRecording = () => {
    toggle();
    setIsVoiceInput(true);
    startRecording();
  };

  const handleStopRecording = async () => {
    reset();
    setIsVoiceInput(false);
    stopRecording();
  };

  const handleRecordingSend = async () => {
    reset();
    setIsVoiceInput(false);
    let file = await stopRecording();
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    dispatch(uploadFile({ file, groupId: group._id, msg: "Audio message" }));
  };

  const groupName = createGroupName(group.name);
  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };

  const isSmallScreen = () => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showZoom, setShowZoom] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorType, setErrorType] = useState(null);
  const { disputeId } = useParams();
  console.log("disputeId", disputeId);
  const { activeGroup, groups } = useSelector(
    (state) => state.groups,
    shallowEqual
  );
  console.log("activeGroup", activeGroup);
  console.log("groups", groups);
  const currentGroup = groups.filter((group) => group._id === activeGroup)[0];
  console.log("currentGroup", currentGroup);
  const roomName = `${disputeId}_${currentGroup.name}-dpcgc_${activeGroup}`;
  console.log("roomName", roomName);
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  async function startConferenceZoom() {
    try {
      setLoading(true);
      setErrorType(null);
      let response = await instance({
        method: "post",
        url: "/createzoom/",
      });
      setLoading(false);
      setShowVC(false);
      if (response.data.user_key == profile.user.id) {
        setShowZoom(false);
        // window.open(response.data.start_url, "_blank").focus();
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = response.data.start_url;
        a.click();
        document.body.removeChild(a);
      } else {
        setShowZoom(false);
        // window.open(response.data.join_url, "_blank").focus();
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = response.data.join_url;
        a.click();
        document.body.removeChild(a);
      }
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        console.log(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  async function closePreviousZoomMeetings() {
    try {
      setLoading(true);
      await instance({
        method: "post",
        url: "/closezoompreviousmeetings",
        data: {},
      });
      startConferenceZoom();
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  const renderErrorContainer = () => {
    switch (errorType) {
      case "not-started":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <IconButton
              onClick={() => {
                // history.push("/" + arr[1] + "/" + arr[2]);
                // history.goBack()
                // setShowVC(false);
                setShowZoom(false);
              }}
            >
              <ArrowBackIosIcon /> Back
            </IconButton>
            <h3>Meeting not started by host</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={startConferenceZoom}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Retry
              </Button>
            </Box>
          </Box>
        );
      case "not-found":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Meeting with the dispute id {disputeId} does not exist</h3>
          </Box>
        );
      case "in-meeting":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>You are already a host in some other meeting.</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={startConferenceZoom}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Retry
              </Button>
            </Box>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={closePreviousZoomMeetings}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Close Previous Meetings & Retry
              </Button>
            </Box>
          </Box>
        );
      case "meeting-close-error":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Error while closing meetings.</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={closePreviousZoomMeetings}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Close Previous Meetings & Retry
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  if (showZoom)
    return (
      <Box display="flex" justifyContent="center" flexBasis="50%" flexGrow={1}>
        <div style={containerStyle}>
          {loading && (
            <Box display="flex" justifyContent="center" pt="3rem">
              <CircularCenteredLoader />
            </Box>
          )}
          {errorType && renderErrorContainer()}
          <div id="jitsi-container" style={jitsiContainerStyle} />
        </div>
      </Box>
    );

  return (
    <Box className={parentClasses.content}>
      <IconButton
        onClick={() => {
          // history.push("/" + arr[1] + "/" + arr[2]);
          history.goBack();
        }}
      >
        <ArrowBackIosIcon /> Back
      </IconButton>
      <Box className={classes.container}>
        <Box>
          <Hidden mdUp>
            <IconButton
              onClick={() => {
                setStep(0);
              }}
            >
              <ArrowBackIosIcon /> Back to Hearing Room
            </IconButton>
          </Hidden>
        </Box>
        <Box>
          <Hidden mdUp>
            <IconButton
              onClick={() => {
                setStep(2);
              }}
            >
              View Recordings
            </IconButton>
          </Hidden>
        </Box>
        <Box></Box>
        <Box height="5.5rem"></Box>
        <Box display="flex" justifyContent="center">
          <img
            // className={classes.vectorImage}
            src={HearingRoomSVG}
            alt="hearing room vector image"
            style={{ maxWidth: "100%" }}
          />
        </Box>
        <Box height="2rem"></Box>
        <Box display="flex" justifyContent="center" style={{ padding: "10px" }}>
          <Typography className={classes.disputename}>{disputeName}</Typography>
        </Box>
        {/* <Box height="3rem"></Box> */}
        <Box display="flex" justifyContent="center">
          <Grid
            container
            justify="center"
            spacing={isSmallScreen() ? 1 : 2}
            style={{ padding: "10px" }}
          >
            {/* <Grid item></Grid> */}
            {currentGroup.name.split("_")[0].includes("dpcgcarbitration") ? (
              <Grid item style={{ padding: "8px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.joinbtn}
                  onClick={() => {
                    setShowVC(true);
                    setVcType("dyte");
                    // setShowZoom(true);
                    // startConferenceZoom();
                  }}
                >
                  {/* <FormattedMessage id="common.joinZoom" /> */}
                  Join hearing
                </Button>
              </Grid>
            ) : (
              <Grid item style={{ padding: "8px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.joinbtn}
                  onClick={() => {
                    setShowVC(true);
                    setVcType("dyte");
                  }}
                >
                  {/* <FormattedMessage id="common.join" /> */}
                  Join hearing
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box height="23rem"></Box>
        {/* <Box height="1rem"></Box>
        <Box display="flex" justifyContent="center">
          
        </Box> */}

        {/* <Box
          className={classes.header}
          bgcolor={grey["200"]}
          display="flex"
          alignItems="center"
        > */}
        {/* <Hidden mdUp>
            <IconButton
              onClick={() => {
                setStep(0);
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Hidden> */}

        {/* <GroupDisplay
            group={group}
            name={groupName}
            fontSize="large"
            caption={false}
            rightDocOpen={rightDocOpen}
            setStep={setStep}
          /> */}
        {/* <Box flexGrow={1}></Box> */}
        {/* <Box
            display="flex"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          >
            {!smallScreen && Object.keys(members).length > 0 && (
              <PeopleAltOutlinedIcon fontSize="large" color="primary" />
            )}
          </Box> */}
        {/* </Box> */}
        {/* <Box p="0 3rem" className={classes.messages} ref={messageContainer}>
          {messages.map((message) => {
            return (
              <Message
                message={message}
                domId={message._id}
                key={message._id}
              />
            );
          })}

          <Box display="flex" justifyContent="flex-end" p="0.4rem 0">
            {typing.value && typing.groupId === group._id && (
              <Typography variant="caption">{`${typing.username} is typing...`}</Typography>
            )}
          </Box>
        </Box> */}
        {/* <Box className={classes.footer}>
          {isVoiceInput ? (
            <VoiceInputBox
              handleCancelRecording={handleStopRecording}
              handleFileUpload={handleFileUpload}
              handleRecordingSend={handleRecordingSend}
              seconds={seconds}
            />
          ) : (
            <InputBox
              groupId={group._id}
              handleStartRecording={handleStartRecording}
            />
          )}
        </Box> */}
      </Box>
      {/* <MembersDisplay members={members} open={open} handleClose={handleClose} /> */}
    </Box>
  );
}
