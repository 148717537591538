import DateFnsUtils from "@date-io/date-fns";
import { Grid, InputAdornment, OutlinedInput } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, parseISO } from "date-fns";
import React from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
    width: "100%",
    borderRadius: "0.3rem",
    resize: "none",
    padding: theme.spacing(1),
    fontSize: "1.5rem",
    color: "#000000"
  },
  question:{
      fontSize: "16px",
      lineHeight:"24px",
      [theme.breakpoints.down("md")]:{
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]:{
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]:{
        fontSize: "14px",
      }
  },
  container: {
    width: 700,
  },
  number: {
    width: "3em",
    color: "#000000",
  },
}));

const Question = ({ question, answerValues, index }) => {
  const radioName = Math.floor(Math.random() * 1000000);
  // console.log("radioName:", radioName)

  if (question.type === "date") {
    // console.log("question.name:", question.id, ":", question.name)
    // console.log("answerValues:", answerValues)
    // console.log("answerValues:", answerValues.get(question.id))
    // console.log("parseISO:", parseISO(answerValues.get(question.id)))
  }

  const classes = useStyles();
  let element;

  const [booleanValue, setBooleanValue] = React.useState(
    answerValues.has(question.id)
      ? answerValues.get(question.id) === "true"
      : false
  );

  const [value, setValue] = React.useState(
    answerValues.has(question.id) ? answerValues.get(question.id) : ""
  );

  const [dateValue, setDataValue] = React.useState(
    answerValues.has(question.id) && question.type === "date"
      ? answerValues.get(question.id) !== null
        ? parseISO(answerValues.get(question.id))
        : null
      : setupInitialDate()
  );

  if (question.type === "date") {
    console.log(question.id, " dateValue:", dateValue);
  }

  function setupInitialDate() {
    const maxdate = new Date();
    maxdate.setDate(maxdate.getDate() - 15);

    if (question.type === "date") {
      setDateInAnswer(maxdate);
    }
    return maxdate;
  }

  function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  const handleDate = (event) => {
    console.log("hereeee:", event);
    let o = new Date(event)
    console.log('o',o)
    console.log('isvalid',isValidDate(o))
    if(event && isValidDate(o)){

      let value = event;
      setDataValue(value);
      setDateInAnswer(value);
    }
  };

  function setDateInAnswer(date) {
    answerValues.set(question.id, format(date, "yyyy-MM-dd"));
  }

  const [selectedDate, handleDateChange] = React.useState(new Date());

  const [timeValue, setTimeValue] = React.useState(
    answerValues.has(question.id) && question.type === "time"
      ? console.log("setAnswermm: ", answerValues.get(question.id))
      : setupInitialTime()
  );

  function setupInitialTime() {
    let time = new Date(2018, 11, 24, 0, 0, 0, 0);
    if (question.type === "time") {
      setTimeInAnswer(time);
    }
    return time;
  }

  const handleTime = (event) => {
    let value = event;
    setTimeValue(value);
    setTimeInAnswer(value);
  };

  function setTimeInAnswer(time) {
    console.log("formated time: ", format(time, "HH:mm:ss"));
    answerValues.set(question.id, format(time, "HH:mm:ss"));
  }

  const handleChangeRadio = (event) => {
    // console.log("here");
    setValue(event.target.value);
    // console.log(question.id, "value:", value);
    answerValues.set(question.id, event.target.value);
  };

  const handleChangeDropdown = (event) => {
    setValue(event.target.value);
    answerValues.set(question.id, event.target.value);
  };

  const handleChangeChecked = (event) => {
    let checked = event.target.checked;
    setBooleanValue(checked);
    answerValues.set(question.id, checked);
  };

  let numericRexEx = /^[0-9\b]+$/;

  const handleNumericValue = (event) => {
    let currentValue = event.target.value;

    if (currentValue === "" || numericRexEx.test(currentValue)) {
      setValue(currentValue);
      answerValues.set(question.id, currentValue);
    }
  };

  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [hour, setHour] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const handleHourChange = (e) => {
    setHour(e.target.value);
    answerValues.set(
      question.id,
      e.target.value + ":" + minutes + ":" + seconds
    );
  };
  const handleMinuteChange = (e) => {
    setMinutes(e.target.value);
    answerValues.set(question.id, hour + ":" + e.target.value + ":" + seconds);
  };
  const handleSecondChange = (e) => {
    setSeconds(e.target.value);
    answerValues.set(question.id, hour + ":" + minutes + ":" + e.target.value);
  };

  const [hour1, setHour1] = React.useState(0);
  const [minutes1, setMinutes1] = React.useState(0);
  const [seconds1, setSeconds1] = React.useState(0);

  const [hour2, setHour2] = React.useState(0);
  const [minutes2, setMinutes2] = React.useState(0);
  const [seconds2, setSeconds2] = React.useState(0);

  const handleHourChange1 = (e) => {
    setHour1(e.target.value);
    answerValues.set(
      question.id,
      e.target.value +
        ":" +
        minutes1 +
        ":" +
        seconds1 +
        " to " +
        hour2 +
        ":" +
        minutes2 +
        ":" +
        seconds2
    );
  };
  const handleMinuteChange1 = (e) => {
    setMinutes1(e.target.value);
    answerValues.set(
      question.id,
      hour1 +
        ":" +
        e.target.value +
        ":" +
        seconds1 +
        " to " +
        hour2 +
        ":" +
        minutes2 +
        ":" +
        seconds2
    );
  };
  const handleSecondChange1 = (e) => {
    setSeconds1(e.target.value);
    answerValues.set(
      question.id,
      hour1 +
        ":" +
        minutes1 +
        ":" +
        e.target.value +
        " to " +
        hour2 +
        ":" +
        minutes2 +
        ":" +
        seconds2
    );
  };

  const handleHourChange2 = (e) => {
    setHour2(e.target.value);
    answerValues.set(
      question.id,
      hour1 +
        ":" +
        minutes1 +
        ":" +
        seconds1 +
        " to " +
        e.target.value +
        ":" +
        minutes2 +
        ":" +
        seconds2
    );
  };
  const handleMinuteChange2 = (e) => {
    setMinutes2(e.target.value);
    answerValues.set(
      question.id,
      hour1 +
        ":" +
        minutes1 +
        ":" +
        seconds1 +
        " to " +
        hour2 +
        ":" +
        e.target.value +
        ":" +
        seconds2
    );
  };
  const handleSecondChange2 = (e) => {
    setSeconds2(e.target.value);
    answerValues.set(
      question.id,
      hour1 +
        ":" +
        minutes1 +
        ":" +
        seconds1 +
        " to " +
        hour2 +
        ":" +
        minutes2 +
        ":" +
        e.target.value
    );
  };

  if (question.type === "boolean") {
    element = (
      <>
        {" "}
        <Switch
          data-testid={`claimant-question-${index}`}
          checked={booleanValue}
          onChange={handleChangeChecked}
          color="primary"
          name="checked"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </>
    );
  } else if (question.type === "simple") {
    element = (
      <>
        {" "}
        <TextareaAutosize
          data-testid={`claimant-question-${index}`}
          data-question-type="textarea"
          required
          label="Required"
          onChange={handleChangeRadio}
          className={classes.item}
          value={value}
          rowsMin={1}
        />
      </>
    );
  } else if (question.type === "number") {
    element = (
      <>
        <OutlinedInput
          data-testid={`claimant-question-${index}`}
          id="outlined-adornment-amount"
          name="outlined-adornment-amount"
          startAdornment={<InputAdornment position="start">₹</InputAdornment>}
          value={value}
          onChange={handleNumericValue}
        />
      </>
    );
  } else if (question.type === "radio" || question.type === "dropdown") {
    element = (
      <>
        <RadioGroup
          // data-testid={`claimant-question-${index}`}
          // aria-label="gender"
          // name="gender1"
          name={radioName}
          value={value}
          onChange={handleChangeRadio}
          className={classes.item}
        >
          <Box display="flex" flexDirection="column" alignItems="start">
            {JSON.parse(question.extra_args)["options"].map((value, index) => (
              <Box display="flex" style={{ margin: "0.5rem 0" }}>
                <input
                  type="radio"
                  id={''+value+index}
                  name={radioName}
                  value={value}
                  onChange={handleChangeRadio}
                  style={{
                    transform: "scale(1.5)",
                    margin: "6px 3px 0px 6px",
                    cursor: "pointer",
                  }}
                />
                <Box width="0.6rem"></Box>
                <Box alignItems="start" style={{ textAlign: "left" }}>
                  <Typography>
                    <label
                      for={''+value+index}
                      className={classes.question}
                      style={{
                        color: "#454545",
                        cursor: "pointer",
                      }}
                    >
                      {value}
                    </label>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </RadioGroup>
      </>
    );
  } else if (question.type === "dropdown_toBeCorrect") {
    element = (
      <>
        <InputLabel>Select</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          onChange={handleChangeDropdown}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </>
    );
  } else if (question.type === "date") {
    const maxdate = new Date();
    maxdate.setDate(maxdate.getDate() - 15);

    const mindate = new Date();
    mindate.setDate(maxdate.getDate() - 15);
    element = (
      <>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
          <KeyboardDatePicker
            margin="normal"
            // minDate={mindate}
            maxDate={maxdate}
            format="dd/MM/yyyy"
            value={dateValue}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={handleDate}
          />
        {/* </MuiPickersUtilsProvider> */}
      </>
    );
  } else if (question.type === "time") {
    element = (
      <>
        <input
          className={classes.number}
          type="number"
          min="1"
          max="5"
          value={hour}
          onChange={handleHourChange}
        ></input>
        :
        <input
          className={classes.number}
          type="number"
          min="0"
          max="59"
          value={minutes}
          onChange={handleMinuteChange}
        ></input>
        :
        <input
          className={classes.number}
          type="number"
          min="0"
          max="59"
          value={seconds}
          onChange={handleSecondChange}
        ></input>
      </>
    );
  } else if (question.type === "time_range") {
    element = (
      <>
      <Grid container>
        <Grid item xs sm={6} style={{
      display:"flex",
      alignItems:"center",
      padding:"5px"}}>
          <Typography style={{ lineHeight: "24px", fontSize: "14px" }}>
            {"From: "}
          </Typography>
          <Box width="0.8rem"></Box>
          <input
            className={classes.number}
            type="number"
            min="1"
            max="5"
            value={hour1}
            onChange={handleHourChange1}
          ></input>
          :
          <input
            className={classes.number}
            type="number"
            min="0"
            max="59"
            value={minutes1}
            onChange={handleMinuteChange1}
          ></input>
          :
          <input
            className={classes.number}
            type="number"
            min="0"
            max="59"
            value={seconds1}
            onChange={handleSecondChange1}
          ></input>
        </Grid>
        <Grid item xs sm={6} style={{
      display:"flex",
      alignItems:"center",
      padding:"5px"}}>
          <Typography style={{ lineHeight: "24px", fontSize: "14px" }}>
            {"To: "}
          </Typography>
          <Box width="0.8rem"></Box>
          <input
            className={classes.number}
            type="number"
            min="1"
            max="5"
            value={hour2}
            onChange={handleHourChange2}
          ></input>
          :
          <input
            className={classes.number}
            type="number"
            min="0"
            max="59"
            value={minutes2}
            onChange={handleMinuteChange2}
          ></input>
          :
          <input
            className={classes.number}
            type="number"
            min="0"
            max="59"
            value={seconds2}
            onChange={handleSecondChange2}
          ></input>
          </Grid>
      </Grid>
       
        {/* <Box width="1.6rem" style={{border:"1px solid red"}} ></Box> */}
       
      </>
    );
  }

  return (
    <Box>
      <Box textAlign="start">
        <Typography className={classes.question}>
          {question.name}
        </Typography>
      </Box>
      <Box height="0.8rem"></Box>
      <Box display="flex" justifyContent="start">
        {element}
      </Box>
    </Box>
  );
};
export default Question;
