import { websocketUrl } from "api/globalaxios";
import { backgrounds } from "contexts/BackgroundContext";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange, useWebsocket } from "hooks";
import isEmpty from "lodash/isEmpty";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCase, getCases } from "slices/casesSlice";
import { createChatGroup, createRvChatGroup } from "slices/chatGroupsSlice";
import { getDispute } from "slices/disputeSlice";
import { getAllGroups } from "slices/groupSlice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getWindowDimension } from "utils/responsiveUtils";
import Chat from "./Chat";

// context for ws and container ref
export const containerContext = createContext(null);

const checkRv = (profileType) => {
  const profileArray = profileType.split("_");

  const isRv = profileArray[profileArray.length - 1] === "rv";

  return isRv;
};

export default function ChatContainer() {
  const dispatch = useDispatch();

  let { disputeId } = useParams();

  useBodyBackgroundChange(backgrounds.WHITE);

  const { groups, loading: groupLoading } = useSelector(
    (state) => state.groups
  );

  const { chatgroup, loading: chatGroupLoading } = useSelector(
    (state) => state.chatgroup
  );

  const { activeDispute, loading: disputeLoading } = useSelector(
    (state) => state.disputes
  );

  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const [showVC, setShowVC] = useState(false);
  const [vcType, setVcType] = useState("dyte");

  // selected group
  const [selected, setSelected] = useState(0);

  useDeepCompareEffect(() => {
    let selectedGroupIndex = groups.findIndex((group) => {
      return group.name === chatgroup.group_name;
    });

    if (selectedGroupIndex && selectedGroupIndex >= 0) {
      setSelected(selectedGroupIndex);
    }
  }, [chatgroup, groups]);

  // Chat messages container
  const messageContainer = React.useRef(null);

  const { connected, ws } = useWebsocket(websocketUrl, messageContainer);

  // responsive screen logic
  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);

  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };

  useEffect(() => {
    dispatch(getDispute(disputeId));

    dispatch(getCase(disputeId));
    dispatch(getCases());
    // dispatch(getProfile()).then((response) => {

    // }
    if (checkRv(profileType)) {
      dispatch(createRvChatGroup(disputeId)).then((resp) => {
        dispatch(getAllGroups(disputeId));
      });
    } else {
      dispatch(createChatGroup(disputeId)).then((resp) => {
        dispatch(getAllGroups(disputeId));
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hasLoaded = !groupLoading && !disputeLoading && !chatGroupLoading;

  return (
    <containerContext.Provider
      value={{ messageContainer, ws, showVC, setShowVC, vcType, setVcType }}
    >
      {hasLoaded && !isEmpty(groups) && connected ? (
        <Chat
          screenWidth={screenWidth}
          disputeId={activeDispute.id}
          showVC={showVC}
          chatgroup={chatgroup}
          groups={groups}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <CircularCenteredLoader />
      )}
    </containerContext.Provider>
  );
}
