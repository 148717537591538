import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import SingleDetail from "features/singleDispute/SingleDetail";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCase } from "slices/casesSlice";
import { DocGenerator } from "utils";

const renderDetails = ({
  questions,
  answers,
  addToogledAnswer,
  removeToogledAnswer,
}) => {
  return questions.map((question, index) => {
    let answer = answers.find((ans) => ans.question === question.id);
    return (
      <SingleDetail
        key={question.id}
        question={question}
        answer={answer}
        addToogledAnswer={addToogledAnswer}
        removeToogledAnswer={removeToogledAnswer}
      />
    );
  });
};
const useStyles = makeStyles((theme) => ({
  submitbtn: {
    fontSize: "18px",
    borderRadius: "2px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}));

const DetailsReview = ({
  activeDispute,
  activeCase,
  questions,
  answers,
  profile,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    if (toogledAnswers.length > 0) {
      alert("save all the answers first.");
    } else {
      let casePayload = {
        id: activeCase.id,
        case_status: "review_complete",
      };
      setLoading(true);
      await dispatch(updateCase(casePayload));
      setLoading(false);
    }
  };

  const [toogledAnswers, setToogledAnswers] = useState([]);
  const addToogledAnswer = (id) => {
    let temp = [...toogledAnswers];
    let index = temp.indexOf(id);
    if (index <= -1) temp.push(id);
    setToogledAnswers(temp);
  };
  const removeToogledAnswer = (id) => {
    let temp = [...toogledAnswers];
    let index = temp.indexOf(id);
    if (index > -1) temp.splice(index, 1);
    setToogledAnswers(temp);
  };

  return (
    <Grid container>
      <Grid item sm={10} md={10} style={{ margin: "0 auto" }}>
        {/* <Typography variant="h4">
          {" "}
          <FormattedMessage id="pages.review.review" />
        </Typography> */}
        {/* <Box height="2rem"></Box> */}
        <Box>
          {renderDetails({
            questions,
            answers,
            addToogledAnswer,
            removeToogledAnswer,
          })}
        </Box>
        <Box height="3rem"></Box>
        <Grid container justify="space-evenly" style={{ padding: "10px" }}>
          <Grid item md={5} sm={4} style={{ padding: "10px" }}>
            {/* <Box width="2rem"></Box> */}
            <Button
              variant="outlined"
              color="primary"
              className={classes.submitbtn}
              onClick={() => {
                DocGenerator(activeCase.dispute, questions[0].case_file_type);
              }}
            >
              {profile.profile_type == "general" ? (
                <FormattedMessage id="buttons.downloadgrievance" />
              ) : (
                <FormattedMessage id="buttons.downloadresponse" />
              )}
            </Button>
          </Grid>
          <Grid item md={5} sm={4} style={{ padding: "10px" }}>
            {/* <Box width="2rem"></Box> */}
            <DebouncedButton
              variant="contained"
              className={classes.submitbtn}
              style={{
                background: "#2F80ED",
                color: "white",
              }}
              onClickHandler={onClick}
              disabled={loading}
              loading={loading}
              value={<FormattedMessage id="buttons.proceed" />}
              debounceTime={1000}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // console.log(questions);
};

export default DetailsReview;
