import {
  Box,
  FormControl,
  Grid,
  Button,
  makeStyles,
  MenuItem,
  Select,
  Table,
  Typography,
} from "@material-ui/core";
import { blue, deepPurple, green, purple, red } from "@material-ui/core/colors";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PieChart from "./NewPieChart";
import "./Analytics.css"
import { DateRangePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import Chart from "react-google-charts";
import Rating from '@material-ui/lab/Rating';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format, subYears } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import 'react-nice-dates/build/style.css'

import {ComplianceReportDocxGenerator, 
  ComplianceReportPdfGenerator, 
  ComplianceCompanyReportDocxGenerator,
  ComplianceCompanyReportPdfGenerator,
  ComplianceSROReportDocxGenerator,
  ComplianceSROReportPdfGenerator,
} from "utils/apituils"
import Tabl from "./Table"
import arrowDownImage from "./down-arrow.svg"
import arrowUpImage from "./up-arrow.svg"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  createCompanyMap,
  createSROMap,
  createDataMapFiltered,
  getNoOfDisputesData,
  getTimeTaken,
} from "./NewAnalytics.utils";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "2rem",
    lineHeight: "3rem",
  },
  barGraphContainer: {
    width: "60%",
    marginTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pieContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rangeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  formControl: {
    minWidth: "10rem",
  },
  colorsValuesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

const colors = ['#3F51B5', '#D9D7D7', '#454545'];

export default function Analytics({
    start_Date,
    set_start,
    end_Date,
    set_end,
    Nodc,
    NodSRO,
    TimeTakenCom,
    TimeTakenSRO,
    TimeTakenCompanyandSRO,
    averageTimeCompany,
    averageTimeSRO,
    averageTimeCompanySRO
  }) {

  const Checkbox = props => (
    <input type="checkbox" style={{ margin: "15px" }} {...props} />
  )
  
  const [noOfDisputes, setNoOfDisputes] = useState(true)
  const handleNoOfDisputes = event => { setNoOfDisputes(event.target.checked) }

  const [action, setAction] = useState(true)
  const handleAction = event => { setAction(event.target.checked) }

  const [timeTaken, setTimeTaken] = useState(true)
  const handleTimeTaken = event => { setTimeTaken(event.target.checked) }

  const [feedback, setFeedback] = useState(true)
  const handleFeedback = event => { setFeedback(event.target.checked) }

  const [displayCompany, setdisplayCompany] = useState(false);
  const [countCompany, setcountCompany] = useState(0);

  const [displaySRO, setdisplaySRO] = useState(false);
  const [countSRO, setcountSRO] = useState(0);

  const [displayCompanySRO, setdisplayCompanySRO] = useState(false);
  const [countCompanySRO, setcountCompanySRO] = useState(0);
  const [rating, setrating] = useState(4);


  let arrActionsTaken = [
    ["Element", "value", { role: "style" }],
    ["Apology", 60, "#3F51B5"],
    ["Redaction", 12, "#454545"],
    ["Compensation", 34, "#D9D7D7"],
  ]

  var opti = {
    legend: { position: "none" },
    bar: { groupWidth: '95%' },
    width: "90%",
  }

  


  const toggleCompany = () => {
    let t = displayCompany;
    setdisplayCompany(!t);
  }

  const toggleSRO = () => {
    let t = displaySRO;
    setdisplaySRO(!t);
  }

  const toggleCompanySRO = () => {
    let t = displayCompanySRO;
    setdisplayCompanySRO(!t);
  }

  function searchHandler(event){
    
    

  }

  const ColorValues = ({ colors, data }) => {
    return (
      <div className="compnayLevelRightPnale">
        {data.map((singleData, index) => {
          return (
            <Box className="companyColor" display="flex" mb="0.7rem" key={singleData.name}>
              <Box
                height="1rem"
                width="1rem"
                style={{ backgroundColor: colors[index] }}
              >
              </Box>
              <Box width="0.3rem">
              </Box>
              <Typography>{singleData.name}</Typography>
              <Box className="countMargin">
                <br /><b>{singleData.value}</b>
              </Box>
            </Box>
          );
        })}
      </div>
    );
  };


  function Company() {
    if (displayCompany) {
      document.getElementById('icon1').src = arrowUpImage;
      return (
        <div >
          {
            noOfDisputes && <div class="outer short  small-width">
            <h4>Number of disputes</h4>
              <p>Total <b>{!isEmpty(Nodc) ? Nodc[0].value + Nodc[1].value : 0 }</b></p>
            <Box className="pie">
              <PieChart data={Nodc} colors={colors} />
            </Box>
            <Box className="piecolor">
              <ColorValues data={Nodc} colors={colors} />
            </Box>
          </div>
          }
         

          {
          timeTaken && 
          <div class="outer short" style={{ height: "32rem" }}>
            <div class="companyTimeTaken">
              <section>Time taken to close the grievance</section><br />
            Average time taken at Company level  <b>
            {isNaN(averageTimeCompany) ? 0 :`${averageTimeCompany} days`}
              </b>
            </div>
            <div className="space-btm my-custom-scrollbar" >
              <Tabl data={TimeTakenCom}/>
            </div>
            <div class="companyExpand">
              <p>EXPAND LIST</p>
            </div>
          </div>
          }
          {
          action && 
          <div class="outer short">
            <div class="companyTimeTaken">
              <section>Actions taken</section><br />
              Actions taken by the Grievance Redressal Officer
            </div>
            <div class="ActionsTaken">
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={arrActionsTaken}
                options={opti}
              />
            </div>
          </div>
          }
          {
          feedback && 
          <div class="outer short" style={{ height: "15rem" }}>
            <div class="companyTimeTaken">
              <section>Feedback</section><br />
              Average Satisfaction & feedback ratings from customers
            </div>
            <div>
              <div class="rating"><Rating value={rating} readOnly size="large" /></div>
            </div>
          </div>
          }

          <div class="buttons">
            <input type="submit" onClick={e=>{
              if(start_Date && end_Date){
                ComplianceCompanyReportPdfGenerator(start_Date, end_Date)
              }else{
                alert("Please Select Appropriate Dates");
              }}}
            value="Download .PDF" style={{ background: "#3F51B5", color: "white" }} />
            <input type="submit" onClick={e=>{
              if(start_Date && end_Date){
                ComplianceCompanyReportDocxGenerator(start_Date, end_Date)
              }else{
                alert("Please Select Appropriate Dates");
              }}}
             value="Download .DOCX" style={{ background: " #454545", color: "white" }} />
          </div>
        </div>
      )
    }
    else {
      if (countCompany != 0) {
        document.getElementById('icon1').src = arrowDownImage;
        return null;
      }else {
        setcountCompany(1);
        return null;
      }
    }
  }

  function SRO() {
    if (displaySRO) {
      document.getElementById('icon2').src = arrowUpImage;
      return (
        <div>
          {
            noOfDisputes && <div class="outer short  small-width">
            <h4>Number of grievances raised</h4>
              <p>Total <b>{!isEmpty(NodSRO) ? NodSRO[0].value + NodSRO[1].value : 0 }</b></p>
            <Box className="pie">
              <PieChart data={NodSRO} colors={colors} />
            </Box>
            <Box className="piecolor">
              <ColorValues data={NodSRO} colors={colors} />
            </Box>
          </div>
          }
          {/* {
          noOfDisputes && startDate && endDate ? 
            <DrawPieChartData numOfDisputes={NoOfDisputesSROData}/> : 
            <DrawPieChartData numOfDisputes={NodSRO}/> 
          } */}
          {
          timeTaken && 
          <div class="timeTakenCard">
            <div class="companyTimeTaken">
              <section>Time taken to close the grievance</section><br />
              Average time taken at SRO Level <b>
            {isNaN(averageTimeSRO) ? 0 :`${averageTimeSRO} days`}
            </b>
            </div>
            <div className="space-btm my-custom-scrollbar" >
              <Tabl data={TimeTakenSRO}/>
            </div>
            <div class="companyExpand">
              <p>EXPAND LIST</p>
            </div>
          </div>
          }
          {
          action && 
          <div class="outer short">
            <div class="companyTimeTaken">
              <section>Actions recommended</section><br />
              Actions recommended by SRO
            </div>
            <div class="ActionsTaken">
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={arrActionsTaken}
                options={opti}
              />
            </div>
          </div>
          }
          
          <div class="buttons">
            <input type="submit" onClick={e=>{
              if(start_Date && end_Date){
                ComplianceSROReportPdfGenerator(start_Date, end_Date)
              }else{
                alert("Please Select Appropriate Dates");
              }}}
            value="Download .PDF" style={{ background: "#3F51B5", color: "white" }} />
            <input type="submit" onClick={e=>{
              if(start_Date && end_Date){
                ComplianceSROReportDocxGenerator(start_Date, end_Date)
              }else{
                alert("Please setlect Dates");
              }            
              }
            }
             value="Download .DOCX" style={{ background: " #454545", color: "white" }} />
          </div>
        </div>
      )
    }
    else {
      if (countSRO != 0) {
        document.getElementById('icon2').src = arrowDownImage;
        return null;
      }
      else {
        setcountSRO(1);
        return null;
      }
    }
  }

  function CompanySRO() {
    if (displayCompanySRO) {
      document.getElementById('icon3').src = arrowUpImage;
      return (
        <div>
          {timeTaken && <div class="outer short" style={{ height: "32rem" }}>
            <div class="companyTimeTaken">
              <section>Time taken to close the grievance</section><br />
            Average time taken at both levels  <b>
              {isNaN(averageTimeCompanySRO) ? 0 :`${averageTimeCompanySRO} days`}
              </b>
            </div>
            <div 
            className="space-btm my-custom-scrollbar" 
            >
             <Tabl data={TimeTakenCompanyandSRO}/>
            </div>
            <div class="companyExpand">
              <p>EXPAND LIST</p>
            </div>
          </div>}
          
          <div class="buttons">
            <input type="submit" onClick={e=>{
              if(start_Date && end_Date){
                ComplianceReportPdfGenerator(start_Date, end_Date)
              }else{
                alert("Please Select Appropriate Dates");
              }}}
            value="Download .PDF" style={{ background: "#3F51B5", color: "white" }} />
            <input type="submit" onClick={e=>{
              if(start_Date && end_Date){
                ComplianceReportDocxGenerator(start_Date, end_Date)
              }else{
                alert("Please setlect Dates");
              }
              }
            }
             value="Download .DOCX" style={{ background: " #454545", color: "white" }} />
          </div>
        </div>
      )
    }
    else {
      if (countCompanySRO != 0) {
        document.getElementById('icon3').src = arrowDownImage;
        return null;
      }
      else {
        setcountCompanySRO(1);
        return null;
      }
    }
  }

  return (
    <div>
      <div class="outer outer-box">
        <div class="inner1">
          <b>Search analytics for:</b>
        </div>
        <div class="inner2" >
          <div class="inner21">
            <label>
              <Checkbox name="cht" checked={noOfDisputes} onChange={handleNoOfDisputes} />
              <span>Number of grievances</span>
            </label>
            <label>
              <Checkbox name="cht" checked={action} onChange={handleAction} />
              <span>Action taken/recommended</span>
            </label>
            <label>
              <Checkbox name="cht" checked={timeTaken} onChange={handleTimeTaken} />
              <span>Time taken</span>
            </label>
            <label>
              <Checkbox name="cht" checked={feedback} onChange={handleFeedback}
              />
              <span>Feedback</span>
            </label>
          </div>
          <div class="inner22">
            <div class="inner221">
              <div class="inner221main">
                <DateRangePicker
                  startDate={start_Date}
                  endDate={end_Date}
                  onStartDateChange={set_start}
                  onEndDateChange={set_end} 
                  maximumDate={new Date()}
                  minimumLength={1}
                  format='dd/MM/yyyy'
                >
                  {({ startDateInputProps, endDateInputProps, focus }) => (
                    <div className='date-range'>
                     <div class="date-stye"><span>Date</span></div> 
                     <div class="date-left">
                      <input {...startDateInputProps} placeholder='Start date'
                      />
                      -
                      <input {...endDateInputProps} placeholder='End date'
                      />
                      </div>
                      <div class="date-right">
                      <img src="/calender.png" alt="" class="calendericon" />
                      </div>
                    </div>
                  )}
                </DateRangePicker>
              </div>
            </div>
            
            <div className="inner222">
              <input type="submit" className="input1" onClick={
                () => {
                        set_start(); 
                        set_end();
                      }
                }
                value="CLEAR" />
              {/* <input type="submit" className="input1 input2" onClick={e => searchHandler(e)} value="SEARCH" /> */}
            </div>            
          </div>
        </div>
      </div>

      <div class="outer company outer-box1" onClick={toggleCompany}>
        <div class="companytext">
          <p>Company level</p>
        </div>
        <div class="companyicon">
          <span ><img id="icon1" src={arrowDownImage} /></span>
        </div>
      </div>
      <Company />

      <div class="outer company asdf outer-box1" onClick={toggleSRO}>
        <div class="companytext">
          <p>SRO level</p>
        </div>
        <div class="companyicon">
          <span ><img id="icon2" src={arrowDownImage} /></span>
        </div>
      </div>
      <SRO />
      
      <div class="outer company asdf bottomMargin outer-box1" onClick={toggleCompanySRO}>
        <div class="companytext">
          <p>Company & SRO </p>
        </div>
        <div class="companyicon">
          <span ><img id="icon3" src={arrowDownImage} /></span>
        </div>
      </div>
      <CompanySRO />

    </div>
  );
}