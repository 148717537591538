import instance from "api/globalaxios";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  CLAIMANT,
  MEDIATOR,
  RESPONDENT,
  SECRETARIAT,
} from "app/constants";
import { getDisputesCount } from "features/disputes/utils";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getCasesForPagination } from "slices/casesSlice";
import { getDisputesForPagination } from "slices/disputeSlice";
import DisputesList from "./DisputesList";
import isEmpty from "lodash/isEmpty";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

const getActiveAndResolved = (disputes) => {
  if (!disputes) {
    return 0;
  }
  let newDisputes = { active: [], resolved: [] };
  disputes.forEach((singleDispute) => {
    if (singleDispute.status === "complete") {
      newDisputes.resolved.push(singleDispute);
    } else {
      newDisputes.active.push(singleDispute);
    }
  });

  return newDisputes;
};

export default function DisputesListContainer() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { disputesType } = useParams();
  const pathArray = pathname.split("/");
  const role = pathArray[pathArray.length - 2];

  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(true);

  const [cases, setCases] = useState();
  const [disputes, setDisputes] = useState();
  const [respondent, setRespondent] = useState();
  const [claimant, setClaimant] = useState();
  const [totalpages, setTotalpages] = useState();

  const profile = useSelector((state) => state.profile.profile);

  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  React.useEffect(() => {
    setLoading(true);
    dispatch(getDisputesForPagination(currentPage, disputesType)).then(
      (res) => {
        console.log(res);
        let count = res.count;
        let t = parseInt(count / 100);
        let r = count % 100;
        let total = t + (r > 0 ? 1 : 0);
        console.log(total);
        setTotalpages(total);
        setDisputes(res.results);
        setLoading(false);
      }
    );
    // dispatch(getCasesForPagination(currentPage, disputesType)).then(
    //   (response) => {
    //     console.log(response);
    //     setCases(response.results);
    //   }
    // );
  }, [disputesType]);

  const getUserDataAnotherPage = (pageNo) => {
    setLoading(true);
    dispatch(getDisputesForPagination(pageNo, disputesType)).then((res) => {
      console.log(res);
      setDisputes(res.results);
      setLoading(false);
    });
    // dispatch(getCasesForPagination(pageNo, disputesType)).then((response) => {
    //   console.log(response);
    //   setCases(response.results);
    // });
  };

  // React.useEffect(() => {
  //   if (!isEmpty(cases) && !isEmpty(disputes)) {
  //     console.log("hereeeee");
  //     let { respondentDisputes, claimaintDisputes } = getDisputesCount(
  //       cases,
  //       disputes
  //     );
  //     setRespondent(respondentDisputes);
  //     setClaimant(claimaintDisputes);
  //     setLoading(false);
  //   }
  // }, [cases, disputes]);

  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === MEDIATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === SECRETARIAT;

  const formattedDisputes =
    role === ARBITRATOR ||
    role === MEDIATOR ||
    role === ARBITRATOR_RV ||
    role === SECRETARIAT
      ? getActiveAndResolved(disputes)
      : disputes;

  if (loading) {
    return <CircularCenteredLoader />;
  }
  return (
    <Fragment>
      <DisputesList
        profileType={profileType}
        disputes={disputes}
        // disputes={
        //   role === RESPONDENT
        //     ? respondent
        //     : role === CLAIMANT
        //     ? claimant
        //     : formattedDisputes
        // }
        user={profile.user}
        role={role}
        disputesType={disputesType}
        isProfessionalAccount={isProfessionalAccount}
        totalNoOfPages={totalpages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getUserDataAnotherPage={getUserDataAnotherPage}
      />
    </Fragment>
  );
}
