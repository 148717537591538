import React from "react";
import DisputesReceivedList from "./DisputesReceivedList";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useState } from "react";
import instance from "api/globalaxios";

export default function ReceivedDisputes({ setActiveDispute, setStep }) {
  localStorage.setItem("whichTabHomePage", 1);
  localStorage.setItem("whichTabDisputeDisplay", 1);
  return (
    <DisputesReceivedList
      setActiveDispute={setActiveDispute}
      setStep={setStep}
    />
  );
}
