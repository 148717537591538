import { Box, Button, IconButton, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EditIcon from "@material-ui/icons/Edit";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import FormattedMessage from "features/common/TranslatedMessage";
import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDispute, uploadDispute } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    padding: "2rem 0",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paper: {
    textAlign: "center",
    padding: "3rem 1rem",
  },
  heading: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#000",
  },
  iconContainer: {
    background: "rgba(112, 124, 151, 0.15)",
    display: "inline-block",
    borderRadius: "0.3rem",
  },
  icon: {
    color: "rgba(112, 124, 151, 0.7)",
    fontSize: "6rem",
  },
}));

export default function ArbitratorUpload({ dispute, award_type }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const onClickProceed = () => {
    dispatch(uploadDispute(files, dispute)).then((resp) =>
      dispatch(updateDispute({ id: dispute.id, status: "webnyay_upload" }))
    );
  };

  const shouldPreview = files.length > 0;

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.heading}>
          {award_type}
        </Typography>
        <Box height="1rem"></Box>
        <Box className={classes.iconContainer}>
          <IconButton
            disabled={shouldPreview}
            aria-label="delete"
            onClick={() => {
              !shouldPreview && setOpen(true);
            }}
          >
            {shouldPreview ? (
              <DescriptionOutlinedIcon className={classes.icon} />
            ) : (
              <PostAddOutlinedIcon className={classes.icon} />
            )}
          </IconButton>
        </Box>
        <Box height="1rem"></Box>
        {shouldPreview &&
          files.map((file) => {
            return (
              <Box>
                <p>{file.name}</p>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <EditIcon style={{ fontSize: "2.5rem" }} />
                </IconButton>
              </Box>
            );
          })}
      </Paper>
      <Box height="1.5rem"></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onClickProceed}
        disabled={files.length < 1}
      >
        <FormattedMessage id="buttons.proceed" />
      </Button>
      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={async (files) => {
          console.log("Files:", files);

          setFiles(files);

          setOpen(false);
        }}
        showPreviews={true}
        filesLimit="1"
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg", "image/png", "image/jpg",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />
    </Box>
  );
}
