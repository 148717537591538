import {
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DateTimePicker } from "@material-ui/pickers";
import { isAfter, isBefore } from "date-fns";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addCalenderEvent } from "slices/appointmentSlice";
import { getAllGroups } from "slices/groupSlice";
import { createGroupName } from "utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    minHeight: "10rem",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 220,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  greyColor: {
    color: theme.palette.common.grey1,
  },
}));

export default function AddEventForm({
  groups,
  eventInfo,
  disputeId,
  handleAddEventClose,
  disputes,
  groupName,
  routeState,
  sources,
}) {
  console.log('disputeId',disputeId)
  console.log('disputes',disputes)
  // console.log('groupName',groupName)

  const [dispute, setDispute] = useState()
  const [loading, setLoading] = useState(disputeId?true:false)

  const [btnloading, setBtnloading] = useState(false)

  console.log('dispute',dispute)

  React.useEffect(()=>{
    disputes.map((dispute=>{
      if(dispute.id == disputeId){
        setDispute(dispute);
        setLoading(false)
      }
    }))
  })


  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = React.useState("dpcgc_vc");
  const handleChangeRadio = (event) => {
    setType(event.target.value);
  };

  // state handler for group change
  const [selectedGroup, setSelectedGroup] = React.useState();
  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    console.log('event',event.target.value);
    if(event.target.value.split('_')[0].includes('dpcgcADRP-breakout')){
      setType('dpcgc_vc')
    }else{
      setType('zoom_vc')
    }
  };

  
  // console.log('eventInfo',eventInfo);

  // start date and end date picker state handlers
  const [startDate, setStartDate] = React.useState(eventInfo.start);
  const [endDate, setEndDate] = React.useState(eventInfo.end);

  // errors after validating the time slots
  const [customError, setCustomError] = React.useState("");

  //error produced by datepicker
  const [autoError, setAutoError] = React.useState("");

  const isValid = autoError.length === 0 && customError.length === 0;

  // check whether the selected time falls between already booked meetings
  const validateTime = (time) => {
    let errorCount = 0;
    sources[0].events.forEach((event) => {
      if (
        isAfter(time, Date.parse(event.meeting.start)) &&
        isBefore(time, Date.parse(event.meeting.end))
      ) {
        errorCount++;
      } else {
      }
    });
    if (errorCount > 0) {
      setCustomError("You already have an appointment");
    } else {
      setCustomError("");
    }
  };

  const [selectedDispute, setSelectedDispute] = React.useState(disputeId);

  const handleDisputeChange = (event) => {
    setSelectedDispute(event.target.value);
  };

  const createGroupNameHere = (groupName) => {
    let nameArray = groupName.split("_");
    let name = "";
    nameArray.slice(0, nameArray.length - 1).forEach((element) => {
      name = name + " " + element;
    });
    return name;
  };

  // onSubmit for schedule button
  const handleClickSchedule = async (e) => {
    // console.log(type)
    
    if(selectedDispute && selectedGroup){
    const groupName = createGroupNameHere(selectedGroup);
    let data = {
      dispute: selectedDispute,
      name: `${groupName} call`,
      group: groupName,
      start: startDate,
      end: endDate,
      platform: type
    };

    console.log('selected group',selectedGroup);
    console.log('type',type);
    setBtnloading(true)
    dispatch(addCalenderEvent(data)).then((res) => {
      handleAddEventClose();
      setBtnloading(false)
      routeState && history.goBack();
    });
  }
  else{
    alert("Please choose a grievance and hearing room")
  }
  };

  React.useEffect(() => {
    if (selectedDispute) {
      dispatch(getAllGroups(selectedDispute));
    }
  }, [selectedDispute]);

  function addThirtyMinutes(date){

    setStartDate(date);

    // console.log(date);

    var copiedDate = new Date(date);
    copiedDate.setMinutes(copiedDate.getMinutes()+30)
    copiedDate = new Date(copiedDate);
  
    // console.log("startDate: ", date)
    // console.log("startDate+30: ", copiedDate)

    setEndDate(copiedDate);
  }

  if(loading)
    return <CircularCenteredLoader />

  return (
    <div className={classes.paper}>
      <Typography variant="h5">Schedule a Hearing</Typography>
      <Box className={classes.container}>
        <Box height="3rem"></Box>

        <DateTimePicker
          fullWidth
          variant="inline"
          label="Start time"
          inputVariant="outlined"
          value={startDate}
          onChange={(event) => addThirtyMinutes(event)}
          // disabled={eventInfo.startStr}
          minDate={new Date()}
          minDateMessage="Please select slots atleast 5 mins after current time"
          disablePast={true}
          strictCompareDates={true}
          onAccept={(info) => {
            validateTime(info);
          }}
          onError={(error) => {
            // console.log(error);
            setAutoError(error);
          }}
        />

        <Box height="1.5rem"></Box>

        <DateTimePicker
          fullWidth
          variant="inline"
          label="End time"
          inputVariant="outlined"
          value={endDate}
          onChange={setEndDate}
          // disabled={eventInfo.endStr}
          disablePast={true}
          minDate={startDate}
          minDateMessage="Date Should be after start date"
          strictCompareDates={true}
          onAccept={(info) => {
            validateTime(info);
          }}
          onError={(error) => {
            setAutoError(error);
          }}
        />
        <Box height="1.5rem"></Box>
        {!disputeId ? (
          <DisputeSelect
            disputes={disputes}
            setSelectedDispute={setSelectedDispute}
            handleDisputeChange={handleDisputeChange}
            disabled={disputeId}
          />
        ) : (
          <TextField
            fullWidth
            disabled
            label="Grievance ID"
            defaultValue={dispute.custom_dispute_id}
            variant="outlined"
          />
        )}
        <Box height="1.5rem"></Box>
        <GroupSelect
            groups={groups}
            selectedGroup={selectedGroup}
            handleGroupChange={handleGroupChange}
            // disabled={groupName}
          />
         {/* {!groupName ? (
          <GroupSelect
            groups={groups}
            selectedGroup={selectedGroup}
            handleGroupChange={handleGroupChange}
            disabled={groupName}
          />
        ) : (
          <TextField
            fullWidth
            disabled
            label="Hearing Room"
            // defaultValue={groupName}
            defaultValue={"DPCGC_"+disputeId}
            variant="outlined"
          />
        )} */}
        <Box height="1.5rem"></Box>
        
        {selectedGroup && <>
        <Grid container justify="flex-start">
          <Grid item>
            <Typography style={{fontSize:"16px"}}>
              Scheduled via:
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column"  spacing={1}>
          
          {selectedGroup.split('_')[0].includes('dpcgcarbitration') ?
          <Grid item>
            <Box display="flex" style={{  }}>
              <input type="radio" id="zoom" name="type1" value="zoom_vc" onChange={handleChangeRadio} style={{ transform: "scale(1.5)", margin: "6px 3px 0px 6px" }} checked={type=="zoom_vc"}/>
              <Box width="0.6rem"></Box>
              <Box alignItems="start" style={{ textAlign: "left" }}>
                <Typography><label for="zoom" style={{ fontSize: "12px", color: "#454545", cursor: "pointer" }}>Zoom VC</label></Typography>
              </Box>
            </Box>
          </Grid>
          :
          <Grid item>
            <Box display="flex" style={{ }}>
              <input type="radio" id="dpcgc" name="type1" value="dpcgc_vc" onChange={handleChangeRadio} style={{ transform: "scale(1.5)", margin: "6px 3px 0px 6px" }} checked={type=="dpcgc_vc"}/>
              <Box width="0.6rem"></Box>
              <Box alignItems="start" style={{ textAlign: "left" }}>
                <Typography><label for="dpcgc" style={{ fontSize: "12px", color: "#454545", cursor: "pointer" }}>DPCGC VC</label></Typography>
              </Box>
            </Box>
          </Grid>
          }
        </Grid>
        </>
      }
        <Box height="1.5rem"></Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSchedule}
          disabled={!isValid || btnloading}
        >
          Schedule
        </Button>
        <Box height="1rem"></Box>
        {customError.length > 0 && (
          <Alert severity="error">{customError}</Alert>
        )}
      </Box>
    </div>
  );
}

const GroupSelect = ({
  groups,
  selectedGroup,
  handleGroupChange,
  disabled,
}) => {
  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      <InputLabel id="group-select-label">Hearing Room</InputLabel>
      <Select
        labelId="group-select-label"
        id="group-select"
        value={selectedGroup}
        onChange={handleGroupChange}
        label="Hearing Room"
      >
        {groups.map((group) => {
          let groupName = group.name.substr(0,10);      
          // console.log('groupname : ',groupName);
              
          if(groupName != "devdpcgcSu" && groupName != 'dpcgcSuppo'){
            if(groupName == "devdpcgcar" || groupName == 'dpcgcarbit'){
              groupName = "DPCGC";
            }
            else{
              groupName = "DPCGC breakout";
            }
            return (
              <MenuItem key={group._id} value={group.name}>
                {groupName}
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};

const DisputeSelect = ({
  disputes,
  selectedDispute,
  handleDisputeChange,
  disabled,
}) => {
  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      <InputLabel id="group-select-label">Grievance ID</InputLabel>
      <Select
        labelId="group-select-label"
        id="group-select"
        value={selectedDispute}
        onChange={handleDisputeChange}
        label="Grievance ID"
      >
        {disputes.map((dispute) => {
          return (
            <MenuItem key={dispute.id} value={dispute.id}>
              {dispute.custom_dispute_id} : {dispute.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
