import React from "react";

import {
  Box,
  Typography,
  makeStyles,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";

import { deleteMeeting } from "slices/appointmentSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    minHeight: "10rem",
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: '70%',
    },
    [theme.breakpoints.down("xs")]: {
      width: '80%',
    },
  },

  greyColor: {
    color: theme.palette.common.grey1,
  },
}));

export default function EventDisplay({
  eventDetails,
  handleEventDetailsClose,
  profileType,
  sources
}) {
  const classes = useStyles();

  console.log('eventDetails',eventDetails);
  console.log('sources',sources);
  
  

  const dispatch = useDispatch();

  const {
    title,
    extendedProps: {
      appointmentId,
      custom,
      dispute,
      meetingId,
      appointmentName,
      isPastEvent,
    },
  } = eventDetails.event["_def"];

  console.log('custom',custom);
  

  const onCancel = () => {
    dispatch(deleteMeeting(meetingId, dispute)).then(() => {
      handleEventDetailsClose();
    });
  };

    
    
  const [by, setBy] =React.useState()
  const [meetingRoom, setMeetingRoom] =React.useState()

  // let title2 = "" + title;
  // title2 = title2.replace("arbitration","dpcgc")

  // console.log(meetingId);
  // console.log(sources[0]["events"]); 
  React.useEffect(()=>{
    if(sources[0]["events"].length>0){ 
      sources[0]["events"].map(e => {
        if(e.meeting.id==meetingId){          
          let meet = e.meeting.group;
          console.log('meet',meet);
          
          if(meet == "arbitration" || meet == 'devdpcgcarbitration' || meet == 'dpcgcarbitration'){
            setMeetingRoom("DPCGC")
          }
          else if(meet == "ADRP-breakout" || meet == 'devdpcgcADRP-breakout' || meet == 'dpcgcADRP-breakout'){
            setMeetingRoom("DPCGC breakout")
          }
          else{
            setMeetingRoom(meet);
          }
          if(e.meeting.platform == "dpcgc_vc"){
            setBy("DPCGC VC")
          }
          else if(e.meeting.platform == "zoom_vc"){
            setBy("Zoom VC")
          }
        }
      });
    }
  },[]) 
 

  const data = [
    {
      title: "Grievance ID",
      value: custom.split('__')[1],
    },
    {
      title: "Meeting Room",
      // value: "DPCGC/DPCGC breakout",
      value : meetingRoom
    },
    {
      title: "By",
      // value: "DPCGC VC/Zoom VC",
      value: by ? by:"DPCGC VC" ,
    },
  ];

  return (
    <div className={classes.paper}>
      <Typography variant="h5">Event Details</Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="60%"
        m="0 auto"
      >
        {data.map((singleItem) => {
          return (
            <Info
              title={singleItem.title}
              value={singleItem.value}
              key={singleItem.title}
            />
          );
        })}


        {profileType === "secretariat" &&
          <Button
            disabled={isPastEvent}
            color="primary"
            variant="contained"
            onClick={onCancel}
          >
            {isPastEvent ? "Completed" : "Cancel Meeting"}
          </Button>
        }
        {(profileType !== "secretariat" && isPastEvent) &&
          <Button
            disabled={isPastEvent}
            color="primary"
            variant="contained"
            onClick={onCancel}
          >
            {"Completed"}
          </Button>
        }
      </Box>
    </div>
  );
}

const Info = ({ title, value }) => {
  return (
    <Box
      width="100%"
      m="1rem 0"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box>
        <Typography style={{ color: "#000" }} variant="h6">
          {title}
        </Typography>
      </Box>
      <Box height="0.3rem"></Box>
      <Box>
        <Typography variant="h6">{value}</Typography>
      </Box>
    </Box>
  );
};
