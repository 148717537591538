import { containerContext } from "features/Chat/ChatContainer";
import throttle from "lodash/throttle";
import React, { useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAnchorMessage } from "slices/chatSlice";
import { fetchMembers, setActive } from "slices/groupSlice";
import BackgroundProvider from "contexts/BackgroundContext";
import {
  fetchHistory,
  scrollToLast,
  scrollToMessage,
  subscribeToRoom,
  typingIndicatorSub,
  unsubscribeToRoom,
} from "utils/chatUtils";
import ChatBody from "./ChatBody";

// import isEmpty from "lodash/isEmpty";

const throttledFetchMembers = throttle(fetchMembers, 6000);

export default function ChatBodyContainer({
  selected,
  classes,
  rightDocOpen,
  smallScreen,
  setStep,
}) {

  // *****this code is taken from UploadContainer.js by manoj for testing*************
  const activeCase = useSelector((state) => state.cases.activeCase);
  const activeDispute = useSelector((state) => state.disputes.activeDispute);
  const profile = useSelector((state) => state.profile.profile);

  // if(activeDispute.name.split(" ")[3] == "Shemaroo" && activeCase.type == "claimant"){
  //   console.log("activeDispute:", activeDispute.name.split(" ")[3]);
  //   console.log("activeCase.type:", activeCase.type);
  // }

  // console.log("activeDispute:", activeDispute.name);
  // console.log("activeCase.type:", activeCase.type);
  // ***************************************************************************************
  const dispatch = useDispatch();
  const { ws, messageContainer, showVC, setShowVC } = useContext(
    containerContext
  );

  const { typing, messages, anchorMessage } = useSelector(
    (state) => state.chat,
    shallowEqual
  );

  
  const { groups, loading, members } = useSelector(
    (state) => state.groups,
    shallowEqual
    );
    const [newLoading, setNewLoading] = useState(true)

  let group = groups[selected];
  const prevGroup = useRef();

  useEffect(() => {
    dispatch(setActive(group._id))
    dispatch(throttledFetchMembers(group._id));
    prevGroup.current && unsubscribeToRoom(ws);
    fetchHistory(ws, group._id);
    anchorMessage
      ? scrollToMessage(messageContainer, anchorMessage, () => {
        dispatch(setAnchorMessage(null));
        })
        : scrollToLast(messageContainer);
    subscribeToRoom(ws, group._id);
    typingIndicatorSub(ws, group._id);
    prevGroup.current = group._id;
    setNewLoading(false)
  }, [group._id]);

  return (
    <React.Fragment>
      {!newLoading && Object.keys(group).length > 0 && (
        <BackgroundProvider>
        <ChatBody
        disputeName={activeDispute.name}
          rightDocOpen={rightDocOpen}
          group={group}
          classes={classes}
          messages={messages}
          members={members}
          typing={typing}
          messageContainer={messageContainer}
          smallScreen={smallScreen}
          setStep={setStep}
          showVC={showVC}
        />
        </BackgroundProvider>
      )}
    </React.Fragment>
  );
}
