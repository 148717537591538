import { Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { SECRETARIAT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  value: {
    // fontSize: 14,
    border: "none",
    padding: "0.5rem",
    fontSize: "15px",
    lineHeight: "19px",
    
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
}));

export default function DisputeRow({ dispute }) {
  const classes = useStyles();
  const history = useHistory();

  const data = [
    {
      title: <FormattedMessage id="dispute.disputeId" />,
      value: dispute.custom_dispute_id,
    },
    {
      title: <FormattedMessage id="dispute.title" />,
      value: dispute.name,
    },
    {
      title: <FormattedMessage id="dispute.mode" />,
      value: dispute.flow_type,
    },
    {
      title: <FormattedMessage id="dispute.startDate" />,
      value: formatDate(dispute.approved_date),
    },
  ];

  const onRowClick = () => {
    history.push(`/disputes/${dispute.id}`);
  };

  return (
    <React.Fragment>
      {/* <Box height="0.2rem"></Box> */}

      <Paper className={classes.paper} onClick={onRowClick}>
        <Grid container justify="flex-start">
          {data.map(({ title, value }) => {
            return (
              <Grid sm={3} xs={3} key={title}>
                <Box className={classes.contentContainer}>
                  <div>
                    {/* <Typography className={classes.title}>{title}</Typography> */}
                    {/* <Hidden smDown>
                      <Box height="1rem"></Box>
                    </Hidden> */}
                    <Typography className={classes.value}>
                      {value
                        ? value == "arbitration"
                          ? "Grievance"
                          : value
                        : "--"}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
      <Box height="0.6rem"></Box>
    </React.Fragment>
  );
}
