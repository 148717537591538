import React, { useState, useContext } from "react";
import {
  Box,
  IconButton,
  makeStyles,
  useTheme,
  Icon,
  LinearProgress,
  withStyles,
  Typography,
} from "@material-ui/core";

import { useDispatch } from "react-redux";
import { uploadFile } from "slices/groupSlice";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "2.5rem",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    background: theme.palette.secondPrimary,
  },
  closeIcon: {
    cursor: "pointer",
  },

  container: {
    borderTop: `0.2rem solid ${theme.palette.border}`,
    paddingTop: "0.5rem",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.mainGradient,
  },
}))(LinearProgress);

const formatTimer = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds - minutes * 60;

  return `${minutes}:${remainingSeconds}`;
};

export default function VoiceInputBox({
  handleCancelRecording,
  handleRecordingSend,
  seconds,
}) {
  const dispatch = useDispatch();

  const username = useSelector((state) => state.user.user.first_name);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex" className={classes.container}>
      <Box>
        <IconButton onClick={handleCancelRecording}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </Box>
      <Box flexGrow={9} display="flex" alignItems="center">
        <Box flexGrow={1}>
          <BorderLinearProgress />
        </Box>
        <Box width="0.5rem"></Box>
        <Typography>{formatTimer(seconds)}</Typography>
      </Box>
      <IconButton onClick={handleRecordingSend}>
        <Icon className={classes.icon}>send_Circle</Icon>
      </IconButton>
    </Box>
  );
}
