import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FolderIcon from "@material-ui/icons/Folder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { MEDIATION, RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { DocGenerator } from "utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import { updateDispute, uploadDispute } from "slices/disputeSlice";
import { useDispatch } from "react-redux";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import { downloadEvidence } from "slices/disputeSlice";
import instance from "api/globalaxios";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  topText: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
  disputeheading: {
    fontSize: "26px",
    lineHeight: "36px",
    paddingTop: "35px",
    paddingLeft: "35px",
    paddingRight: "600px"
  },
  text: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    // background:"grey"
  },
  blue: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#2F80ED",
    lineHeight: "24px",
    fontSize:"16px",
    cursor: "text",
    padding:"10px 10px 10px 0",
    "&:hover": {
      color: "#2F80ED",
    },
    [theme.breakpoints.down("xs")]:{
      fontSize: "14px",
    }
  },
  fonts: {
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    [theme.breakpoints.down("sm")]:{
      lineHeight: "20px",
    fontSize: "14px",
    }
  },
  greenbutton: {
    backgroundColor: "#6FCF97",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]:{
      padding: "2px 6px"
    }
  },
  yellowbutton: {
    backgroundColor: "#F2C94C",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]:{
      padding: "2px 6px"
    }
  },
  item: {
    '&:hover': {
      // cursor: 'pointer',
      background: "#EEEEEE"
    }
  },
  closedbtn: {
    background: "#E0E0E0",
    color: "white",
    '&:hover': {
      backgroundColor: "#E0E0E0",
    }
  },
  joinbtn:{
    background: "#2F80ED",
    fontSize: "20px",
    borderRadius: "2px",
    [theme.breakpoints.down("md")]:{
      fontSize:"16px",
    },
    [theme.breakpoints.down("xs")]:{
      fontSize:"14px",
    }
  },
  calenderBtn:{
    fontSize: "16px",
    color: "#4F4F4F",
    textTransform: "none",
    borderRadius: "2px",
    width: "100%",
    maxWidth:"100%",
    [theme.breakpoints.down("md")]:{
      fontSize:"16px",
    },
    [theme.breakpoints.down("xs")]:{
      fontSize:"14px",
    }
  },
  closed: {
    background: "#E0E0E0",
    '&:hover': {
      backgroundColor: "#E0E0E0",
    }
  }
}));
export default function DisputeDisplay({ activeDispute, activeCase, profile }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [hearingDate, setHearingDate] = React.useState("--");

  React.useEffect(() => {
    if (hearingDate == "--") {
      dispatch(getAllMeetings2());
    }
  }, [hearingDate])

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [decisionFiles, setdecisionFiles] = React.useState(activeDispute.all_docs);
  const [finalDecision, setFinalDecision] = React.useState();
  const [evidenceFiles, setevidenceFiles] = React.useState(activeDispute.all_evidence)

  const [isDisputeClosed, setIsDisputeClosed] = React.useState(activeDispute.status == "closed");

  React.useEffect(() => {
    decisionFiles.forEach((file) => {
      if (file.is_final) {
        setFinalDecision(file.id);
      }
    })
  }, [decisionFiles]);

  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };

  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };


  const getAllMeetings2 = () => async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/api/user-meetings-list/`,
      });
      // console.log(response.data);
      let data = response.data.appointments;
      let latestAppointment;
      data.forEach((appointment) => {
        if (appointment.meeting.dispute == activeDispute.id) {
          // console.log(appointment.meeting);
          if (latestAppointment) {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            let d2 = new Date(latestAppointment.meeting.start);
            if (d1 < d2 && d1 > d) {
              latestAppointment = appointment
            }
            else {

            }
          } else {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            if (d1 > d)
              latestAppointment = appointment
          }
        }
      })

      console.log("Latest hearing ::: ", latestAppointment);
      if (latestAppointment) {
        setHearingDate(format(new Date(latestAppointment.meeting.start), "d MMMM y hh:mm a"));
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };


  const renderFiles = (files, deleteFile) => {
    return files.length !== 0
      ? files.map((file, index) => {
        return (
          <Box textAlign="center" position="relative"
            key={index}
          // display="flex"
          // justifyContent="center"
          >
            <Box>
              <Typography style={{ wordBreak: "break-all", paddingTop: "20px" }} variant="subtitle2">{file.name}</Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })
      : null;
  };



  const UploadDialog = ({ open, setOpen, addFile }) => {
    const classes = useStyles();
    return (
      <Box textAlign="center" position="relative" className={isDisputeClosed ? classes.closed : classes.fonts}>
        <Box>
          <Box >
            <IconButton
              className={classes.icon}
              aria-label="delete"
              // color="primary"
              onClick={() => {
                if (!isDisputeClosed)
                  setOpen(true);
              }}
            >
              <AddCircleOutlineIcon className={classes.icon}
              />
            </IconButton>
          </Box>
        </Box>
        <DropzoneDialog
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            console.log("Files:", files);
            files.forEach((file) => {
              addFile(file);
            });
            setOpen(false);
          }}
          showPreviews={true}
          filesLimit="1"
          showFileNamesInPreview={true}
          acceptedFiles={[
            "image/jpeg", "image/png", "image/jpg",
            "application/msword",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </Box>
    );
  };

  const onClickProceed = () => {
    if (files.length !== 0) {
      dispatch(uploadDispute(files, activeDispute)).then((resp) =>
        dispatch(updateDispute({ id: activeDispute.id, status: "webnyay_upload" }))
      );
    }
  };

  return (
    <Container>
      <Box p="2rem 0">

        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper style={{ background: "none", border: "none", boxShadow: "none" }}><Button
              onClick={() => {
                // history.push("/disputes");
                history.goBack()
              }}
              size="large"
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button></Paper>
            <Paper style={{ padding: "3.5rem" }}>

              {(profile.profile_type == "general" || profile.profile_type == "arbitrator" || profile.profile_type == "guest_general" || profile.profile_type == "guest_arbitrator") &&
                <Box width="15rem" className={classes.text}>
                  <Typography style={{ fontSize: "28px", lineHeight: "36px" }}>{activeDispute.name}</Typography>
                  <Box height="1rem"></Box>
                  <Typography className={classes.blue} onClick={() => {
                    DocGenerator(activeDispute.id, "claimant");
                  }}
                    style={{ textDecorationLine: "underline", cursor: "pointer", fontWeight: "bold", fontSize: "16px" }}>VIEW GRIEVANCE</Typography>
                  <Box height="2rem"></Box>
                </Box>
              }
              {(profile.profile_type == "vendor" || profile.profile_type == "guest_company") && <>
                <Box width="15rem" className={classes.text}>
                  <Typography style={{ fontSize: "28px", lineHeight: "36px" }}>{activeDispute.name}</Typography>
                  <Box height="1rem"></Box>
                </Box>
                <Grid container direction="row">
                  <Grid item xs sm={3} md={3}>
                    <Typography className={classes.blue} onClick={() => {
                      DocGenerator(activeDispute.id, "claimant");
                    }}
                      style={{ textDecorationLine: "underline", cursor: "pointer", fontWeight: "bold", fontSize: "16px" }}>VIEW GRIEVANCE</Typography>
                  </Grid>
                  <Grid item xs sm={3} md={3}>
                    <Typography className={classes.blue} onClick={() => {
                      DocGenerator(activeDispute.id, RESPONDENT);
                    }}
                      style={{ textDecorationLine: "underline", cursor: "pointer", fontWeight: "bold", fontSize: "16px" }}>VIEW RESPONSE</Typography>
                  </Grid>
                </Grid>
                <Box height="2rem"></Box>
              </>
              }
              <Grid container className={classes.fonts}>
                <Grid item xs={12} md={6}>
                  <a className={classes.blue}>Grievance ID   </a>{activeDispute.custom_dispute_id}
                  <Box height="0.1rem"></Box>
                  <a className={classes.blue}>Hearing Date   </a>{isDisputeClosed ? "--" : hearingDate}
                  <Box height="0.1rem"></Box>
                  <a className={classes.blue}>Decision   </a>{finalDecision ? <u
                    style={{ cursor: "pointer", fontWeight: "bold", fontSize: "16px", fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`, color: "#2F80ED", lineHeight: "24px", }}
                    onClick={() => { downloadLegalDoc(finalDecision); }}
                  >VIEW</u> : "(In Progress)"}
                  <Box height="0.1rem"></Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="space-evenly">
                    <Grid item xs={12} sm={6} md={6} style={{padding:"10px"}}>
                      <Button
                        className={classes.calenderBtn}
                        variant="outlined"
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push("/events");
                        }}
                      >
                        <FormattedMessage id="buttons.calendar" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{padding:"10px"}}>
                      <Button
                        variant="outlined"
                        className={classes.calenderBtn}
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push(`/disputes/${activeDispute.id}/files`);
                        }}
                      >
                        <FormattedMessage id="common.files" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Box display="flex" justifyContent="center">
                <Button
                  style={{ margin: "0.5rem 0 0 0" }}
                  color="primary"
                  size="large"
                  startIcon={<VisibilityIcon />}
                  onClick={() => {
                    DocGenerator(activeDispute.id, "claimant");
                  }}
                >
                  <FormattedMessage id="dispute.statementOfClaim" />
                </Button>
              </Box>
              <Box display="flex" justifyContent="center">
                {activeDispute.flow_type !== MEDIATION && (
                  <Button
                    style={{ margin: "0 0 0.5rem 0" }}
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      DocGenerator(activeDispute.id, RESPONDENT);
                    }}
                  >
                    <FormattedMessage id="dispute.statementOfDefence" />
                  </Button>
                )}
              </Box> */}

            </Paper>
            <Box height="4rem"></Box>
            {activeDispute.status != "new" && activeDispute.status != "respondent_inprogress" && activeDispute.status != "respondent_complete" &&
              <Box display="flex" justifyContent="center">
                {isDisputeClosed ?
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.closedbtn}
                  // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px" }}
                  >
                    <FormattedMessage id="common.join" />
                  </Button>
                  :
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.joinbtn}
                    onClick={() => {
                      history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    <FormattedMessage id="common.join" />
                  </Button>
                }
              </Box>
            }
            <Box height="4rem"></Box>

            {/* {(temp) &&
            (profile.profile_type=="general")  && */}

            {/* {evidenceFiles.length > 0 && <> */}
            {<>
              <Box className={classes.fonts} style={{ padding: "20px", color: "#333333", lineHeight: "36px" }}>
                <Grid container justify="space-between" direction="row">
                  <Grid item sm={7}>
                    Document Name
                  </Grid>
                  <Grid item sm={2} xs={4}>
                    Submission
                  </Grid>
                </Grid>
              </Box>
              <Paper >
                {evidenceFiles.length > 0 &&
                  evidenceFiles.map((file) => {
                    return (
                      <Box className={classes.item}>
                        <Box className={classes.fonts} style={{ padding: "10px 20px", color: "#4F4F4F", lineHeight: "19px", cursor: "pointer" }} onClick={() => {
                          if (file.s3path)
                            downloadEvidence(file.id);
                          else
                            console.log("s3path is null");
                        }}>
                          <Grid container direction="row" justify="space-between">
                            <Grid item  sm={7} xs={4} style={{ overflow: "hidden", "text-overflow": "ellipsis", padding:"5px",
                                  alignSelf:"center" }}>
                              {file.name}
                            </Grid>
                            <Grid item sm={3} xs={4} style={{padding:"5px",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"}}>
                              {file.sent_by === "Complainant" ?
                                <button className={classes.greenbutton}>{file.sent_by}</button>
                                :
                                <button className={classes.yellowbutton}>{file.sent_by}</button>
                              }
                            </Grid>
                            <Grid item sm={2} xs={4} style={{ color: "#828282",padding:"5px",
                                display:"flex",
                                alignItems:"center" }}>
                              {formatDate(file.modified)}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Paper>
            </>
            }
            {(profile.profile_type == "arbitrator" || profile.profile_type == "guest_arbitrator") && <>
              <Box className={classes.fonts} style={{ padding: "20px", color: "#333333", lineHeight: "36px" }}>
                <Box height="3rem"></Box>
                <Grid container justify="space-between" direction="row">
                  <Grid item sm={7}>
                    Upload Decision
                  </Grid>
                  <Grid item sm={3}>
                    Upload Date
                  </Grid>
                </Grid>
              </Box>
              <Paper >
                {isDisputeClosed ?
                  <Box >
                    {decisionFiles.length > 0 &&
                      decisionFiles.map((file) => {
                        return (
                          <React.Fragment>
                            <Box style={{ padding: "10px 20px", color: "#4F4F4F", lineHeight: "19px" }} className={classes.closed}>
                              <Grid container direction="row" justify="space-between" className={classes.fonts}>
                                <Grid item xs={9} sm={9} style={{ overflow: "hidden", "text-overflow": "ellipsis" }}>
                                  {file.name}
                                </Grid>
                                <Grid item xs={3} sm={3} style={{ color: "#828282" }}>
                                  {formatDate(file.modified)}
                                </Grid>
                              </Grid>
                            </Box>
                          </React.Fragment>
                        )
                      })
                    }
                  </Box>
                  :
                  <Box style={{ cursor: "pointer" }}>
                    {decisionFiles.length > 0 &&
                      decisionFiles.map((file) => {
                        return (
                          <React.Fragment>
                            <Box style={{ padding: "10px 20px", color: "#4F4F4F", lineHeight: "19px" }} className={classes.item} onClick={() => {
                              if (file.s3path)
                                downloadLegalDoc(file.id);
                              else
                                console.log("s3path is null");
                            }}>
                              <Grid container direction="row" justify="space-between" className={classes.fonts}>
                                <Grid item xs={9} sm={9} style={{ overflow: "hidden", "text-overflow": "ellipsis" }}>
                                  {file.name}
                                </Grid>
                                <Grid item xs={3} sm={3} style={{ color: "#828282" }}>
                                  {formatDate(file.modified)}
                                </Grid>
                              </Grid>
                            </Box>
                          </React.Fragment>
                        )
                      })
                    }
                  </Box>
                }

                <Box className={isDisputeClosed ? classes.closed : classes.fonts} style={{ padding: "10px 20px", color: "#4F4F4F", lineHeight: "19px" }}>
                  <Grid container justify="center">
                    <Grid item>
                      {activeDispute.status != "new" && activeDispute.status != "respondent_inprogress" && activeDispute.status != "respondent_complete" &&
                        <Box>
                          {
                            files.length === 0 && (
                              <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />)
                          }
                          {renderFiles(files, deleteFile)}
                        </Box>
                      }
                    </Grid>
                  </Grid>
                </Box>

              </Paper>
              <Box height="3rem"></Box>

              <Box display="flex" justifyContent="center">
                {isDisputeClosed || (activeDispute.status == "new" || activeDispute.status == "respondent_inprogress" || activeDispute.status == "respondent_complete") ?
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.closedbtn}
                  // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px" }}
                  >
                    <FormattedMessage id="buttons.submit" />
                  </Button>

                  :
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.joinbtn}
                    onClick={onClickProceed}
                  >
                    <FormattedMessage id="buttons.submit" />
                  </Button>

                }
              </Box>
            </>
            }

            <Box height="3rem"></Box>
          </Grid>

        </Grid>
      </Box>
      <Box height="5rem"></Box>
    </Container>
  );
}
