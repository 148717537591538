import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  ARBITRATOR_COMPLETE,
  CLAIMANT_ADVOCATE_COMPLETE,
  RESPONDENT_ADVOCATE_COMPLETE,
  RESPONDENT_COMPLETE,
} from "app/constants";
import { parseISO } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import isEmpty from "lodash/isEmpty";
import matchSorter from "match-sorter";
import React from "react";

const getTitle = (rvStatus) => {
  switch (rvStatus) {
    case CLAIMANT_ADVOCATE_COMPLETE:
      return "Find your lawyer";
    case RESPONDENT_COMPLETE:
      return "Find Respondent";
    case RESPONDENT_ADVOCATE_COMPLETE:
      return "Find your lawyer";
    case ARBITRATOR_COMPLETE:
      return "Find Arbitrator";
  }
};

export default function UserVerifyForm({
  profileFetched,
  setNotEmpty,
  rvStatus,
  profiles,
}) {
  const onSubmit = async (e) => {
    setLoading(true);
    // let profile = await getProfileByPhonenumber(values.phone);
    if (isEmpty(selectedProfile)) {
      profileFetched({});
    } else {
      profileFetched({
        ...selectedProfile,
        birth_date: parseISO(selectedProfile.birth_date),
      });
      setNotEmpty();
    }
    setLoading(false);
  };

  const [loading, setLoading] = React.useState(false);
  const [selectedProfile, setSelectedProfile] = React.useState({});

  return (
    <Box p="2rem 0" textAlign="center">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={12} md={6}>
          <Box p="0rem 2rem">
            <Typography variant="h4" gutterBottom>
              {getTitle(rvStatus)}
            </Typography>

            <Autocomplete
              id="combo-box-demo"
              fullWidth
              autoComplete={true}
              options={profiles}
              onChange={(event, value) => {
                setSelectedProfile(value);
              }}
              filterOptions={(options, state) => {
                return matchSorter(options, state.inputValue, {
                  keys: [
                    (item) => `${item.user.first_name} ${item.user.last_name}`,
                    "phone_number",
                  ],
                });
              }}
              onInputChange={(event) => {}}
              getOptionLabel={(option) => option.user.first_name}
              renderOption={(option, state) => {
                return (
                  <React.Fragment>
                    {option.user.first_name} {option.user.last_name}
                    <Box display="inline-block" width="1rem"></Box>
                    <Typography variant="caption">
                      {option.phone_number}
                    </Typography>
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<FormattedMessage id="pages.intro.search" />}
                  variant="outlined"
                />
              )}
            />

            <Box height="2rem"></Box>

            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              onClick={onSubmit}
            >
              {isEmpty(selectedProfile) ? (
                <FormattedMessage id="buttons.create" />
              ) : (
                <FormattedMessage id="buttons.search" />
              )}
            </Button>
          </Box>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
}
