import { Box, Button, Grid } from "@material-ui/core";
import { backgrounds } from "contexts/BackgroundContext";
import { addHours, addMinutes, isAfter, isBefore } from "date-fns";
import Calender from "features/common/Calendar";
import CircularLoader from "features/common/CircularCenteredLoader";
import Modal from "features/common/Modal";
import AddEventForm from "features/globalCalendar/AddEventForm";
import EventDisplay from "features/globalCalendar/NewEventDisplay";
import { useBodyBackgroundChange } from "hooks";
import querystring from "query-string";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAllMeetings, getAllMeetingsOfUser } from "slices/appointmentSlice";
import { getDisputes } from "slices/disputeSlice";
import FormattedMessage from "features/common/TranslatedMessage";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const parseDate = (date) => {
  return new Date(date);
};

const selectAllow = (eventInfo) => {
  let currentDate = new Date();
  if (isAfter(eventInfo.start, currentDate)) {
    return isAfter(eventInfo.start, currentDate);
  } else {
    return false;
  }
  // return true;
};

const transformFunction = (eventData) => {
  // console.info(eventData);

  let {
    meeting: { start, end, name, dispute, id: meetingId },
    id: appointmentId,
    name: appointmentName,
    custom : custom
  } = eventData;

  let isPastEvent = isBefore(parseDate(start), new Date());

  let title = isPastEvent ? `${name} (past event)` : `${name}`;

  return {
    title,
    start: parseDate(start),
    end: parseDate(end),
    display: isPastEvent ? "block" : "auto",

    isPastEvent,
    dispute,
    meetingId,
    custom,
    appointmentId,
    appointmentName,
    backgroundColor: isPastEvent ? "grey" : "#3788d8",
    borderColor: isPastEvent ? "grey" : "#3788d8",
  };
};

export default function ViewCalenderContainer() {
  const location = useLocation();
  const history = useHistory();
  const arr = location.pathname.split("/");
  const userId = arr[4];
  // console.log(userId);

  useBodyBackgroundChange(backgrounds.WHITE);

  const { disputeId, group: groupName } = querystring.parse(location.search);
  const routeState = location.state;
  const [addEventOpen, setOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [eventInfo, setEventInfo] = React.useState({});
  const [eventDetails, setEventDetails] = React.useState({});
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  //   console.log("profile_type: ", profileType)

  //   console.log("location : ",location);
  //   console.log("disputeId : ",disputeId);
  //   console.log("groupName : ",groupName);

  const dispatch = useDispatch();

  const handleEventDetailsClose = () => {
    setDetailsOpen(false);
  };

  const handleAddEventClose = () => {
    setOpen(false);
  };

  const onSelect = (info) => {
    setEventInfo(info);
    setOpen(true);
  };

  const handleAddEventClick = () => {
    const currentDate = new Date();

    setEventInfo({
      start: addMinutes(currentDate, 5),
      end: addHours(currentDate, 1),
    });

    setOpen(true);
  };

  const onEventClick = (info) => {
    setEventDetails(info);
    setDetailsOpen(true);
  };

  React.useEffect(() => {
    dispatch(getAllMeetingsOfUser(userId));
    // dispatch(getAllMeetings());
    dispatch(getDisputes());
  }, []);

  const {
    appointments: { allUserMeetings, loading: appointmentLoading },
    disputes: { disputes, loading: disputeLoading },
    groups: { groups },
  } = useSelector((state) => state, shallowEqual);

  let sources = [{ events: allUserMeetings }];

  let loading = disputeLoading || appointmentLoading;

  const childProps = {
    transformFunction,
    sources,
    selectable: true,
    onSelect,
    onEventClick,
    selectAllow,
    handleAddEventClick,
  };

  if (loading) return <CircularLoader />;

  return (
    <React.Fragment>
      <Box height="0.8rem"></Box>
      <Grid container justify="center">
        <Grid sm={8} xs={8} item>
          <Grid container justify="flex-start">
            <Grid item>
              <Button
                onClick={() => {
                  // history.push("/disputes");
                  history.goBack()
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Calender {...childProps} />
      {/* {
        profileType === "secretariat" &&
        <Modal open={addEventOpen} handleClose={handleAddEventClose}>
        <AddEventForm
          groups={groups}
          eventInfo={eventInfo}
          disputeId={disputeId}
          handleAddEventClose={handleAddEventClose}
          disputes={disputes}
          groupName={groupName}
          routeState={routeState}
          sources={sources}
        />
      </Modal>
      } */}

      <Modal open={detailsOpen} handleClose={handleEventDetailsClose}>
        <EventDisplay
          eventDetails={eventDetails}
          handleEventDetailsClose={handleEventDetailsClose}
          profileType={profileType}
          sources={sources}
        />
      </Modal>
    </React.Fragment>
  );
}
