import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2rem 0",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paper: {
    textAlign: "center",
    padding: "3rem 1rem",
  },
  heading: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#000",
  },
  list: {
    textAlign: "start",

    "& li": {
      margin: "1rem 0",
      lineHeight: "1.5rem",
    },
  },
}));

export default function ({ onClickHandler }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.heading}>
          <FormattedMessage id="pages.arbitrator.conflict" />
        </Typography>
        <p>
          <FormattedMessage id="pages.arbitrator.static.confirm" />{" "}
        </p>

        <ol className={classes.list}>
          <li>
            <FormattedMessage id="pages.arbitrator.static.heading1" />
          </li>
          <li>
            <FormattedMessage id="pages.arbitrator.static.heading2" />
          </li>
          <li>
            {" "}
            <FormattedMessage id="pages.arbitrator.static.heading3" />
          </li>
          <li>
            <FormattedMessage id="pages.arbitrator.static.heading4" />
          </li>
        </ol>
        <Box height="1rem"></Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={(e) => onClickHandler()}
        >
          <FormattedMessage id="buttons.agree" />
        </Button>
      </Paper>
    </Box>
  );
}
