import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { format, parseISO } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import Question from "features/singleDispute/Question";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editAnswer, getAnswers } from "slices/answerSlice";
import pen from "images/pen.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    margin: "1rem",
  },
  question_heading: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
    letterSpacing: "0.00938em",
    [theme.breakpoints.down("md")]: { fontSize: "16px" },
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
    color: theme.palette.text.primary,
  },
  answerValues: {
    fontSize: "14px",
    lineHeight: "20px",
    padding: "10px 0",
    [theme.breakpoints.down("md")]: { fontSize: "14px" },
    [theme.breakpoints.down("sm")]: { fontSize: "12px" },
  },
}));

export default function SingleDetail({
  question,
  answer,
  addToogledAnswer,
  removeToogledAnswer,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeCase = useSelector((state) => state.cases.activeCase);
  const [editMode, toggleEditMode] = React.useState(false);
  // console.log(answer);
  const answerValues = new Map();
  answerValues.set(question.id, answer.value);

  const onSubmitAndCheck = () => {
    if (
      !question.optional &&
      question.type !== "boolean" &&
      question.type !== "date"
    ) {
      var returned_ans = checkMendatoryBoolean(question.id);
      if (typeof returned_ans === "undefined") {
        alert(
          "Please fill mandatory field => " +
            question.name.substr(0, 40) +
            "...."
        );
        return 0;
      } else if (returned_ans.trim() === "") {
        alert(
          "Please fill mandatory field => " +
            question.name.substr(0, 40) +
            "...."
        );
        return 0;
      }
    }
    if (
      !question.optional &&
      question.mandatory_boolean &&
      question.type === "boolean"
    ) {
      var returned_ans = checkMendatoryBoolean(question.id);
      if (!returned_ans) {
        alert("Please Agree with: " + question.name.substr(0, 40) + "....");
        return 0;
      }
    }
    if (!question.optional && question.type === "time") {
      let arr = answerValues.get(question.id).split(":");
      if (arr[0] > 5 || arr[1] > 59 || arr[2] > 59) {
        alert("Please enter time less than 5:59:59");
        return 0;
      }
    }
    onSubmit();
  };

  const onSubmit = async (e) => {
    await dispatch(
      editAnswer({
        question: question.id,
        answerId: answer.id,
        answer:
          answerValues.get(question.id) !== null
            ? answerValues.get(question.id).toString()
            : null,
        caseId: activeCase.id,
      })
    );
    toggleEditMode(false);
    removeToogledAnswer(answer.id);
    await dispatch(getAnswers(activeCase.id));
  };

  let valueToDisplay = answer.value;
  if (question.type === "boolean") {
    if (answer.value === "true") {
      valueToDisplay = "Yes";
    } else {
      valueToDisplay = "No";
    }
  } else if (question.type === "date") {
    if (answer.value === "" || answer.value === null) {
      valueToDisplay = null;
    } else {
      valueToDisplay = format(parseISO(answer.value), "dd-MM-yyyy");
    }
  }

  function checkMendatoryBoolean(questionId) {
    for (var entry of answerValues.entries()) {
      if (entry[0] === questionId) {
        return entry[1];
      }
    }
  }

  return (
    <Grid container>
      {/* <Grid item xs sm md={2}></Grid> */}
      <Grid item xs={12} sm={12} md={12}>
        <Paper className={classes.paper}>
          {!editMode ? (
            <React.Fragment>
              <Box>
                <Grid container justify="flex-end" alignItems="flex-start">
                  <Grid item xs={11}>
                    <Box textAlign="start">
                      <Typography
                        variant="h6"
                        className={classes.question_heading}
                      >
                        {question.name}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.answerValues}
                      >
                        {valueToDisplay}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        toggleEditMode(true);
                        addToogledAnswer(answer.id);
                      }}
                    >
                      <img src={pen}></img>
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container justify="flex-end" alignItems="flex-start">
                <Grid item xs={11}>
                  <Box textAlign="start">
                    <Question question={question} answerValues={answerValues} />
                  </Box>
                </Grid>

                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      toggleEditMode(true);
                      addToogledAnswer(answer.id);
                    }}
                  >
                    <img src={pen}></img>
                  </IconButton>
                </Grid>
              </Grid>
              <Box height="1rem"></Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={onSubmitAndCheck}
              >
                <FormattedMessage id="buttons.save" />
              </Button>
              {/* <Question question={question} answerValues={answerValues} />
              <Box height="1rem"></Box>
              <Button variant="contained" color="primary" onClick={onSubmit}>
                <FormattedMessage id="buttons.save" />
              </Button> */}
            </React.Fragment>
          )}
        </Paper>
      </Grid>

      <Grid item xs sm md={2}></Grid>
    </Grid>
  );
}
