import { backgrounds, useBackground } from "contexts/BackgroundContext";
import includes from "lodash/includes";
import React from "react";

const getColor = (color) => {
  if (includes(backgrounds, color)) {
    return color;
  }
  return backgrounds.DEFAULT;
};

export default function useBodyBackgroundChange(color) {
  const { setBackground } = useBackground();

  React.useEffect(() => {
    const colorToSet = getColor(color);
    setBackground(colorToSet);
    return () => {
      setBackground(backgrounds.DEFAULT);
    };
  }, []);
}
