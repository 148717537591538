import React from "react";
import DisputesList from "./DisputesList";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useState } from "react";
import instance from "api/globalaxios";

export default function RegisteredDisputes() {
  localStorage.setItem("whichTabHomePage", 2);
  localStorage.setItem("whichTabDisputeDisplay", 1);

  return <DisputesList />;
}
