import React, { PureComponent } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          data={this.props.data}
          margin={{
            top: 10,
            right: 30,
            left: 15,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date">
            <Label
              offset={0}
              value="Date of grievance creation"
              position="bottom"
            />
          </XAxis>
          <YAxis>
            <Label
              offset={0}
              value="Grievance count"
              angle="-90"
              position="insideLeft"
            />
          </YAxis>
          <Tooltip />
          <Area
            type="monotone"
            dataKey="GrievanceCount"
            stroke="#8884d8"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
