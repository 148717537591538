import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { errorInterceptor } from "api/globalaxios";
import { IntlProviderWrapper } from "contexts/IntlContext";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import theme from "theme";
import App from "./App";
import store from "./app/store";
import "./index.css";

errorInterceptor(store);

const Main = () => (
  <React.StrictMode>
    <Provider store={store}>
      <IntlProviderWrapper>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </IntlProviderWrapper>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<Main />, document.getElementById("root"));
