import {
  RESPONDENT,
  CLAIMANT_COMPLETE,
  CLAIMANT_ADVOCATE_COMPLETE,
  RESPONDENT_COMPLETE,
} from "app/constants";

export default (disputes, cases) => {
  let filteredDisputes = [];
  cases.forEach((cse) => {
    let currentDispute = disputes.find((dispute) => dispute.id === cse.dispute);
    if (
      cse.type === RESPONDENT &&
      (currentDispute.rv_status === CLAIMANT_ADVOCATE_COMPLETE ||
        currentDispute.rv_status === CLAIMANT_COMPLETE ||
        currentDispute.rv_status === RESPONDENT_COMPLETE)
    ) {
    } else {
      filteredDisputes.push(currentDispute);
    }
  });
  return filteredDisputes;
};
