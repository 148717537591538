import {
  ADJUDICATION,
  ARBITRATION,
  CLAIMANT_COMPLETE,
  MEDIATION,
  NEGOTIATION,
  NEW,
  RESPONDENT_COMPLETE,
} from "app/constants";
import { compareAsc, isAfter, isBefore, subMonths, subWeeks } from "date-fns";
import { formatDate } from "utils";
import { format, subYears } from "date-fns";
export const calculatePercentage = (count, totalCount) => {
  return (count / totalCount) * 100;
};

const getLabel = (value) => {
  switch (value) {
    case NEW:
      return "Dispute Created";
    case RESPONDENT_COMPLETE:
      return "Respondent Details Filled";
    case CLAIMANT_COMPLETE:
      return "Claimant Details filled";
    case "terms_complete":
      return "Terms Complete";
    case "price_agreement_complete":
      return "Price Agreement Complete";
    case "professional_upload":
      return "Professional Upload";
    case "webnyay_upload":
      return "Webnyay Upload";
    case "award_upload":
      return "Award Upload";
    case "complete":
      return "Complete";
    case "paid":
      return "Paid";
    case "closed":
      return "Closed";
    case MEDIATION:
      return "Mediation";
    case NEGOTIATION:
      return "Negotiation";
    case ARBITRATION:
      return "Arbitration";
    case ADJUDICATION:
      return "Adjudication";
    case "":
      return "None";
  }
};

export const getlenNoOfDisputesData = (data) => {
  let len = 0;
  data.forEach((item) => {
    len += item['value'];
  });
  return len;
}

export const getAverageTimeTaken = (data) =>{
  let total = data.length;
  let sum  = 0;
  data.forEach((item)=>{    
    let str = item['TIME'];
    sum = sum + parseInt(str.split(" ")[0])
  })
  return parseInt(sum/total);
}

export const getTimeTaken = (data) => {
  let result = [];
  data.forEach((dispute) => {
    if (dispute.rv_status == true) {
      let c = {};
      c['DISPUTE ID'] = dispute.id;
      c['DISPUTE NAME'] = dispute.name;
      const date1 = new Date(dispute.created);
      const date2 = new Date(dispute.modified);
      const days = Math.ceil(Math.abs(date2 - date1)/ (1000 * 60 * 60 * 24));      
      c['TIME'] = '' + days + ' day';
      result.push(c);
    }
  });
  return result;
}

export const getNoOfDisputesData = (data) => {
  let result = [{ value: 0, name: "Resolved" }, { value: 0, name: "Pending" }]
  data.forEach((dispute) => {
    if (dispute.rv_status == true) {
      result[0]['value'] = result[0]['value'] + 1;
    }
    else {
      result[1]['value'] = result[1]['value'] + 1;
    }
  });
  return result;
}


export const getTimeTakenSRO = (data) => {
  let result = [];
  data.forEach((dispute) => {
    if (dispute.status == 'complete') {
      let c = {};
      c['DISPUTE ID'] = dispute.id;
      c['DISPUTE NAME'] = dispute.name;
      const date1 = new Date(dispute.created);
      const date2 = new Date(dispute.modified);
      const days = Math.ceil(Math.abs(date2 - date1)/ (1000 * 60 * 60 * 24));      
      c['TIME'] = '' + days + ' day';
      result.push(c);
    }
  });
  return result;
}

export const getNoOfDisputesDataSRO = (data) => {
  let result = [{ value: 0, name: "Resolved" }, { value: 0, name: "Pending" }]
  data.forEach((dispute) => {
    if (dispute.status == 'complete') {
      result[0]['value'] = result[0]['value'] + 1;
    }
    else {
      result[1]['value'] = result[1]['value'] + 1;
    }
  });
  return result;
}


export const filterByRange = (data, startDate, endDate) => {
  let result = [];
  endDate.setDate(endDate.getDate() + 1)
  data.forEach((dispute) => {
    let disputeDate = new Date(dispute.created);
    if (disputeDate >= startDate && disputeDate <= endDate) {
      let c = {};
      c['id'] = dispute.id;
      c['created'] = dispute.created;
      c['modified'] = dispute.modified;
      c['name'] = dispute.name;
      c['order_date'] = dispute.order_date;
      c['description'] = dispute.description;
      c['status'] = dispute.status;
      c['rv_status'] = dispute.rv_status;
      c['flow_type'] = dispute.flow_type;
      c['company_product'] = dispute.company_product;
      c['case_manager'] = dispute.case_manager;
      c['doc'] = dispute.doc;
      c['professionals'] = dispute.professionals;
      result.push(c);
    }
  });
  endDate.setDate(endDate.getDate() - 1)
  return result;
}

export const createSROMap = (disputes) => {
  let SROMap = [];
  disputes.forEach((dispute) => {
    if (dispute.professionals.length > 0) {
      let c = {};
      c['id'] = dispute.id;
      c['created'] = dispute.created;
      c['modified'] = dispute.modified;
      c['name'] = dispute.name;
      c['order_date'] = dispute.order_date;
      c['description'] = dispute.description;
      c['status'] = dispute.status;
      c['rv_status'] = dispute.rv_status;
      c['flow_type'] = dispute.flow_type;
      c['company_product'] = dispute.company_product;
      c['case_manager'] = dispute.case_manager;
      c['doc'] = dispute.doc;
      c['professionals'] = dispute.professionals;
      SROMap.push(c);
    }
  });
  return SROMap;
}

export const createDataMapFiltered = (disputes, startDate, endDate) => {
  let SROMap = [];

  disputes.forEach((dispute) => {
    if (dispute.professionals.length > 0 && 
      format(new Date(dispute.created), "yyyy-MM-dd") >= startDate &&
      format(new Date(dispute.created), "yyyy-MM-dd") < endDate ){
      let c = {};
      c['id'] = dispute.id;
      c['created'] = dispute.created;
      c['modified'] = dispute.modified;
      c['name'] = dispute.name;
      c['order_date'] = dispute.order_date;
      c['description'] = dispute.description;
      c['status'] = dispute.status;
      c['rv_status'] = dispute.rv_status;
      c['flow_type'] = dispute.flow_type;
      c['company_product'] = dispute.company_product;
      c['case_manager'] = dispute.case_manager;
      c['doc'] = dispute.doc;
      c['professionals'] = dispute.professionals;
      SROMap.push(c);
    }
  });
  return SROMap;
}

export const createCompanyMap = (disputes) => {
  let companyMap = [];
  disputes.forEach((dispute) => {
    if (dispute.professionals.length == 0) {
      let c = {};
      c['id'] = dispute.id;
      c['created'] = dispute.created;
      c['modified'] = dispute.modified;
      c['name'] = dispute.name;
      c['order_date'] = dispute.order_date;
      c['description'] = dispute.description;
      c['status'] = dispute.status;
      c['rv_status'] = dispute.rv_status;
      c['flow_type'] = dispute.flow_type;
      c['company_product'] = dispute.company_product;
      c['case_manager'] = dispute.case_manager;
      c['doc'] = dispute.doc;
      c['professionals'] = dispute.professionals;
      companyMap.push(c);
    }
  });
  return companyMap;
}

export const createDisputeStatusMap = (disputes) => {
  const statusMap = new Map();

  disputes.forEach((dispute) => {
    if (statusMap.has(dispute.status)) {
      const currentKey = statusMap.get(dispute.status);
      statusMap.set(dispute.status, currentKey + 1);
    } else {
      statusMap.set(dispute.status, 1);
    }
  });

  return statusMap;
};

export const createDisputeModeMap = (disputes) => {
  const modeMap = new Map();

  disputes.forEach((dispute) => {
    // console.log(dispute.flow_type);
    if (modeMap.has(dispute.flow_type)) {
      const currentKey = modeMap.get(dispute.flow_type);
      modeMap.set(dispute.flow_type, currentKey + 1);
    } else {
      modeMap.set(dispute.flow_type, 1);
    }
  });

  return modeMap;
};

export const createDisputeCountMap = (disputes) => {
  const countMap = new Map();

  disputes.forEach((dispute) => {
    const dateKey = formatDate(dispute.order_date);

    if (countMap.has(dateKey)) {
      const currentKey = countMap.get(dateKey);
      countMap.set(dateKey, currentKey + 1);
    } else {
      countMap.set(dateKey, 1);
    }
  });

  return countMap;
};

export const transformDisputeStatusPieData = (statusMap, totalCount) => {
  const percentageMap = new Map();

  const percentageArray = [];

  statusMap.forEach((value, key) => {
    percentageMap.set(key, calculatePercentage(value, totalCount));
    percentageArray.push({ value: Math.floor(value), name: getLabel(key) });
  });

  return percentageArray;
};

export const transformDisputeToBarData = (countMap) => {
  const barGraphData = [];

  countMap.forEach((value, key) => {
    barGraphData.push({
      date: key,
      disputeCount: value,
    });
  });

  return barGraphData.sort((a, b) => {
    return compareAsc(Date.parse(a.date), Date.parse(b.date));
  });
};

const timeValues = ["week", "month", "quarter", "half", "all"];

const getProperTimeValue = (time) => {
  if (timeValues.includes(time)) {
    return time;
  } else {
    return "week";
  }
};

export const evaluatePastTime = (time) => {
  const currentDate = new Date();

  switch (getProperTimeValue(time)) {
    case "week":
      return subWeeks(currentDate, 1);
    case "month":
      return subMonths(currentDate, 1);
    case "quarter":
      return subMonths(currentDate, 3);
    case "half":
      return subMonths(currentDate, 6);
    case "all":
      return null;
  }
};

export const filterDispute = (disputes, time) => {
  const fromDate = evaluatePastTime(time);
  const nowDate = new Date();
  let filteredDisputes;
  if (fromDate) {
    filteredDisputes = disputes.filter((dispute) => {
      const parsedDate = new Date(dispute.order_date);

      return isAfter(parsedDate, fromDate) && isBefore(parsedDate, nowDate);
    });
  }

  return fromDate ? filteredDisputes : disputes;
};
