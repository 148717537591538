import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import {
  ADJUDICATION,
  ARBITRATION,
  MEDIATION,
  RESPONDENT_COMPLETE,
  RESPONDENT_INPROGRESS,
  GRIEVANCE_REDRESSAL,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import isEmpty from "lodash/isEmpty";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { updateCase } from "slices/casesSlice";
import { updateDispute } from "slices/disputeSlice";
import { getCompanyType } from "slices/profileSlice";
import { shallowEqual, useSelector } from "react-redux";
import FullPageSpinner from "features/common/FullPageSpinner";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    maxWidth: "29rem",
  },
  radioHeading: {
    fontSize: "28px",
    lineHeight: "36px",
    // textTransform: "capitalize",
  },
  radioDetails: {
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
  },
}));

const options = [ARBITRATION, MEDIATION, ADJUDICATION, GRIEVANCE_REDRESSAL];

const getOptionDetails = (option) => {
  const details = {
    0: <FormattedMessage id="methods.arbitration.details" />,
    1: <FormattedMessage id="methods.mediation.details" />,
    2: <FormattedMessage id="methods.adjudication.details" />,
    3: <FormattedMessage id="methods.grievance_redressal.details"/>,
  };
  return details[option];
};

const RadioCard = ({ option, selectedValue, handleChange }) => {
  const detail = getOptionDetails(option);
  const classes = useStyles();
  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Box display="flex" alignItems="flex-start">
          <Radio
            checked={selectedValue === options[option]}
            onChange={handleChange}
            value={options[option]}
            color="primary"
          />
          <Box width="0.8rem"></Box>
          <Box>
            <Typography
              variant="subtitle1"
              color="primary"
              className={classes.radioHeading}
            >
              {options[option]}
            </Typography>
            {/* <Box height="1.5rem"></Box> */}
            {/* <Typography variant="subtitle2" style={{ textTransform: "none" }}>
              {detail}
            </Typography> */}
          </Box>
        </Box>
      </Paper>
      <Box width="1rem"></Box>
    </Fragment>
  );
};

export default function RespondentFlowSelection({activeDispute, activeCase, flows, }) {
  const [selectedValue, setSelectedValue] = React.useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  console.log("flows:", flows);

  const [loading, setLoading] = React.useState(true);
  const [companyType, setcompanyType] = React.useState([]);

  React.useEffect(() => {
    getCompanyType().then((data) => {
      setcompanyType(data);
      setLoading(false);
    });
  }, []);

  console.log("loading:", loading);
  console.log("Now The company type is:", companyType);

  let renderedOptions = null;

  if (loading) return <FullPageSpinner />;

  if(companyType === "nbf" || companyType === "ott"){
    renderedOptions = isEmpty(flows) ? [GRIEVANCE_REDRESSAL] 
      : options.filter((option) => {
                                    let hasFlow = false;
                                    flows.forEach((flow) => {
                                      if (flow.flow === option) {
                                        hasFlow = true;
                                      }
                                    });
                                    return hasFlow;
                                  });
  } else {
    renderedOptions = isEmpty(flows) ? [ARBITRATION, MEDIATION] 
      : options.filter((option) => {
                                  let hasFlow = false;
                                  flows.forEach((flow) => {
                                    if (flow.flow === option) {
                                      hasFlow = true;
                                    }
                                  });
                                  return hasFlow;
                                });
  }

  return (
    <Container>
      <Box height="3rem"></Box>
      {/* <Box textAlign="center" width="70%" margin="0 auto">
        <Typography variant="h3" style={{ lineHeight: "3rem" }}>
          <FormattedMessage id="pages.waitingScreens.respondentChoice.question" />
        </Typography>
      </Box> */}
      <Box height="3rem"></Box>
      <Box display="flex" justifyContent="center">
        {renderedOptions.map((option, index) => {
          return (
            <RadioCard
              option={options.indexOf(option)}
              selectedValue={selectedValue}
              handleChange={handleChange}
              key={index}
            />
          );
        })}
      </Box>
      <Box height="2rem"></Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          onClick={(e) => {
            dispatch(updateCase({ proposed_flow: selectedValue, id: activeCase.id }))
              .then((response) => {
                let disputePayload = {
                  id: activeDispute.id,
                  status: RESPONDENT_COMPLETE,
                  flow_type: selectedValue,
                };

                if (selectedValue === GRIEVANCE_REDRESSAL){
                  disputePayload.status = RESPONDENT_INPROGRESS;
                  disputePayload.flow_type = ARBITRATION;
                } else if ( selectedValue === ARBITRATION || selectedValue === ADJUDICATION) {
                  disputePayload.status = RESPONDENT_INPROGRESS;
                  disputePayload.flow_type = selectedValue;
                }

                return dispatch(updateDispute(disputePayload));
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          color="primary"
          style={{background:"#2F80ED",color:"white",fontSize:"20px",borderRadius:"2px"}}
          disabled={isEmpty(selectedValue)}
        >
          <FormattedMessage id="buttons.proceed" />
        </Button>
      </Box>
      <Box height="2rem"></Box>
    </Container>
  );
}
