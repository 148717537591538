import * as Yup from "yup";
import { format, subYears } from "date-fns";

export const DisputeRvSchema = Yup.object().shape({
  email: Yup.string().required().email("Invalid email"),
  phone: Yup.string()
    .required()
    .matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    }),
  affirmation: Yup.boolean().oneOf([true], "Please check the given field"),
});
