import {
  ADVOCATE_RV,
  ARBITRATOR_RV,
  GENERAL_RV,
  SECRETARIAT,
} from "app/constants";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useDisputes } from "hooks";
import isEmpty from "lodash/isEmpty";
import React, { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import Disputes from "./DisputesRv";

export default function DisputesContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.profile, shallowEqual);
  const user = useSelector((state) => state.user.user);

  let {
    profile: { profile_type },
  } = profile;
  const isRV =
    profile_type === GENERAL_RV ||
    profile_type === ADVOCATE_RV ||
    profile_type === ARBITRATOR_RV;

  let { cases, disputes, respondent, claimant, loading } = useDisputes(isRV);

  let childProps = {
    cases,
    disputes,
    profile,
    user,
    respondentDisputes: respondent,
    claimaintDisputes: claimant,
    isRV,
  };

  useDeepCompareEffect(() => {
    if (
      (!isEmpty(profile) && profile.profile.profile_type === ARBITRATOR_RV) ||
      profile.profile.profile_type === SECRETARIAT
    ) {
      history.push(`/disputes/${profile.profile.profile_type}/active`);
    }
  }, [profile]);

  return (
    <Fragment>
      {!isEmpty(profile) &&
      profile.profile.profile_type !== ARBITRATOR_RV &&
      !loading ? (
        <Disputes {...childProps} />
      ) : (
        <CircularCenteredLoader />
      )}
    </Fragment>
  );

  return;
}
