import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "60%",
    textAlign: "start",
    margin: "3rem auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      textAlign: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button": {
      padding: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: 345,
  },
  media: {
    height: 140,
  },
  button: {
    padding: "1rem 3rem",
    fontSize: "1.2rem",
    transition: "400ms all",
    "&:hover": {
      borderRadius: "5rem",
    },
  },
}));
