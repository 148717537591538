import React from "react";
import FileViewer from "react-file-viewer";
// import File from "images/SampleSpec.docx";
import File from "images/360photo.jpg";

import mime from "mime";
import { Box, Paper } from "@material-ui/core";

// const CustomErrorComponent = (props) => {
//   console.log(props);
//   return <div></div>;
// };
const type = mime.getExtension(mime.getType(File));

export default () => {
  return (
    <Box
      height="50rem"
      overflow="hidden"
      p="1rem 0"
      width="60%"
      m="2rem auto"
      boxShadow={1}
    >
      <FileViewer
        fileType={type}
        filePath={File}
        onError={(err) => {
          console.log(err);
        }}
      />
    </Box>
  );
};
