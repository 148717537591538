import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  
  container: {
    width: "60%",
    textAlign: "start",
    margin: "6rem auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      textAlign: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize:"20px",
    color: "#EEEEEE",
    marginTop: "2rem",
    "& button": {
      padding: "0.7rem 1.3rem",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "1.5rem",
    },
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: 345,
  },
  media: {
    height: 140,
  },
  button: {
    padding: "1rem 3rem",
    fontSize: "1.2rem",
    transition: "400ms all",
    "&:hover": {
      borderRadius: "5rem",
    },
  },
  name:{
    fontSize:"48px",
    [theme.breakpoints.down("sm")]: {
      fontSize:"30px"
    },
  },
  newdisputebtn:{
    fontSize: "20px",
    align: "centre",
    color: "#4F4F4F",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize:"14px"
    },
  },
  calenderbtn:{
    fontSize: "20px",
    color: "#4F4F4F",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize:"14px"
    },
  }
}));
