import { Box, RadioGroup, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 30px",
    [theme.breakpoints.down("sm")]:{
    padding: "10px 5px",
    }
  },
  question: {
    fontSize: "20px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]:{
      fontSize: "18px",
      }
  },
}));

export default function Question({ question, answer, answerValues }) {
  if (answer) {
    answerValues.has(question.id)
      ? console.log()
      : answerValues.set(question.id, answer.answer);
  } else {
    answerValues.has(question.id)
      ? console.log()
      : answerValues.set(question.id, "");
  }
  console.log("question : ", question);
  console.log("answer : ", answer);
  console.log("answerValues ::::: ", answerValues);

  const classes = useStyles();

  const radioName = Math.floor(Math.random() * 1000000);

  const options = ["true", "false"];

  const [value, setValue] = React.useState(
    answer ? (answer.answer == true ? "true" : "false") : ""
  );
  const handleChangeRadio = (event) => {
    setValue(event.target.value);
    console.log("event : ", event.target.value);
    console.log("event typeof : ", typeof event.target.value);

    if (event.target.value == "true") {
      answerValues.set(question.id, true);
      console.log(true);
    } else {
      answerValues.set(question.id, false);
      console.log(false);
    }
  };
  return (
    <Box className={classes.root}>
      <Typography className={classes.question}>{question.name}</Typography>
      <RadioGroup
      // name={radioName}
      // value={value} //onChange={handleChangeRadio}
      >
        <Box display="flex" flexDirection="column" alignItems="start">
          {options.map((val, index) => (
            <Box display="flex" style={{ margin: "0.5rem 0" }}>
              <input
                type="radio"
                id={index}
                // name={index}
                value={val}
                checked={value == val ? true : false}
                onChange={handleChangeRadio}
                style={{ transform: "scale(1.5)", margin: "6px 3px 0px 6px",cursor: "pointer", }}
              />
              <Box width="0.6rem"></Box>
              <Box alignItems="start" style={{ textAlign: "left" }}>
                <Typography>
                  <label
                    // for={index}
                    style={{
                      fontSize: "16px",
                      color: "#454545",
                      // cursor: "pointer",
                    }}
                  >
                    {val}
                  </label>
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </RadioGroup>
    </Box>
  );
}
