import React from "react";
import Form from "./IntroForm";

export default function introrv({ profile, user }) {
  return (
    <div>
      <Form profile={profile} user={user} />
    </div>
  );
}
