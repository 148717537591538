import { makeStyles } from "@material-ui/core";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  MEDIATOR,
  SECRETARIAT
} from "app/constants";
import classNames from "classnames";
import { useBackground } from "contexts/BackgroundContext";
import Analytics from "features/Analytics";
import Chat from "features/Chat";
import EndZoomMeeting from "features/Chat/EndZoomMeeting";
import ForgotPassword from "features/ForgotPassword";
import ForgotPasswordResult from "features/ForgotPasswordResult";
import ResetPassword from "features/ResetPassword";
import ResetPasswordResult from "features/ResetPasswordResult";
import Disputes from "features/disputes";
import DisputesList from "features/disputesList";
import DisputesRv from "features/disputesRv";
import Fallback from "features/fallBack";
import FileViewer from "features/fileView";
import Files from "features/files";
import DpcgcFooter from "features/footer/DpcgcFooter";
import GlobalCalendar from "features/globalCalendar";
import ViewCalenderContainer from "features/globalCalendar/ViewCalender";
import Header from "features/header";
import Intro from "features/intro";
import IntroRv from "features/intro_rv";
import LinkedinCallback from "features/linkedinCallback";
import Login from "features/login";
import NotFound from "features/notFound";
import Profile from "features/profile";
import Popup from "features/profile/Popup";
import Signup from "features/signup";
import SingleDispute from "features/singleDispute";
import SingleDisputeRv from "features/singleDisputeRv";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { isLoggedIn } from "slices/loginSlice";

const useStyles = makeStyles((theme) => {
  return {
    lightBlue: {
      backgroundColor: theme.palette.common.lightBlue,
    },
    white: {
      backgroundColor: "white",
    },
    default: {
      backgroundColor: theme.palette.common.white,
    },
  };
});

function Routes() {
  const classes = useStyles();

  const {
    profile: { profile_type: profileType },
  } = useSelector((state) => state.profile, shallowEqual);

  const { background } = useBackground();

  function renderSpecific() {
      return (
        <GuestRoute path="/login" exact>
          <Login />
        </GuestRoute>
      );
    
  }

  return (
    <React.Fragment>
      <div className={classNames("content", { [classes[background]]: true })}>
        <Header position="fixed" />

        <Switch>
          {conditionalDashboard(profileType)}

          {disputesListRoutes(profileType)}

          {conditionalDispute(profileType)}

          {conditionalIntro(profileType)}

          {viewCalender(profileType)}

          <Route path="/linkedin-callback" exact>
            <LinkedinCallback />
          </Route>

          <PrivateRoute path="/profile" exact>
            <Profile />
          </PrivateRoute>

          <PrivateRoute path="/events" exact>
            <GlobalCalendar />
          </PrivateRoute>

          <PrivateRoute path="/analytics" exact>
            <Analytics profile={profileType} />
          </PrivateRoute>

          <PrivateRoute path="/fileview" exact>
            <FileViewer />
          </PrivateRoute>

          <PrivateRoute path="/disputes/:disputeId/chat" exact>
            <Chat />
          </PrivateRoute>

          <PrivateRoute path="/disputes/:disputeId/end-zoom-meeting" exact>
            <EndZoomMeeting />
          </PrivateRoute>

          <PrivateRoute path="/disputes/:disputeId/files" exact>
            <Files />
          </PrivateRoute>

          {renderSpecific()}

          <GuestRoute path="/forgot-password" exact>
            <ForgotPassword />
          </GuestRoute>

          <GuestRoute path="/reset-password" exact>
            <ResetPassword />
          </GuestRoute>

          <GuestRoute path="/forgot-password-confirm" exact>
            <ForgotPasswordResult />
          </GuestRoute>

          <GuestRoute path="/reset-password-confirm" exact>
            <ResetPasswordResult />
          </GuestRoute>

          <GuestRoute path="/signup" exact>
            <Signup />
          </GuestRoute>

          <Route path="/fallback" exact>
            <Fallback />
          </Route>
          <Route path="/popup" exact>
            <Popup />
          </Route>
          <Route
            path="/contact"
            exact
            component={() => {
              window.location.href = "https://www.webnyay.in/contact";
              return null;
            }}
          />

          <PrivateRoute path="/" exact>
            <Redirect
              to={{
                pathname: "/disputes",
              }}
            />
          </PrivateRoute>
          <Route path="/*">
            <NotFound />
          </Route>
        </Switch>
      </div>

      {/* <Footer /> */}
      <DpcgcFooter />
    </React.Fragment>
  );
}

function viewCalender(profileType) {
  if (profileType === "secretariat") {
    return (
      <PrivateRoute path={`/disputes/:disputeId/events/:profileId`} exact>
        <ViewCalenderContainer />
      </PrivateRoute>
    );
  }
}

function disputesListRoutes(profileType) {
  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === MEDIATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === SECRETARIAT ||
    profileType === "guest_arbitrator";
  if (isProfessionalAccount) {
    return (
      <PrivateRoute path={`/disputes/${profileType}/:disputesType`} exact>
        <DisputesList />
      </PrivateRoute>
    );
  } else {
    const types = ["respondent", "claimant"];
    return types.map((type, index) => {
      return (
        <PrivateRoute
          key={index}
          path={`/disputes/${type}/:disputesType`}
          exact
        >
          <DisputesList />
        </PrivateRoute>
      );
    });
  }
}

function GuestRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() === "" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/disputes",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() !== "" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function conditionalDispute(profileType = "_default") {
  if (profileType) {
    const list = profileType.split("_");
    const isRvProfile = list[list.length - 1] === "rv";
    return (
      <PrivateRoute path="/disputes/:disputeId" exact>
        {isRvProfile ? <SingleDisputeRv /> : <SingleDispute />}
      </PrivateRoute>
    );
  }
}

function conditionalDashboard(profileType = "_default") {
  if (profileType) {
    const list = profileType.split("_");
    const isRvProfile = list[list.length - 1] === "rv";
    let Dashboard;

    if (isRvProfile) {
      Dashboard = DisputesRv;
    } else {
      Dashboard = Disputes;
    }

    return (
      <PrivateRoute path="/disputes" exact>
        <Dashboard />
      </PrivateRoute>
    );
  }
}

function conditionalIntro(profileType = "_default") {
  if (profileType) {
    const list = profileType.split("_");
    const isRvProfile = list[list.length - 1] === "rv";
    return (
      <PrivateRoute path="/intro" exact>
        {isRvProfile ? <IntroRv /> : <Intro />}
      </PrivateRoute>
    );
  }
}

export default Routes;
