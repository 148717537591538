import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button, Divider, Paper, RadioGroup } from "@material-ui/core";
import { updateDispute } from "slices/disputeSlice";
import { Backdrop, Fade } from "@material-ui/core";

import { useDispatch } from "react-redux";

import instance from "api/globalaxios";
import { Grid } from "@material-ui/core";
import { ComplianceReportGenerator } from "utils/apituils";
import { Switch } from "react-router-dom";
import Question from "./Question";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
// import EditProfileRow from "./EditProfileRow";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]:{
      width:"350px"
    },
    [theme.breakpoints.down("xs")]:{
      width:"240px"
    }
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
}));

export default function QuestionModal({
  openmodal,
  handleCloseModal,
  activeDispute,
  setOpenmodal3,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState();
  const answerValues = new Map();
  const [loading, setLoading] = useState(true);

  // console.log("answerValues -----> ", answerValues);

  function setAnswersAPI(answerValues) {
    return async (dispatch) => {
      try {
        const response = await instance.post(
          `/api/closedisputeanswer/${activeDispute.id}/`,
          [
            {
              dispute: activeDispute.id,
              answer: answerValues.get(2) == true ? 1 : 0,
              question: 2,
            },
            {
              dispute: activeDispute.id,
              answer: answerValues.get(1) == true ? 1 : 0,
              question: 1,
            },
            {
              dispute: activeDispute.id,
              answer: answerValues.get(3) == true ? 1 : 0,
              question: 3,
            },
          ]
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const OnSibmit = () => {
    console.log("answerValues -----> ", answerValues);
    if (
      !(
        (answerValues.get(1) == true || answerValues.get(1) == false) &&
        (answerValues.get(2) == true || answerValues.get(2) == false) &&
        (answerValues.get(3) == true || answerValues.get(3) == false)
      )
    ) {
      console.log(answerValues.get(1));
      console.log(answerValues.get(2));
      console.log(answerValues.get(3));
      alert("Choose one option in every question");
    } else {
      dispatch(setAnswersAPI(answerValues));
      setOpenmodal3(false);
    }
  };

  function getQuestions() {
    return async (dispatch) => {
      try {
        const response = await instance.get("/closedisputequestion");
        setQuestions(response.data.results);
        return response.data.results;
      } catch (error) {
        console.log(error);
      }
    };
  }

  function getAnswers(ques) {
    return async (dispatch) => {
      try {
        const response = await instance.get(
          `/api/closedisputeanswer/${activeDispute.id}/`
        );
        setAnswers(response.data);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  React.useEffect(() => {
    dispatch(getQuestions()).then((ques) => {
      dispatch(getAnswers(ques)).then(() => {
        setLoading(false);
      });
    });
  }, []);

  if (loading) return <CircularCenteredLoader />;
  return (
    <Modal
      open={openmodal}
      onClose={handleCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus
    >
      <Fade in={openmodal}>
        <div className={classes.paper}>
          <Typography className={classes.editprofile}>
            Report Details
          </Typography>
          <Box height="1.5rem"></Box>
          {/* <Button
            onClick={(e) => {
              ComplianceReportGenerator();
            }}
          >
            download
          </Button> */}
          <Question
            question={questions[0]}
            answer={
              answers.length > 0
                ? answers.find((answer) => answer.question === questions[0].id)
                : null
            }
            answerValues={answerValues}
          />
          <Question
            question={questions[1]}
            answer={
              answers.length > 0
                ? answers.find((answer) => answer.question === questions[1].id)
                : null
            }
            answerValues={answerValues}
          />
          <Question
            question={questions[2]}
            answer={
              answers.length > 0
                ? answers.find((answer) => answer.question === questions[2].id)
                : null
            }
            answerValues={answerValues}
          />
          <Box height="2rem" />
          <Button variant="contained" color="primary" onClick={OnSibmit} style={{fontSize:"16px"}}>
            SUBMIT
          </Button>
        </div>
      </Fade>
    </Modal>
  );
}
