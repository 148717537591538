import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import classNames from "classnames";

const SingleItem = ({ isSelected, data, classes, setSelected, index }) => {
  return (
    <Box
      onClick={() => {
        setSelected(index);
      }}
      className={classNames(classes.item, { [classes.selected]: isSelected })}
    >
      {data}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  itemBox:{
    display:"flex",
    flexDirection:"column",
    [theme.breakpoints.down("xs")]:{
      flexDirection:"row",
    }
  },
  item: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.4rem",
    lineHeight: "1.8rem",
    padding: "1rem 0.8rem",
    cursor: "pointer",
    height:"fit-content",
    whiteSpace:"nowrap"
  },
  selected: {
    background: "#E5E5E5",
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("xs")]:{
      borderLeft:"none",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    }
  },
}));

export default function ProfileMenu({ selected, setSelected, data }) {
  const classes = useStyles();

  return (
    <Box className={classes.itemBox}>
      {data.map((singleItem, index) => {
        return (
          <SingleItem
            classes={classes}
            data={singleItem}
            key={index}
            isSelected={selected === index}
            setSelected={setSelected}
            index={index}
          />
        );
      })}
    </Box>
  );
}
