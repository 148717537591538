import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

import { updateCase } from "./casesSlice";

export const initialState = {
  loading: false,
  hasErrors: false,
  answers: [],
};

const answerSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.answers = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { setProgress, setSucessfull, setError } = answerSlice.actions;
export default answerSlice.reducer;

export function getAnswers(caseid) {
  console.log("In Answers fetch");
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: "/api/answer/",
        params: {
          case__id: caseid,
        },
      });

      console.log(response.data);
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message)
      // console.log(error.request.response)
      dispatch(setError);
    }
  };
}

export function createAnswers({ createAnswerPayload, casePayload }) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/api/answer/",
        data: createAnswerPayload,
      });
      console.log(response.data);
      dispatch(setSucessfull(response.data));
      dispatch(updateCase(casePayload));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function editAnswer({ question, answerId, answer, caseId }) {
  console.log(question, answerId, answer, caseId);
  return async (dispatch) => {
    try {
      let response = await instance({
        method: "put",
        url: `/api/answer/${answerId}/`,
        data: { question, value: answer, case: caseId },
      });
      console.log(response.data);
      // dispatch(setSucessfull(response.data));
    } catch (error) {
      console.log(error.request.status);
      console.log(error.message);
      // console.log(error.request.response);
    }
  };
}
