import instance from "../api/globalaxios";
import fileDownload from "js-file-download";
import { chatUrl, chatInstance } from "api/globalaxios";
import { format, subYears } from "date-fns";

export const downloadDoc = (url) => {
  console.log(url);
  // window.open(`${chatUrl}${url}`, "_blank");
  window.location.href = `${chatUrl}${url}`;
  
  // chatInstance({
  //   method: "get",
  //   url,
  //   // responseType: "blob",
  // }).then((response) => {
  //   console.log(response);
  //   fileDownload(response.data, `chat_doc.doc`);
  // });
};

export function ComplianceReportGenerator(
  start_Date,
  end_Date,
  range,
  disputes
) {
  if (start_Date && end_Date) {
    // not use range
  } else {
    if (range == "week") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 6);
    } else if (range == "month") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 30);
    } else if (range == "quarter") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 89);
    } else if (range == "half") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 179);
    } else {
      start_Date = new Date(disputes[disputes.length - 1].created);
      start_Date.setHours(0, 0, 0, 0);
      end_Date = new Date(disputes[0].created);
      end_Date.setHours(0, 0, 0, 0);
    }
  }
  instance({
    method: "get",
    url: "/generatecompliancereportdpcgc/" + format(start_Date, "dd-MM-yyyy") + "/" + format(end_Date, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_${format(start_Date, "yyyy-MM-dd")}_to_${format(
        end_Date,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function ComplianceSROReportPdfGenerator(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatecompliancesroreport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_SRO_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function ComplianceSROReportDocxGenerator(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url: "/generatecompliancesroreport/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_SRO_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function ComplianceCompanyReportPdfGenerator(startDate, endDate) {
  instance({
    method: "get",
    url:
      "/generatecompliancecompanyreport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_company_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function ComplianceCompanyReportDocxGenerator(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url:
      "/generatecompliancecompanyreport/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_company_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function ComplianceReportPdfGenerator(startDate, endDate) {
  instance({
    method: "get",
    url: "/generatecompliancereport/2/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.pdf`
    );
  });
}

export function ComplianceReportDocxGenerator(startDate, endDate) {
  console.log(startDate);
  console.log(endDate);
  instance({
    method: "get",
    url: "/generatecompliancereport/1/" + format(startDate, "dd-MM-yyyy") + "/" + format(endDate, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `ComplianceReport_${format(startDate, "yyyy-MM-dd")}_to_${format(
        endDate,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}

export function DocGenerator(dispute_id, profile_type) {
  instance({
    method: "get",
    url: "/generatedoc/" + dispute_id + "/" + profile_type + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}_${profile_type}.pdf`);
  });
}

export function LegalDocGet(dispute_id) {
  instance({
    method: "get",
    url: "/renderdoc/" + dispute_id + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, `dispute_${dispute_id}.doc`);
  });
}

export function getOne2OneMeeting(id, profile_type) {
  if (profile_type === "claimant" || profile_type === "respondent") {
    return startMeeting(id + "_one2one");
  }
  return null;
}

export function getCommonMeeting(id) {
  return startMeeting(id + "_common");
}

function startMeeting(roomName) {
  return "webnyay_" + roomName;
}

export function Level2ReportGenerator(start_Date, end_Date, range, disputes) {
  if (start_Date && end_Date) {
    // not use range
  } else {
    if (range == "week") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 6);
    } else if (range == "month") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 30);
    } else if (range == "quarter") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 89);
    } else if (range == "half") {
      end_Date = new Date();
      end_Date.setHours(0, 0, 0, 0);
      start_Date = new Date(end_Date);
      start_Date.setDate(start_Date.getDate() - 179);
    } else {
      start_Date = new Date(disputes[disputes.length - 1].created);
      start_Date.setHours(0, 0, 0, 0);
      end_Date = new Date(disputes[0].created);
      end_Date.setHours(0, 0, 0, 0);
    }
  }
  instance({
    method: "get",
    url: "/generatelevel2report/1/" + format(start_Date, "dd-MM-yyyy") + "/" + format(end_Date, "dd-MM-yyyy") + "/",
    responseType: "blob",
  }).then((response) => {
    fileDownload(
      response.data,
      `Level2Report_${format(start_Date, "yyyy-MM-dd")}_to_${format(
        end_Date,
        "yyyy-MM-dd"
      )}.docx`
    );
  });
}
