import React, { useEffect, useState } from "react";
import { getDisputes } from "slices/disputeSlice";
import { getCases } from "slices/casesSlice";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getDisputesCount, getRvDisputesCount } from "features/disputes/utils";
import isEmpty from "lodash/isEmpty";

export default function useDisputes(isRV) {
  const dispatch = useDispatch();
  const [respondent, setRespondentDisputes] = useState({
    active: [],
    resolved: [],
  });
  const [claimant, setClaimantDisputes] = useState({
    active: [],
    resolved: [],
  });

  const { cases, loading: caseLoading } = useSelector(
    (state) => state.cases,
    shallowEqual
  );
  const { disputes, loading: disputeLoading } = useSelector(
    (state) => state.disputes,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDisputes());
    dispatch(getCases());
  }, []);

  useEffect(() => {
    if (!isEmpty(cases) && !isEmpty(disputes)) {
      let { respondentDisputes, claimaintDisputes } = getDisputesCount(
        cases,
        disputes
      );

      setRespondentDisputes(respondentDisputes);
      setClaimantDisputes(claimaintDisputes);
    }
  }, [cases.length, disputes.length]);

  return {
    loading: disputeLoading || caseLoading,
    cases,
    disputes,
    respondent,
    claimant,
  };
}
