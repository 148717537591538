import { Grid, Typography, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { SECRETARIAT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { register } from "slices/userSlice";
import { formatDate } from "utils";
import instance from "api/globalaxios";
import { useDispatch } from "react-redux";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: "0.5rem",
    cursor: "pointer",
    // borderRadius:"0",
    // width:"50rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  value: {
    // fontSize: 14,
    border: "none",
    // padding: "0.3rem 1rem",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "14px",
      padding: "0.8rem 1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
      padding: "0.8rem 1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "14px",
      padding: "0.8rem 1rem",
    },
  },
  contentContainer: {
    display: "flex",
    // justifyContent: "center",
    textTransform: "capitalize",
  },
  btn: {
    fontSize: "16px",
    "border-radius": "3px",
    padding:"16px",
    // lineHeight: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      // lineHeight: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      // lineHeight: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      padding:"5px"
      // lineHeight: "18px",
    },
  },
  btn2: {
    fontSize: "16px",
    "border-radius": "3px",
    padding:"16px",
    // lineHeight: "30px",
    background: "#6FCF97",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      // lineHeight: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      padding:"5px"
      // lineHeight: "18px",
    },
    "&:hover": {
      backgroundColor: "grey",
    },
  },
}));

export default function NewDisputeRow({ dispute, setStep, setActiveDispute }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const disputeName = dispute.name;
  const disputeDate = formatDate(dispute.created);
  const [open, setOpen] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const onRowClick = () => {
    // history.push(`/disputes/${dispute.id}`);
    setActiveDispute(dispute);
    setStep(2);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const openpopup = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const register = () => {
    dispatch(registerDispute());
    setIsRegistered(true);
    setOpen(false);
  };

  function registerDispute() {
    return async (dispatch) => {
      try {
        const response = await instance.put(
          "/approvedispute/" + dispute.id + "/"
        );
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  }

  const fun = (event) => {
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justify="center">
        <Grid item sm={8} xs={12}>
          <Paper className={classes.paper} onClick={onRowClick}>
            <Grid container direction="row" justify="flex-start">
              <Grid
                item
                sm={6}
                xs={6}
                style={{ padding: "0.7rem 1rem", textAlign: "left" }}
              >
                <Box className={classes.contentContainer}>
                  <div>
                    <Typography className={classes.value}>
                      {disputeName}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                sm={3}
                xs={3}
                style={{ padding: "0.7rem 1rem", textAlign: "right" }}
              >
                <Box className={classes.contentContainer}>
                  <div>
                    <Typography className={classes.value}>
                      {disputeDate}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item sm={3} xs={3} style={{ textAlign: "right" }}>
                {isRegistered ? (
                  <Button
                    className={classes.btn2}
                    style={{ height: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                      fun(event);
                    }}
                  >
                    <FormattedMessage id="buttons.registered" />
                  </Button>
                ) : (
                  <Button
                    className={classes.btn}
                    style={{ height: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                      openpopup(event);
                    }}
                  >
                    <FormattedMessage id="buttons.register" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Popup
        open={open}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal}
        position="center"
        modal
        nested
        contentStyle={{ background: "white", width: "330px", height: "185px" }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              "letter-spacing": "0.0015em",
            }}
          >
            Are you sure?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Move this grievance to ‘Grievances Registered’?
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                CANCEL
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={register}
              >
                REGISTER
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
      <Box height="0.6rem"></Box>
    </React.Fragment>
  );
}
