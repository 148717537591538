import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
    flexGrow: "1",
  },
  heading: { textTransform: "uppercase", cursor: "pointer" },
}));

const Heading = ({ type }) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <Box display="flex" justifyContent="center">
      <FileCopyIcon />
      <Box width="0.5rem"></Box>
      <Typography
        variant="body1"
        className={classes.heading}
        onClick={(e) => {
          history.push(`/disputes/${type}/active`);
        }}
      >
        {type}
      </Typography>
    </Box>
  );
};

const Counter = ({ heading, count }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Typography variant="body2">{heading}</Typography>
        <Typography variant="body2">{count}</Typography>
      </Box>
    </Box>
  );
};

export default ({ type, disputesMap }) => {
  const classes = useStyles();
  let { active, resolved } = disputesMap;
  return (
    <Paper className={classes.paper}>
      <Heading type={type} />
      <Box height="0.3rem"></Box>
      <Divider />
      <Box height="0.3rem"></Box>
      <Grid container>
        <Grid item md={5} sm={5} xs={5}>
          <Counter
            heading={<FormattedMessage id="dispute.active" />}
            count={active.length}
          />
        </Grid>
        <Grid item md={2} sm={2} xs={2}>
          <Divider orientation="vertical" style={{ margin: "0 auto" }} />
        </Grid>
        <Grid item md={5} sm={5} xs={5}>
          <Counter
            heading={<FormattedMessage id="dispute.resolved" />}
            count={resolved.length}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
