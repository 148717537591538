import React, { useEffect, useState } from "react";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useHistory, useParams } from "react-router-dom";
import instance from "api/globalaxios";
import { Box, Button } from "@material-ui/core";

export default function EndZoomMeeting() {
    let { disputeId } = useParams();
    let history = useHistory();
    
    const [loading, setLoading] = useState(true);
    const [meetingEnded, setMeetingEnded] = useState(false);
    const [error, setError] = useState(false);


    const endMeeting = async () =>{
        try {
            await instance({
                method: "post",
                url: `/closezoommeeting/${disputeId}/`,
                data: {},
            });
            setMeetingEnded(true);
            setLoading(false);
        } catch(err){
            setError(true);
            console.log(err)
        }
    }

    useEffect(()=>{
        endMeeting();
    },[disputeId])

    return <Box display="flex" justifyContent="center" flexBasis="50%" flexGrow={1}>
        {loading && (
          <Box display="flex" justifyContent="center" pt="3rem">
            <CircularCenteredLoader />
          </Box>
        )}
        {meetingEnded && <Box display="flex" alignItems="center" pt="3rem" flexDirection="column">
            <h3>Meeting with the dispute id {disputeId} ended successfully</h3>
            <Box mt="3rem">
                <Button
                    variant="contained"
                    color="primary"
                    // size="large"
                    onClick={()=>{
                        history.push(`/disputes/${disputeId}/chat`)
                    }}
                    style={{background:"#2F80ED",color:"white",fontSize:"20px",borderRadius:"2px"}}
                >
                    Dispute Chat Page
                </Button>
            </Box>
        </Box>}
        {error && <Box display="flex" justifyContent="center" pt="3rem">
            <h3>Error while ending meeting with dispute id {disputeId}</h3>
        </Box>}
    </Box>
}
  