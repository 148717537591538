import { Box, makeStyles, Typography } from "@material-ui/core";
import Avatar from "features/Chat/Avatar";
import DocTemplate from "features/Chat/DocTemplate";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    maxWidth: "70%",
  },
  root: {
    borderRadius: "0 10px 10px 10px",
    background: "#EFF0F0",
    border: "1px solid #D0CFCF",
    wordBreak: "break-all",
  },
  time: {
    color: "rgba(112, 124, 151, 0.7)",
  },
}));

export default function RecievedMessage({
  content,
  domId,
  file,
  attachements,
  parsedTimestamp,
  from,
}) {
  const classes = useStyles();
  return (
    <Fragment>
      {file ? (
        <DocTemplate
          file={file}
          domId={domId}
          classes={classes}
          position="flex-start"
          from={from}
          parsedTimestamp={parsedTimestamp}
        />
      ) : (
        <Box m="2rem 0">
          <div className={classes.container} id={domId}>
            <Avatar value={from[0].toUpperCase()} />
            <Box width="1rem"></Box>
            <Box textAlign="end">
              <Box p="0.7rem" className={classes.root}>
                <Typography variant="subtitle1">{content}</Typography>
              </Box>
              <Typography variant="caption" className={classes.time}>
                {parsedTimestamp}
              </Typography>
            </Box>
          </div>
        </Box>
      )}
    </Fragment>
  );
}
