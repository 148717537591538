import { makeStyles } from "@material-ui/core";

const drawerWidth = 300;
const docWidth = 300;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "90vh",
    // height: "100%",
    borderRadius: "1rem",
    flexDirection: (props) => (props.smallScreen ? "column" : "row"),
  },
  drawer: {
    width: (props) => (props.smallScreen ? "100%" : drawerWidth),
    // borderRight: `0.1rem solid ${theme.palette.border}`,
    height: "100%",
    overflowY: "scroll",
  },
  selected: {
    background: "rgba(0, 0, 0, 0.04)",
  },
  // item: {
  //   "&:hover": {
  //     background: blue["700"],
  //   },
  // },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    maxWidth: "100%"
  },
  rightDoc: {
    // borderLeft: `0.1rem solid ${theme.palette.border}`,
    height: "100%",
    minWidth: 300,
    width: (props) => (props.smallScreen ? "100%" : 300),
    overflowY: "scroll",
  },
  greyColor: {
    color: theme.palette.common.grey1,
  },
  vcModeContainer: {
    display: "flex",
  },
}));
