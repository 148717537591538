import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EventIcon from "@material-ui/icons/Event";
import {
  CLAIMANT,
  GENERAL,
  GENERAL_RV,
  RESPONDENT,
  VENDOR,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import Dispute from "features/disputes/Dispute";
import DisputeNumberCard from "features/disputes/partials/DisputeNumberCard";
import { rvFilter } from "features/disputes/utils";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";


const getName = (profile, user) => {
  return (
    (profile.profile_type === GENERAL && user.first_name) ||
    (profile.profile_type === VENDOR && user.first_name) ||
    (profile.profile_type === GENERAL_RV && user.first_name) ||
    (profile.profile_type === "guest_general" && user.first_name) ||
    (profile.profile_type === "guest_company" && user.first_name)
  );
};

const Disputes = ({
  disputes,
  cases,
  profile,
  user,
  claimaintDisputes,
  respondentDisputes,
  isRV,
  count,
}) => {
  const classes = useStyles();
  let history = useHistory();

  let {
    profile: { profile_type },
  } = profile;

  // console.log("DISPUTES:",disputes);
  // console.log("CLaimantDISPUTES:",claimaintDisputes);
  // console.log("RespondantDISPUTES:",respondentDisputes);

  const onClick = (e) => {
    if (isRV) {
      history.push(`/intro-rv`);
    } else {
      history.push(`/intro`);
    }
  };

  const filteredDisputes = isRV ? rvFilter(disputes, cases) : { ...disputes };

  let latestDispute = filteredDisputes[0];

  return (
    <Grid container justify="center" >
      {/* <Grid item md={1}></Grid> */}
      <Grid item xs={12} md={10}>
        <Box className={classes.container}>
          <Grid container justify="space-between">
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h3" align="center" className={classes.name}>
                <FormattedMessage id="common.hi" />,{" "}
                {getName(profile.profile, user)}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className={classes.buttonContainer}>
                {(profile_type === VENDOR ||
                  profile_type === "guest_company") && (
                  <Button
                    variant="outlined"
                    color="myblack"
                    className={classes.calenderbtn}
                    // startIcon={<AssessmentIcon />}
                    onClick={() => {
                      history.push("/analytics");
                    }}
                  >
                    <FormattedMessage id="buttons.analytics" />
                  </Button>
                )}
                <Box width="0.8rem"></Box>
                {(profile_type === VENDOR ||
                  profile_type === "guest_company") && (
                  <Button
                    className={classes.calenderbtn}
                    variant="outlined"
                    color="myblack"
                    // startIcon={<EventIcon />}
                    onClick={() => {
                      history.push("/events");
                    }}
                  >
                    <FormattedMessage id="buttons.calendar" />
                  </Button>
                )}
                <Box width="0.8rem"></Box>
                {/* {(profile_type === GENERAL || profile_type === VENDOR || isRV) && ( */}
                {(profile_type === GENERAL || isRV) && (
                  <Button
                    className={classes.newdisputebtn}
                    variant="outlined"
                    color="myblack"
                    // startIcon={<AddIcon />}
                    onClick={onClick}
                    data-testid="create-new-dispute"
                  >
                    <FormattedMessage id="buttons.createNewDispute" />
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
          <Box height="0.7rem"></Box>
          {!isEmpty(latestDispute) && (
            <Dispute
              dispute={latestDispute}
              cases={cases}
              profile={profile.profile}
            />
          )}

          <Box height="1rem"></Box>

          <Box display="flex">
            {(() => {
              if (profile_type === GENERAL || profile_type == "guest_general") {
                return <DisputeNumberCard type={CLAIMANT} count={count} />;
              } else if (
                profile_type === VENDOR ||
                profile_type == "guest_company"
              ) {
                return <DisputeNumberCard type={RESPONDENT} count={count} />;
              }
            })()}

            {/* <Box width="1rem"></Box> */}
          </Box>

          {/* </Box> */}
        </Box>
      </Grid>
      {/* <Grid item md={1} xs={0}></Grid> */}
    </Grid>
  );
};
export default Disputes;
