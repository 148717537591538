import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Hidden,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ReplyIcon from "@material-ui/icons/Reply";
import FormattedMessage from "features/common/TranslatedMessage";
import DisputeRow from "features/disputesList/DisputeRow";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  headeropen: {
    background: theme.palette.primary.main,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff",
    // padding: "1rem",
    borderRadius: "0.3rem",
    padding: "3rem",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  headerclosed: {
    background: theme.palette.primary.main,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff",
    // padding: "1rem",
    borderRadius: "0.3rem",
    padding: "3rem",
    [theme.breakpoints.down("md")]: {
      padding: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  headerarbitrator: {
    background: theme.palette.primary.main,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "#fff",
    padding: "1rem",
    borderRadius: "0.3rem",
    // padding:"3rem"
  },
  heading: {
    textTransform: "capitalize",
    color: "#F2F2F2",
    fontSize: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
  icon: {
    fontSize: "3rem",
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  paper: {
    // padding: "0.5rem",
    // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    // color: "#333333",
    // padding: "1rem",
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    // whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  value: {
    border: "none",
    padding: "1rem",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      ffontSize: "14px",
      lineHeight: "20px",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
}));

export default function DisputesList({
  disputes,
  role,
  profile,
  user,
  disputesType,
  isProfessionalAccount,
  profileType,
  totalNoOfPages,
  currentPage,
  setCurrentPage,
  getUserDataAnotherPage,
}) {
  const classes = useStyles(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const data = [
    {
      title: <FormattedMessage id="dispute.gId" />,
    },
    {
      title: <FormattedMessage id="dispute.grievance" />,
    },
    {
      title: <FormattedMessage id="dispute.status" />,
    },
    {
      title: <FormattedMessage id="dispute.startDate" />,
    },
    {
      title: <FormattedMessage id="dispute.view_grievance" />,
    },
  ];

  // console.log("PROFILE::::", profile);
  // console.log("USER:::::", user);
  // console.log(disputes);
  // console.log(role);
  // console.log(disputesType);
  return (
    <Container>
      <Box height="3rem"></Box>

      {role !== "arbitrator" && role !== "guest_arbitrator" && (
        <Paper
          style={{ background: "none", border: "none", boxShadow: "none" }}
        >
          <Button
            onClick={() => {
              // history.push("/disputes");
              history.goBack()
            }}
            size="large"
            startIcon={<ArrowBackIosIcon />}
          >
            <FormattedMessage id="buttons.back" />
          </Button>
        </Paper>
      )}

      {disputesType == "active" &&
        role !== "arbitrator" &&
        role !== "guest_arbitrator" && (
          <Box className={classes.headeropen}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.heading} variant="h3">
                {(() => {
                  return (
                    <FormattedMessage id={`dispute.${disputesType}state`} />
                  );
                })()}
              </Typography>
            </Box>
          </Box>
        )}
      {disputesType == "resolved" &&
        role !== "arbitrator" &&
        role !== "guest_arbitrator" && (
          <Box className={classes.headerclosed}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.heading} variant="h3">
                {(() => {
                  return (
                    <FormattedMessage id={`dispute.${disputesType}state`} />
                  );
                })()}
              </Typography>
            </Box>
          </Box>
        )}
      {(role === "arbitrator" || role === "guest_arbitrator") && (
        <Box className={classes.headerarbitrator}>
          <Box>
            <Grid container direction="row" justify="flex-end">
              <Grid item>
                <Typography style={{ color: "#F2F2F2" }}>
                  {(() => {
                    if (disputesType === "active") {
                      return "Open Grievances";
                    } else {
                      return "Closed Grievances";
                    }
                  })()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              className={classes.heading}
              variant="h3"
              style={{ margin: "0.5rem 0rem 2.5rem 3rem" }}
            >
              {(() => {
                return `Hello, ${user.first_name}`;
              })()}
            </Typography>
          </Box>
        </Box>
      )}
      {(role === "arbitrator" || role === "guest_arbitrator") && (
        <>
          <Box height="1rem"></Box>
          <Grid container direction="row" justify="flex-end">
            <Grid item>
              <Button
                style={{
                  fontSize: "20px",
                  color: "#4F4F4F",
                  textTransform: "none",
                }}
                variant="outlined"
                color="myblack"
                onClick={() => {
                  history.push("/events");
                }}
              >
                <FormattedMessage id="buttons.calendar" />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <Box height="2rem"></Box>
      {!isEmpty(disputes) && (
        <React.Fragment>
          <Box height="0.5rem"></Box>
          {role === "arbitrator" || role === "guest_arbitrator" ? (
            <div className={classes.paper}>
              <Grid container justify="space-evenly">
                <Grid item sm={3} xs={3}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[0].title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[1].title}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item sm={3}>
            <Box className={classes.contentContainer}>
                <Typography className={classes.title}>{data[2].title}</Typography>
              </Box>
            </Grid> */}
                <Grid item sm={3} xs={3}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[3].title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[4].title}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className={classes.paper}>
              <Grid container justify="space-evenly">
                <Grid item sm={2} xs={2}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[0].title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[1].title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[2].title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={3} xs={3}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[3].title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <Box className={classes.contentContainer}>
                    <Typography className={classes.title}>
                      {data[4].title}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
          <Box height="1rem"></Box>
          {disputes.map((dispute) => {
            return (
              <DisputeRow
                profileType={profileType}
                key={dispute.id}
                dispute={dispute}
                disputesType={disputesType}
              />
            );
          })}
        </React.Fragment>
      )}
      <Grid container>
        <Grid item>
          <IconButton
            disabled={currentPage == 1}
            onClick={(e) => {
              setCurrentPage(currentPage - 1);
              getUserDataAnotherPage(currentPage - 1);
            }}
          >
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
          </IconButton>
          <IconButton
            disabled={currentPage >= totalNoOfPages}
            onClick={(e) => {
              setCurrentPage(currentPage + 1);
              getUserDataAnotherPage(currentPage + 1);
            }}
          >
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
}
