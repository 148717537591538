import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import instance from "api/globalaxios";
import { RESPONDENT } from "app/constants";
import { format } from "date-fns";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FormattedMessage from "features/common/TranslatedMessage";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { downloadLegalDoc, updateDispute } from "slices/disputeSlice";
import { DocGenerator, formatDate } from "utils";
import { getWindowDimension } from "utils/responsiveUtils";
import QuestionModal from "./QuestionModal";

const useStyles = makeStyles((theme) => ({
  topText: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
  disputeheading: {
    fontSize: "26px",
    lineHeight: "36px",
    paddingTop: "35px",
    paddingLeft: "35px",
    paddingRight: "600px",
  },
  disputeName: {
    color: "#4F4F4F",
    fontSize: "28px",
    lineHeight: "36px",
    padding: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "26px",
    }
  },
  text: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    // background:"grey"
  },
  blue: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#2F80ED",
    lineHeight: "24px",
    fontSize: "16px !important"
  },
  bluevalue: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    lineHeight: "24px",
    fontSize: "14px !important"
  },
  fonts: {
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
  },
  box: {
    padding: "4rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  headings: {
    lineHeight: "36px",
    fontSize: "16px",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#333333",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "14px",
    },
  },
  greenbutton: {
    backgroundColor: "#6FCF97",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
      padding: "2px 2px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
      padding: "2px 2px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
  },
  yellowbutton: {
    backgroundColor: "#F2C94C",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
  },
  item: {
    "&:hover": {
      // cursor: 'pointer',
      background: "#EEEEEE",
    },
  },
  btn: {
    fontSize: "20px",
    "border-radius": "3px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
  },
  btn2: {
    fontSize: "20px",
    "border-radius": "3px",
    lineHeight: "30px",
    background: "#6FCF97",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  btnsize: {
    fontSize: "20px",
    borderRadius: "2px",
    textTransform: "none", [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "26px",
    },

  },
  box: {
    padding: "4rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  closedbtn: {
    background: "#E0E0E0",
    color: "white",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
}));

export default function DisputeDetails({ activeDispute, step, setStep }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isRegistered, setIsRegistered] = React.useState(
    activeDispute.approved
  );
  const [isDisputeClosed, setIsDisputeClosed] = React.useState(
    activeDispute.status == "closed"
  );
  const [hearingDate, setHearingDate] = React.useState("--");
  console.log('hearingDate',hearingDate)
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const [claimantcasestatus, setClaimantstatus] = useState('');


  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = React.useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = (screenWidth) => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    step != 3
      ? localStorage.setItem("whichTabDisputeDisplay", 1)
      : localStorage.setItem("whichTabDisputeDisplay", 6);
  }, []);

  React.useEffect(() => {
    // localStorage.setItem("whichTabDisputeDisplay", 2);
    instance({
      method: "get",
      url: `/getmembers/${activeDispute.id}/`,
      params: "",
    }).then((response) => {
      setProfiles(response.data);
    });
  }, []);

  React.useEffect(() => {
    // localStorage.setItem("whichTabDisputeDisplay", 2);
    instance({
      method: "get",
      url: `/api/casefile/`,
      params: {
        dispute__id: activeDispute.id,
      },
    }).then((response) => {
      console.log(" dataaaaa", response.data)
      response.data.results.map((item) => {
        if (item.type == 'claimant') {
          setClaimantstatus(item.case_status)
        }
      });
      dispatch(getAllMeetings2()).then(()=>{
        setLoading(false);
      })
    });
  }, []);


  let arr = activeDispute.name.split("&");
  const complainantName = arr[0];
  const companyName = arr[1];
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [decisionFiles, setdecisionFiles] = React.useState(
    activeDispute.all_docs
  );
  const [finalDecision, setFinalDecision] = React.useState();
  React.useEffect(() => {
    decisionFiles.forEach((file) => {
      if (file.is_final) {
        setFinalDecision(file.id);
      }
    });
  }, [decisionFiles]);

  const getAllMeetings2 = () => async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/api/user-meetings-list/`,
      });
      // console.log(response.data);
      let data = response.data.appointments;
      let latestAppointment;
      data.forEach((appointment) => {
        if (appointment.meeting.dispute == activeDispute.id) {
          // console.log(appointment.meeting);
          if (latestAppointment) {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            let d2 = new Date(latestAppointment.meeting.start);
            if (d1 < d2 && d1 > d) {
              latestAppointment = appointment;
            } else {
            }
          } else {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            if (d1 > d) latestAppointment = appointment;
          }
        }
      });

      console.log("Latest hearing ::: ", latestAppointment);
      if (latestAppointment) {
        setHearingDate(
          format(new Date(latestAppointment.meeting.start), "d MMMM y hh:mm a")
        );
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeModal2 = () => {
    setOpen2(false);
  };

  const openpopup = () => {
    setOpen(true);
  };

  const openpopup2 = () => {
    setOpen2(true);
  };

  const register = () => {
    dispatch(registerDispute());
    setIsRegistered(true);
    setOpen(false);
  };

  const closeGrievence = () => {
    dispatch(updateDispute({ id: activeDispute.id, status: "closed" }));
    setIsDisputeClosed(true);
    setOpen2(false);
  };

  function registerDispute() {
    return async (dispatch) => {
      try {
        const response = await instance.put(
          "/approvedispute/" + activeDispute.id + "/"
        );
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  }

  const [openmodal3, setOpenmodal3] = React.useState(false);

  const handleCloseModal3 = (e) => {
    e.stopPropagation();
    setOpenmodal3(false);
  };
  if (loading) return <CircularCenteredLoader></CircularCenteredLoader>
  return (
    <Box className={classes.box}>
      <Grid container justify="flex-start">
        <Grid item>
          <Typography
            className={classes.disputeName}
          >
            {activeDispute.name}
          </Typography>
        </Grid>
      </Grid>
      <Box height="3rem"></Box>
      <Grid container justify="center">
        <Grid md={7} sm={8} xs={11} item>
          <Grid container justify="flex-start">
            <Grid item style={{ padding: "2px 9px" }}>
              <Button
                onClick={() => {
                  console.log('set step clicked')
                  setStep(0);
                  history.push("/disputes");
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          md={isSmallScreen(screenWidth) ? 7 : 7}
          sm={isSmallScreen(screenWidth) ? 8 : 8}
          xs={isSmallScreen(screenWidth) ? 11 : 11}
          item
        >
          <Paper style={{ padding: "2rem" }}>
            <Grid
              container
              justify="flex-start"
              spacing={0}
              direction="column"
              alignItems="flex-start"
            >
              <Grid item >
                <Typography
                  className={classes.disputeName}>
                  {complainantName} & {companyName}
                </Typography>
              </Grid>
              {/* <Grid item>
                                <Typography style={{ color: "#4F4F4F", fontSize: "28px", lineHeight: "36px" }}>& {companyName}</Typography>
                            </Grid> */}
            </Grid>

            <Box height="0.5rem"></Box>

            <Grid container justify="flex-start">
              {
                claimantcasestatus != 'new' && claimantcasestatus != 'inprogress' && claimantcasestatus != 'dispute_lodged' && claimantcasestatus != 'questions_complete' && claimantcasestatus != 'upload_complete' &&

                <Grid item style={{ padding: "16px" }}>
                  <Typography
                    className={classes.blue}
                    onClick={() => {
                      DocGenerator(activeDispute.id, "claimant");
                    }}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    VIEW GRIEVANCE
                  </Typography>
                </Grid>
              }
              {step == 2 &&
                activeDispute.status != "new" &&
                activeDispute.status != "respondent_inprogress" &&
                activeDispute.status != "respondent_complete" &&
                (
                  <Grid item style={{ padding: "16px" }}>
                    <Typography
                      className={classes.blue}
                      onClick={() => {
                        DocGenerator(activeDispute.id, RESPONDENT);
                      }}
                      style={{
                        textDecorationLine: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      VIEW RESPONSE
                    </Typography>
                  </Grid>
                )}
              {(step == 1 || step == 3) &&
                activeDispute.status != "new" &&
                activeDispute.status != "respondent_inprogress" &&
                activeDispute.status != "respondent_complete" &&
                (
                  <Grid item style={{ padding: "16px" }}>
                    <Typography
                      className={classes.blue}
                      onClick={() => {
                        DocGenerator(activeDispute.id, RESPONDENT);
                      }}
                      style={{
                        textDecorationLine: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      VIEW RESPONSE
                    </Typography>
                  </Grid>
                )}
            </Grid>
            <Box height="0.5rem"></Box>
            {step == 1 || step == 3 ? (
              <Grid container direction="row" style={{ padding: "10px" }}>
                <Grid item sm={7}>
                  <Grid
                    container
                    justify="flex-start"
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <a className={classes.blue} style={{cursor:'text'}}>Grievance ID </a>
                      <span className={classes.bluevalue}>{activeDispute.custom_dispute_id}</span>
                    </Grid>
                    <Grid item style={{textAlign: 'left'}}>
                      <a className={classes.blue} style={{cursor:'text'}}>Hearing Date </a>
                      <span className={classes.bluevalue}>{isDisputeClosed ? "--" : hearingDate}</span>
                    </Grid>
                    <Grid item>
                      <a className={classes.blue} style={{cursor:'text'}}>Decision </a>
                      {finalDecision ? (
                        <u
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                            color: "#2F80ED",
                            lineHeight: "24px",
                          }}
                          onClick={() => {
                            downloadLegalDoc(finalDecision);
                          }}
                        >
                          VIEW
                        </u>
                      ) : (
                        <span className={classes.bluevalue}>(In Progress)</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={5} style={{ display: "flex", alignItems: "flex-end", padding: "10px" }}>
                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                  >
                    <Grid item style={{ margin: "5px" }}>
                      <Button
                        style={{
                          fontSize: "16px",
                          color: "#4F4F4F",
                          textTransform: "none",
                          borderRadius: "2px",
                          width: "125px",
                        }}
                        variant="outlined"
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push("/events");
                        }}
                      >
                        <FormattedMessage id="buttons.calendar" />
                      </Button>
                    </Grid>
                    <Grid item style={{ margin: "5px" }}>
                      <Button
                        variant="outlined"
                        style={{
                          fontSize: "16px",
                          color: "#4F4F4F",
                          textTransform: "none",
                          borderRadius: "2px",
                          width: "125px",
                        }}
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push(`/disputes/${activeDispute.id}/files`);
                        }}
                      >
                        <FormattedMessage id="common.files" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box>
                <Box height="4rem"></Box>
                <Grid
                  container
                  justify="flex-start"
                  direction="column"
                  alignItems="flex-start"
                >
                  {isRegistered && (
                    <Grid item>
                      <a className={classes.blue}>Grievance ID </a>
                      <span className={classes.bluevalue}>{activeDispute.custom_dispute_id}</span>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography>
                      <a className={classes.blue}>Receiving date </a>
                      <span className={classes.bluevalue}>{formatDate(activeDispute.created)}</span>
                    </Typography>

                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
          <Box height="3rem"></Box>
          {step == 1 && (
            // activeDispute.status != "new" &&
            // activeDispute.status != "respondent_inprogress" &&
            // activeDispute.status != "respondent_complete" &&
            <Grid container direction="row" justify="center" >
              <Grid item style={{ padding: "10px" }}>
                {isDisputeClosed ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.closedbtn}
                    // style={{ fontSize: "20px", borderRadius: "2px", textTransform: "none" }}
                    onClick={() => {
                      // history.push(
                      //     `/events?disputeId=${activeDispute.id}&group= arbitration_${activeDispute.id}`
                      //   );
                    }}
                  >
                    <FormattedMessage id="buttons.schedulehearing" />
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.btnsize}
                    onClick={() => {
                      history.push(
                        `/events?disputeId=${activeDispute.id}&group= arbitration_${activeDispute.id}`
                      );
                    }}
                  >
                    <FormattedMessage id="buttons.schedulehearing" />
                  </Button>
                )}
              </Grid>
              <Grid item style={{ padding: "10px" }}>
                {isDisputeClosed ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.closedbtn}
                    // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px", textTransform: "none" }}
                    onClick={() => {
                      // history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    <FormattedMessage id="common.join" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnsize}
                    style={{
                      background: "#2F80ED"
                    }}
                    onClick={() => {
                      history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    <FormattedMessage id="common.join" />
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {step == 2 &&
            (isRegistered ? (
              <Button
                className={classes.btn2}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="buttons.registered" />
              </Button>
            ) : (
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={(event) => {
                  openpopup(event);
                }}
              >
                <FormattedMessage id="buttons.register" />
              </Button>
            ))}
          {step == 3 &&
            (isDisputeClosed ? (
              <Grid container direction="row" justify="center">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    className={classes.closedbtn}
                    variant="contained"
                    color="primary"
                  >
                    Report details
                  </Button>
                </Grid>
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    className={classes.closedbtn}
                    variant="contained"
                    color="primary"
                  >
                    Close Grievance
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="row" justify="center">
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                      setOpenmodal3(true);
                    }}
                  >
                    Report details
                  </Button>
                  {openmodal3 && (
                    <QuestionModal
                      openmodal={openmodal3}
                      handleCloseModal={handleCloseModal3}
                      activeDispute={activeDispute}
                      setOpenmodal3={setOpenmodal3}
                    />
                  )}
                </Grid>
                <Grid item style={{ padding: "10px" }}>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                      openpopup2(event);
                    }}
                  >
                    Close Grievance
                  </Button>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      {(step == 2) && (
        <Box className={classes.box}>

          <Box height="0.5rem"></Box>
          <Grid container justify="center">
            {/* {isSmallScreen(screenWidth) ? ( */}
            <Grid sm={10} xs={11} md={12} item>
              <Paper
                style={{
                  padding: "1rem 0.5rem",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  className={classes.headings}
                >
                  <Grid item sm={3} xs={3}>
                    Name
                  </Grid>
                  <Grid item sm={2} xs={2} >
                    Profile
                  </Grid>
                  <Grid item sm={2} xs={2} >
                    Calender
                  </Grid>
                  <Grid item sm={2} xs={2}>
                    Phone Number
                  </Grid>
                  <Grid item sm={3} xs={3}>
                    Email ID
                  </Grid>
                </Grid>
              </Paper>

              {profiles.map((profile) => {
                if (profile.profile_type != "arbitrator" && profile.profile_type != "vendor")
                  // comment to show arbitrator
                  return (
                    <>
                      <Paper style={{ padding: "0.5rem " }}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          className={classes.headings}
                          style={{ color: "#4F4F4F" }}
                        >
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            style={{
                              // textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >

                            {profile.user.first_name} {profile.user.last_name}
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            style={{
                              // textAlign: "left",
                              wordWrap: "anywhere",
                            }}
                          >
                            {profile.profile_type == "general" && (
                              <button className={classes.greenbutton}>
                                Complainant
                              </button>
                            )}

                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            style={{
                              // textAlign: "left",
                              cursor: "pointer",
                              wordWrap: "break-word",
                            }}
                            onClick={(e) => {
                              history.push(
                                `/disputes/${activeDispute.id}/events/${profile.id}`
                              );
                            }}
                          >
                            <u>VIEW</u>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            style={{
                              // textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            {profile.phone_number}
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            style={{
                              // textAlign: "left",
                              wordWrap: "break-word",
                            }}
                          >
                            {profile.user.email}
                          </Grid>
                        </Grid>
                      </Paper>
                      <Box height="0.5rem"></Box>
                    </>
                  );
              })}

            </Grid>

            {/* )} */}
          </Grid>
        </Box>
      )}

      <Popup
        open={open}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal}
        position="center"
        modal
        nested
        contentStyle={{ background: "white", width: "330px", height: "185px" }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              "letter-spacing": "0.0015em",
            }}
          >
            Are you sure?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Move this grievance to ‘Grievances Registered’?
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid container direction="row" justify="flex-end" style={{ padding: "10px" }}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                CANCEL
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={register}
              >
                REGISTER
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
      <Popup
        open={open2}
        closeOnDocumentClick
        closeOnEscape
        onClose={closeModal2}
        position="center"
        modal
        nested
        contentStyle={{ background: "white", width: "330px", height: "185px" }}
        overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
      >
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Typography
            style={{
              fontSize: "20px",
              color: "#333333",
              "font-weight": "bold",
              "line-height": "23px",
              "letter-spacing": "0.0015em",
            }}
          >
            Are you sure?
          </Typography>
          <Box height="0.5rem"></Box>
          <Typography
            style={{
              fontSize: "16px",
              color: "#828282",
              "line-height": "28px",
            }}
          >
            Are you sure you want to close this grievance?
          </Typography>
          <Box height="0.7rem"></Box>
        </Box>
        <Box style={{ padding: "1rem 1.3rem" }}>
          <Grid container direction="row" justify="flex-end" style={{ padding: "10px" }}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#828282",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeModal2}
              >
                CANCEL
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#2F80ED",
                  "line-height": "16px",
                  "letter-spacing": "0.02em",
                  "font-weight": "500",
                  cursor: "pointer",
                }}
                onClick={closeGrievence}
              >
                CLOSE
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </Box>
  );
}
