import React, { Fragment, useReducer } from "react";
import RespondentDetailsForm from "features/intro/RespondentDetailsForm";
import UserVerifyForm from "features/intro/UserVerifyForm";

const initialState = {
  fetched: false,
  profileDate: {},
  empty: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return { ...state, fetched: true, profileData: action.payload };
    case "notEmpty":
      return { ...state, empty: false };
  }
};

export default function VendorAsClaimant({ user, profile, companies }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { fetched, profileData, empty } = state;

  const profileFetched = (data) => {
    dispatch({ type: "set", payload: data });
  };

  const setNotEmpty = () => {
    dispatch({ type: "notEmpty" });
  };

  const childProps = { user, profile, companies };

  return (
    <Fragment>
      {fetched ? (
        <RespondentDetailsForm
          {...childProps}
          respondentProfileValues={profileData}
          empty={empty}
        />
      ) : (
        <UserVerifyForm
          profileFetched={profileFetched}
          setNotEmpty={setNotEmpty}
        />
      )}
    </Fragment>
  );
}
