import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  container: {
    transform: "translateZ(0px)",
    position: "relative",
    // justifySelf: "flex-start",
  },
  speedDial: {
    position: "absolute",
    bottom: "8px",
    left: 0,
    [theme.breakpoints.down("sm")]: {
      // bottom: "0px",
    },
  },
  fab: {
    boxShadow: "none",
    width: "3rem",
    height: "3rem",
    background: theme.palette.primary.mainGradient,
    [theme.breakpoints.down("sm")]: {
      width: "3.3rem",
      height: "2rem",
    },
  },
}));

export default function OpenIconSpeedDial({ handlers, actions }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <div className={classes.container}>
    <SpeedDial
      ariaLabel="SpeedDial openIcon example"
      icon={<SpeedDialIcon openIcon={<EditIcon />} />}
      className={classes.speedDial}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      hidden={false}
      classes={classes}
    >
      {actions.map((action, index) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={handlers[index]}
        />
      ))}
    </SpeedDial>
    // </div>
  );
}
