import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import { backgrounds } from "contexts/BackgroundContext";
import FormattedMessage from "features/common/TranslatedMessage";
import { useBodyBackgroundChange } from "hooks";
import MailSentVectorSVG from "images/mail-sent/rafiki.svg";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "6rem",
    color: "#707C97",
  },

  primaryText1: {
    fontSize: "22px",
    lineHeight: "36px",
    fontWeight: "600",
    marginBottom: "34px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    lineHeight: "22px"

    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    lineHeight: "22px"

    },
  },
  primaryText: {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    lineHeight: "25px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    lineHeight: "22px"
    },
  },
  container: {
    padding:"10px"
    // // width: "87%",
    // float: "right",
    // // marginTop: "28px",
    // [theme.breakpoints.down("sm")]: {
    //   // width: "90%",
    // },
  },
  vectorImage: {
    width: "38%",
    [theme.breakpoints.down("sm")]: {
      width: "45%",
      order: 1,
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "45%",
      order: 1,
      marginBottom: "2rem",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

export default function ClaimantWaiting({ disputeName }) {
  const classes = useStyles();

  useBodyBackgroundChange();

  return (
    <Fragment>
      <Grid container justify="center">
        {/* <Box height="5rem"></Box> */}
        <Grid item className={classes.container} md={11} sm={10}>
          <div className={classes.mainContainer}>
            <div className={classes.content}>
              <Typography className={classes.primaryText1}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading1" />
              </Typography>
              <Box height="0.1rem"></Box>
              <Typography variant="h3" className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading2" />
              </Typography>
              {/* <Box height="0.1rem"></Box>
              <Typography variant="h3" className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading3" />
              </Typography>
              <Box height="2rem"></Box>
              <Typography variant="h3" className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaiting.heading4" />
              </Typography> */}
            </div>

            {/* <Hidden smDown>
              <Box flexGrow={1}></Box>
            </Hidden> */}

            <img
              className={classes.vectorImage}
              src={MailSentVectorSVG}
              alt="mail sent vector image"
            />
          </div>
          <Box height="1.5rem"></Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
