import * as Yup from "yup";

export const DetailsSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  password: Yup.string()
    .min(6, "Password Too Short!")
    .max(20, "Password Too Long!")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
  phoneNumber: Yup.string()
    .required("phone number is required")
    .min(10, "Invalid phone number")
    .max(10, "Invalid phone number"),
});
