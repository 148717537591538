import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2rem",
    },

    loginButton: {
      margin: theme.spacing(3, 0, 2),
    },
  };
});

export default function PasswordResetResult() {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="md">
      <Paper className={classes.paper}>
        <Typography variant="h5" color="primary">
          <FormattedMessage id="auth.passwordchanged" />
        </Typography>
      </Paper>
    </Container>
  );
}
