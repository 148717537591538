import {
  Button,
  Container,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "slices/loginSlice";
import * as Yup from "yup";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email Required"),
});

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: "80%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2rem",
    },

    loginButton: {
      margin: theme.spacing(3, 0, 2),
      // backgroundColor: "#000",
      // color: "white",
      // border: "1px solid transparent",

      // "&:hover": {
      //   backgroundColor: "transparent",
      //   border: "1px solid #000",
      //   color: "#000",
      // },
    },
  };
});

export default function PasswordReset() {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      let result = await dispatch(forgotPassword(values.email));
      if (result.status === "OK") {
        history.push("/forgot-password-confirm");
      }
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="filled"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label={<FormattedMessage id="user.email" />}
            name="email"
            autoComplete="email"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginButton}
          >
            {<FormattedMessage id="auth.paswordReset" />}
          </Button>
          {/* {formik.errors.email && formik.touched.email && (
          <Alert severity="error">{formik.errors.email}</Alert>
        )}
        {hasErrors && apiErrors.email && (
          <Alert severity="error">{apiErrors.email[0]}</Alert>
        )} */}
        </form>
      </Paper>
    </Container>
  );
}
