import { Box } from "@material-ui/core";
import { ARBITRATOR, CLAIMANT, MEDIATOR, RESPONDENT } from "app/constants";
import React, { Fragment } from "react";
import ArbitratorSingleDispute from "./ArbitratorSingleDispute";
import ClaimantFlowSteps from "./ClaimantFlowSteps";
import DisputeDisplay from "./DisputeDisplay";
import RespondentFlowSteps from "./RespondentFlowSteps/RespondentFlowSteps";
export default function SingleDispute({
  profile,
  activeCase,
  activeDispute,
  companyFlows,
}) {
  console.log("activeDispute.status:", activeDispute.status);
  let flows = companyFlows.filter((companyFlow) => {
    // console.log("companyFlow:", companyFlow);
    // console.log("activeDispute", activeDispute);
    return companyFlow.company_product === activeDispute.company_product;
  });

  if ( profile.profile_type === ARBITRATOR || profile.profile_type === MEDIATOR ) {
    return (
      // <ArbitratorSingleDispute
      //   activeDispute={activeDispute}
      //   profile={profile}
      // />
      <DisputeDisplay activeCase={activeCase} activeDispute={activeDispute} profile={profile}></DisputeDisplay>
    );
  }

  switch (activeCase.type) {
    case CLAIMANT:
      return (
        <Fragment>
          <Box height="2rem"></Box>
          <ClaimantFlowSteps
            profile={profile}
            activeCase={activeCase}
            activeDispute={activeDispute}
            flows={flows}
          />
        </Fragment>
      );
      break;

    case RESPONDENT:
      return (
        <Fragment>
          <Box height="2rem"></Box>
          <RespondentFlowSteps
            profile={profile}
            activeCase={activeCase}
            activeDispute={activeDispute}
            flows={flows}
          />
        </Fragment>
      );
      break;

    default:
      return "Unknown step";
  }
}
