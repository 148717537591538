import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DisputeDisplay from "features/singleDispute/DisputeDisplay";

import RespondentFlowSelection from "features/singleDispute/RespondentFlowSteps/RespndentFlowSelection";
import RespondentSelectionStatus from "features/singleDispute/RespondentFlowSteps/RespondentSelectionStatus";
import RespondentDecisionStatus from "features/singleDispute/RespondentFlowSteps/RespndentDecisionStatus";
import SingleDisputeDetails from "../SingleDisputeDetails";
import ClaimantWaiting from "features/singleDispute/ClaimantFlowSteps/ClaimantWaiting";
import {
  CLAIMANT,
  MEDIATION,
  CLAIMANT_COMPLETE,
  RESPONDENT_COMPLETE,
  ARBITRATION,
  REVIEW_COMPLETE,
  COMPLETE,
  RESPONDENT_INPROGRESS,
  RESPONDENT,
} from "app/constants";
import { useDispatch } from "react-redux";
import { updateDispute } from "slices/disputeSlice";
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import instance from "api/globalaxios";




export default function RespondentFlowSteps({
  activeDispute,
  activeCase,
  profile,
  flows,
}) {
  let dispatch = useDispatch();
  function getStepContent() {
    switch (activeDispute.status) {
      case "new":
        return (
          <RespondentFlowSelection
            activeDispute={activeDispute}
            activeCase={activeCase}
            flows={flows}
          />
        );
      case RESPONDENT_INPROGRESS:
        if (activeCase.case_status !== REVIEW_COMPLETE) {
          return <SingleDisputeDetails is3steps={true} profile={profile}/>;
        } else {
          //Update dispute to claimant complete in case of arbitration
          if (activeDispute.flow_type === ARBITRATION) {
            dispatch(
              updateDispute({
                id: activeDispute.id,
                status: CLAIMANT_COMPLETE,
              })
            );
          } else {
            // its adjudication. Not tasks for respondent .Wait for claimant to respond
            dispatch(
              updateDispute({
                id: activeDispute.id,
                status: RESPONDENT_COMPLETE,
              })
            );
          }
          return <CircularProgress />;
        }

        

      case RESPONDENT_COMPLETE:
        //if (activeDispute.flow_type === MEDIATION) {
        return (
          <RespondentSelectionStatus flowType={activeCase.proposed_flow} />
        );
      //} else {

      ///}
      //}

      // if (activeCase.proposed_flow === ARBITRATION) {
      //   if (activeCase.case_status !== REVIEW_COMPLETE) {
      //     return <SingleDisputeDetails />;
      //   }
      // }
      // return (
      //   <RespondentDecisionStatus
      //     flowType={activeCase.proposed_flow}
      //     name={CLAIMANT}
      //     id={activeDispute.id}
      //   />
      // );
      case COMPLETE:
        return "Case is complete";

      case CLAIMANT_COMPLETE:

      default:
        return (
          <DisputeDisplay
          profile={profile}
            activeDispute={activeDispute}
            activeCase={activeCase}
            claimantcasestatus={claimantcasestatus}
          />
        );
    }
    
  }
  const [claimantcasestatus, setClaimantstatus] = useState('');
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    // localStorage.setItem("whichTabDisputeDisplay", 2);
    instance({
      method: "get",
      url: `/api/casefile/`,
      params: {
        dispute__id: activeDispute.id,
        type:'claimant',
      },
    }).then((response) => {
      console.log(" dataaaaa", response.data)
  
      response.data.results.map((item) => {
        if (item.type == 'claimant') {
  
          setClaimantstatus(item.case_status)
  
  
        }
  
      });
      setLoading(false);
    });
  }, []);

  console.log("statusss", claimantcasestatus)
 
 
  

  return (
    <React.Fragment>
      {getStepContent(activeDispute, activeCase, profile)}
    </React.Fragment>
  );
}
