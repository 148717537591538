import React, { Fragment } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import debounce from "lodash/debounce";

export default function DebouncedButton({
  value,
  onClickHandler,
  debounceTime,
  loading,
  ...remProps
}) {
  const debouncedHandler = debounce(onClickHandler, debounceTime);

  return (
    <Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button {...remProps} onClick={debouncedHandler}>
          {value}
        </Button>
      )}
    </Fragment>
  );
}
