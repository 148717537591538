import { backgrounds } from "contexts/BackgroundContext";
import CircularLoading from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange } from "hooks";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDisputes, getDisputesAll } from "slices/disputeSlice";
import Analytics from "./Analytics";
import {
  createDisputeCountMap,
  createDisputeModeMap,
  createDisputeStatusMap,
  filterDispute,
  transformDisputeStatusPieData,
  transformDisputeToBarData,
  getNatureOfGrievance,
  getConcernRelate,
  filterDisputeByDate,
} from "./Analytics.utils";

export default function AnalyticsContainer({ profile }) {
  useBodyBackgroundChange(backgrounds.WHITE);

  const dispatch = useDispatch();
  const [disputeStatusPieData, setStatusPieData] = useState([]);
  // const [disputeModePieData, setModePieData] = useState([]);
  const [disputeCountBarData, setCountBarData] = useState([]);

  const [range, setRange] = React.useState("week");
  const [previousRange, setPreviousRange] = useState("week");

  const [start, setstart] = useState();
  const [end, setend] = useState();

  const handleChange = (event) => {
    setRange(event.target.value);
    setPreviousRange(event.target.value);
    setend();
    setstart();
  };

  // const { disputes, loading } = useSelector(
  //   (state) => state.disputes,
  //   shallowEqual
  // );
  const [disputes, setDisputes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  // const {
  //   profile: { profile_type: profileType },
  // } = useSelector((state) => state.profile, shallowEqual);

  const [nature_grievance_complainant, setnature_grievance_complainant] =
    useState([]);
  const [concern_related_complainant, setConcern_related_complainant] =
    useState([]);

  // const totalCount = disputes.length;
  React.useEffect(() => {
    dispatch(getDisputesAll()).then((res) => {
      // console.log(res);
      setDisputes(res);
      setLoading(false);
      setTotalCount(res.length);
    });
  }, []);

  // console.log("disputes : ", disputes);

  React.useEffect(() => {
    console.log("disputes : ", disputes);
    if (disputes.length > 0) {
      let filteredDisputes;
      if (start && end) {
        setRange("");
        filteredDisputes = filterDisputeByDate(disputes, start, end);
      } else {
        setRange(previousRange);
        filteredDisputes = filterDispute(disputes, range);
      }
      console.log(
        "inasdinfiasndfinasidnfsadf filteredDisputes ",
        filteredDisputes
      );
      setStatusPieData(
        transformDisputeStatusPieData(
          createDisputeStatusMap(filteredDisputes),
          totalCount
        )
      );
      // setModePieData(
      //   transformDisputeStatusPieData(
      //     createDisputeModeMap(filteredDisputes),
      //     totalCount
      //   )
      // );
      setCountBarData(
        transformDisputeToBarData(createDisputeCountMap(filteredDisputes))
      );
      setnature_grievance_complainant(getNatureOfGrievance(filteredDisputes));
      setConcern_related_complainant(getConcernRelate(filteredDisputes));
    }
  }, [disputes.length, range, start, end]);

  if (loading) return <CircularLoading />;

  return (
    <Analytics
      profile={profile}
      disputes={disputes}
      start_Date={start}
      set_start={setstart}
      end_Date={end}
      set_end={setend}
      handleChange={handleChange}
      range={range}
      disputeStatusData={disputeStatusPieData}
      // disputeModeData={disputeModePieData}
      disputeCountBarData={disputeCountBarData}
      nature_grievance_complainant={nature_grievance_complainant}
      concern_related_complainant={concern_related_complainant}
    />
  );
}
