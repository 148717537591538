import ChatBody from "features/Chat/NewChatBody";
import ChatDoc from "features/Chat/NewChatDoc";
import ChatSideBar from "features/Chat/NewChatSidebar";
import Vc from "features/Chat/Vc";
import React, { Fragment, useState } from "react";
import useStyles from "./ChatStyles";

const isSmallScreen = (width) => {
  return width < 960;
};

const getMobileStepContent = (
  step,
  classes,
  selected,
  setSelected,
  setStep,
  screenWidth,
  openDoc
) => {
  switch (step) {
    case 0:
      return (
        <ChatSideBar
          classes={classes}
          selected={selected}
          setSelected={setSelected}
          setStep={setStep}
        />
      );
    case 1:
      return (
        <ChatBody
          selected={selected}
          classes={classes}
          rightDocOpen={openDoc}
          smallScreen={isSmallScreen(screenWidth)}
          setStep={setStep}
        />
      );
    case 2:
      return (
        <ChatDoc
          classes={classes}
          setStep={setStep}
          selected={selected}
          smallScreen={isSmallScreen(screenWidth)}
        />
      );
  }
};

const DesktopChat = ({
  classes,
  selected,
  setSelected,
  setStep,
  screenWidth,
  openDoc,
}) => {
  return (
    <Fragment>
      <ChatSideBar
        classes={classes}
        selected={selected}
        setSelected={setSelected}
        setStep={setStep}
      />
      <ChatBody
        selected={selected}
        classes={classes}
        rightDocOpen={openDoc}
        smallScreen={isSmallScreen(screenWidth)}
        setStep={setStep}
      />
      <ChatDoc
        classes={classes}
        setStep={setStep}
        selected={selected}
        smallScreen={isSmallScreen(screenWidth)}
      />
    </Fragment>
  );
};

const MobileChat = ({
  step,
  classes,
  selected,
  setSelected,
  setStep,
  screenWidth,
  openDoc,
}) => {
  return (
    <Fragment>
      {getMobileStepContent(
        step,
        classes,
        selected,
        setSelected,
        setStep,
        screenWidth,
        openDoc
      )}
    </Fragment>
  );
};

{
}

export default function Chat({ screenWidth, showVC, selected, setSelected}) {
  const [docOpen, setOpen] = useState(true);
  const [step, setStep] = useState(0);
  const classes = useStyles({
    docOpen,
    smallScreen: isSmallScreen(screenWidth),
  });

  const openDoc = () => {
    setOpen((prevState) => !prevState);
  };

  const childProps = {
    step,
    classes,
    selected,
    setSelected,
    setStep,
    screenWidth,
    openDoc,
  };

  return (
    <div className={classes.root}>
      {!showVC ? (
        isSmallScreen(screenWidth) ? (
          <MobileChat {...childProps} />
        ) : (
          <DesktopChat {...childProps} />
        )
      ) : (
        isSmallScreen(screenWidth) ? (
          <Vc />
        ) : (
          <Fragment>
            <ChatSideBar
              classes={classes}
              selected={selected}
              setSelected={setSelected}
              setStep={setStep}
            />
            <Vc />
            <ChatDoc
              classes={classes}
              setStep={setStep}
              selected={selected}
              smallScreen={isSmallScreen(screenWidth)}
            />
          </Fragment>
        )
      )}
    </div>
  );
}
