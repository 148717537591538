import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import Avatar from "features/Chat/Avatar";

export default function GroupDisplay({
  group: { name: groupName, lastMessage: { msg = "" } = {} },
  name,
  fontSize,
  caption,
  rightDocOpen,
  setStep,
}) {
  const onClick = () => {
    setStep && setStep(2);
  };
  return (
    <Box display="flex" onClick={onClick} style={{ cursor: "pointer" }}>
      {/* <Avatar value={groupName[0].toUpperCase()} /> */}
      <Box width="1rem"></Box>
      <div>
        <Typography
          variant="subtitle2"
          style={{ fontSize: "16px",lineHeight:"19px",color:"#4F4F4F"}}
        >
          {name}
        </Typography>
        {/* {caption && <Typography variant="caption">{msg}</Typography>} */}
        <Box height="1rem"></Box>
      </div>
    </Box>
  );
}
