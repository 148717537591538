import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Hidden,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CreateLoginDetails from "./CreateLoginDetails";
import { useState } from "react";
import instance from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { getWindowDimension } from "utils/responsiveUtils";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: "4rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  },
  headings: {
    lineHeight: "36px",
    fontSize: "16px",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#333333",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "14px",
    }
  },
  value: {},
  greenbutton: {
    backgroundColor: "#6FCF97",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      padding: "2px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
      padding: "2px 8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
  },
  yellowbutton: {
    backgroundColor: "#F2C94C",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      padding: "2px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
      padding: "2px 8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
  },
  arbitratorbtn: {
    backgroundColor: "#c8a2e0",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      padding: "2px 8px",
    },
  },
  guestbtn: {
    backgroundColor: "#e0d9d7",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 10px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    paddingLeft:"12px",
    
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      padding: "1px 6px",
      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
      padding: "1px 6px",
      
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
      lineHeight: "10px",
      padding: "8px 8px",
      paddingLeft:"7px",
      
    },
  },
  
}));

export default function DetailsOfParties({ activeDispute }) {
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [reloadProfiles, setReloadProfiles] = useState(false);

  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = (screenWidth) => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    localStorage.setItem("whichTabDisputeDisplay", 2);
    instance({
      method: "get",
      url: `/getmembers/${activeDispute.id}/`,
      params: "",
    }).then((response) => {
      setProfiles(response.data);
    });
  }, []);

  React.useEffect(() => {
    if (profiles.length > 0) {
      setLoading(false);
      // console.log(profiles);
    }
  }, [profiles]);

  React.useEffect(() => {
    if (reloadProfiles) {
      instance({
        method: "get",
        url: `/getmembers/${activeDispute.id}/`,
        params: "",
      }).then((response) => {
        setProfiles(response.data);
        setReloadProfiles(false);
      });
    }
  }, [reloadProfiles]);

  return (
    <Box className={classes.box}>
      <Grid container justify="flex-start">
        <Grid item>
          <Typography
            style={{ color: "#4F4F4F", fontSize: "36px", lineHeight: "36px" }}
          >
            {activeDispute.name}
          </Typography>
        </Grid>
      </Grid>
      <Box height="3rem"></Box>
      <Grid container justify="center">
        {isSmallScreen(screenWidth) ? (
          <Grid sm={10} xs={11} item>
            <Paper
              style={{
                padding: "1rem 1.5rem",
                background: "none",
                boxShadow: "none",
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                className={classes.headings}
              >
                <Grid item sm={3} xs={3} >
                  Name
                </Grid>
                <Grid item sm={2} xs={2} >
                  Profile
                </Grid>
                <Grid item sm={2} xs={2} >
                  Calender
                </Grid>
                <Grid item sm={2} xs={2}>
                  Phone Number
                </Grid>
                <Grid item sm={3} xs={3}>
                  Email ID
                </Grid>
              </Grid>
            </Paper>
            {loading ? (
              <CircularCenteredLoader />
            ) : (
              <>
                {profiles.map((profile) => {
                  if (profile.profile_type != "arbitrator")
                    // comment to show arbitrator
                    return (
                      <>
                        <Paper style={{ padding: "0.5rem " }}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            className={classes.headings}
                            style={{ color: "#4F4F4F" }}
                          >
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              style={{
                                // textAlign: "left",
                                wordWrap: "break-word",
                              }}
                            >
                              {profile.user.first_name} {profile.user.last_name}
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={2}
                              style={{
                                // textAlign: "left",
                                wordWrap: "anywhere",
                              }}
                            >
                              {profile.profile_type == "general" && (
                                <button className={classes.greenbutton}>
                                  Complainant
                                </button>
                              )}
                              {profile.profile_type == "vendor" && (
                                <button className={classes.yellowbutton}>
                                  OCCP
                                </button>
                              )}
                              {profile.profile_type == "arbitrator" && (
                                <button className={classes.arbitratorbtn}>
                                  GRB member
                                </button>
                              )}
                              {profile.profile_type == "guest_general" && (
                                <button className={classes.guestbtn}>
                                  Guest Complainant
                                </button>
                              )}
                              {profile.profile_type == "guest_company" && (
                                <button className={classes.guestbtn}>
                                  Guest OCCP
                                </button>
                              )}
                              {profile.profile_type == "guest_arbitrator" && (
                                <button className={classes.guestbtn}>
                                  Guest GRB member
                                </button>
                              )}
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={2}
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                wordWrap: "break-word",
                              }}
                              onClick={(e) => {
                                history.push(
                                  `/disputes/${activeDispute.id}/events/${profile.id}`
                                );
                              }}
                            >
                              <u>VIEW</u>
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={2}
                              style={{
                                // textAlign: "left",
                                wordWrap: "break-word",
                              }}
                            >
                              {profile.phone_number}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              style={{
                                // textAlign: "left",
                                wordWrap: "break-word",
                              }}
                            >
                              {profile.user.email}
                            </Grid>
                          </Grid>
                        </Paper>
                        <Box height="0.5rem"></Box>
                      </>
                    );
                })}
              </>
            )}
            <Box height="5rem"></Box>
            <CreateLoginDetails
              activeDispute={activeDispute}
              setReloadProfiles={setReloadProfiles}
            ></CreateLoginDetails>
          </Grid>
        ) : (
          <Grid sm={11} xs={11} item>
            <Paper
              style={{
                padding: "1rem 1.5rem",
                background: "none",
                boxShadow: "none",
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                className={classes.headings}
              >
                <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                  Name
                </Grid>
                <Grid item sm={2} xs={2} style={{ textAlign: "left" }}>
                  Profile
                </Grid>
                <Grid item sm={2} xs={2} style={{ textAlign: "left",paddingLeft:'10px' }}>
                  Calender
                </Grid>
                <Grid item sm={2} xs={2} style={{ textAlign: "left" }}>
                  Phone Number
                </Grid>
                <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                  Email ID
                </Grid>
              </Grid>
            </Paper>
            {loading ? (
              <CircularCenteredLoader />
            ) : (
              <>
                {profiles.map((profile) => {
                  if (profile.profile_type != "arbitrator")
                    // comment to show arbitrator
                    return (
                      <>
                        <Paper style={{ padding: "0.6rem 1.5rem" }}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            className={classes.headings}
                            style={{ color: "#4F4F4F" }}
                          >
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              style={{ textAlign: "left" }}
                            >
                              {profile.user.first_name} {profile.user.last_name}
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={2}
                              style={{ textAlign: "left" }}
                            >
                              {profile.profile_type == "general" && (
                                <button className={classes.greenbutton}>
                                  Complainant
                                </button>
                              )}
                              {profile.profile_type == "vendor" && (
                                <button className={classes.yellowbutton}>
                                  OCCP
                                </button>
                              )}
                              {profile.profile_type == "arbitrator" && (
                                <button className={classes.arbitratorbtn}>
                                  GRB member
                                </button>
                              )}
                              {profile.profile_type == "guest_general" && (
                                <button className={classes.guestbtn}>
                                  Guest Complainant
                                </button>
                              )}
                              {profile.profile_type == "guest_company" && (
                                <button className={classes.guestbtn}>
                                  Guest OCCP
                                </button>
                              )}
                              {profile.profile_type == "guest_arbitrator" && (
                                <button className={classes.guestbtn}>
                                  Guest GRB member
                                </button>
                              )}
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={2}
                              style={{ textAlign: "left", cursor: "pointer",paddingLeft:'10px' }}
                              onClick={(e) => {
                                history.push(
                                  `/disputes/${activeDispute.id}/events/${profile.id}`
                                );
                              }}
                            >
                              <u>VIEW</u>
                            </Grid>
                            <Grid
                              item
                              sm={2}
                              xs={2}
                              style={{ textAlign: "left" }}
                            >
                              {profile.phone_number}
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              xs={3}
                              style={{ textAlign: "left", overflowWrap: "break-word"}}
                            >
                              {profile.user.email}
                            </Grid>
                          </Grid>
                        </Paper>
                        <Box height="0.5rem"></Box>
                      </>
                    );
                })}
              </>
            )}
            <Box height="5rem"></Box>
            <CreateLoginDetails
              activeDispute={activeDispute}
              setReloadProfiles={setReloadProfiles}
            ></CreateLoginDetails>
            <Box height="2.5rem"></Box>
            <Box height="2.5rem"></Box>
            <Box height="2.5rem"></Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
