import { Box, Button, InputAdornment, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import DisplayContainer from "features/profile/DisplayContainer";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "slices/loginSlice";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {  Visibility, VisibilityOff } from '@material-ui/icons';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password Too Short! Minimum Length - 8")
    .max(15, "Password Too Long! Maximum Length - 15")
    .matches(/^(.*[A-Z].*)/, "Must Contain an Upper Case Alphabet")
    .matches(/^(.*[a-z].*)/, "Must Contain a Lower Case Alphabet")
    .matches(/^(.*[0-9].*)/, "Must Contain a number")
    .matches(/^(.*\W.*)/, "Must Contain a special character")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
  currentpassword: Yup.string().required("Current Password Required"),
});

export default function ProfileSecurity() {
  const dispatch = useDispatch();
  // const [state, setState] = useState({
  //   currentPassword: "",
  //   newPassword: "",
  //   error: "",
  //   nonFieldErrors: [],
  // });

  const onSubmit = async (currentPassword, newPassword) => {
    try {
      console.log(currentPassword);
      console.log(newPassword);
      if (currentPassword === newPassword) {
        alert("Please enter a different password!");
      } else {
        await dispatch(changePassword(currentPassword, newPassword));
        console.log("password changed succesfully");

        Swal.fire({
          icon: "success",
          title: "Password changed succesfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log("err", err);
      alert("Wrong password");
      // let parsedError = JSON.parse(err);
      // if (parsedError.error.non_field_errors) {
      //   setErrors("", parsedError.error.non_field_errors);
      // } else {
      //   setErrors(parsedError.error, []);
      // }
    }
  };

  const formik = useFormik({
    initialValues: {
      currentpassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      console.log(values.currentpassword);
      console.log(values.password);
      console.log(values.confirmPassword);
      onSubmit(values.currentpassword, values.confirmPassword);
    },
  });

  // const { currentPassword, newPassword, error, nonFieldErrors } = state;

  // const onChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setState({ ...state, [name]: value });
  // };

  // const setErrors = (error, nonFieldErrors) => {
  //   setState({ ...state, error, nonFieldErrors });
  // };

  const [passwordType, setPasswordType] = useState('password')
  const tooglePasswordType = () =>{
    if(passwordType == 'password'){
      setPasswordType('text')
    }
    else{
      setPasswordType('password')
    }
  }


  return (
    <Box>
      <DisplayContainer title={<FormattedMessage id="auth.password" />}>
        <form
          // className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="filled"
            margin="normal"
            type={passwordType}
            value={formik.currentpassword}
            required
            fullWidth
            id="currentpassword"
            label="Current Password"
            name="currentpassword"
            autoComplete="password"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={tooglePasswordType} style={{cursor:"pointer"}}>
                  {passwordType == 'password' ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.currentpassword && (
            <Alert severity="error">{formik.errors.currentpassword}</Alert>
          )}
          <TextField
            variant="filled"
            margin="normal"
            type={passwordType}
            value={formik.password}
            required
            fullWidth
            id="password"
            label={<FormattedMessage id="auth.password" />}
            name="password"
            autoComplete="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={tooglePasswordType} style={{cursor:"pointer"}}>
                  {passwordType == 'password' ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          <TextField
            variant="filled"
            margin="normal"
            type={passwordType}
            value={formik.confirmPassword}
            required
            fullWidth
            id="confirmPassword"
            label={<FormattedMessage id="auth.confirmPassword" />}
            name="confirmPassword"
            autoComplete="confirmPassword"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={tooglePasswordType} style={{cursor:"pointer"}}>
                  {passwordType == 'password' ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Alert severity="error">{formik.errors.confirmPassword}</Alert>
          )}
          <Box height="2.5rem"></Box>
          <Button variant="contained" color="primary" type="submit">
            {<FormattedMessage id="buttons.resetPassword" />}
          </Button>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // className={classes.loginButton}
          >
            {<FormattedMessage id="auth.resetPassword" />}
          </Button> */}
          {/* {formik.errors.email && formik.touched.email && (
          <Alert severity="error">{formik.errors.email}</Alert>
        )}
        {hasErrors && apiErrors.email && (
          <Alert severity="error">{apiErrors.email[0]}</Alert>
        )} */}
        </form>
        {/* <TextField
          name="currentPassword"
          value={currentPassword}
          onChange={onChange}
          placeholder="current password"
          fullWidth
          type="password"
        />
        <Box height="1rem"></Box>
        {error.length > 0 && <Alert severity="error">{error}</Alert>}
        <Box height="2.5rem"></Box>
        <TextField
          name="newPassword"
          value={newPassword}
          onChange={onChange}
          placeholder="new password"
          fullWidth
          type="password"
        />
        <Box height="1rem"></Box>
        {nonFieldErrors.map((error, index) => {
          return (
            <Alert severity="error" key={index}>
              {error}
            </Alert>
          );
        })}
        <Box height="2.5rem"></Box> */}

        {/* <Button variant="contained" color="primary" onClick={onSubmit}>
          {<FormattedMessage id="buttons.resetPassword" />}
        </Button> */}
      </DisplayContainer>
    </Box>
  );
}
