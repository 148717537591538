import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#E0E0E0",
    // minHeight: "5rem",
    padding: "3rem 5rem",
  },
}));

export default function DpcgcFooter() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography style={{ fontSize: "13px" }}>
              For support, contact:{" "}
              <span style={{ fontWeight: "bold" }}>techsupport@webnyay.in</span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: "13px" }}>
              Powered by{" "}
              <a
                style={{ fontWeight: "bold", cursor: "pointer" }}
                href="https://www.webnyay.in"
                target="_blank"
              >
                Webnyay
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
