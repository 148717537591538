import React from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  Hidden,
  Snackbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { updateDispute, uploadDispute } from "slices/disputeSlice";
import { useDispatch } from "react-redux";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import instance from 'api/globalaxios';
import { getWindowDimension } from "utils/responsiveUtils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  box: {
    padding: "4rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0.5rem",
    },
  }
}));

export default function DecisionByDPCGC({ activeDispute }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [dispute, setDispute] = React.useState(activeDispute);
  const [decisionFiles, setdecisionFiles] = React.useState(dispute.all_docs);

  const [openSnackBar1, setOpenSnackBar1] = React.useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };

  React.useEffect(() => {
    if (openSnackBar1) {
      instance({
        method: "get",
        url: "/api/dispute/",
        params: {
          id: dispute.id,
        },
      }).then((response) => {
        setDispute(response.data.results[0]);
        // console.log(response.data.results[0]);
      })
    }
  }, [openSnackBar1])

  React.useEffect(()=>{
    setdecisionFiles(dispute.all_docs);
  },[dispute])

  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = React.useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = (screenWidth) => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    localStorage.setItem('whichTabDisputeDisplay', 5);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])


  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };

  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  const renderFiles = (files, deleteFile) => {
    return files.length !== 0
      ? files.map((file, index) => {
        return (
          <Box textAlign="center" position="relative"
            key={index}
          // display="flex"
          // justifyContent="center"
          >
            <Box>
              <Typography style={{ wordBreak: "break-all", paddingTop: "20px" }} variant="subtitle2">{file.name}</Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })
      : null;
  };



  const UploadDialog = ({ open, setOpen, addFile }) => {
    const classes = useStyles();
    return (
      <Box textAlign="center" position="relative">
        <Box>
          <Box >
            <IconButton
              className={classes.icon}
              aria-label="delete"
              // color="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              <AddCircleOutlineIcon className={classes.icon}
              />
            </IconButton>
          </Box>
        </Box>
        <DropzoneDialog
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            // console.log("Files:", files);
            files.forEach((file) => {
              addFile(file);
            });
            setOpen(false);
          }}
          showPreviews={true}
          filesLimit="1"
          showFileNamesInPreview={true}
          acceptedFiles={[
            "image/jpeg", "image/png", "image/jpg",
            "application/msword",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </Box>
    );
  };

  const onClickProceed = (e) => {
    e.preventDefault();
    if (files.length !== 0) {
      dispatch(uploadDispute(files, dispute)).then((resp) =>
        dispatch(updateDispute({ id: dispute.id, status: "webnyay_upload" }))
      );
    }
  };

  const handleShareClick = (e, file) => {
    e.stopPropagation()
    dispatch(shareDecision(file)).then((response) => {
      setdecisionFiles(dispute.all_docs);
      setOpenSnackBar1(true);
      // console.log(dispute);
    });

  }

  const shareDecision = (file) => {
    return async (dispatch) => {
      try {
        // console.log("here-------------",file);
        const response = await instance.put(
          "/finaldecession/" + file.id + "/",
          { "dispute_id": dispute.id }, {
          headers: {
            "Accept": "application/json",
            "Content-type": "application/json",
          }
        }
        ); 
      } catch (error) {
        console.log(error);
      }
    };
  }

  return (
    <Box className={classes.box}>
      <Grid container justify="flex-start">
        <Grid item sm={11} xs={11}>
          <Typography style={{ color: "#4F4F4F", fontSize: "36px", lineHeight: "36px" }}>{dispute.name}</Typography>
        </Grid>
      </Grid>
      <Box height="3rem"></Box>
      <Grid container justify="center">
        {isSmallScreen(screenWidth) ? (
          <Grid sm={11} xs={11} item >
            <Paper style={{ background: "none", padding: "1rem", boxShadow: "none", fontSize: "16px", color: "#333333" }}>
              <Grid container>
                <Grid item sm={8} xs={8}>
                  <Typography style={{ textAlign: "left" }}>Upload Decision</Typography>
                </Grid>
                <Grid i tem sm={3} xs={3}>
                  <Typography style={{ textAlign: "left" }}>Upload Date</Typography>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <Typography style={{ textAlign: "left" }}></Typography>
                </Grid>
              </Grid>
            </Paper>
            {decisionFiles.length > 0 &&
              decisionFiles.map((file) => {
                return (
                  <React.Fragment>
                    <Box style={{ cursor: "pointer" }} onClick={() => {
                      if (file.s3path)
                        downloadLegalDoc(file.id);
                      else
                        console.log("s3path is null");
                    }}>
                      <Paper style={{ padding: "1rem 1.5rem", fontSize: "16px", }}>
                        <Grid container >
                          <Grid item sm={8} xs={8}>
                            <Typography style={{ color: "#333333", textAlign: "left",'overflow-wrap':"break-word"}}>{file.name}</Typography>
                          </Grid>
                          <Grid item sm={3} xs={3} style={{marginLeft:"auto"}}>
                            <Typography style={{ color: "#828282", textAlign: "left" }}>{formatDate(file.created)}</Typography>
                          </Grid>
                          <Grid item sm={1} xs={1}>
                            {(file.is_final) ?
                              <Typography style={{ color: "#2F80ED", textAlign: "left" }}  ><u>Shared</u></Typography>
                              :
                              <Typography style={{ color: "#2F80ED", textAlign: "left", cursor: "pointer" }} onClick={(event) => handleShareClick(event, file)} ><u>Share</u></Typography>
                            }
                          </Grid>
                        </Grid>
                      </Paper>
                      <Box height="1rem"></Box>
                    </Box>
                  </React.Fragment>
                )
              })
            }
            {
              files.length === 0 && (
                <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />)
            }
            {renderFiles(files, deleteFile)}
            {files.length != 0 &&
              <Button
                variant="contained"
                color="primary"
                style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px", marginTop: "20px" }}
                onClick={(e) => { onClickProceed(e) }}
              >
                <FormattedMessage id="buttons.submit" />
                <Box height="3rem"></Box>
              </Button>
            }
            <Box height="3rem"></Box>
          </Grid>
        ) : (
          <Grid sm={7} xs={7} item >
            <Paper style={{ background: "none", padding: "1rem", boxShadow: "none", fontSize: "16px", color: "#333333" }}>
              <Grid container>
                <Grid item sm={8} xs={8}>
                  <Typography style={{ textAlign: "left" }}>Upload Decision</Typography>
                </Grid>
                <Grid i tem sm={3} xs={3}>
                  <Typography style={{ textAlign: "left" }}>Upload Date</Typography>
                </Grid>
                <Grid item sm={1} xs={1}>
                  <Typography style={{ textAlign: "left" }}></Typography>
                </Grid>
              </Grid>
            </Paper>
            {decisionFiles.length > 0 &&
              decisionFiles.map((file) => {
                return (
                  <React.Fragment>
                    <Box style={{ cursor: "pointer" }} onClick={() => {
                      if (file.s3path)
                        downloadLegalDoc(file.id);
                      else
                        console.log("s3path is null");
                    }}>
                      <Paper style={{ padding: "1rem 1.5rem", fontSize: "16px", }}>
                        <Grid container >
                          <Grid item sm={8} xs={8}>
                            <Typography style={{ color: "#333333", textAlign: "left",'overflow-wrap':"break-word" }}>{file.name}</Typography>
                          </Grid>
                          <Grid item sm={3} xs={3} style={{marginLeft:"auto"}}>
                            <Typography style={{ color: "#828282", textAlign: "left" }}>{formatDate(file.created)}</Typography>
                          </Grid>
                          <Grid item sm={1} xs={1}>
                            {(file.is_final) ?
                              <Typography style={{ color: "#2F80ED", textAlign: "left" }}  ><u>Shared</u></Typography>
                              :
                              <Typography style={{ color: "#2F80ED", textAlign: "left", cursor: "pointer" }} onClick={(event) => handleShareClick(event, file)} ><u>Share</u></Typography>
                            }
                          </Grid>
                        </Grid>
                      </Paper>
                      <Box height="1rem"></Box>
                    </Box>
                  </React.Fragment>
                )
              })
            }
            {
              files.length === 0 && (
                <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />)
            }
            {renderFiles(files, deleteFile)}
            {files.length != 0 &&
              <Button
                variant="contained"
                color="primary"
                style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px", marginTop: "20px" }}
                onClick={(e) => { onClickProceed(e) }}
              >
                <FormattedMessage id="buttons.submit" />
                <Box height="3rem"></Box>
              </Button>
            }
            <Box height="3rem"></Box>
          </Grid>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="PDecision is shared."
      // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Decision is shared.
        </Alert>
      </Snackbar>

    </Box>
  )
}
