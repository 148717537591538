import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function CircularCenteredLoader() {
  return (
    <Box display="flex" justifyContent="center" margin="3rem 0 0 0">
      <CircularProgress disableShrink />
    </Box>
  );
}
