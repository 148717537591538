import {
  Box,
  Container,
  Hidden,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import {
  ADVOCATE_RV,
  ARBITRATOR_COMPLETE,
  ARBITRATOR_RV,
  CLAIMANT,
  CLAIMANT_ADVOCATE_COMPLETE,
  GENERAL_RV,
  NEW,
  RESPONDENT,
  RESPONDENT_COMPLETE,
} from "app/constants";
import Details from "features/singleDisputeRv/Details";
import DisputeDisplayRv from "features/singleDisputeRv/DisputeDisplayRv";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
  },
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getStep(status) {
  if (status === NEW) return 0;

  if (status === CLAIMANT_ADVOCATE_COMPLETE) return 1;
  if (status === RESPONDENT_COMPLETE) return 2;
  if (status === ARBITRATOR_COMPLETE) return 3;

  return 4;
}

function getSteps() {
  return ["claimant_advocate", "Respondent", "Arbitrator", "Details"];
}

function getStepContent(step, dispute, profile, activeCase) {
  switch (step) {
    case 0:
      return (
        <Details
          activeDispute={dispute}
          activeCase={activeCase}
          caseFileType={CLAIMANT}
          rvStatus={CLAIMANT_ADVOCATE_COMPLETE}
          profileType={ADVOCATE_RV}
        />
      );
    case 1:
      return (
        <Details
          activeDispute={dispute}
          activeCase={activeCase}
          caseFileType={RESPONDENT}
          rvStatus={RESPONDENT_COMPLETE}
          profileType={GENERAL_RV}
        />
      );
    case 2:
      return (
        <Details
          activeDispute={dispute}
          activeCase={activeCase}
          rvStatus={ARBITRATOR_COMPLETE}
          profileType={ARBITRATOR_RV}
        />
      );

    default:
      return <DisputeDisplayRv activeDispute={dispute} profile={profile} />;
  }
}

export default function ClaimantStepper({
  activeDispute,
  profile,
  activeCase,
}) {
  const classes = useStyles();

  let activeStep = getStep(activeDispute.rv_status);

  const steps = getSteps();

  return (
    <Container maxWidth="lg">
      <Box className={classes.container}>
        <Box className={classes.root}>
          <Hidden smDown>
            <Box height="6rem"></Box>
            <Box>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ padding: 0, background: "none" }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Hidden>
          <Box height="2rem"></Box>
        </Box>
        <Box width="4rem"></Box>
        <Box flexGrow={1}>
          <Box height="2rem"></Box>

          <Box>
            {getStepContent(activeStep, activeDispute, profile, activeCase)}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
