import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Box, Tooltip } from "@material-ui/core";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { addYears, format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "80%",
    height: "30rem",
    margin: "3rem auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
}));

export default function Calender({
  transformFunction,
  sources,
  selectable,
  onSelect,
  onEventClick,
  selectAllow,
  handleAddEventClick,
}) {
  // const currentDate = format(new Date(), "yyyy-MM-dd");
  // const limitDate = format(addYears(new Date(), 2), "yyyy-MM-dd");

  let events = [];

  // console.log(sources[0]["events"]);
  if (sources[0]["events"].length > 0) {
    sources[0]["events"].map((e) => {
      let c = {};
      c["id"] = e.id;
      c["created"] = e.created;
      let meet = {};
      meet["created"] = e.meeting.created;
      meet["dispute"] = e.meeting.dispute;
      meet["end"] = e.meeting.end;
      meet["group"] = "DPCGC hearing";
      meet["id"] = e.meeting.id;
      meet["modified"] = e.meeting.modified;
      meet["name"] = "DPCGC hearing";
      meet["platform"] = e.meeting.platform;
      meet["start"] = e.meeting.start;
      c["meeting"] = meet;
      c["modified"] = e.modified;
      c["name"] = "DPCGC hearing";
      c["profile"] = e.profile;
      c['custom']= e.name
      events.push(c);
    });
  }
  let data = [{ events: events }];
  // console.log(data[0]["events"]);

  const location = useLocation();

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FullCalendar
        themeSystem={"materia"}
        handleWindowResize={true}
        // validRange={{
        //   start: currentDate,
        //   end: limitDate,
        // }}
        customButtons={{
          custom1: {
            text: "Schedule",
            click: handleAddEventClick,
          },
        }}
        eventSources={data}
        // eventSources={sources}
        eventClick={onEventClick}
        selectable={selectable}
        eventDataTransform={transformFunction}
        selectAllow={selectAllow}
        // businessHours={{
        //   daysOfWeek: [1, 2, 3, 4, 5, 6],

        //   startTime: "09:00",
        //   endTime: "17:00",
        // }}
        dateClick={(info) => {
          console.info(info);
        }}
        height="100%"
        select={onSelect}
        // selectConstraint="businessHours"

        headerToolbar={
          location.search == ""
            ? {
                left: "title",
                center: "",
                right: "timeGridDay,listWeek,dayGridMonth today prev,next",
              }
            : {
                left: "title",
                center: "",
                right:
                  "custom1 timeGridDay,listWeek,dayGridMonth today prev,next",
              }
        }
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        navLinks={true}
      />
    </Box>
  );
}
