import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import DisputeDisplay from "features/Secretariat/DisputeDisplay/DisputeDisplay";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCase } from "slices/casesSlice";
import { getCompanyFlows } from "slices/companyFlowsSlice";
import { getDispute } from "slices/disputeSlice";
import GuestDisputeDisplay from "./DisputeDisplay/GuestDisputeDisplay";
import SingleDispute from "./SingleDispute";

export default function SingleDisputeContainer() {
  let { disputeId } = useParams();

  const {
    profile: { profile, loading: profileLoading },
    disputes: { loading: disputeLoading, activeDispute: activeDispute },
    cases: { loading: caseLoading, activeCase: activeCase },
    companyFlows: { loading: companyFlowsLoading, companyFlows },
  } = useSelector((state) => state, shallowEqual);

  const [relaod, setRelaod] = React.useState()

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getDispute(disputeId));
    dispatch(getCase(disputeId));
    dispatch(getCompanyFlows());
    console.log('reloading')
  }, [relaod]);

  const passObjects = {
    profile,
    activeDispute,
    activeCase,
    companyFlows,
    setRelaod
  };
  const loading =
    profileLoading ||
    caseLoading ||
    disputeLoading ||
    companyFlowsLoading ||
    isEmpty(activeDispute) ||
    isEmpty(activeCase);
    if(!profileLoading && profile.profile_type == "secretariat")
    return <DisputeDisplay />

  if (profile.profile_type && !loading) {
    if(profile.profile_type == "guest_claimant" || profile.profile_type == "guest_company" || profile.profile_type == "guest_arbitrator"){
      return <GuestDisputeDisplay {...passObjects} />
    }
    return <SingleDispute {...passObjects} />;
  }
  else return <CircularCenteredLoader />;
}
