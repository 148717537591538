import { containerContext } from "features/Chat/ChatContainer";
import React, { useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VideoConferencing from "./VideoConferencing";

export default function VideoConferencingContainer() {
  const { disputeId } = useParams();

  const { activeGroup, groups } = useSelector(
    (state) => state.groups,
    shallowEqual
  );

  const currentGroup = groups.filter((group) => group._id === activeGroup)[0];

  const { setShowVC, vcType } = useContext(containerContext);

  const user = useSelector((state) => state.user.user, shallowEqual);

  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  let childProps = {
    user,
    profile,
    disputeId,
    roomName: `${disputeId}_${currentGroup.name}-dpcgc_${activeGroup}`,
    setShowVC: setShowVC,
    vcType: vcType
  };
  return <VideoConferencing {...childProps} />;
}
