import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Hidden,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ReplyIcon from "@material-ui/icons/Reply";
import instance from "api/globalaxios";
import FormattedMessage from "features/common/TranslatedMessage";

// import DisputeRow from "./DisputeRow";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NewDisputeRow from "./NewDisputeRow ";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: "0.5rem",
    // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    // color: "#333333",
    padding: "1rem",
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    // whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  contentContainer1: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
  },
  contentContainer2: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
}));

export default function DisputesReceivedList({ setActiveDispute, setStep }) {
  const classes = useStyles(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const Grievance = () => {
    return <FormattedMessage id="dispute.grievance" />;
  };

  const ReceiveDate = () => {
    return <FormattedMessage id="dispute.receiveDate" />;
  };

  const data = [
    {
      title: <FormattedMessage id="dispute.grievance" />,
    },
    {
      title: <FormattedMessage id="dispute.receiveDate" />,
    },
  ];

  const [totalNoOfPages, setTotalNoOfPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [disputes, setDisputes] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    dispatch(getDisputeByPageNo(currentPage));
  }, []);

  function getDisputeByPageNo(pageNo) {
    return async (dispatch) => {
      setLoading(true);
      try {
        const response = await instance.get(
          `/api/dispute/?page=${pageNo}&type=received`
          // `/api/dispute/?page=${pageNo}`
        );
        if (pageNo == 1) {
          setTotalNoOfPages(parseInt(response.data.count / 100) + 1);
        }
        setDisputes(response.data.results);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  if (loading) {
    return <CircularCenteredLoader />;
  }
  return (
    <Container>
      {!isEmpty(disputes) && (
        <React.Fragment>
          <Box height="0.5rem"></Box>
          <div className={classes.paper}>
            <Grid container direction="row" justify="center">
              <Grid item sm={8} xs={12}>
                <Box className={classes.paper}>
                  <Grid container direction="row" justify="flex-start">
                    <Grid item sm={6} xs={6}>
                      <Box className={classes.contentContainer1}>
                        <div>
                          <Typography className={classes.title}>
                            <Grievance />
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                      <Box className={classes.contentContainer2}>
                        <div>
                          <Typography className={classes.title}>
                            <ReceiveDate />
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
          <Box height="1rem"></Box>
          {disputes.map((dispute) => {
            if (!dispute.approved)
              return (
                <NewDisputeRow
                  setActiveDispute={setActiveDispute}
                  key={dispute.id}
                  dispute={dispute}
                  setStep={setStep}
                />
              );
          })}
          <Grid container>
            <Grid item style={{ marginLeft: "10rem" }}>
              <IconButton
                disabled={currentPage == 1}
                onClick={(e) => {
                  setCurrentPage(currentPage - 1);
                  dispatch(getDisputeByPageNo(currentPage - 1));
                }}
              >
                <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
              </IconButton>
              <IconButton
                disabled={currentPage == totalNoOfPages}
                onClick={(e) => {
                  setCurrentPage(currentPage + 1);
                  dispatch(getDisputeByPageNo(currentPage + 1));
                }}
              >
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </IconButton>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Container>
  );
}
