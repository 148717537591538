import { makeStyles } from "@material-ui/core/styles";
import { CLAIMANT, RESPONDENT } from "app/constants";
import ClaimantDispute from "features/disputes/ClaimantDispute";
import RespondentDispute from "features/disputes/RespondentDispute";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCase } from "slices/casesSlice";
import { getDispute } from "slices/disputeSlice";
import { useState } from "react";
import instance from "api/globalaxios";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  dispute_heading: {
    fontSize: "28px",
    textAlign: "center",
    padding: "46px 100px 30px 100px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      padding: "12px 15px 12px 15px",
    },
  },
  date_text: {
    [theme.breakpoints.down("sm")]: { fontSize: "0.8rem" },
  },
  blue: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#2F80ED",
    lineHeight: "24px",
    fontSize:"22px",
    [theme.breakpoints.down("sm")]: { fontSize: "16px" },
  },
  proceedbtn: {
    background: "#2F80ED",
    color: "white",
    fontSize: "20px",
    borderRadius: "2px",
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
  },
}));


const Dispute = ({ dispute, cases, profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };

  const [claimantName, respondentName] = dispute.name.split("&");

  //   let answer = answers.find((ans) => ans.question === question.id);

  let selectedCase = cases.find((cse) => cse.dispute === dispute.id);

  const disputeStatus = dispute.status === "closed" ? "Resolved" : "Active";
  console.log("casessss",cases)
  const [claimantcasestatus, setClaimantstatus] = useState('');
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    // localStorage.setItem("whichTabDisputeDisplay", 2);
    instance({
      method: "get",
      url: `/api/casefile/`,
      params: {
        dispute__id: dispute.id,
        type:'claimant',
      },
    }).then((response) => {
      console.log(" dataaaaa", response.data)

      response.data.results.map((item) => {
        if (item.type == 'claimant') {

          setClaimantstatus(item.case_status)


        }

      });
      setLoading(false);
    });
  }, []);

  console.log("statussssss", claimantcasestatus)

  const onClick = async (e) => {
    if (selectedCase) {
      await dispatch(getDispute(dispute.id));
      dispatch(getCase(dispute.id)).then(
        (response) => {
          console.log(response);
          history.push(`disputes/${dispute.id}`);
        },
        (error) => {
          console.log(error);
          throw error;
        }
      );
    }
  };

  if (
    profile.profile_type == "guest_general" ||
    (selectedCase && selectedCase.type === CLAIMANT)
  ) {
    return (
      <ClaimantDispute
        dispute={dispute}
        classes={classes}
        onClick={onClick}
        disputeStatus={disputeStatus}
        claimantName={claimantName}
        respondentName={respondentName}
      />
    );
  } else if (!selectedCase || selectedCase.type === RESPONDENT) {
    return (
      <RespondentDispute
        dispute={dispute}
        disputeStatus={disputeStatus}
        profile={profile}
        classes={classes}
        onClick={onClick}
        selectedCase={selectedCase}
        claimantName={claimantName}
        respondentName={respondentName}
        claimantcasestatus={claimantcasestatus}

      />
    );
  } else {
    return null;
  }
};

export default Dispute;
