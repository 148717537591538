import React, { useEffect, useState } from "react";
import DetailsReview from "./DetailsReview";
import { CircularProgress } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import { getAnswers } from "slices/answerSlice";
import { getQuestions } from "slices/questionSlice";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

export default function DetailsReviewContainer({profile}) {
  let dispatch = useDispatch();

  let {
    questions: { questions },
    answers: { answers },
    disputes: { activeDispute },
    cases: { activeCase },
  } = useSelector((state) => state, shallowEqual);

  let childProps = {
    activeCase,
    activeDispute,
    questions,
    answers,
    profile
  };

  let loading = isEmpty(questions) || isEmpty(answers);

  useEffect(() => {
    dispatch(getQuestions(activeDispute.company_product, activeCase.type));
    dispatch(getAnswers(activeCase.id));
  }, []);

  return (
    <React.Fragment>
      {!loading ? <DetailsReview {...childProps} /> : <CircularProgress />}
    </React.Fragment>
  );
}
