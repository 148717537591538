import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useDispatch } from "react-redux";
import { createCase } from "slices/casesSlice";
import { DocGenerator, formatDate, LegalDocGet } from "utils";



export default function RespondentDispute({
  dispute,
  profile,
  classes,
  onClick,
  selectedCase,
  disputeStatus,
  claimantName,
  respondentName,
  claimantcasestatus,
}) {
  const dispatch = useDispatch();
  

  const actionClick = async (e) => {
    let name = dispute.name;
    let description = dispute.description;

    let createCaseBody = {
      summary: description,
      description: description,
      profile: profile.id,
      case_status: "in_progress",
      dispute: dispute.id,
      type: RESPONDENT,
    };
    await dispatch(createCase(createCaseBody));
  };

 





  function showViewOrActionForRespondent(selectedCase, actionClick, onClick) {
    if (selectedCase && selectedCase.id) {
      if (selectedCase.case_status === "closed") {
        return (
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ margin: "0.5rem 0" }}
            // style={{ width: "50%" }}
            onClick={async () => {
              LegalDocGet(selectedCase.dispute);
            }}
          >
            Award Download
          </Button>
        );
      } else {
        return (
          <Button
            className={classes.proceedbtn}
            variant="contained"
            color="primary"
            // size="large"
            onClick={onClick}
          >
            <FormattedMessage id="buttons.proceed" />
          </Button>
        );
      }
    } else {
      return (
        <Button
          style={{ margin: "0.5rem 0" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={actionClick}
        >
          <FormattedMessage id="buttons.action" />
        </Button>
      );
    }
  }
  console.log("disputestatuss", dispute)
  return (
    <Box textAlign="center" display="flex" justifyContent="center">
      <Paper className={classes.item}>
        <Card>
          <CardContent>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Grid container >
                  <Grid item xs style={{ display: "flex" }}>
                    <FiberManualRecordIcon style={{ color: "#6FCF79" }} />
                    <Typography variant="body2" style={{ fontSize: "14px" }}>{disputeStatus}</Typography>
                  </Grid>
                  {/* <Box flexGrow={1}></Box> */}
                  <Grid item xs style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="body2" style={{ fontSize: "14px" }}>
                      {/* <FormattedMessage id="dispute.latestDispute" /> */}
                      Latest Grievance
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box height="1rem" />

              <Typography
                gutterBottom
                variant="body1"
                className={classes.dispute_heading}
              // style={{fontSize:"28px", textAlign:"center",padding:"46px 130px 10px 130px"}}
              >
                {claimantName} filed a grievance against {respondentName}{" "}
                on {formatDate(dispute.order_date)}
              </Typography>

              <Box height="1rem" />
              <Grid item xs={12} display="flex" justifyContent="center">
                {claimantcasestatus != 'new' && claimantcasestatus != 'inprogress' && claimantcasestatus != 'dispute_lodged' && claimantcasestatus != 'questions_complete' && claimantcasestatus != 'upload_complete' &&
                  <Typography className={classes.blue} onClick={() => {
                    DocGenerator(dispute.id, "claimant");
                  }}
                    style={{ textDecorationLine: "underline", cursor: "pointer", fontWeight: "bold" }}>View Grievance</Typography>
                }

                {/* <Button
                  // style={{ margin: "0.5rem 0" }}
                  color="primary"
                  size="large"
                  startIcon={<VisibilityIcon />}
                  onClick={() => {
                    DocGenerator(dispute.id, "claimant");
                  }}
                >
                  <FormattedMessage id="dispute.statementOfClaim" />
                </Button> */}
              </Grid>
              <Box height="3rem"></Box>

              {showViewOrActionForRespondent(selectedCase, actionClick, onClick)}
              <Box height="3rem"></Box>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}























// <Box display="flex" justifyContent="center">
// <Button
//   style={{ margin: "0.5rem 0" }}
//   color="primary"
//   size="large"
//   startIcon={<VisibilityIcon />}
//   onClick={() => {
//     DocGenerator(dispute.id, "claimant");
//   }}
// >
//   <FormattedMessage id="dispute.statementOfClaim" />
// </Button>
// </Box>