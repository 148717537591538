import React, { Fragment } from "react";
import Profile from "./Profile";
import { useSelector, shallowEqual } from "react-redux";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import isEmpty from "lodash/isEmpty";



import {
    Box,
    Button,
    FormControl,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";

export default function Popup() {
    const { profile, loading } = useSelector(
        (state) => state.profile,
        shallowEqual
    );
    console.log(profile);

    function openGoogle() {
        window.open("https://www.codevscolor.com/reactjs-open-link-new-tab", "_blank", "noopener noreferrer");
    }
    // function a() {
    //     window.addEventListener("click", function (openGoogle) { });
    // }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    function tab() {
        let newTab = window.open();
        newTab.location.href = 'https://stackoverflow.com/questions/7139103/open-page-in-new-window-without-popup-blocking/50249439#50249439';

    }
    function pop (){
        const pop = (url, w, h) => {
            const popup = window.open('https://thewebdev.info/2021/04/24/how-to-avoid-browser-popup-blockers-with-javascript-code/', '_blank', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=500,height=500');
            return popup !== null && typeof popup !== 'undefined'
        }
        console.log(pop('https://example.com'))

    }

    


    return (
        <Grid item xs={6}>
            <Grid container justify="flex-end">

                <Grid item>
                    <Button
                        onClick={(e) => {
                            window.open('https://stackoverflow.com/questions/47406344/how-to-open-a-page-in-new-tab-on-click-of-a-button-in-react-i-want-to-send-some', '_blank').focus();

                        }}

                    >
                        button 1
                    </Button>
                </Grid>
            </Grid>
            <Grid container justify="flex-end">
                <Grid item>
                    <Button
                        onClick={(e) => {

                            window.location.href = 'http://www.google.com';
                        }}

                    >
                        button 2
                    </Button>
                </Grid>
            </Grid>
            <Grid container justify="flex-end">
                <Grid item>
                    <Button
                        onClick={() => openInNewTab('https://stackoverflow.com')}
                    >
                        button 3
                    </Button>
                </Grid>
            </Grid>
            <Grid container justify="flex-end">
                <Grid item>
                    <Button
                        onClick={openGoogle}
                    >
                        button 4
                    </Button>
                </Grid>

            </Grid>
            <Grid container justify="flex-end">

                <Grid item>
                    <Button
                        // onClick={(e) => {
                        //     newTab.location.href ='https://stackoverflow.com/questions/7139103/open-page-in-new-window-without-popup-blocking/50249439#50249439';

                        // }}
                        onClick={tab}

                    >
                        button 5
                    </Button>
                </Grid>
            </Grid>
            <Grid container justify="flex-end">

                <Grid item>
                    <Button
                        // onClick={(e) => {
                        //     newTab.location.href ='https://stackoverflow.com/questions/7139103/open-page-in-new-window-without-popup-blocking/50249439#50249439';

                        // }}
                        onClick={pop}

                    >
                        button6
                    </Button>
                </Grid>
            </Grid>
            

        </Grid>
    );
}
