import { Box, Button, Hidden, makeStyles, Typography } from "@material-ui/core";
import { AGREEMENT_FAILED, AGREEMENT_SUCCESS } from "app/constants";
import classNames from "classnames";
import { backgrounds } from "contexts/BackgroundContext";
import FormattedMessage from "features/common/TranslatedMessage";
import { useBodyBackgroundChange } from "hooks";
import VECTORSVG from "images/judge/rafiki.svg";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "5rem",
    color: "#707C97",
  },
  redText: {
    color: theme.palette.common.red,
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  primaryText: {
    fontSize: "2rem",
    lineHeight: "2.5rem",
    fontWeight: "600",
  },
  container: {
    width: "60%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  vectorImage: {
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      order: 1,
      marginBottom: "2rem",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

export default function RespondentDecisionStatus({
  flowType,
  name,
  status,
  disputeId,
}) {
  const history = useHistory();
  let {
    redText,
    container,
    primaryText,
    mainContainer,
    vectorImage,
    content,
  } = useStyles();

  useBodyBackgroundChange(backgrounds.LIGHTBLUE);

  const RejectedText = () => {
    return (
      <Fragment>
        <Typography className={classNames(redText, primaryText)}>
          <FormattedMessage id="pages.waitingScreens.claimantDecisionStatus.rejected1" />
        </Typography>
        <Box height="2rem"></Box>

        <Typography className={primaryText}>
          {" "}
          <FormattedMessage id="pages.waitingScreens.claimantDecisionStatus.rejected2" />
        </Typography>
      </Fragment>
    );
  };

  const AcceptedText = () => {
    return (
      <Typography className={primaryText}>
        <FormattedMessage id="pages.waitingScreens.claimantDecisionStatus.accepted" />
      </Typography>
    );
  };

  return (
    <Box textAlign="start">
      <Hidden smDown>
        <Box height="5rem"></Box>
      </Hidden>
      <Box className={container}>
        <div className={mainContainer}>
          <div class={content}>
            <Fragment>
              {(status === AGREEMENT_SUCCESS && AcceptedText()) ||
                (status === AGREEMENT_FAILED && RejectedText())}
            </Fragment>
            <Box height="1.5rem"></Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/disputes/${disputeId}/chat`);
              }}
            >
              <FormattedMessage id="common.goToHearingRooms" />
            </Button>
            <Box height="1.5rem"></Box>
          </div>
          <Hidden smDown>
            <Box flexGrow={1}></Box>
          </Hidden>
          <img
            className={vectorImage}
            src={VECTORSVG}
            alt="Judge vector image"
          />
        </div>
      </Box>
    </Box>
  );
}
