import { DyteSpinner } from "@dytesdk/react-ui-kit";
import { Box, Button, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { instance } from "api/globalaxios";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import DyteMeetingVC from "./DyteMeeting";
// import { ZoomMtg } from "@zoomus/websdk";

// // ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');
// ZoomMtg.setZoomJSLib("https://dev-app.dpcgc.org/dev-lib", "/av");

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();
// // loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load("en-US");
// ZoomMtg.i18n.reload("en-US");

const VideoConferencing = ({
  roomName,
  user,
  profile,
  setShowVC,
  vcType,
  disputeId,
}) => {
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [dyteToken, setDyteToken] = useState('');
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const vcContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  async function startConferenceZoom() {
    try {
      setLoading(true);
      setErrorType(null);
      let response = await instance({
        method: "post",
        url: "/createzoommeeting",
        data: {
          disputeId:"" + disputeId + roomName,
        },
      });
      setLoading(false);
      setShowVC(false)
      // if(profile.profile_type == 'secretariat'){
      //   window.open(response.data.start_url, '_blank').focus();
      // }
      // else{
        // let newTab = window.open();
        if(response.data.user_key == profile.user.id){
          window.open(response.data.start_url, '_blank').focus();
          // window.location.href = response.data.start_url;
        }
        else{
          window.open(response.data.join_url, '_blank').focus();
          // window.location.href = response.data.join_url;
        }
      // }
      // const signature = response?.data?.signature;
      // document.getElementById("zmmtg-root").style.display = "block";
      // const meetConfig = {
      //   signature: signature,
      //   apiKey: "yIatISCgQQGNzCdgV9Ukvg",
      //   meetingNumber: response?.data?.id,
      //   userName: username,
      //   // password optional; set by Host
      //   passWord: response?.data?.password,
      //   success: (result) => {
      //     console.log("success", result);
      //   },
      //   error: (res) => {
      //     console.log(res);
      //   },
      // };
      // ZoomMtg.init({
      //   disableRecord: false,
      //   videoDrag: true,
      //   sharingMode: "both",
      //   leaveUrl: `/disputes/${disputeId}/end-zoom-meeting`,
      //   isSupportAV: true,
      //   success: function () {
      //     ZoomMtg.join(meetConfig);
      //   },
      //   error: (err) => {
      //     console.log(err);
      //   },
      // });
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        console.log(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  async function closePreviousZoomMeetings() {
    try {
      setLoading(true);
      await instance({
        method: "post",
        url: "/closezoompreviousmeetings",
        data: {},
      });
      startConferenceZoom();
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  async function startConferenceDyte(){
    try {
      setLoading(true);
      setErrorType(null);
      let response = await instance({
        method: "post",
        url: "/createdytemeeting",
        data: {
          disputeId: "" + disputeId + roomName,
        },
      });
      setLoading(false);
      setDyteToken(response.data.user.token);
    } catch (err) {
      const reason = err.response?.data?.reason;
      if (reason) {
        setErrorType(reason);
        console.log(reason);
        setLoading(false);
        return;
      }
      console.log(err);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (!isEmpty(api)) api.executeCommand("hangup");
    else {
      if (vcType === "zoom") {
        startConferenceZoom();
      } else if (vcType === "dyte"){
        startConferenceDyte();
      }
    }
  }, [roomName]);

  const renderErrorContainer = () => {
    switch (errorType) {
      case "not-started":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <IconButton
              onClick={() => {
                // history.push("/" + arr[1] + "/" + arr[2]);
                // history.goBack()
                setShowVC(false);
              }}
            >
              <ArrowBackIosIcon /> Back
            </IconButton>
            <h3>Meeting not started by host</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={startConferenceZoom}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Retry
              </Button>
            </Box>
          </Box>
        );
      case "not-found":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Meeting with the dispute id {disputeId} does not exist</h3>
          </Box>
        );
      case "in-meeting":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>You are already a host in some other meeting.</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={startConferenceZoom}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Retry
              </Button>
            </Box>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={closePreviousZoomMeetings}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Close Previous Meetings & Retry
              </Button>
            </Box>
          </Box>
        );
      case "meeting-close-error":
        return (
          <Box
            display="flex"
            alignItems="center"
            pt="3rem"
            flexDirection="column"
          >
            <h3>Error while closing meetings.</h3>
            <Box mt="3rem">
              <Button
                variant="contained"
                color="primary"
                // size="large"
                onClick={closePreviousZoomMeetings}
                style={{
                  background: "#2F80ED",
                  color: "white",
                  fontSize: "20px",
                  borderRadius: "2px",
                }}
              >
                Close Previous Meetings & Retry
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const removeSessionStorage = () => {
    sessionStorage.clear();
  };

  function handleEndVideoDyte(){
    removeSessionStorage();
    setShowVC(false);
    setApi(null);
    setDyteToken("");
  }

  return (
    <Box display="flex" justifyContent="center" flexBasis="50%" flexGrow={1}>
      <div style={containerStyle}>
        {loading && (
          <Box display="flex" justifyContent="center" pt="3rem">
            <CircularCenteredLoader />
          </Box>
        )}
        {errorType && renderErrorContainer()}
        {(vcType === "dyte" && dyteToken) ? (
          <DyteMeetingVC
            dyteToken={dyteToken}
            setShowVC={setShowVC}
            vcType={vcType}
            disputeId={disputeId}
            roomName={roomName}
            vcContainerStyle={vcContainerStyle}
            onEnd={handleEndVideoDyte}
          />
        ):
        (vcType === 'jitsi') ?
          (
            <div id="vc-container" style={vcContainerStyle} />
          )
        :
          (
            <DyteSpinner/>
          )
        }
      </div>
    </Box>
  );
};
export default VideoConferencing;
