import get from "lodash/get";
import React from "react";
import { FormattedMessage } from "react-intl";

const defaults = require("translations/en.json");

export default function TranslatedMessage({ id, ...values }) {
  return (
    <FormattedMessage
      id={id}
      values={values}
      defaultMessage={get(defaults, id)}
    />
  );
}
