import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { SECRETARIAT } from "app/constants";
import classNames from "classnames";
import GroupDisplay from "features/Chat/GroupDisplay";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createGroupName } from "utils";

const findSecretariatGroup = (cases, disputeId, groupName, activeDispute) => {
  let profileId = groupName.split("-")[1];

  if (profileId) {
    // Support group with claimant and respondent
    let supportTo = cases.find((singleCase) => {
      return (
        singleCase.dispute === disputeId &&
        singleCase.profile === parseInt(profileId)
      );
    });
    if (supportTo) return `${supportTo.type}-1`;

    // Support group with lawyers

    let supportToLawyer = cases.find((singleCase) => {
      return (
        singleCase.dispute === disputeId &&
        singleCase.lawyer === parseInt(profileId)
      );
    });
    if (supportToLawyer) return `${supportToLawyer.type}-2`;

    if (activeDispute.professionals.length > 0) {
      let professionalIndex = activeDispute.professionals.findIndex(
        (singleProfessional) => singleProfessional === parseInt(profileId)
      );
      if (professionalIndex > -1) return `Arbitrator-${professionalIndex + 1}`;
    }
  }

  return groupName;
};

const renderGroups = (
  groups,
  classes,
  selected,
  setSelected,
  setStep,
  disputeId,
  cases,
  profileType,
  activeDispute
) => {
  return groups.map((group, index) => {
    const listClasses = classNames({
      [classes.item]: true,
      [classes.selected]: selected === index,
    });

    let groupName = createGroupName(group.name);
    // console.log("groupName: ", groupName);

    // if case manager is the logged in user
    if (profileType === SECRETARIAT) {
      groupName = findSecretariatGroup(
        cases,
        disputeId,
        groupName,
        activeDispute
      );
    }

    // show_grp = <GroupDisplay group={group} caption={true} name={groupName} />;
    // console.log("profileType: ", profileType );
    console.log("groupName---->", groupName);
  
    if(groupName == "DPCGC" || (groupName == "Breakout" ))
    return (
      <React.Fragment>
        <ListItem
          button
          key={group.name}
          index={index}
          className={listClasses}
          onClick={(e) => {
            setSelected(index);
            setStep(1);
          }}
        >
        {/* {show_grp} */}
        {
          ((profileType == "general"   ||
            profileType == "vendor"    ||
            profileType == SECRETARIAT ||
            profileType == "lawyer"    ||
            profileType == "secretariat") && groupName == "DPCGC")
            ? 
            <GroupDisplay group={group} caption={true} name={groupName} />
            : (profileType == "arbitrator" && (groupName == "DPCGC" || groupName == "Breakout")) 
            ?
            <GroupDisplay group={group} caption={true} name={groupName} />
            :
            // ""
            <GroupDisplay group={group} caption={true} name={groupName} />
        }
          
          {/* <GroupDisplay group={group} caption={true} name={groupName} /> */}
        </ListItem>
        {/* <Divider /> */}
      </React.Fragment>
    );
      

  });
};

export default function ChatSidebar({
  classes,
  selected,
  setSelected,
  setStep,
}) {
  const groups = useSelector((state) => state.groups.groups);
  const cases = useSelector((state) => state.cases.cases);
  const activeDispute = useSelector(
    (state) => state.disputes.activeDispute,
    shallowEqual
  );
  const { id: disputeId } = activeDispute;
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const theme = useTheme();
  return (
    <Box>
      <Box className={classes.drawer}>
        <List style={{ padding: "0" }}>
          <ListItem
            style={{
              height: "6rem",
              borderBottom: `0.1rem solid ${theme.palette.border}`,
            }}
          >
            <Typography variant="h5" style={{ fontSize: "20px",lineHeight:"23px",color:"#333333",marginLeft:"17px" }}>
              <FormattedMessage id="common.hearingRoom" />
            </Typography>
          </ListItem>
          {renderGroups(
            groups,
            classes,
            selected,
            setSelected,
            setStep,
            disputeId,
            cases,
            profileType,
            activeDispute
          )}
          {/* <Divider style={{ background: "#fff" }} /> */}
        </List>
      </Box>
    </Box>
  );
}
