import { Box, Hidden, makeStyles, Typography } from "@material-ui/core";
import { backgrounds } from "contexts/BackgroundContext";
import FormattedMessage from "features/common/TranslatedMessage";
import { useBodyBackgroundChange } from "hooks";
import VECTORSVG from "images/judge/rafiki.svg";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    textAlign: "start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "5rem",
    color: "#707C97",
  },
  redText: {
    color: theme.palette.common.red,
  },
  primaryText: {
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.5rem",
    },
  },
  secondaryText: {
    fontSize: "1.3rem",
  },
  container: {
    width: "60%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  vectorImage: {
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      order: 1,
      marginBottom: "2rem",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

export default function RespondentSelectionStatus({ flowType }) {
  const classes = useStyles();

  useBodyBackgroundChange(backgrounds.LIGHTBLUE);

  return (
    <Box>
      <Box height="5rem"></Box>
      <Box className={classes.container}>
        <div className={classes.mainContainer}>
          <div className={classes.content}>
            <Typography className={classes.primaryText}>
              <FormattedMessage id="pages.waitingScreens.respondentWaiting.heading1" />
            </Typography>
            <Typography className={classes.primaryText}>
              <FormattedMessage id="pages.waitingScreens.respondentWaiting.heading2" />
              {flowType}
            </Typography>
            <Box height="2rem"></Box>
            <Typography variant="h3" className={classes.primaryText}>
              <FormattedMessage id="pages.waitingScreens.respondentWaiting.heading3" />
              {/* {flowType} */}
            </Typography>
          </div>
          <Hidden smDown>
            <Box flexGrow={1}></Box>
          </Hidden>
          <img
            className={classes.vectorImage}
            src={VECTORSVG}
            alt="judge svg image"
          />
        </div>
      </Box>
    </Box>
  );
}
