import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FolderIcon from "@material-ui/icons/Folder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { MEDIATION, RESPONDENT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { DocGenerator } from "utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import {
  downloadCaseNote,
  updateDispute,
  uploadCaseNote,
  uploadDispute,
} from "slices/disputeSlice";
import { useDispatch } from "react-redux";
import { downloadLegalDoc } from "slices/disputeSlice";
import { formatDate } from "utils";
import { downloadEvidence } from "slices/disputeSlice";
import instance from "api/globalaxios";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  topText: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
  disputeheading: {
    fontSize: "26px",
    lineHeight: "36px",
    paddingTop: "35px",
    paddingLeft: "35px",
    paddingRight: "600px",
  },
  text: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    // background:"grey"
  },
  blue: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    color: "#2F80ED",
    lineHeight: "24px",
    fontSize: "16px",
    cursor: "text",
    padding: "10px 10px 10px 0",
    "&:hover": {
      color: "#2F80ED",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    }
  },
  fonts: {
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
      fontSize: "14px",
    }
  },
  greenbutton: {
    backgroundColor: "#6FCF97",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 6px"
    }
  },
  yellowbutton: {
    backgroundColor: "#F2C94C",
    border: "none",
    color: "#4F4F4F;",
    padding: "5px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "20px",
    borderRadius: "24px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 6px"
    }
  },
  item: {
    "&:hover": {
      // cursor: 'pointer',
      background: "#EEEEEE",
    },
  },
  closedbtn: {
    background: "#E0E0E0",
    color: "white",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  joinbtn: {
    background: "#2F80ED",
    fontSize: "20px",
    borderRadius: "2px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    }
  },
  calenderBtn: {
    fontSize: "16px",
    color: "#4F4F4F",
    textTransform: "none",
    borderRadius: "2px",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    }
  },
  closed: {
    background: "#E0E0E0",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  cardPadding: {
    padding: "3.5rem",
    [theme.breakpoints.down("md")]: {
      padding: "2.5rem"
    }
  },
  cardName: {
    fontSize: "28px",
    lineHeight: "36px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      lineHeight: "28px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
      lineHeight: "25px"
    }
  }
}));
export default function DisputeDisplay({ activeDispute, activeCase, profile ,claimantcasestatus}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [hearingDate, setHearingDate] = React.useState("--");

  React.useEffect(() => {
    if (hearingDate == "--") {
      dispatch(getAllMeetings2());
    }
  }, [hearingDate]);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [files2, setFiles2] = React.useState([]);
  const [decisionFiles, setdecisionFiles] = React.useState(
    activeDispute.all_docs
  );
  const [CaseNotes, setCaseNotes] = React.useState(activeDispute.all_notes);
  const [finalDecision, setFinalDecision] = React.useState();
  const [evidenceFiles, setevidenceFiles] = React.useState(
    activeDispute.all_evidence
  );

  const [isDisputeClosed, setIsDisputeClosed] = React.useState(
    activeDispute.status == "closed"
  );

  React.useEffect(() => {
    decisionFiles.forEach((file) => {
      if (file.is_final) {
        setFinalDecision(file.id);
      }
    });
  }, [decisionFiles]);

  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };

  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  const addFile2 = (file) => {
    files2.push(file);
    setFiles2(files2);
  };

  const deleteFile2 = (index) => {
    let localFiles = [...files2];
    localFiles.splice(index, 1);
    setFiles2(localFiles);
  };

  console.log("activeecaseee", activeCase)

  const getAllMeetings2 = () => async (dispatch) => {
    try {
      let response = await instance({
        method: "get",
        url: `/api/user-meetings-list/`,
      });
      // console.log(response.data);
      let data = response.data.appointments;
      let latestAppointment;
      data.forEach((appointment) => {
        if (appointment.meeting.dispute == activeDispute.id) {
          // console.log(appointment.meeting);
          if (latestAppointment) {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            let d2 = new Date(latestAppointment.meeting.start);
            if (d1 < d2 && d1 > d) {
              latestAppointment = appointment;
            } else {
            }
          } else {
            let d = new Date();
            let d1 = new Date(appointment.meeting.start);
            if (d1 > d) latestAppointment = appointment;
          }
        }
      });

      console.log("Latest hearing ::: ", latestAppointment);
      if (latestAppointment) {
        setHearingDate(format(new Date(latestAppointment.meeting.start), "d MMMM y hh:mm a"));
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  const renderFiles = (files, deleteFile) => {
    return files.length !== 0
      ? files.map((file, index) => {
        return (
          <Box
            textAlign="center"
            position="relative"
            key={index}
          // display="flex"
          // justifyContent="center"
          >
            <Box>
              <Typography
                style={{ wordBreak: "break-all", paddingTop: "20px" }}
                variant="subtitle2"
              >
                {file.name}
              </Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })
      : null;
  };

  const renderFiles2 = (files, deleteFile) => {
    return files.length !== 0
      ? files.map((file, index) => {
        return (
          <Box
            textAlign="center"
            position="relative"
            key={index}
          // display="flex"
          // justifyContent="center"
          >
            <Box>
              <Typography
                style={{ wordBreak: "break-all", paddingTop: "20px" }}
                variant="subtitle2"
              >
                {file.name}
              </Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })
      : null;
  };

  const UploadDialog = ({ open, setOpen, addFile }) => {
    const classes = useStyles();
    return (
      <Box
        textAlign="center"
        position="relative"
        className={isDisputeClosed ? classes.closed : classes.fonts}
      >
        <Box>
          <Box>
            <IconButton
              className={classes.icon}
              aria-label="delete"
              // color="primary"
              onClick={() => {
                if (!isDisputeClosed) setOpen(true);
              }}
            >
              <AddCircleOutlineIcon className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        <DropzoneDialog
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            console.log("Files:", files);
            files.forEach((file) => {
              addFile(file);
            });
            setOpen(false);
          }}
          showPreviews={true}
          filesLimit="1"
          showFileNamesInPreview={true}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/msword",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </Box>
    );
  };

  const UploadDialog2 = ({ open, setOpen, addFile }) => {
    const classes = useStyles();
    return (
      <Box
        textAlign="center"
        position="relative"
        className={isDisputeClosed ? classes.closed : classes.fonts}
      >
        <Box>
          <Box>
            <IconButton
              className={classes.icon}
              aria-label="delete"
              // color="primary"
              onClick={() => {
                if (!isDisputeClosed) setOpen(true);
              }}
            >
              <AddCircleOutlineIcon className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        <DropzoneDialog
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={5000000}
          open={open}
          onClose={() => setOpen(false)}
          onSave={(files) => {
            console.log("Files:", files);
            files.forEach((file) => {
              addFile(file);
            });
            setOpen(false);
          }}
          showPreviews={true}
          filesLimit="1"
          showFileNamesInPreview={true}
          acceptedFiles={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/msword",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
        />
      </Box>
    );
  };

  const onClickProceed = () => {
    if (files.length !== 0) {
      dispatch(uploadDispute(files, activeDispute)).then((resp) =>
        dispatch(
          updateDispute({ id: activeDispute.id, status: "webnyay_upload" })
        )
      );
    }
  };

  const onClickProceed2 = () => {
    if (files2.length !== 0) {
      dispatch(uploadCaseNote(files2, activeDispute)).then((resp) =>
        console.log()
      );
    }
  };

  console.log("activeDispute:", activeDispute);

  return (
    <Container>
      <Box p="2rem 0">
        <Grid container justify="center">
          {/* <Grid item md={2} sm={0} xs={0}  style={{border:"1px solid"}}></Grid> */}
          <Grid item md={8} sm={12} xs={12}>
            <Paper
              style={{ background: "none", border: "none", boxShadow: "none", padding: "10px" }}
            >
              <Button
                onClick={() => {
                  // history.push("/disputes");
                  history.goBack()
                }}
                size="large"
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Paper>
            <Paper className={classes.cardPadding}>
              {(profile.profile_type == "general" ||
                profile.profile_type == "arbitrator" ||
                profile.profile_type == "guest_general" ||
                profile.profile_type == "guest_arbitrator") && (
                  <Grid container>
                    <Grid item md={5}>
                      <Box className={classes.text}>
                        <Typography className={classes.cardName}>
                          {activeDispute.name}
                        </Typography>
                        <Box height="1rem"></Box>
                        {claimantcasestatus != 'new' && claimantcasestatus != 'inprogress' && claimantcasestatus != 'dispute_lodged' && claimantcasestatus != 'questions_complete' && claimantcasestatus != 'upload_complete' &&
                          <Typography
                            className={classes.blue}
                            onClick={() => {
                              DocGenerator(activeDispute.id, "claimant");
                            }}
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            VIEW GRIEVANCE
                          </Typography>
                        }

                        <Box height="2rem"></Box>
                      </Box>
                    </Grid>
                  </Grid>

                )}
              {(profile.profile_type == "vendor" ||
                profile.profile_type == "guest_company") && (
                  <>
                    <Grid container>
                      <Grid item md={5}>
                        <Box className={classes.text}>
                          <Typography
                            className={classes.cardName}
                          >
                            {activeDispute.name}
                          </Typography>
                          <Box height="1rem"></Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                     {claimantcasestatus != 'new' && claimantcasestatus != 'inprogress' && claimantcasestatus != 'dispute_lodged' && claimantcasestatus != 'questions_complete' && claimantcasestatus != 'upload_complete' &&
                        <Grid item xs sm={3} md={3}>
                          <Typography
                            className={classes.blue}
                            onClick={() => {
                              DocGenerator(activeDispute.id, "claimant");
                            }}
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            VIEW GRIEVANCE
                          </Typography>
                        </Grid>
                      }

                      {activeDispute.status != "new" &&
                activeDispute.status != "respondent_inprogress" &&
                activeDispute.status != "respondent_complete" &&
                        <Grid item xs sm={3} md={3}>
                          <Typography
                            className={classes.blue}
                            onClick={() => {
                              DocGenerator(activeDispute.id, RESPONDENT);
                            }}
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            VIEW RESPONSE
                          </Typography>
                        </Grid>
                      }

                    </Grid>
                    <Box height="2rem"></Box>
                  </>
                )}
              <Grid container className={classes.fonts} justify="center" alignItems="flex-end" style={{ padding: "10px 0 10px 0" }}>
                <Grid item xs={12} md={6}>
                  <a className={classes.blue}>Grievance ID </a>
                  {activeDispute.approved
                    ? activeDispute.custom_dispute_id
                    : "--"}
                  <Box height="0.1rem"></Box>
                  <a className={classes.blue}>Hearing Date </a>
                  {isDisputeClosed ? "--" : hearingDate}
                  <Box height="0.1rem"></Box>
                  <a className={classes.blue}>Decision </a>
                  {finalDecision ? (
                    <u
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                        color: "#2F80ED",
                        lineHeight: "24px",
                      }}
                      onClick={() => {
                        downloadLegalDoc(finalDecision);
                      }}
                    >
                      VIEW
                    </u>
                  ) : (
                    "(In Progress)"
                  )}
                  <Box height="0.1rem"></Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="space-evenly">
                    <Grid item xs={12} sm={6} md={6} style={{ padding: "10px" }}>
                      <Button
                        className={classes.calenderBtn}
                        variant="outlined"
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push("/events");
                        }}
                      >
                        <FormattedMessage id="buttons.calendar" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ padding: "10px" }}>
                      <Button
                        variant="outlined"
                        className={classes.calenderBtn}
                        color="myblack"
                        size="large"
                        onClick={() => {
                          history.push(`/disputes/${activeDispute.id}/files`);
                        }}
                      >
                        <FormattedMessage id="common.files" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Box display="flex" justifyContent="center">
                <Button
                  style={{ margin: "0.5rem 0 0 0" }}
                  color="primary"
                  size="large"
                  startIcon={<VisibilityIcon />}
                  onClick={() => {
                    DocGenerator(activeDispute.id, "claimant");
                  }}
                >
                  <FormattedMessage id="dispute.statementOfClaim" />
                </Button>
              </Box>
              <Box display="flex" justifyContent="center">
                {activeDispute.flow_type !== MEDIATION && (
                  <Button
                    style={{ margin: "0 0 0.5rem 0" }}
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      DocGenerator(activeDispute.id, RESPONDENT);
                    }}
                  >
                    <FormattedMessage id="dispute.statementOfDefence" />
                  </Button>
                )}
              </Box> */}
            </Paper>
            <Box height="4rem"></Box>
            {
              // activeDispute.status != "new" &&
              // activeDispute.status != "respondent_inprogress" &&
              // activeDispute.status != "respondent_complete" &&
              <Box display="flex" justifyContent="center">
                {isDisputeClosed ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.closedbtn}
                  // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px" }}
                  >
                    <FormattedMessage id="common.join" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.joinbtn}
                    onClick={() => {
                      history.push(`/disputes/${activeDispute.id}/chat`);
                    }}
                  >
                    <FormattedMessage id="common.join" />
                  </Button>
                )}
              </Box>
            }
            <Box height="4rem"></Box>

            {/* {(temp) &&
            (profile.profile_type=="general")  && */}

            {/* {evidenceFiles.length > 0 && <> */}
            {
              <>
                <Box
                  className={classes.fonts}
                  style={{
                    padding: "20px",
                    color: "#333333",
                    lineHeight: "36px",
                  }}
                >
                  <Grid container justify="space-between" direction="row">
                    <Grid item sm={7}>Case Evidence</Grid>
                    <Grid item sm={2} xs={4}>Submission</Grid>
                  </Grid>
                </Box>
                <Paper>
                  {evidenceFiles.length > 0 &&
                    evidenceFiles.map((file) => {
                      return (
                        <Box className={classes.item}>
                          <Box
                            className={classes.fonts}
                            style={{
                              padding: "10px 20px",
                              color: "#4F4F4F",
                              lineHeight: "19px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (file.s3path) downloadEvidence(file.id);
                              else console.log("s3path is null");
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                            >
                              <Grid
                                item
                                sm={7}
                                xs={4}
                                style={{
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  padding:"5px",
                                  alignSelf:"center"
                                }}
                              >
                                {file.name}
                              </Grid>
                              <Grid item sm={3} xs={4} style={{
                                padding:"5px",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                              }}>
                                {file.sent_by === "Complainant" ? (
                                  <button className={classes.greenbutton}>
                                    {file.sent_by}
                                  </button>
                                ) : (
                                  <button className={classes.yellowbutton}>
                                    {file.sent_by}
                                  </button>
                                )}
                              </Grid>
                              <Grid item sm={2} xs={4} style={{
                                color: "#828282", padding: "5px",
                                display: "flex",
                                alignItems: "center"
                              }}>
                                {formatDate(file.modified)}
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      );
                    })}
                </Paper>
              </>
            }
            {(profile.profile_type == "arbitrator" ||
              profile.profile_type == "guest_arbitrator") && (
                <>
                  <Box
                    className={classes.fonts}
                    style={{
                      padding: "20px",
                      color: "#333333",
                      lineHeight: "36px",
                    }}
                  >
                    <Box height="3rem"></Box>
                    <Grid container justify="space-between" direction="row">
                      <Grid item sm={7}>Upload Case Note</Grid>
                      <Grid item sm={2}>Upload Date</Grid>
                    </Grid>
                  </Box>
                  <Paper>
                    {isDisputeClosed ? (
                      <Box>
                        {CaseNotes.length > 0 &&
                          CaseNotes.map((file) => {
                            return (
                              <React.Fragment>
                                <Box
                                  style={{
                                    padding: "10px 20px",
                                    color: "#4F4F4F",
                                    lineHeight: "19px",
                                  }}
                                  className={classes.closed}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    className={classes.fonts}
                                  >
                                    <Grid
                                      item
                                      sm={9}
                                      style={{
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      }}
                                    >
                                      {file.name}
                                    </Grid>
                                    <Grid item style={{ color: "#828282" }}>
                                      {formatDate(file.modified)}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </React.Fragment>
                            );
                          })}
                      </Box>
                    ) : (
                      <Box style={{ cursor: "pointer" }}>
                        {CaseNotes.length > 0 &&
                          CaseNotes.map((file) => {
                            return (
                              <React.Fragment>
                                <Box
                                  style={{
                                    padding: "10px 20px",
                                    color: "#4F4F4F",
                                    lineHeight: "19px",
                                  }}
                                  className={classes.item}
                                  onClick={() => {
                                    if (file.s3path) downloadCaseNote(file.id);
                                    else console.log("s3path is null");
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    className={classes.fonts}
                                  >
                                    <Grid
                                      item
                                      sm={9}
                                      style={{
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      }}
                                    >
                                      {file.name}
                                    </Grid>
                                    <Grid item style={{ color: "#828282" }}>
                                      {formatDate(file.modified)}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </React.Fragment>
                            );
                          })}
                      </Box>
                    )}

                    <Box
                      className={isDisputeClosed ? classes.closed : classes.fonts}
                      style={{
                        padding: "10px 20px",
                        color: "#4F4F4F",
                        lineHeight: "19px",
                      }}
                    >
                      <Grid container justify="center">
                        <Grid item>
                          {activeDispute.status != "new" &&
                            activeDispute.status != "respondent_inprogress" &&
                            activeDispute.status != "respondent_complete" && (
                              <Box>
                                {files2.length === 0 && (
                                  <UploadDialog2
                                    open={open2}
                                    setOpen={setOpen2}
                                    addFile={addFile2}
                                  />
                                )}
                                {renderFiles2(files2, deleteFile2)}
                              </Box>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  <Box height="3rem"></Box>

                  <Box display="flex" justifyContent="center">
                    {isDisputeClosed ||
                      activeDispute.status == "new" ||
                      activeDispute.status == "respondent_inprogress" ||
                      activeDispute.status == "respondent_complete" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.closedbtn}
                      // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px" }}
                      >
                        <FormattedMessage id="buttons.submit" />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.joinbtn}
                        onClick={onClickProceed2}
                      >
                        <FormattedMessage id="buttons.submit" />
                      </Button>
                    )}
                  </Box>
                </>
              )}
            {(profile.profile_type == "arbitrator" ||
              profile.profile_type == "guest_arbitrator") && (
                <>
                  <Box
                    className={classes.fonts}
                    style={{
                      padding: "20px",
                      color: "#333333",
                      lineHeight: "36px",
                    }}
                  >
                    <Box height="3rem"></Box>
                    <Grid container justify="space-between" direction="row">
                      <Grid item>Upload Decision</Grid>
                      <Grid item>Upload Date</Grid>
                    </Grid>
                  </Box>
                  <Paper>
                    {isDisputeClosed ? (
                      <Box>
                        {decisionFiles.length > 0 &&
                          decisionFiles.map((file) => {
                            return (
                              <React.Fragment>
                                <Box
                                  style={{
                                    padding: "10px 20px",
                                    color: "#4F4F4F",
                                    lineHeight: "19px",
                                  }}
                                  className={classes.closed}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    className={classes.fonts}
                                  >
                                    <Grid
                                      item
                                      sm={9}
                                      style={{
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      }}
                                    >
                                      {file.name}
                                    </Grid>
                                    <Grid item style={{ color: "#828282" }}>
                                      {formatDate(file.modified)}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </React.Fragment>
                            );
                          })}
                      </Box>
                    ) : (
                      <Box style={{ cursor: "pointer" }}>
                        {decisionFiles.length > 0 &&
                          decisionFiles.map((file) => {
                            return (
                              <React.Fragment>
                                <Box
                                  style={{
                                    padding: "10px 20px",
                                    color: "#4F4F4F",
                                    lineHeight: "19px",
                                  }}
                                  className={classes.item}
                                  onClick={() => {
                                    if (file.s3path) downloadLegalDoc(file.id);
                                    else console.log("s3path is null");
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    className={classes.fonts}
                                  >
                                    <Grid
                                      item
                                      sm={9}
                                      style={{
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                      }}
                                    >
                                      {file.name}
                                    </Grid>
                                    <Grid item style={{ color: "#828282" }}>
                                      {formatDate(file.modified)}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </React.Fragment>
                            );
                          })}
                      </Box>
                    )}

                    <Box
                      className={isDisputeClosed ? classes.closed : classes.fonts}
                      style={{
                        padding: "10px 20px",
                        color: "#4F4F4F",
                        lineHeight: "19px",
                      }}
                    >
                      <Grid container justify="center">
                        <Grid item>
                          {activeDispute.status != "new" &&
                            activeDispute.status != "respondent_inprogress" &&
                            activeDispute.status != "respondent_complete" && (
                              <Box>
                                {files.length === 0 && (
                                  <UploadDialog
                                    open={open}
                                    setOpen={setOpen}
                                    addFile={addFile}
                                  />
                                )}
                                {renderFiles(files, deleteFile)}
                              </Box>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  <Box height="3rem"></Box>

                  <Box display="flex" justifyContent="center">
                    {isDisputeClosed ||
                      activeDispute.status == "new" ||
                      activeDispute.status == "respondent_inprogress" ||
                      activeDispute.status == "respondent_complete" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.closedbtn}
                      // style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px" }}
                      >
                        <FormattedMessage id="buttons.submit" />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          background: "#2F80ED",
                          fontSize: "20px",
                          borderRadius: "2px",
                        }}
                        onClick={onClickProceed}
                      >
                        <FormattedMessage id="buttons.submit" />
                      </Button>
                    )}
                  </Box>
                </>
              )}

            <Box height="3rem"></Box>
          </Grid>
        </Grid>
      </Box>
      <Box height="5rem"></Box>
    </Container>
  );
}
