import {
  Box,
  Container,
  Button,
  makeStyles,
  Typography,
  Grid,
  Paper,
  Hidden,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateDispute } from "slices/disputeSlice";
import { useBodyBackgroundChange } from "hooks";
import VECTORSVG from "images/judge/rafiki.svg";
import { backgrounds } from "contexts/BackgroundContext";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DocGenerator } from "utils/apituils";

import {
  ARBITRATION,
  COMPLETE,
  CLAIMANT_COMPLETE,
  RESPONDENT,
  CLAIMANT,
  ADJUDICATION,
} from "app/constants";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    textAlign: "start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "5rem",
    color: "#707C97",
  },
  redText: {
    color: theme.palette.common.red,
  },
  primaryText: {
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.5rem",
    },
  },
  secondaryText: {
    fontSize: "1.3rem",
    lineHeignt: "1.3rem",
  },
  primaryButtons: {},
  container: {
    width: "50%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  vectorImage: {
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      order: 1,
      marginBottom: "2rem",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

export default function ClaimandAdjudicationDecision({
  activeDispute,
  activeCase,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useBodyBackgroundChange(backgrounds.LIGHTBLUE);

  async function onClickHandler(disputeStatus) {
    await dispatch(
      updateDispute({
        id: activeDispute.id,
        flow_type: ADJUDICATION,
        status: disputeStatus,
      })
    );
  }

  return (
    <>
      <Box>
        <Box height="5rem"></Box>
        <Box className={classes.container}>
          <div className={classes.mainContainer}>
            <div className={classes.content}>
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaitingAdjudication.heading1" />
                {/* {flowType} */}
              </Typography>
              <Box height="3rem" />
              <Typography className={classes.primaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaitingAdjudication.heading2" />
              </Typography>
              <Box height="2rem"></Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={async (e) => {
                    DocGenerator(activeDispute.id, RESPONDENT);
                  }}
                >
                  <Typography variant="button">
                    {" "}
                    <FormattedMessage id="pages.waitingScreens.claimantWaitingAdjudication.buttonResponse" />
                  </Typography>
                </Button>
                <Box width="4rem" />
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    DocGenerator(activeDispute.id, CLAIMANT);
                  }}
                >
                  <Typography variant="button">
                    <FormattedMessage id="dispute.statementOfClaim" />
                  </Typography>
                </Button>
              </Box>
              <Box height="2rem"></Box>
              <Typography className={classes.secondaryText}>
                <FormattedMessage id="pages.waitingScreens.claimantWaitingAdjudication.heading3" />
                {/* {flowType} */}
              </Typography>
            </div>
            <Hidden smDown>
              <Box flexGrow={1}></Box>
            </Hidden>
            <img
              className={classes.vectorImage}
              src={VECTORSVG}
              alt="judge svg image"
            />
          </div>
          <Box height="2rem"></Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(e) => {
                onClickHandler(COMPLETE);
              }}
            >
              <Typography variant="button">
                {" "}
                <FormattedMessage id="dispute.resolve" />
              </Typography>
            </Button>
            <Box width="4rem" />
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={(e) => {
                onClickHandler(CLAIMANT_COMPLETE);
              }}
            >
              <Typography variant="button">
                {" "}
                <FormattedMessage id="common.goToHearingRooms" />
              </Typography>
            </Button>
          </Box>
          <Box height="2rem" />
        </Box>
      </Box>
    </>
  );
}
