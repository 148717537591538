import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import { CLAIMANT } from "app/constants";
import { format } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import { introFormContext } from "features/intro/IntroContainer";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RespondentDetailsSchema } from "schemas/RespondentDetailsSchema";
import { createCase } from "slices/casesSlice";
import { createDispute } from "slices/disputeSlice";
import { createProfileIfNot } from "slices/profileSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RespondentDetailsForm = ({
  profile,
  user,
  companies,
  respondentProfileValues: {
    user: { email = "", first_name = "", last_name = "" } = {},
    phone_number = "",
    birth_date = Date("yyyy-MM-dd"),
    gender = "male",
  },
  empty: profileEmpty,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);

  const {
    claimantFormValues: {
      birth_date: claimantBirthdate,
      company_product,
      order_date,
    },
  } = useContext(introFormContext);

  let history = useHistory();

  const debouncedOnSubmit = debounce((values) => {
    let company = companies.find((element) => element.id === profile.company);

    let name = `${company.name} vs ${values.firstName} ${values.lastName}`;
    let description = name;
    let disputeBody = {
      name: name,
      company_product: company_product,
      order_date: format(order_date, "yyyy-MM-dd"),
      description: description,
    };

    let createCaseBody = {
      summary: description,
      description: description,
      profile: profile.id,
      type: CLAIMANT,
    };

    const respondentProfileBody = {
      email: values.respondentEmail,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      phoneNumber: values.phoneNumber,
    };

    dispatch(createDispute(disputeBody))
      .then((response) => {
        createCaseBody.dispute = response;
        return dispatch(createCase(createCaseBody));
      })
      .then(() => {
        return dispatch(createProfileIfNot(respondentProfileBody));
      })
      .then((response) => {
        let respondentCaseBody = {
          summary: createCaseBody.summary,
          description: createCaseBody.description,
          type: "respondent",
          profile: response.id,
          dispute: createCaseBody.dispute,
        };

        return dispatch(createCase(respondentCaseBody));
      })
      .then((response) => {
        history.push(`/disputes/${response.dispute}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000);

  const formik = useFormik({
    initialValues: {
      respondentEmail: email,
      firstName: first_name,
      lastName: last_name,
      password: "Test@123",
      confirmPassword: "Test@123",
      phoneNumber: phone_number,
    },
    onSubmit: debouncedOnSubmit,
    validationSchema: RespondentDetailsSchema,
  });

  console.log(formik.errors);
  return (
    <Box p="2rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} md={6}>
          <Box>
            <Box p="0rem 2rem">
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom align="center">
                  <FormattedMessage id="pages.intro.respondentDetails" />
                </Typography>
                <Box height="1rem"></Box>

                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.respondentEmail}
                  required
                  fullWidth
                  id="respondentEmail"
                  label={<FormattedMessage id="user.email" />}
                  name="respondentEmail"
                  autoComplete="respondentEmail"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.respondentEmail &&
                  formik.touched.respondentEmail && (
                    <Alert severity="error">
                      {formik.errors.respondentEmail}
                    </Alert>
                  )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.firstName}
                  required
                  fullWidth
                  id="firstName"
                  label={<FormattedMessage id="user.firstName" />}
                  name="firstName"
                  autoComplete="firstName"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <Alert severity="error">{formik.errors.firstName}</Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={formik.values.lastName}
                  required
                  fullWidth
                  id="lastName"
                  label={<FormattedMessage id="user.lastName" />}
                  name="lastName"
                  autoComplete="lastName"
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <Alert severity="error">{formik.errors.lastName}</Alert>
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phoneNumber"
                  label={<FormattedMessage id="user.phoneNumber" />}
                  type="number"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={!profileEmpty || !isEmpty(phone_number)}
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <Alert severity="error">{formik.errors.phoneNumber}</Alert>
                )}

                <Box display="flex" justifyContent="center">
                  {caseLoading || disputeLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={caseLoading || disputeLoading}
                    >
                      {<FormattedMessage id="buttons.submit" />}
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default RespondentDetailsForm;
