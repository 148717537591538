import React, { useState } from 'react'
import {
    Box,
    Button,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography,
    Hidden,
    Snackbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { getWindowDimension } from "utils/responsiveUtils";
import { useDispatch } from "react-redux";
import { downloadEvidence, uploadEvidence } from "slices/disputeSlice";
import { formatDate } from "utils";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DropzoneDialog } from "material-ui-dropzone";
import instance from 'api/globalaxios';
import deleteSvg from "images/delete.svg";

const useStyles = makeStyles((theme) => ({
    fonts: {
        lineHeight: "24px",
        fontSize: "16px",
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
        padding: "10px 20px",
        color: "#4F4F4F",
        lineHeight: "19px",
        [theme.breakpoints.down("md")]: {
            fontSize: "16px",
            padding: "5px 10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            padding: "5px 10px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            padding: "5px 10px",
        }
    },
    fonts2: {
        lineHeight: "24px",
        fontSize: "16px",
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    },
    greenbutton: {
        backgroundColor: "#6FCF97",
        border: "none",
        color: "#4F4F4F;",
        padding: "5px 10px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "20px",
        borderRadius: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            padding: "2px 8px",
        },
    },
    yellowbutton: {
        backgroundColor: "#F2C94C",
        border: "none",
        color: "#4F4F4F;",
        padding: "5px 10px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "12px",
        lineHeight: "20px",
        borderRadius: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            padding: "2px 8px",
        },
    },
    item: {
        '&:hover': {
            // cursor: 'pointer',
            background: "#EEEEEE"
        }
    },
    box: {
        padding: "4rem 3rem",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0.5rem",
        },
    },
    hoverdelete:{
        paddingTop:"5px",
        '&:hover':{
            background:"grey",
            borderRadius:"50px"
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function EvidenceSubmissions({ activeDispute }) {
    const history = useHistory();
    const classes = useStyles();

    const [dispute, setDispute] = useState(activeDispute);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        console.log(dispute);
    }, []);


    const { height, width } = getWindowDimension();
    const [screenWidth, setWidth] = useState(width);
    const handleResize = () => {
        const { height, width } = getWindowDimension();
        setWidth(width);
    };
    const isSmallScreen = (screenWidth) => {
        return screenWidth < 960;
    };
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        localStorage.setItem('whichTabDisputeDisplay', 3);
        return () => {
            window.removeEventListener("resize", handleResize);
          };
    }, [])

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [evidenceFiles, setevidenceFiles] = React.useState(dispute.all_evidence);

    const [type, setType] = React.useState();

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    const addFile = (file) => {
        files.push(file);
        setFiles(files);
    };

    const deleteFile = (index) => {
        let localFiles = [...files];
        localFiles.splice(index, 1);
        setFiles(localFiles);
    };

    const handleChangeRadio = (event) => {
        setType(event.target.value);
    };

    const deleteEvidence = (e, file) => {
        e.stopPropagation(file);
        try {
            dispatch(deleteEvidenceFunction(file)).then((response) => {
                instance({
                    method: "get",
                    url: "/api/dispute/",
                    params: {
                        id: activeDispute.id,
                    },
                }).then((response) => {
                    console.log(response.data);
                    setDispute(response.data.results[0]);
                    setevidenceFiles(response.data.results[0].all_evidence);
                    setOpenSnackBar(true);
                },
                    (error) => {
                        console.log(error);
                    }
                )
            })
        }
        catch(e){
            console.log(e);
        }
    }

    const deleteEvidenceFunction = (file) => {
        return async (dispatch) => {
            try {
                return await instance.delete(
                    "/api/evidencedocurl/" + file.id + "/",
                    {
                        headers: {
                            "Accept": "application/json",
                            "Content-type": "application/json",
                        }
                    }
                ).then((response)=>{
                    console.log(response.data);
                }
                ,(error)=>{
                    console.log(error);
                }
                );
            } catch (error) {
                console.log(error);
            }
        };
    }

    const renderFiles = (files, deleteFile) => {
        return files.length !== 0
            ? files.map((file, index) => {
                return (
                    <Box textAlign="center" position="relative"
                        key={index}
                    // display="flex"
                    // justifyContent="center"
                    >
                        <Box>
                            <Grid container direction="row">
                                <Grid item sm={6} xs={6}>
                                    <Typography style={{ wordBreak: "break-all", paddingTop: "20px" }} variant="subtitle2">{file.name}</Typography>
                                    <IconButton
                                        aria-label="delete"
                                        color="primary"
                                        onClick={() => {
                                            deleteFile(index);
                                            setType();
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <Box display="flex" style={{ margin: "0.5rem 0" }}>
                                                <input type="radio" id="Complaiant" name="type1" value="Complainant" onChange={handleChangeRadio} style={{ transform: "scale(1.5)", margin: "6px 3px 0px 6px" }} />
                                                <Box width="0.6rem"></Box>
                                                <Box alignItems="start" style={{ textAlign: "left" }}>
                                                    <Typography><label for="Complaiant" style={{ fontSize: "16px", color: "#454545", cursor: "pointer" }}>Complainant</label></Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box display="flex" style={{ margin: "0.5rem 0" }}>
                                                <input type="radio" id="OCCP" name="type1" value="OCCP" onChange={handleChangeRadio} style={{ transform: "scale(1.5)", margin: "6px 3px 0px 6px" }} />
                                                <Box width="0.6rem"></Box>
                                                <Box alignItems="start" style={{ textAlign: "left" }}>
                                                    <Typography><label for="OCCP" style={{ fontSize: "16px", color: "#454545", cursor: "pointer" }}>OCCP</label></Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                );
            })
            : null;
    };

    const onClickProceed = () => {
        if (files.length !== 0) {
            if (type) {
                dispatch(uploadEvidence(files, activeDispute, type)).then((resp) =>
                    console.log("DONE")
                );
            }
            else {
                alert("Choose one option : Complainant or OCCP.")
            }
        }
    };



    const UploadDialog = ({ open, setOpen, addFile }) => {
        const classes = useStyles();
        return (
            <Box textAlign="center" position="relative">
                <Box>
                    <Box >
                        <IconButton
                            className={classes.icon}
                            aria-label="delete"
                            // color="primary"
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            <AddCircleOutlineIcon className={classes.icon}
                            />
                        </IconButton>
                    </Box>
                </Box>
                <DropzoneDialog
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    maxFileSize={5000000}
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={(files) => {
                        console.log("Files:", files);
                        files.forEach((file) => {
                            addFile(file);
                        });
                        setOpen(false);
                    }}
                    showPreviews={true}
                    filesLimit="1"
                    showFileNamesInPreview={true}
                    acceptedFiles={[
                        "image/jpeg", "image/png", "image/jpg",
                        "application/msword",
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ]}
                />
            </Box>
        );
    };

    return (
        <Box className={classes.box}>
            <Grid container justify="flex-start">
                <Grid item sm={10} xs={11}>
                    <Typography style={{ color: "#4F4F4F", fontSize: "36px", lineHeight: "36px" }}>{activeDispute.name}</Typography>
                </Grid>
            </Grid>
            <Box height="3rem"></Box>
            <Grid container justify="center">
                {isSmallScreen(screenWidth) ? (
                    <Grid sm={10} xs={11} item >
                        {(true) && <>
                            <Box className={classes.fonts} >
                                <Grid container justify="flex-start" direction="row">
                                    <Grid item sm={6} xs={6} style={{ textAlign: "left" }}>
                                        Document Name
                                    </Grid>
                                    <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>

                                    </Grid>
                                    <Grid item sm={3} xs={3} style={{ textAlign: "right" }}>
                                        Submission
                                    </Grid>
                                </Grid>
                            </Box>
                            <Paper >
                                {evidenceFiles.length > 0 &&
                                    evidenceFiles.map((file) => {
                                        return (
                                            <Box className={classes.fonts} onClick={() => {
                                                if (file.s3path)
                                                    downloadEvidence(file.id);
                                                else
                                                    console.log("s3path is null");
                                            }}>
                                                <Grid container direction="row" justify="flex-start">
                                                    <Grid item sm={5} xs={5} style={{ textAlign: "left", overflow: "hidden", "text-overflow": "ellipsis" }}>
                                                        {file.name}
                                                    </Grid>
                                                    <Grid item sm={1} xs={1} className={classes.hoverdelete}>
                                                        <img src={deleteSvg} alt="delete" onClick={(e) => { deleteEvidence(e, file) }}></img>
                                                    </Grid>
                                                    <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>
                                                        {file.sent_by === "Complainant" ?
                                                            <button className={classes.greenbutton}>{file.sent_by}</button>
                                                            :
                                                            <button className={classes.yellowbutton}>{file.sent_by}</button>
                                                        }
                                                    </Grid>
                                                    <Grid item sm={3} xs={3} style={{ color: "#828282", textAlign: "right" }}>
                                                        {formatDate(file.modified)}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    })}
                            </Paper>
                            <Box height="1rem"></Box>
                            <Box>
                                {
                                    files.length === 0 && (
                                        <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />)
                                }
                                {renderFiles(files, deleteFile)}
                                {files.length != 0 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px", marginTop: "20px" }}
                                        onClick={onClickProceed}
                                    >
                                        <FormattedMessage id="buttons.submit" />
                                        <Box height="3rem"></Box>
                                    </Button>
                                }
                            </Box>
                        </>
                        }
                    </Grid>
                ) : (<>
                    <Grid sm={7} xs={7} item >
                        {(true) && <>
                            <Box className={classes.fonts2} style={{ padding: "20px", color: "#333333", lineHeight: "36px" }}>
                                <Grid container justify="flex-start" direction="row">
                                    <Grid item sm={7} xs={7} style={{ textAlign: "left" }}>
                                        Document Name
                                    </Grid>
                                    <Grid item sm={3} xs={3} style={{ textAlign: "left" }}>

                                    </Grid>
                                    <Grid item sm={2} xs={2} style={{ textAlign: "left" }}>
                                        Submission
                                    </Grid>
                                </Grid>
                            </Box>
                            <Paper >
                                {evidenceFiles.length > 0 &&
                                    evidenceFiles.map((file) => {
                                        return (
                                            <Box className={classes.fonts2} style={{ padding: "10px 10px", color: "#4F4F4F", lineHeight: "19px", cursor: "pointer" }} onClick={() => {
                                                if (file.s3path)
                                                    downloadEvidence(file.id);
                                                else
                                                    console.log("s3path is null");
                                            }}>
                                                <Grid container direction="row" justify="flex-start">
                                                    <Grid item md={6} sm={6} xs={6} style={{ textAlign: "left", overflow: "hidden", "text-overflow": "ellipsis" }}>
                                                        {file.name}
                                                    </Grid>
                                                    <Grid item md={1} sm={1} xs={1} className={classes.hoverdelete}>
                                                        <img src={deleteSvg} alt="delete" onClick={(e) => { deleteEvidence(e, file) }}></img>
                                                    </Grid>
                                                    <Grid item md={3} sm={3} xs={3} style={{ textAlign: "left" }}>
                                                        {file.sent_by === "Complainant" ?
                                                            <button className={classes.greenbutton}>{file.sent_by}</button>
                                                            :
                                                            <button className={classes.yellowbutton}>{file.sent_by}</button>
                                                        }
                                                    </Grid>
                                                    <Grid item md={2} sm={2} xs={2} style={{ color: "#828282", textAlign: "left", fontSize:'12px' }}>
                                                        {formatDate(file.modified)}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    })}
                            </Paper>
                            <Box height="1rem"></Box>
                            <Box>
                                {
                                    files.length === 0 && (
                                        <UploadDialog open={open} setOpen={setOpen} addFile={addFile} />)
                                }
                                {renderFiles(files, deleteFile)}
                                {files.length != 0 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ background: "#2F80ED", fontSize: "20px", borderRadius: "2px", marginTop: "20px" }}
                                        onClick={onClickProceed}
                                    >
                                        <FormattedMessage id="buttons.submit" />
                                        <Box height="3rem"></Box>
                                    </Button>
                                }
                            </Box>
                        </>
                        }
                    </Grid>
                </>
                )}

            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={6000}
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                message="File deleted successfully!"
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar} severity="success">
                    File deleted successfully!
                </Alert>
            </Snackbar>

        </Box>
    )
}
