import * as Yup from "yup";
import { format, subYears } from "date-fns";

const phoneRegExp = /^[6-9]\d{9}$/;

export const DisputeSchema = Yup.object().shape({
  email: Yup.string().required().email("Invalid email"),
  // phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  phone: Yup.string()
  .required("phone number is required")
  .min(10, "Invalid phone number")
  .max(10, "Invalid phone number"),
  order_date: Yup.date().required().typeError("Please select a valid date"),
  company: Yup.number()
    .required()
    .typeError("Please select a company from the dropdown."),
  company_product_type: Yup.string()
    .required()
    .typeError("Please select an option from the dropdown"),
  company_product: Yup.number()
    .required()
    .typeError("Please set the company product to proceed"),
});
