export const createGroupName = (groupName) => {
  console.log("groupName......", groupName);
  let nameArray = groupName.split("_");
  let name = "";
  nameArray.slice(0, nameArray.length - 1).forEach((element) => {
    name = name + " " + element;
  });

  console.log("name:" + name +'/');
  if(name == " devdpcgcarbitration" || name == " dpcgcarbitration"){
    name = "DPCGC";
    // name = " Main Room";
  }
  if (name == " devdpcgcADRP-breakout" || name == " dpcgcADRP-breakout"){
    name = "Breakout";
  }
  return name;
};
