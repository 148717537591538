import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { getRecordings, downloadRecording} from "slices/disputeSlice";
import { useParams } from "react-router-dom";
import RecordingsModal from "features/Chat/RecordingModal";

const useStyles = makeStyles((theme) => ({
  fileCard: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
  },
  filesContainer: {
    "&:last-child": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  greyColor: {
    color: theme.palette.common.grey1,
  },
}));

export default function Recordings() {

  let { disputeId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open2, setOpen2] = React.useState(false); 
  
  const {
    disputes: {
      recordings: recordings,
    },
  } = useSelector((state) => state, shallowEqual);

  React.useEffect(() => {
    dispatch(getRecordings(disputeId));
  }, []);

  console.log("recordings:", recordings);
  console.log("disputeId:", disputeId);

  const createFileName = (FileName) => {
    let nameArray = FileName.split("_");
    return nameArray[nameArray.length - 1];
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <Box>
      <Box display="flex" className={classes.greyColor} p="0 0 0 1rem">
        {/* <VideoLibraryIcon /> */}
        <Box width="0.4rem"></Box>
        <Typography variant="subtitle1" style={{color:"#333333"}}>Recordings</Typography>
      </Box>
      <Box height="1rem"></Box>
      <Box className={classes.filesContainer}>
        {recordings.length > 0 &&
          recordings.map((recording, index) => {
            return (
              <React.Fragment>
                {index < 4 && (
                  <Box
                    onClick={async () => {
                      downloadRecording(recording.id);
                    }}
                    key={recording.file_name}
                    p="1rem 0 1rem 1rem"
                    className={classes.fileCard}
                    textAlign="start"
                  >
                    <Typography variant="subtitle1">
                      {createFileName(recording.file_name)}
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            );
          })}
      </Box>
      <Box height="0.5rem"></Box>
      {recordings.length > 4 && (
        <Box display="flex" justifyContent="center">
          <Button color="primary" onClick={handleOpen2}>
            View More
          </Button>
        </Box>
      )}
      <RecordingsModal
          open={open2}
          handleClose={handleClose2}
          files={recordings}
        />
    </Box>
  );
}
