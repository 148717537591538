import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import { format, isBefore } from "date-fns";
import { subMinutes } from "date-fns/esm";
import { containerContext } from "features/Chat/ChatContainer";
import FormattedMessage from "features/common/TranslatedMessage";
import isEmpty from "lodash/isEmpty";
import React, { Fragment, useContext, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { checkMeeting, deleteMeeting } from "slices/appointmentSlice";
import useDeepCompareEffect from "use-deep-compare-effect";
import { createGroupName } from "utils";
import { stringToTime } from "./Schedule.utils";

const useStyles = makeStyles((theme) => ({
  selected: { background: theme.palette.primary.mainGradient },
  slot: { margin: "0.3rem 0" },
  heading: {
    color: theme.palette.common.grey1,
    cursor: "pointer",
  },
}));

const formatSlotTime = (time) => {
  const parsableTime = stringToTime(time);
  const slotArray = time.split(":");
  return format(parsableTime, "h:mm a");
};

const getMeetingStatus = (meeting) => {
  const currentDate = new Date();

  const slotDate = Date.parse(meeting.start);
  if (isBefore(subMinutes(slotDate, 10), currentDate)) {
    return "join";
  }

  return "wait";
};

export default function Schedule({ selected }) {
  const [expand, setExpand] = React.useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [meetingStatus, setMeetingStatus] = useState();
  const [meeting, setMeeting] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { showVC, setShowVC } = useContext(containerContext);

  const { meetings } = useSelector((state) => state.appointments, shallowEqual);

  const groups = useSelector((state) => state.groups.groups, shallowEqual);

  const currentGroup = groups[selected];

  const classes = useStyles();
  const { disputeId } = useParams();

  React.useEffect(() => {
    dispatch(checkMeeting(disputeId));
  }, [disputeId]);

  useDeepCompareEffect(() => {
    let status = !isEmpty(meeting) ? getMeetingStatus(meeting) : meetingStatus;

    setMeetingStatus(status);
  }, [meeting]);

  useDeepCompareEffect(() => {
    setExpand(false);
  }, [currentGroup]);

  useDeepCompareEffect(() => {
    console.log(meetings, currentGroup);
    const filteredMeetings = meetings.filter((singleMeeting) => {
      return singleMeeting.group === createGroupName(currentGroup.name).trim();
    });
    filteredMeetings[0] ? setMeeting(filteredMeetings[0]) : setMeeting({});
  }, [meetings, currentGroup]);

  const onCancel = () => {
    dispatch(deleteMeeting(meeting.id, disputeId));
  };

  return (
    <Box textAlign="start" p="0 0 0 1rem">
      {isEmpty(meeting) ? (
        <Fragment>
          <Box
            display="flex"
            pr="4rem"
            className={classes.heading}
            onClick={() => {
              setExpand((prevState) => !prevState);
            }}
          >
            <Typography variant="subtitle1">
              <FormattedMessage id="pages.chat.scheduleCall" />
            </Typography>
            <Box flexGrow={1}></Box>

            <Box justifySelf="flex-end">
              <TodayIcon />
            </Box>
          </Box>

          {expand && (
            <Box p="1rem 0">
              <Typography>
                <FormattedMessage id="pages.chat.noCall" />
              </Typography>
              <Box height="1rem"></Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push(
                    `/events?disputeId=${disputeId}&group=${currentGroup.name}`,
                    { from: location.pathname }
                  );
                }}
              >
                <FormattedMessage id="pages.chat.scheduleCall" />
              </Button>
            </Box>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {meetingStatus === "wait" ? (
            <Box>
              <Typography variant="subtitle1" color="primary">
                Scheduled meeting {meeting.name} at:
              </Typography>
              <Typography variant="subtitle1" color="primary">
                {`${format(Date.parse(meeting.start), "h:mm a")} on ${format(
                  Date.parse(meeting.start),
                  "do MMMM yyyy"
                )}`}
              </Typography>
              <Box height="01rem"></Box>
              <Box display="flex" justifyContent="space-evenly">
                <Button variant="contained" onClick={onCancel} color="primary">
                  <FormattedMessage id="buttons.cancel" />
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.push(
                      `/events?disputeId=${disputeId}&group=${currentGroup.name}`,
                      { from: location.pathname }
                    );
                  }}
                >
                  <FormattedMessage id="pages.chat.scheduleAnother" />
                </Button>
              </Box>
            </Box>
          ) : (
            <Fragment>
              <Box>
                <Typography variant="subtitle1">
                  {meeting.name} is underway
                </Typography>
                <Box height="0.5rem"></Box>
                <Button
                  style={{ paddingLeft: "0" }}
                  color="primary"
                  onClick={() => {
                    setShowVC((prevState) => !prevState);
                  }}
                >
                  <FormattedMessage id="buttons.clickToJoin" />
                </Button>
              </Box>
            </Fragment>
          )}
        </Fragment>
      )}
    </Box>
  );
}
