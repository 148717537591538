import React from "react";

export const backgroundContext = React.createContext();

export const backgrounds = {
  LIGHTBLUE: "lightBlue",
  WHITE: "white",
  DEFAULT: "default",
};

export default function BackgroundContext({ children }) {
  const Provider = backgroundContext.Provider;

  const [background, setBackground] = React.useState(backgrounds.DEFAULT);

  return <Provider value={{ background, setBackground }}>{children}</Provider>;
}

export const useBackground = () => React.useContext(backgroundContext);
