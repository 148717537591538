import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
    flexGrow: "1",
  },
  heading: {
    // border: "none",
    fontSize: "16px",
    lineHeight: "36px",
  },
}));

const Heading = ({ type }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      {/* <FileCopyIcon /> */}
      <Box width="0.5rem"></Box>
      <Typography variant="body1" className={classes.heading}>
        <FormattedMessage id={`dispute.${type}`} />
      </Typography>
    </Box>
  );
};

const Counter = ({ heading, count, to }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <Link to={to} style={{ color: "#4F4F4F" }}>
          <Typography variant="body2">{heading}</Typography>
        </Link>
        <Typography variant="body2">{count}</Typography>
      </Box>
    </Box>
  );
};

export default ({ type, count }) => {
  const classes = useStyles();
  let { active, resolved } = count;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box height="2rem"></Box>
        </Grid>
        <Grid item xs={12}>
          <Heading type={type} />
        </Grid>
        <Grid item xs={12}>
          <Box height="0.5rem"></Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Paper className={classes.paper}>
                <Counter
                  heading={<FormattedMessage id="dispute.open" />}
                  count={active}
                  to={`/disputes/${type}/active`}
                />
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <Counter
                  heading={<FormattedMessage id="dispute.resolved" />}
                  count={resolved}
                  to={`/disputes/${type}/resolved`}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// <Grid container>
//             <Grid item md={5} sm={5} xs={5}>
//               <Counter
//                 heading={<FormattedMessage id="dispute.open" />}
//                 count={active.length}
//                 to={`/disputes/${type}/active`}
//               />
//             </Grid>
//             <Grid item md={2} sm={2} xs={2}>
//               <Divider orientation="vertical" style={{ margin: "0 auto" }} />
//             </Grid>
//             <Grid item md={5} sm={5} xs={5}>
//               <Counter
//                 heading={<FormattedMessage id="dispute.resolved" />}
//                 count={resolved.length}
//                 to={`/disputes/${type}/resolved`}
//               />
//             </Grid>
//           </Grid>
