import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FolderIcon from "@material-ui/icons/Folder";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  topText: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
  icon: {
    height: "2rem",
    width: "2rem",
    color: theme.palette.text.secondary,
  },
}));
export default function DisputeDisplay({ activeDispute, profile }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Container>
      <Box p="2rem 0">
        <Button
          onClick={() => {
            history.push("/disputes");
          }}
          size="large"
          startIcon={<ArrowBackIosIcon />}
        >
          <FormattedMessage id="buttons.back" />
        </Button>
        <Grid container>
          <Grid item md={2} sm={0} xs={0}></Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Paper style={{ padding: "1rem", textAlign: "center" }}>
              <Box display="flex">
                <Typography
                  className={classes.topText}
                  variant="subtitle2"
                >{`dispute id: ${activeDispute.id}`}</Typography>
                <Box flexGrow={1}></Box>
                <Box>
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push(`/disputes/${activeDispute.id}/files`);
                    }}
                    endIcon={<FolderIcon />}
                  >
                    <FormattedMessage id="common.files" />
                  </Button>
                </Box>
              </Box>
              <Box height="5rem"></Box>
              <Typography variant="h3">{activeDispute.name}</Typography>
              <Box height="1rem"></Box>

              <Box height="1rem"></Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    history.push(`/disputes/${activeDispute.id}/chat`);
                  }}
                >
                  <FormattedMessage id="common.hearingRooms" />
                </Button>
              </Box>
              <Box height="5rem"></Box>
            </Paper>
          </Grid>
          <Grid item md={2} sm={0} xs={0}></Grid>
        </Grid>
      </Box>
    </Container>
  );
}
