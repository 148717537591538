import React from "react";
import { useHistory } from "react-router-dom";
import { updateDispute, getDispute } from "slices/disputeSlice";
import { createCase, getCase } from "slices/casesSlice";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Paper,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

import RespondentDispute from "features/disputesRv/RespondentDispute";
import ClaimantDispute from "features/disputesRv/ClaimantDispute";

import { useDispatch } from "react-redux";
import { DocGenerator, LegalDocGet } from "utils";
import { DropzoneDialog } from "material-ui-dropzone";
import { CLAIMANT, RESPONDENT } from "app/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  dispute_heading: {
    [theme.breakpoints.down("sm")]: { fontSize: "1.2rem" },
  },
  date_text: {
    [theme.breakpoints.down("sm")]: { fontSize: "0.8rem" },
  },
}));

const Dispute = ({ dispute, cases, profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };

  const [claimantName, respondentName] = dispute.name.split("vs");

  let selectedCase = cases.find((cse) => cse.dispute === dispute.id);

  const disputeStatus = dispute.status === "complete" ? "Resolved" : "Active";

  const onClick = async (e) => {
    if (selectedCase) {
      await dispatch(getDispute(dispute.id));
      dispatch(getCase(dispute.id)).then(
        (response) => {
          console.log(response);
          history.push(`disputes/${dispute.id}`);
        },
        (error) => {
          console.log(error);
          throw error;
        }
      );
    }
  };

  if (selectedCase && selectedCase.type === CLAIMANT) {
    return (
      <ClaimantDispute
        dispute={dispute}
        classes={classes}
        onClick={onClick}
        disputeStatus={disputeStatus}
        claimantName={claimantName}
        respondentName={respondentName}
      />
    );
  } else if (!selectedCase || selectedCase.type === RESPONDENT) {
    return (
      <RespondentDispute
        dispute={dispute}
        disputeStatus={disputeStatus}
        profile={profile}
        classes={classes}
        onClick={onClick}
        selectedCase={selectedCase}
        claimantName={claimantName}
        respondentName={respondentName}
      />
    );
  } else {
    return null;
  }
};

export default Dispute;
