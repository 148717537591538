import { Grid, Typography, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { SECRETARIAT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils";
import { DocGenerator } from "utils";
import DownloadSvg from "images/download.svg";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  value: {
    // fontSize: 14,
    border: "none",
    padding: "1rem",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
}));

export default function DisputeRow({ dispute, profileType, disputesType }) {
  const classes = useStyles();
  const history = useHistory();
  let data = [];

  const [showDownload, setShowDownload] = React.useState(false);

  const download = (e) => {
    e.stopPropagation();
    DocGenerator(dispute.id, "claimant");
  };

  if (dispute.approved) {
    data = [
      {
        title: <FormattedMessage id="dispute.disputeId" />,
        value: dispute.custom_dispute_id,
      },
      {
        title: <FormattedMessage id="dispute.title" />,
        value: dispute.name,
      },
      {
        title: <FormattedMessage id="dispute.mode" />,
        value: "Approved",
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
        value: formatDate(dispute.created),
      },
    ];
  } else {
    data = [
      {
        title: <FormattedMessage id="dispute.disputeId" />,
        value: "--",
      },
      {
        title: <FormattedMessage id="dispute.title" />,
        value: dispute.name,
      },
      {
        // title: <FormattedMessage id="dispute.mode" />,
        title: <FormattedMessage id="dispute.status" />,
        value: "Submitted",
      },
      {
        title: <FormattedMessage id="dispute.startDate" />,
        value: formatDate(dispute.created),
      },
    ];
  }

  const onRowClick = () => {
    if (profileType === SECRETARIAT) {
      history.push(`/disputes/${dispute.id}/chat`);
    } else {
      history.push(`/disputes/${dispute.id}`);
    }
  };

  console.log("profileType:", profileType)

  return (
    <React.Fragment>
      {(profileType === "arbitrator" || profileType === "guest_arbitrator" ) ? (
        <Paper className={classes.paper} onClick={onRowClick}>
          <Grid container justify="space-between">
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[0].value ? data[0].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[1].value ? data[1].value : "----"}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item sm={3}>
            <Box className={classes.contentContainer}>
              <Typography className={classes.value}>
                {data[2].value ? data[2].value : "----"}
              </Typography>
            </Box>
          </Grid> */}
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[3].value ? data[3].value : "----"}
                </Typography>
              </Box>
            </Grid>
            {(profileType == "vendor" ||
              profileType == "arbitrator" ||
              profileType == "general" ||
              profileType == "guest_arbitrator" ||
              profileType == "guest_general" || 
              profileType == "guest_company" 
               ) && (
              <Grid item sm={2} xs={2}>
                <Box className={classes.contentContainer}>
                  <Typography
                    className={classes.value}
                    onClick={download}
                    onMouseEnter={() => {
                      setShowDownload(true);
                    }}
                    onMouseLeave={() => {
                      setShowDownload(false);
                    }}
                  >
                    {
                      // showDownload &&
                      "View Grievance  "
                    }
                    <Tooltip title="View Grievance" placement="top">
                      <img
                        src={DownloadSvg}
                        onClick={download}
                        onMouseEnter={() => {
                          setShowDownload(true);
                        }}
                        onMouseLeave={() => {
                          setShowDownload(false);
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      ) : (
        <Paper className={classes.paper} onClick={onRowClick}>
          <Grid container justify="space-between">
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[0].value ? data[0].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[1].value ? data[1].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2} xs={2}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[2].value ? data[2].value : "----"}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Box className={classes.contentContainer}>
                <Typography className={classes.value}>
                  {data[3].value ? data[3].value : "----"}
                </Typography>
              </Box>
            </Grid>
            {(profileType == "vendor" ||
              profileType == "arbitrator" ||
              profileType == "general"||
              profileType == "guest_arbitrator" ||
              profileType == "guest_general" ||
              profileType == "guest_company") && (
              <Grid item sm={2} xs={2}>
                <Box className={classes.contentContainer}>
                  <Typography
                    className={classes.value}
                    onClick={download}
                    onMouseEnter={() => {
                      setShowDownload(true);
                    }}
                    onMouseLeave={() => {
                      setShowDownload(false);
                    }}
                  >
                    {
                      // showDownload &&
                      "View Grievance  "
                    }
                    <Tooltip title="View Grievance" placement="top">
                      <img
                        src={DownloadSvg}
                        onClick={download}
                        onMouseEnter={() => {
                          setShowDownload(true);
                        }}
                        onMouseLeave={() => {
                          setShowDownload(false);
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
      <Box height="1rem"></Box>
    </React.Fragment>
  );
}

// <Grid container justify="flex-start">
// {data.map(({ title, value }) => {
//   return (
//     <Grid sm={3} xs={3} key={title}>
//       <Box className={classes.contentContainer}>
//         <div>
//           {/* <Typography className={classes.title}>{title}</Typography> */}
//           {/* <Hidden smDown>
//             <Box height="1rem"></Box>
//           </Hidden> */}
//           <Typography className={classes.value}>
//             {value ? value : "----"}
//             {/* {value ? (value == "arbitration" ? (dispute.approved ? "Approved" : "Submitted") : value) : "--"} */}
//             {/* {value ? (dispute.approved ? "Approved" : value) : "Submitted"} */}
//             {/* {dispute.approved ? "Approved" : "submitted"} */}
//           </Typography>
//         </div>
//       </Box>
//     </Grid>
//   );
// })}
// </Grid>
//
