import React from "react";
import NewAnalytics from "./NewAnalyticsContainer";
import PreviousAnalytics from "./AnalyticsContainer";
import { getCompanyType } from "slices/profileSlice";
import FullPageSpinner from "features/common/FullPageSpinner";

export default function FlowHandle({ profile }) {
  const [loading, setLoading] = React.useState(true);
  const [companyType, setcompanyType] = React.useState([]);

  React.useEffect(() => {
    getCompanyType().then((data) => {
      setcompanyType(data);
      setLoading(false);
    });
  }, []);

  // console.log("loading:", loading);
  // console.log("Now The company type is:", companyType);

  if (loading) return <FullPageSpinner />;
  if (companyType === "nbf") {
    return <NewAnalytics />;
  } else {
    return <PreviousAnalytics profile={profile} />;
  }
}
