export const CLAIMANT = "claimant";
export const RESPONDENT = "respondent";
export const VENDOR = "vendor";
export const GENERAL = "general";
export const ARBITRATOR = "arbitrator";
export const MEDIATOR = "mediator";
export const ARBITRATION = "arbitration";
export const MEDIATION = "mediation";
export const NEGOTIATION = "negotiation";
export const ADJUDICATION = "adjudication";
export const GRIEVANCE_REDRESSAL = "Proceed to the verification page";
export const GENERAL_RV = "general_rv";

export const WITNESS_RV = "witness_rv";
export const ARBITRATOR_RV = "arbitrator_rv";
export const ADVOCATE_RV = "lawyer_rv";
export const SECRETARIAT = "secretariat_rv";

export const CLAIMANT_COMPLETE = "claimant_complete";
export const UPLOAD_COMPLETE = "upload_complete";
export const COMPLETE = "complete";

//case Status
export const REVIEW_COMPLETE = "review_complete";
// rv disputes status values
export const RESPONDENT_COMPLETE = "respondent_complete";
export const RESPONDENT_INPROGRESS = "respondent_inprogress";
export const CLAIMANT_ADVOCATE_COMPLETE = "claimant_lawyer_complete";
export const RESPONDENT_ADVOCATE_COMPLETE = "respondent_lawyer_complete";
export const ARBITRATOR_COMPLETE = "arbitrator_complete";
export const NEW = "new";

export const AGREEMENT_SUCCESS = "success";
export const AGREEMENT_FAILED = "failure";

export const LOCAL_AUTH = "local";

export const NBF_LOGIN = "NBF_LOGIN";
// export const NBF_URL = "localhost";
export const NBF_URL = "newsbroadcastersfederation.webnyay.in";

export const HOTSTAR_LOGIN = "HOTSTAR_LOGIN";
// export const HOTSTAR_URL = "localhost";
export const HOTSTAR_URL = "hotstar.webnyay.in";
