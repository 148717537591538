import { Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import {
  ARBITRATOR,
  ARBITRATOR_RV,
  MEDIATOR,
  SECRETARIAT,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import DashboardDropDown from "features/header/DashboardDropdown";
import HambergerMenu from "features/header/HamburgerMenu";
import LanguageMenu from "features/header/LanguageMenu";
import ProfileDropdown from "features/header/ProfileDropdown";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    "& img": {
      width: "10rem",
      height: "auto",
      // height: "2.5rem",
      [theme.breakpoints.down("sm")]: {
        width: "7rem",
      },
    },
  },
}));

const AppHeader = () => {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.token);
  const profileType = useSelector(
    (state) => state.profile.profile.profile_type
  );

  const isProfessionalAccount =
    profileType === ARBITRATOR ||
    profileType === ARBITRATOR_RV ||
    profileType === MEDIATOR ||
    profileType === SECRETARIAT ||
    profileType === "guest_arbitrator";
  const loggedIn = token !== "" ? true : false;
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let currUrl = location.pathname.replace("/", "");

  let MenuItems;
  if (loggedIn) {
    MenuItems = (
      <Fragment>
        <Fragment>
          {isProfessionalAccount ? (
            <DashboardDropDown profileType={profileType} />
          ) : (
            <Button
              // color="inherit"
              style={{ textTransform: "none" }}
              onClick={() => {
                localStorage.setItem("whichTabHomePage", 1);
                history.push("/");
                // if(currUrl == "disputes"){
                //   history.go();
                // }
              }}
            >
              {/* <FormattedMessage id="nav.dashboard" /> */}
              Dashboard
            </Button>
          )}
        </Fragment>

        <Box width="2rem"></Box>

        <ProfileDropdown />
      </Fragment>
    );
  } else {
    MenuItems = (
      <Button
        // color="inherit"
        onClick={() => {
          history.push("/login");
        }}
      >
        <FormattedMessage id="auth.login" />
        {/* Login */}
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar style={{ background: "#F2F2F2" }}>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={handleDrawerOpen}
              // color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.title}>
            <Link to="/" style={{ color: "#4F4F4F" }}>
              {/* <img src="/Logo_Colour.png" alt="" /> */}
              Digital Publisher Content Grievances Council
            </Link>
          </div>
          <Box width="2rem"></Box>
          {/* <LanguageMenu /> */}

          <Box width="2rem"></Box>

          <Hidden smDown>{MenuItems}</Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <HambergerMenu
          open={open}
          handleDrawerClose={handleDrawerClose}
          isProfessionalAccount={isProfessionalAccount}
          profileType={profileType}
        />
      </Hidden>
    </div>
  );
};

export default AppHeader;
