import {
  Button,
  Container,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import qs from "query-string";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { resetPassword } from "slices/loginSlice";
import * as Yup from "yup";
import {  Visibility, VisibilityOff } from '@material-ui/icons';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password Too Short! Minimum Length - 8")
    .max(15, "Password Too Long! Maximum Length - 15")
    .matches(/^(.*[A-Z].*)/, "Must Contain an Upper Case Alphabet")
    .matches(/^(.*[a-z].*)/, "Must Contain a Lower Case Alphabet")
    .matches(/^(.*[0-9].*)/, "Must Contain a number")
    .matches(/^(.*\W.*)/, "Must Contain a special character")
    .required("Password Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password Required"),
});

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: "80%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2rem",
    },

    loginButton: {
      margin: theme.spacing(3, 0, 2),
      // backgroundColor: "#000",
      // color: "white",
      // border: "1px solid transparent",

      // "&:hover": {
      //   backgroundColor: "transparent",
      //   border: "1px solid #000",
      //   color: "#000",
      // },
    },
  };
});

export default function PasswordReset() {
  // const { token } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const { token, email } = qs.parse(search);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      let result = await resetPassword({
        email,
        token,
        password: values.password,
      });
      console.log(result);
      if (result.status === "OK") {
        history.push("/reset-password-confirm");
      }
    },
  });

  const classes = useStyles();

  const [passwordType, setPasswordType] = useState('password')
  const tooglePasswordType = () =>{
    if(passwordType == 'password'){
      setPasswordType('text')
    }
    else{
      setPasswordType('password')
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="filled"
            margin="normal"
            type={passwordType}
            value={formik.password}
            required
            fullWidth
            id="password"
            label={<FormattedMessage id="auth.password" />}
            name="password"
            autoComplete="password"
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={tooglePasswordType} style={{cursor:"pointer"}}>
                  {passwordType == 'password' ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert severity="error">{formik.errors.password}</Alert>
          )}
          <TextField
            variant="filled"
            margin="normal"
            type={passwordType}
            value={formik.confirmPassword}
            required
            fullWidth
            id="confirmPassword"
            label={<FormattedMessage id="auth.confirmPassword" />}
            name="confirmPassword"
            autoComplete="confirmPassword"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={tooglePasswordType} style={{cursor:"pointer"}}>
                  {passwordType == 'password' ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <Alert severity="error">{formik.errors.confirmPassword}</Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginButton}
          >
            {<FormattedMessage id="auth.resetPassword" />}
          </Button>
          {/* {formik.errors.email && formik.touched.email && (
          <Alert severity="error">{formik.errors.email}</Alert>
        )}
        {hasErrors && apiErrors.email && (
          <Alert severity="error">{apiErrors.email[0]}</Alert>
        )} */}
        </form>
      </Paper>
    </Container>
  );
}
