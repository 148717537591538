import { DyteMeeting } from "@dytesdk/react-ui-kit";
import { DyteProvider, useDyteClient } from "@dytesdk/react-web-core";
import React, { useEffect } from "react";

const DyteMeetingVC = ({
  dyteToken,
  roomName,
  setShowVC,
  vcType,
  disputeId,
  vcContainerStyle,
  onEnd,
}) => {
  const [meeting, initClient] = useDyteClient();
  useEffect(() => {
    //initalising the dyte client (it is running again)
    initClient({
      authToken: dyteToken,
      // set default values for user media
      defaults: {
        audio: false,
        video: false,
      },
    })
      .then((meeting) => {
        console.log(meeting, "meeting>>>>>>");
      })
      .catch((err) => {
        console.log(err, "err>>>>>>>");
      });

    return () => {
      // window.location.reload(false)
    };
  }, [dyteToken]);

  useEffect(() => {
    if (meeting) {
      meeting.meta.on("disconnected", () => {
        onEnd();
      });
      meeting.self.on("roomLeft", () => {
        onEnd();
      });
    }
  }, [meeting]);

  return (
    <div id="vc-container" style={vcContainerStyle}>
      <DyteProvider value={meeting}>
        <DyteMeeting meeting={meeting} mode="fill" showSetupScreen={false} />
      </DyteProvider>
    </div>
  );
};
export default DyteMeetingVC;
