import {
    Box,
    Paper,
    Grid,
    makeStyles,
    Button,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    Snackbar,
  Typography,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react'
import { useState } from 'react';
import instance from "api/globalaxios";
import { useDispatch } from "react-redux";
import { getWindowDimension } from "utils/responsiveUtils";
import Autocomplete from '@material-ui/lab/Autocomplete';
import matchSorter from "match-sorter";
import { FormattedMessage } from "react-intl";
import isEmpty from "lodash/isEmpty";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    headings: {
        lineHeight: "36px",
        fontSize: "16px",
        fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
        color: "#4F4F4F",
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
            lineHeight: "20px"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "20px"
        },
    },
    submitbtn: {
        fontSize: "20px",
        borderRadius: "2px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    }
}));

export default function CreateLoginDetails({ activeDispute, setReloadProfiles }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [makeAnotherLoginDetails, setMakeAnotherLoginDetails] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [profile, setProfile] = useState("");
    const [close, setClose] = useState(false);
    const [assignFlag, setAssignFlag] = useState(false);
    const [assignProfileId, setassignProfileId] = useState();

    const [openSnackBar1, setOpenSnackBar1] = useState(false);
    const handleCloseSnackBar1 = () => {
        setOpenSnackBar1(false);
    };
    const [openSnackBar2, setOpenSnackBar2] = useState(false);
    const handleCloseSnackBar2 = () => {
        setOpenSnackBar2(false);
    };
    const [openSnackBar3, setOpenSnackBar3] = useState(false);
    const handleCloseSnackBar3 = () => {
        setOpenSnackBar3(false);
    };
    const [openSnackBar4, setOpenSnackBar4] = useState(false);
    const handleCloseSnackBar4 = () => {
        setOpenSnackBar4(false);
    };
    const [openSnackBar5, setOpenSnackBar5] = useState(false);
    const handleCloseSnackBar5 = () => {
        setOpenSnackBar5(false);
    };

    const [openSnackBar6, setOpenSnackBar6] = useState(false);
    const handleCloseSnackBar6 = () => {
        setOpenSnackBar6(false);
    };

    const [openSnackBar7, setOpenSnackBar7] = useState(false);
    const handleCloseSnackBar7 = () => {
        setOpenSnackBar7(false);
    };

    const { height, width } = getWindowDimension();
    const [screenWidth, setWidth] = useState(width);
    const handleResize = () => {
        const { height, width } = getWindowDimension();
        setWidth(width);
    };
    const isSmallScreen = (screenWidth) => {
        return screenWidth < 960;
    };
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
          };
    }, [])

    const [newData, setNewData] = useState("")
    React.useEffect(() => {
        dispatch(loadGuestUsers())
    }, [])
    console.log("newData:", newData)

    const Complainant = "Complainant";
    const OCCP = "OCCP";
    const Arbitrator = "GRB member";
    let profiles = [Complainant, OCCP, Arbitrator];

    function createGuestSignUp(profile_type) {
        return async (dispatch) => {
            //   dispatch(setProgress());
            try {
                const response = await instance.post(
                    "/guestsignup",
                    {
                        "user": {
                            "email": email,
                            "password": "Test@123",
                            "first_name": firstName,
                            "last_name": lastName
                        },
                        "phone_number": phone,
                        "gender": "male",
                        "dispute_id": activeDispute.id,
                        "profile_type": profile_type
                    },
                    {
                        headers: {
                            "content-type": "application/json",
                        },
                    }
                );
                setReloadProfiles(true);
                setClose(true);
                setOpenSnackBar2(true);
                return response.data;
            } catch (error) {
                console.log(error);
                setOpenSnackBar1(true);
            }
        };
    }

    function assignGuestDispute() {
        if(assignProfileId){
            return async (dispatch) => {
                try {
                    const response = await instance.post(
                        "/assignguestdispute",
                        {
                            "dispute_id": activeDispute.id,
                            "profile_id": assignProfileId
                        },
                        {
                            headers: {
                                "content-type": "application/json",
                            },
                        }
                    );
                    setReloadProfiles(true);
                    setClose(true);
                    setOpenSnackBar6(true);
                    return response.data;
                } catch (error) {
                    console.log(error);
                    setOpenSnackBar7(true);
                }
            };
        }
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    const handleSubmit = (e) => {
        if (!firstName || !lastName || !email || !phone || !profile) {
            setOpenSnackBar3(true);
        }
        else {
            if (!pattern.test(email)) {
                setOpenSnackBar4(true);
            } else {
                if (phone.length != 10) {
                    setOpenSnackBar5(true);
                }
                else {
                    let profile_type = "";
                    switch (profile) {
                        case "Complainant":
                            profile_type = "guest_general";
                            break;
                        case "OCCP":
                            profile_type = "guest_company";
                            break;
                        case "GRB member":
                            profile_type = "guest_arbitrator";
                            break;
                    }
                    dispatch(createGuestSignUp(profile_type)).then((response) => {
                        console.log(response);
                        // setClose(true);
                    }
                        , (error) => {
                            console.log(error);
                        }
                    )

                }
            }
        }
    }

    const handleAssign = (e) => {
        if(assignFlag && assignProfileId) {
            dispatch(assignGuestDispute()).then((response) => {
                console.log(response);
            }, (error) => {
                    console.log(error);
                }
            )
        }
    }

    const onFirstNameChange = (e) => {
        setFirstName(e.target.value)
    }
    const onLastNameChange = (e) => {
        setLastName(e.target.value)
    }
    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const onPhoneChange = (e) => {
        setPhone(e.target.value)
    }
    const onProfileChange = (e) => {
        setProfile(e.target.value);
    }

    const loadGuestUsers = () => {
        return async (dispatch) => {
            try{
                instance({
                    method: "get",
                    url: `api/guestuserdata/`,
                    params: "",
                }).then((response) => {
                    setNewData(response.data)
                    // return response.data;
                })            
            } catch (error) {
                console.log(error);
                setOpenSnackBar1(true);
            }
        }
    }

    const handleValuesAssignment = (val) => {
        console.log("val:", val)    
        if(!isEmpty(val)){
            setEmail(val.user.email);
            setFirstName(val.user.first_name);
            setLastName(val.user.last_name);
            setPhone(val.phone_number);

            switch (val.profile_type) {
                case "guest_general":
                    setProfile(Complainant);
                    break;
                case "guest_company":
                    setProfile(OCCP);
                    break;
                case "guest_arbitrator":
                    setProfile(Arbitrator);
                    break;
            }
            setAssignFlag(true)
            setassignProfileId(val.id)
            console.log(email, " ", firstName, " ", lastName, " ", phone, " ", profile)
        }else{
            setEmail("");
            setFirstName("");
            setLastName("");
            setPhone("");
            setProfile("");
            setAssignFlag(false);
            setassignProfileId(null)
        }
    }

    return (
        <Box>
            {isSmallScreen(screenWidth) ?
                <Box>
                    {!close && <>
                        <Paper style={{ padding: "0.6rem 1.5rem", cursor: "pointer", }} onClick={() => { setMakeAnotherLoginDetails(true) }}>
                            <Grid container direction="row" justify="flex-start" className={classes.headings} >
                                <Grid item sm={10} xs={10} style={{ textAlign: "left" }}>+ Create login details</Grid>
                            </Grid>
                            {makeAnotherLoginDetails && <>
                                <Box height="0.8rem"></Box>
                                <Grid container direction="column" justify="space-evenly">
                                    <Grid item sm={5} xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            autoComplete={true}
                                            options={newData}
                                            onChange={(event, value) => {
                                                handleValuesAssignment(value);
                                            }}
                                            filterOptions={(options, state) => {
                                                return matchSorter(options, state.inputValue, {
                                                keys: [
                                                    (item) => `${item.user.email}`,
                                                ],
                                                });
                                            }}
                                            getOptionLabel={(option) => option.user.email}
                                            renderOption={(option, state) => {
                                                return (
                                                <React.Fragment>
                                                    {option.user.email}
                                                    <Box display="inline-block" width="1rem"></Box>
                                                </React.Fragment>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                label={<FormattedMessage id="pages.intro.search" />}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Box height="0.8rem"></Box>
                                <Typography className={classes.heading}>
                                    OR
                                </Typography>
                                <Box height="0.8rem"></Box>
                                <Grid container direction="column" justify="center" spacing={1}>
                                    <Grid item >
                                        <TextField
                                            required
                                            disabled = {assignFlag}
                                            id="firstName"
                                            name="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={firstName}
                                            onChange={((e) => { onFirstNameChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            required
                                            disabled = {assignFlag}
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={lastName}
                                            onChange={((e) => { onLastNameChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            required
                                            disabled = {assignFlag}
                                            id="email"
                                            name="email"
                                            label="Email ID"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={email}
                                            onChange={((e) => { onEmailChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <TextField
                                            required
                                            disabled = {assignFlag}
                                            id="phone"
                                            name="phone"
                                            label="Phone Number"
                                            variant="outlined"
                                            type="number"
                                            fullWidth={true}
                                            value={phone}
                                            onChange={((e) => { onPhoneChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <FormControl
                                            fullWidth={true}
                                            variant="outlined"
                                            // disabled={profiles.length === 0}
                                            disabled = {assignFlag}
                                        >
                                            <InputLabel id="against">
                                                Profile type
                                            </InputLabel>
                                            <Select
                                                id="profile_type"
                                                name="profile_type"
                                                value={profile}
                                                onChange={((e) => { onProfileChange(e) })}
                                                // labelId="company_product"
                                                label="Profile type"
                                            // data-testid="company-product-type-select"
                                            >
                                                {profiles.map((profile, index) => (
                                                    <MenuItem key={profile} value={profile}>{profile}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={3} xs={3} style={{ textAlign: "right" }}>
                                        {!assignFlag && 
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.submitbtn}
                                            data-testid="submit-intro-claimant"
                                            onClick={(e) => { handleSubmit(e) }}
                                            >CREATE
                                        </Button>}
                                        {assignFlag && 
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.submitbtn}
                                            data-testid="submit-intro-claimant"
                                            onClick={(e) => { handleAssign(e) }}
                                            >ASSIGN
                                        </Button>}
                                    </Grid>
                                </Grid>
                                <Box height="0.8rem" ></Box>
                                <Box height="0.8rem"></Box>
                            </>
                            }
                        </Paper>
                        <Box height="0.5rem" ></Box>
                    </>
                    }
                    {makeAnotherLoginDetails &&
                        <CreateLoginDetails activeDispute={activeDispute} setReloadProfiles={setReloadProfiles}/>
                    }
                </Box>
                :
                <Box>
                    {!close && <>
                        <Paper style={{ padding: "0.6rem 1.5rem", cursor: "pointer", }} onClick={() => { setMakeAnotherLoginDetails(true) }}>
                            <Grid container direction="row" justify="flex-start" className={classes.headings} >
                                <Grid item sm={10} xs={10} style={{ textAlign: "left" }}>+ Create login details</Grid>
                            </Grid>
                            {makeAnotherLoginDetails && <>
                                <Box height="0.8rem"></Box>
                                <Grid container direction="row" justify="space-evenly">
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            autoComplete={true}
                                            options={newData}
                                            onChange={(event, value) => {
                                                handleValuesAssignment(value);
                                            }}
                                            filterOptions={(options, state) => {
                                                return matchSorter(options, state.inputValue, {
                                                keys: [
                                                    (item) => `${item.user.email}`,
                                                ],
                                                });
                                            }}
                                            getOptionLabel={(option) => option.user.email}
                                            renderOption={(option, state) => {
                                                return (
                                                <React.Fragment>
                                                    {option.user.email}
                                                    <Box display="inline-block" width="1rem"></Box>
                                                </React.Fragment>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                label={<FormattedMessage id="pages.intro.searche_mail" />}
                                                variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Box height="0.8rem"></Box>
                                <Typography className={classes.heading}>
                                    OR
                                </Typography>
                                <Box height="0.8rem"></Box>
                                <Grid container direction="row" justify="space-evenly">
                                    <Grid item sm={3} xs={3}>
                                        <TextField
                                            disabled = {assignFlag}
                                            required
                                            id="email"
                                            name="email"
                                            label="Email ID"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={email}
                                            onChange={((e) => { onEmailChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                        <TextField
                                            disabled = {assignFlag}
                                            required
                                            id="firstName"
                                            name="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            defaultValue=""
                                            fullWidth={true}
                                            value={firstName}
                                            onChange={((e) => { onFirstNameChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                        <TextField
                                            disabled = {assignFlag}
                                            required
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth={true}
                                            value={lastName}
                                            onChange={((e) => { onLastNameChange(e) })}
                                        />
                                    </Grid>
                                </Grid>

                                <Box height="0.8rem" ></Box>
                                
                                <Grid container direction="row" justify="space-evenly">
                                    <Grid item sm={3} xs={3}>
                                        <TextField
                                            disabled = {assignFlag}
                                            required
                                            id="phone"
                                            name="phone"
                                            label="Phone Number"
                                            variant="outlined"
                                            type="number"
                                            fullWidth={true}
                                            value={phone}
                                            onChange={((e) => { onPhoneChange(e) })}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={3}>
                                        <FormControl
                                            fullWidth={true}
                                            variant="outlined"
                                            // disabled={profiles.length === 0}
                                            disabled = {assignFlag}
                                        >
                                            <InputLabel id="against">
                                                Profile type
                                            </InputLabel>
                                            <Select
                                                id="profile_type"
                                                helperText="Profile type"
                                                name="profile_type"
                                                value={profile}
                                                onChange={((e) => { onProfileChange(e) })}
                                                // labelId="company_product"
                                                label="Profile type"
                                            >
                                                {profiles.map((profile, index) => (
                                                    <MenuItem key={profile} value={profile}>{profile}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={3} xs={3} style={{ textAlign: "right" }}>
                                        {!assignFlag && 
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.submitbtn}
                                            data-testid="submit-intro-claimant"
                                            onClick={(e) => { handleSubmit(e) }}
                                            >CREATE
                                        </Button>}
                                        {assignFlag && 
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.submitbtn}
                                            data-testid="submit-intro-claimant"
                                            onClick={(e) => { handleAssign(e) }}
                                            >ASSIGN
                                        </Button>}
                                    </Grid>
                                </Grid>
                                <Box height="0.8rem"></Box>
                            </>
                            }
                        </Paper>
                        <Box height="0.5rem" ></Box>
                    </>
                    }
                    {makeAnotherLoginDetails &&
                        <CreateLoginDetails activeDispute={activeDispute} setReloadProfiles={setReloadProfiles}/>
                    }
                </Box>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar1}
                onClose={handleCloseSnackBar1}
                message="Problem in creating guest profile."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar1} severity="error">
                    Problem in creating guest profile.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar2}
                onClose={handleCloseSnackBar2}
                message="Guest profile is created."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar1} severity="success">
                Guest profile is created.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar3}
                onClose={handleCloseSnackBar3}
                message="Please enter all the details."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar1} severity="warning">
                Please enter all the details.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar4}
                onClose={handleCloseSnackBar4}
                message="Please enter valid email address."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar1} severity="error">
                Please enter valid email address.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar5}
                onClose={handleCloseSnackBar5}
                message="Please enter valid phone number."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar1} severity="error">
                Please enter valid phone number.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar6}
                onClose={handleCloseSnackBar6}
                message="Guest profile is assigned."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar6} severity="success">
                Guest profile is assigned.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={5000}
                open={openSnackBar7}
                onClose={handleCloseSnackBar7}
                message="Problem in assigning Guest profile."
            // key={vertical + horizontal}
            >
                <Alert onClose={handleCloseSnackBar7} severity="success">
                Problem in assigning Guest profile.
                </Alert>
            </Snackbar>
        </Box>

    )
}
