import React from "react";
import SingleDisputeDetails from "../SingleDisputeDetails";
import ClaimantWaiting from "./ClaimantWaiting";
import ClaimantDecision from "./ClaimantDecision";
import DisputeDisplay from "features/singleDispute/DisputeDisplay";
import {
  RESPONDENT_COMPLETE,
  MEDIATION,
  ADJUDICATION,
  CLAIMANT_COMPLETE,
  COMPLETE,
  RESPONDENT_INPROGRESS,
} from "app/constants";
import ClaimandAdjudicationDecision from "./ClaimantAdjudicationDecision/ClaimandAdjudicationDecision";

export default function ClaimantFlowSteps({
  activeDispute,
  activeCase,
  profile,
}) {
  function getStepContent(activeDispute, activeCase, profile) {
    console.log(activeDispute)
    switch (activeDispute.status) {
      case "new":
        if (activeCase.case_status !== "review_complete") {
          return <SingleDisputeDetails  is3steps={false} profile={profile}/>;
        } else {
          // return <ClaimantWaiting disputeName={activeDispute.name} />;
          return <SingleDisputeDetails  is3steps={false} profile={profile}/>;
        }
      case RESPONDENT_INPROGRESS:
        // return <ClaimantWaiting disputeName={activeDispute.name} />;
        return <SingleDisputeDetails  is3steps={false} profile={profile}/>;

      case RESPONDENT_COMPLETE:
        if (activeDispute.flow_type === MEDIATION) {
          return (
            <ClaimantDecision
              activeDispute={activeDispute}
              activeCase={activeCase}
            />
          );
        } else if (activeDispute.flow_type === ADJUDICATION) {
          return (
            <ClaimandAdjudicationDecision
              activeDispute={activeDispute}
              activeCase={activeCase}
            />
          );
        } else {
          return <ClaimantWaiting disputeName={activeDispute.name} />;
        }

      case CLAIMANT_COMPLETE:
        return (
          <DisputeDisplay
          profile={profile}
            activeDispute={activeDispute}
            activeCase={activeCase}
          />
        );
      case COMPLETE:
        return "done";

      default:
        return (
          <DisputeDisplay
          profile={profile}
            activeDispute={activeDispute}
            activeCase={activeCase}
          />
        );
    }
  }

  return (
    <React.Fragment>
      {getStepContent(activeDispute, activeCase, profile)}
    </React.Fragment>
  );
}
