import React, { Fragment } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAgreement } from "slices/agreementSlice";
import { CircularProgress, Button } from "@material-ui/core";
import { updateCase } from "slices/casesSlice";
import { updateDispute } from "slices/disputeSlice";
import ClaimantDecision from "./ClaimantDecision";
import {
  AGREEMENT_SUCCESS,
  AGREEMENT_FAILED,
  NEGOTIATION,
  ARBITRATION,
  CLAIMANT_COMPLETE,
  MEDIATION,
} from "app/constants";

export default function ClaimantDecisionContainer({
  activeDispute,
  activeCase,
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAgreement(activeDispute.id));
  }, []);

  const {
    agreement: { loading: agreementLoading, agreement: agreement },
  } = useSelector((state) => state, shallowEqual);

  const loading = agreementLoading;

  React.useEffect(() => {
    agreement.status &&
      agreement.status === AGREEMENT_SUCCESS &&
      !loading &&
      dispatch(
        updateDispute({
          id: activeDispute.id,
          flow_type: ARBITRATION,
          status: CLAIMANT_COMPLETE,
        })
      )
        .then((resp) => {
          return dispatch(
            updateCase({ id: activeCase.id, proposed_flow: ARBITRATION })
          );
        })
        .catch((err) => {
          console.log(err);
        });
  }, [agreement.status, loading]);

  const acceptingHandler = (e) => {
    dispatch(
      updateDispute({
        id: activeDispute.id,
        flow_type: MEDIATION,
        status: CLAIMANT_COMPLETE,
      })
    )
      .then((resp) => {
        return dispatch(
          updateCase({
            id: activeCase.id,
            proposed_flow: MEDIATION,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rejectingHandler = (e) => {
    dispatch(
      updateDispute({
        id: activeDispute.id,
        flow_type: NEGOTIATION,
        status: CLAIMANT_COMPLETE,
      })
    )
      .then((resp) => {
        return dispatch(
          updateCase({
            id: activeCase.id,
            proposed_flow: NEGOTIATION,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      {loading ? (
        <CircularProgress />
      ) : agreement.status === AGREEMENT_FAILED ? (
        <ClaimantDecision
          acceptingHandler={acceptingHandler}
          rejectingHandler={rejectingHandler}
        />
      ) : null}
    </Fragment>
  );
}
