import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  blue,
  cyan,
  deepPurple,
  green,
  grey,
  purple,
  red,
} from "@material-ui/core/colors";
import isEmpty from "lodash/isEmpty";
import React from "react";
import Chart from "react-google-charts";
import { DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import {
  Level2ReportGenerator
} from "utils/apituils";
import "./Analytics.css";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "2rem",
    lineHeight: "3rem",
  },
  barGraphContainer: {
    width: "80%",
    marginTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pieContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rangeContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop:"10px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  formControl: {
    minWidth: "10rem",
  },
  colorsValuesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

const colors = [
  blue[500],
  purple[500],
  deepPurple[500],
  red[500],
  green[500],
  cyan[500],
  grey[500],
];

export default function Analytics({
  profile: profileType,
  disputes,
  start_Date,
  set_start,
  end_Date,
  set_end,
  disputeStatusData,
  // disputeModeData,
  disputeCountBarData,
  handleChange: handleRangeChange,
  range,
  nature_grievance_complainant,
  concern_related_complainant,
}) {
  // console.log("start_Date : ", start_Date);
  // console.log("end_Date : ", end_Date);
  // console.log("disputeStatusData :: ", disputeStatusData);
  console.log("nature_grievance_complainant : ", nature_grievance_complainant);
  console.log("concern_related_complainant : ", concern_related_complainant);
  const classes = useStyles();

  const [graph, setGraph] = React.useState(0);

  const handleChange = (event) => {
    setGraph(event.target.value);
  };
  var opti = {
    legend: { position: "none" },
    bar: { groupWidth: "95%" },
    width: "90%",
  };
  let arrnature_grievance_complainant = [
    ["Element", "value", { role: "style" }],

    [
      "Appeal",
      !isEmpty(nature_grievance_complainant)
        ? nature_grievance_complainant[0].value
        : 0,
      "#3F51B5",
    ],
    [
      "Escalation",
      !isEmpty(nature_grievance_complainant)
        ? nature_grievance_complainant[1].value
        : 0,
      "#454545",
    ],
  ];
  let arrconcern_related_complainant = [
    ["Element", "value", { role: "style" }],

    [
      "Age Ratings",
      !isEmpty(concern_related_complainant)
        ? concern_related_complainant[0].value
        : 0,
      "#3F51B5",
    ],
    [
      "Content Descriptors",
      !isEmpty(concern_related_complainant)
        ? concern_related_complainant[1].value
        : 0,
      "#454545",
    ],
    [
      "Synopsis",
      !isEmpty(concern_related_complainant)
        ? concern_related_complainant[2].value
        : 0,
      "#D9D7D7",
    ],
    [
      "Parental/Access Control",
      !isEmpty(concern_related_complainant)
        ? concern_related_complainant[3].value
        : 0,
      "#3F51B5",
    ],
    [
      "Other",
      !isEmpty(concern_related_complainant)
        ? concern_related_complainant[4].value
        : 0,
      "#454545",
    ],
  ];

  return (
    <div>
      <Grid 
      conatiner
      justify="center">
        <Grid item xs={12}>
            <Grid container justify="flex-end">
              {profileType == "secretariat" && (
                <Grid item>
                  <Button
                    onClick={(e) => {
                      Level2ReportGenerator(start_Date, end_Date, range, disputes);

                      // ComplianceReportGenerator(
                      //   start_Date,
                      //   end_Date,
                      //   range,
                      //   disputes
                      // );
                    }}
                  >
                    Generate the compliance report
                  </Button>
                </Grid>
              )}
              <Grid item sm={1} xs={1}></Grid>
              <Grid item >
                <DateRangePicker
                  startDate={start_Date}
                  endDate={end_Date}
                  onStartDateChange={set_start}
                  onEndDateChange={set_end}
                  maximumDate={new Date()}
                  minimumLength={1}
                  format="dd/MM/yyyy"
                >
                  {({ startDateInputProps, endDateInputProps, focus }) => (
                    <Box>
                      <Grid container direction="column" style={{padding:"10px"}}>
                        {/* <Grid item xs style={{ textAlign: "left",padding:"5px" }}>
                          <Typography>Date</Typography>
                        </Grid> */}
                        <Grid item xs style={{padding:"5px"}}>
                        <Grid container justify="center" alignItems="center">
                          <Grid item style={{display:"flex",alignItems:"center"}}>
                          <Typography style={{padding:"5px"}}>Date</Typography>
                          <div>
                          <input
                            {...startDateInputProps}
                            placeholder="Start date"
                          />
                          </div>
                          </Grid>
                          <Grid item xs={2} sm>-</Grid>
                          <Grid item style={{display:"flex",alignItems:"center"}}>
                          <input {...endDateInputProps} placeholder="End date" />
                          <img
                            src="/calender.png"
                            alt=""
                            class="calendericon"
                            style={{ position: "relative" }}
                          />
                          </Grid>
                        </Grid>
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                          <input
                            type="submit"
                            className="input1"
                            onClick={() => {
                              set_start();
                              set_end();
                            }}
                            value="CLEAR"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </DateRangePicker>
              </Grid>
              <Grid item xs sm={3} md={3}>
                <div className={classes.rangeContainer}>
                  <FormControl className={classes.formControl}>
                    <Select
                      variant="outlined"
                      labelId="range-select-label"
                      id="analytics-range-select"
                      value={range}
                      onChange={handleRangeChange}
                    >
                      <MenuItem value="week">Past week</MenuItem>
                      <MenuItem value="month">Past Month</MenuItem>
                      <MenuItem value="quarter">Past 3 Months</MenuItem>
                      <MenuItem value="half">Past 6 Months</MenuItem>
                      <MenuItem value="all">All time</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <Box height="4rem"></Box>
            <Grid container justify="center">
              <Grid item sm={8} xs={10}>
                <Typography className={classes.heading}>
                  Status of all the Grievances
                </Typography>
                <Grid container justify="center">
                  <Grid item md={8} sm={12} xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <PieChart data={disputeStatusData} colors={colors} />
                    </Box>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className={classes.colorsValuesContainer}>
                      <ColorValues data={disputeStatusData} colors={colors} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Box height="2rem"></Box>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={10} sm={8}>
                  <Typography className={classes.heading}>Nature of Grievance</Typography>
                    <div class="ActionsTaken">
                      <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={arrnature_grievance_complainant}
                        options={opti}
                      />
                    </div>
              </Grid>
              <Grid item xs={10} sm={8}>
                  <Typography className={classes.heading}>
                  Category of Grievance
                  </Typography>
                  <div class="ActionsTaken">
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="500px"
                      data={arrconcern_related_complainant}
                      options={opti}
                    />
                  </div>

              </Grid>
              <Grid item xs={10} sm={8}>
                  <div className={classes.barGraphContainer}>
                  <Typography className={classes.heading}>Grievance count</Typography>
                  <Box height="2rem"></Box>

                  <AreaChart data={disputeCountBarData} />
                </div>

              </Grid>
            </Grid>

        </Grid>
      </Grid>
    </div>
  );
}

const ColorValues = ({ colors, data }) => {
  return (
    <div>
      {data.map((singleData, index) => {
        return (
          <Box display="flex" mb="0.7rem" key={singleData.name}>
            <Box
              height="2rem"
              width="2rem"
              style={{ backgroundColor: colors[index] }}
            ></Box>
            <Box width="0.3rem"></Box>
            <Typography>{singleData.name}</Typography>
          </Box>
        );
      })}
    </div>
  );
};
