import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  ListItemText,
  List,
  ListItem,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, useHistory } from "react-router-dom";

const options = ["Active", "Resolved"];
// const options = ["Open", "Closed"];

const StyledMenu = withStyles({
  list: { padding: "0" },
  paper: { borderRadius: "0", width: "8%" },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: "0.8rem",
    color: "black",
    "&:focus": {
      color: "#fff",

      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#fff",
      },
    },
    "&&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  list: {
    padding: "0",
  },
}));

export default function DashboardDropDown({ profileType }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const history = useHistory();
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        aria-controls="lock-menu"
        aria-haspopup="true"
        // color="inherit"
        style={{color:"#454545",textTransform:"none"}}
        onClick={handleClickListItem}
      >
        Dashboard
      </Button>
      <StyledMenu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(event, index);
              history.push(`/disputes/${profileType}/${option.toLowerCase()}`);
            }}
          >
            {option === "Active" ? "Open" : "Closed"}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
