import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  companyFlows: [],
};

const companyFlowSlice = createSlice({
  name: "companyFlows",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.companyFlows = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  setProgress,
  setSucessfull,
  setError,
} = companyFlowSlice.actions;
export default companyFlowSlice.reducer;

export function getCompanyFlows() {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/companyflow/",
    }).then(
      (response) => {
        // console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError);
      }
    );
  };
}
