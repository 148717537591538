import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function FullPageSpinner() {
  return (
    <Box
      height="60vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  );
}
