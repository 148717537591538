import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CLAIMANT } from "app/constants";
import { format } from "date-fns";
import FormattedMessage from "features/common/TranslatedMessage";
import { introFormContext } from "features/intro/IntroContainer";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React, { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DisputeSchema } from "schemas/DisputeSchema";
import { createCase } from "slices/casesSlice";
import { getCompanyProducts } from "slices/companySlice";
import { createDispute } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    padding: "2rem",
  },
}));

const IntroForm = ({ profile, user, companies }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);
  const { isVendor, setFormStep, setClaimantFormValues } = useContext(
    introFormContext
  );
  let history = useHistory();

  const [companyProducts, setCompanyProducts] = React.useState([]);

  useEffect(() => {
    isVendor &&
      getCompanyProducts(profile.company).then((response) => {
        setCompanyProducts(response);
      });
  }, []);

  // debounced submit handler for general user as claimant
  const debouncedOnSubmit = debounce((values) => {
    let company = companies.find((element) => element.id === values.company);

    let name = user.first_name + " " + user.last_name + " & " + company.name;
    let description = name;
    let disputeBody = {
      name: name,
      company_product: values.company_product,
      order_date: format(values.order_date, "yyyy-MM-dd"),
      description: description,
    };

    let createCaseBody = {
      summary: description,
      description: description,
      profile: profile.id,
      type: CLAIMANT,
    };
    dispatch(createDispute(disputeBody))
      .then((response) => {
        createCaseBody.dispute = response;
        return dispatch(createCase(createCaseBody));
      })
      .then((response) => {
        let respondentCaseBody = {
          summary: createCaseBody.summary,
          description: createCaseBody.description,
          type: "respondent",
          dispute: createCaseBody.dispute,
        };

        return dispatch(createCase(respondentCaseBody));
      })
      .then((response) => {
        history.push(`/disputes/${response.dispute}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000);

  // handler for vendor as claimant
  const onProceed = (values) => {
    setClaimantFormValues({ ...values });
    setFormStep(2);
  };

  const formik = useFormik({
    initialValues: {
      email: user.email,
      phone: profile.phone_number,
      birth_date: new Date(profile.birth_date),
      order_date: new Date(),
      company: isVendor ? profile.company : null,
      company_product_type: null,
      company_product: null,
    },
    onSubmit: isVendor ? onProceed : debouncedOnSubmit,
    validationSchema: DisputeSchema,
  });
  return (
    <Box p="2rem 0">
      <Grid container justify="center" >
        {/* <Grid item xs></Grid> */}
        <Grid item xs={10} md={6}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box marginTop="1rem">
              <Typography variant="h4" gutterBottom align="center">
                <FormattedMessage id="pages.intro.introText" />
              </Typography>
              <Box height="1rem"></Box>
              <Box p="0rem 2rem">
                <form onSubmit={formik.handleSubmit} data-testid="intro-form">
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={<FormattedMessage id="user.email" />}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email ? (
                    <Alert severity="error">{formik.errors.email}</Alert>
                  ) : null}
                  <Box height="1.7rem"></Box>
                  <TextField
                    required
                    name="phone"
                    id="phone"
                    label={<FormattedMessage id="user.phoneNumber" />}
                    variant="outlined"
                    type="phone"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.errors.phone ? (
                    <Alert severity="error">{formik.errors.phone}</Alert>
                  ) : null}
                  <Box height="1.7rem"></Box>
                  {!isVendor && (
                    <Fragment>
                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        data-testid="respondent-select"
                      >
                        <InputLabel id="against">
                          {/* <FormattedMessage id="dispute.respondent" /> */}
                          {/* {"Respondent"} */}
                          {"Member"}
                        </InputLabel>
                        <Select
                          id="company"
                          name="company"
                          value={formik.values.company}
                          onChange={(e) => {
                            formik.setFieldValue("company_product", null);
                            formik.setFieldValue("company_product_type", null);
                            formik.handleChange(e);
                            setCompanyProducts([]);
                            getCompanyProducts(e.target.value).then(
                              (response) => {
                                console.log(response);
                                setCompanyProducts(response);
                              }
                            );
                          }}
                          labelId="against"
                          label="Respondent"
                        >
                          {companies.map((company, index) => (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.errors.company ? (
                        <Alert severity="error">{formik.errors.company}</Alert>
                      ) : null}

                      <Box height="1.7rem"></Box>
                    </Fragment>
                  )}

                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    disabled={companyProducts.length === 0}
                    data-testid="company-product-select"
                  >
                    <InputLabel id="against">
                      {"Format"}
                      {/* <FormattedMessage id="dispute.nature" /> */}
                    </InputLabel>
                    <Select
                      id="company_product_type"
                      name="company_product_type"
                      value={formik.values.company_product_type}
                      onChange={(e) => {
                        formik.setFieldValue("company_product", null);
                        formik.handleChange(e);
                      }}
                      labelId="company_product_type"
                      label="Compamy Product Type"
                    >
                      {companyProducts
                        .map((item) => item.type)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.errors.company_product_type ? (
                    <Alert severity="error">
                      {formik.errors.company_product_type}
                    </Alert>
                  ) : null}

                  <Box height="1.7rem"></Box>

                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    disabled={companyProducts.length === 0}
                  >
                    <InputLabel id="against">
                      {" "}
                      <FormattedMessage id="dispute.level" />
                    </InputLabel>
                    <Select
                      id="company_product"
                      name="company_product"
                      value={formik.values.company_product}
                      onChange={formik.handleChange}
                      labelId="company_product"
                      label="Compamy Product"
                      data-testid="company-product-type-select"
                    >
                      {companyProducts
                        .filter(
                          (product) =>
                            product.type === formik.values.company_product_type
                        )
                        .map((product, index) => (
                          <MenuItem
                            key={product.id}
                            value={product.id}
                            data-testid={`company-product-type-item-${index}`}
                          >
                            {product.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.errors.company_product ? (
                    <Alert severity="error">
                      {formik.errors.company_product}
                    </Alert>
                  ) : null}

                  <Box height="1.7rem"></Box>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="order_date"
                      name="order_date"
                      label={<FormattedMessage id="dispute.issueDat" />}
                      format="dd/MM/yyyy"
                      value={formik.values.order_date}
                      disableFuture={true}
                      onChange={(value) => {
                        const event = {
                          persist: () => {},
                          target: {
                            type: "change",
                            name: "order_date",
                            value,
                          },
                        };
                        formik.handleChange(event);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {formik.errors.order_date ? (
                    <Alert severity="error">{formik.errors.order_date}</Alert>
                  ) : null}
                  <Box height="2rem"></Box>
                  <Box display="flex" justifyContent="center">
                    {caseLoading || disputeLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                      {/* <Button
                        type="submit"
                        variant="contained"
                        // color="primary"
                        style={{background:"#2F80ED",color:"white"}}
                        data-testid="submit-intro-claimant"
                        disabled={caseLoading || disputeLoading}
                      >
                        SAVE
                      </Button> */}
                      <Button
                        type="submit"
                        variant="contained"
                        // color="primary"
                        style={{background:"#2F80ED",color:"white",fontSize:"18px"}}
                        data-testid="submit-intro-claimant"
                        disabled={caseLoading || disputeLoading}
                      >
                        {isVendor ? (
                          <FormattedMessage id="buttons.proceed" />
                        ) : (
                          <FormattedMessage id="buttons.submit" />
                        )}
                      </Button>
                      </>
                    )}
                  </Box>
                  <Box height="1.5rem"></Box>
                </form>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs /> */}
      </Grid>
    </Box>
  );
};

export default IntroForm;
