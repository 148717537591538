import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import EventIcon from "@material-ui/icons/Event";
import {
  CLAIMANT,
  GENERAL,
  GENERAL_RV,
  RESPONDENT,
  VENDOR,
} from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import Dispute from "features/disputesRv/Dispute";
import DisputeNumberCard from "features/disputesRv/partials/DisputeNumberCard";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";

const getName = (profile, user) => {
  return (
    (profile.profile_type === GENERAL && user.first_name) ||
    (profile.profile_type === VENDOR && user.first_name) ||
    (profile.profile_type === GENERAL_RV && user.first_name)
  );
};

const Disputes = ({
  disputes,
  cases,
  profile,
  user,
  claimaintDisputes,
  respondentDisputes,
  isRV,
}) => {
  const classes = useStyles();
  let history = useHistory();

  let {
    profile: { profile_type },
  } = profile;

  const onClick = (e) => {
    history.push(`/intro`);
  };

  let latestDispute = disputes[0];

  return (
    <Grid container spacing={3}>
      <Grid item md={1}></Grid>
      <Grid item xs={12} md={10}>
        <Box className={classes.container}>
          <Grid container justify="space-between">
            <Grid item md={4} sm={12} xs={12}>
              <Typography variant="h3">
                <FormattedMessage id="common.hi" />,{" "}
                {getName(profile.profile, user)}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EventIcon />}
                  onClick={() => {
                    history.push("/events");
                  }}
                >
                  <FormattedMessage id="buttons.calendar" />
                </Button>
                <Box width="0.8rem"></Box>
                {(profile_type === GENERAL ||
                  profile_type === VENDOR ||
                  profile_type === GENERAL_RV) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={onClick}
                  >
                    <FormattedMessage id="buttons.createNewDispute" />
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>

          {!isEmpty(latestDispute) && (
            <Dispute
              dispute={latestDispute}
              cases={cases}
              profile={profile.profile}
            />
          )}

          <Box height="1rem"></Box>
          <Box display="flex">
            <DisputeNumberCard
              type={CLAIMANT}
              disputesMap={claimaintDisputes}
            />

            <Box width="1rem"></Box>

            <DisputeNumberCard
              type={RESPONDENT}
              disputesMap={respondentDisputes}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
};
export default Disputes;
