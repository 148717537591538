export default (cases, disputes) => {
  let claimaintDisputes = {
    active: [],
    resolved: [],
  };
  let respondentDisputes = {
    active: [],
    resolved: [],
  };
  cases.forEach((cse) => {
    let currentDispute = disputes.find((dispute) => dispute.id === cse.dispute);
    if(currentDispute){
      if (cse.type === "respondent"
        //  && cse.case_status == "review_complete"
      // && currentDispute.approved
      ) {
        if (currentDispute.status === "closed") {
          respondentDisputes.resolved.push(currentDispute);
        } else {
          respondentDisputes.active.push(currentDispute);
        }
      } else if(cse.type=== "claimant"){
        if (currentDispute.status === "closed") {
          claimaintDisputes.resolved.push(currentDispute);
        } else {
          claimaintDisputes.active.push(currentDispute);
        }
      }
    }
  });
  return { respondentDisputes, claimaintDisputes };
};
