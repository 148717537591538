import {
  ADJUDICATION,
  ARBITRATION,
  CLAIMANT_COMPLETE,
  MEDIATION,
  NEGOTIATION,
  NEW,
  RESPONDENT_COMPLETE,
  RESPONDENT_INPROGRESS,
} from "app/constants";
import {
  compareAsc,
  isAfter,
  isBefore,
  subMonths,
  subWeeks,
  isEqual,
} from "date-fns";
import { formatDate } from "utils";

export const calculatePercentage = (count, totalCount) => {
  return (count / totalCount) * 100;
};

const getLabel = (value) => {
  console.log("value:", value);
  switch (value) {
    case NEW:
      return "Grievance Created";
    case RESPONDENT_COMPLETE:
      return "Respondent Details Filled";
    case CLAIMANT_COMPLETE:
      return "Claimant Details filled";
    case RESPONDENT_INPROGRESS:
      return "Respondant in progress";
    case "terms_complete":
      return "Terms Complete";
    case "price_agreement_complete":
      return "Price Agreement Complete";
    case "professional_upload":
      return "Professional Upload";
    case "webnyay_upload":
      return "Grb Upload";
    case "award_upload":
      return "Award Upload";
    case "complete":
      return "Complete";
    case "paid":
      return "Paid";
    case "closed":
      return "Closed";
    case MEDIATION:
      return "Mediation";
    case NEGOTIATION:
      return "Negotiation";
    case ARBITRATION:
      return "Arbitration";
    case ADJUDICATION:
      return "Adjudication";
    case "":
      return "None";
  }
};

export const createDisputeStatusMap = (disputes) => {
  const statusMap = new Map();
  console.log("disputes : ", disputes);
  disputes.forEach((dispute) => {
    if (statusMap.has(dispute.status)) {
      const currentKey = statusMap.get(dispute.status);
      statusMap.set(dispute.status, currentKey + 1);
    } else {
      statusMap.set(dispute.status, 1);
    }
  });

  console.log("statusMap:", statusMap);
  return statusMap;
};

export const createDisputeModeMap = (disputes) => {
  const modeMap = new Map();

  disputes.forEach((dispute) => {
    // console.log(dispute.flow_type);
    if (modeMap.has(dispute.flow_type)) {
      const currentKey = modeMap.get(dispute.flow_type);
      modeMap.set(dispute.flow_type, currentKey + 1);
    } else {
      modeMap.set(dispute.flow_type, 1);
    }
  });

  console.log("modeMap:", modeMap);
  return modeMap;
};

export const createDisputeCountMap = (disputes) => {
  const countMap = new Map();

  disputes.forEach((dispute) => {
    const dateKey = formatDate(dispute.order_date);

    if (countMap.has(dateKey)) {
      const currentKey = countMap.get(dateKey);
      countMap.set(dateKey, currentKey + 1);
    } else {
      countMap.set(dateKey, 1);
    }
  });

  return countMap;
};

export const transformDisputeStatusPieData = (statusMap, totalCount) => {
  const percentageMap = new Map();

  const percentageArray = [];

  statusMap.forEach((value, key) => {
    percentageMap.set(key, calculatePercentage(value, totalCount));
    percentageArray.push({ value: Math.floor(value), name: getLabel(key) });
  });

  return percentageArray;
};

export const transformDisputeToBarData = (countMap) => {
  const barGraphData = [];

  countMap.forEach((value, key) => {
    barGraphData.push({
      date: key,
      GrievanceCount: value,
    });
  });

  return barGraphData.sort((a, b) => {
    return compareAsc(Date.parse(a.date), Date.parse(b.date));
  });
};

const timeValues = ["week", "month", "quarter", "half", "all"];

const getProperTimeValue = (time) => {
  if (timeValues.includes(time)) {
    return time;
  } else {
    return "week";
  }
};
export const evaluatePastTime = (time) => {
  const currentDate = new Date();

  switch (getProperTimeValue(time)) {
    case "week":
      return subWeeks(currentDate, 1);
    case "month":
      return subMonths(currentDate, 1);
    case "quarter":
      return subMonths(currentDate, 3);
    case "half":
      return subMonths(currentDate, 6);
    case "all":
      return null;
  }
};

export const filterDispute = (disputes, time) => {
  const fromDate = evaluatePastTime(time);
  const nowDate = new Date();
  let filteredDisputes;
  if (fromDate) {
    filteredDisputes = disputes.filter((dispute) => {
      const parsedDate = new Date(dispute.created);
      return isAfter(parsedDate, fromDate) && isBefore(parsedDate, nowDate);
    });
  }
  return fromDate ? filteredDisputes : disputes;
};

export const filterDisputeByDate = (disputes, start_, end_) => {
  let start, end;
  if (start_) start = new Date(start_);
  if (end_) end = new Date(end_);
  let filteredDisputes;
  if (start || end) {
    end.setDate(end.getDate() + 1);
    filteredDisputes = disputes.filter((dispute) => {
      const d = new Date(dispute.created);
      if (start && end) return +d >= +start && +d <= +end;
      else if (start && !end) return +d == +start;
      else if (!start && end) return +d == +end;
    });
  }
  console.log("filteredDisputes ::::: ", filteredDisputes);
  return filteredDisputes;
};

export const getNatureOfGrievance = (data) => {
  // console.log("data: ", data);
  let result = [
    { value: 0, name: "Appeal" },
    { value: 0, name: "Escalation" },
  ];
  data.forEach((dispute) => {
    if (
      dispute.nature_grievance_complainant ==
      "Appeal (against the decision of the DPCGC Council Member)"
    ) {
      result[0]["value"] = result[0]["value"] + 1;
    } else if (
      dispute.nature_grievance_complainant ==
      "Escalation (no decision received from the DPCGC Council Member)"
    ) {
      result[1]["value"] = result[1]["value"] + 1;
    }
  });
  return result;
};

export const getConcernRelate = (data) => {
  // console.log("data: ", data);
  let result = [
    { value: 0, name: "Age Ratings" },
    { value: 0, name: "Content Descriptors" },
    { value: 0, name: "Synopsis" },
    { value: 0, name: "Parental/Access Control" },
    { value: 0, name: "Other" },
  ];
  data.forEach((dispute) => {
    if (dispute.concern_related_complainant == "Age ratings") {
      result[0]["value"] = result[0]["value"] + 1;
    } else if (dispute.concern_related_complainant == "Content descriptors") {
      result[1]["value"] = result[1]["value"] + 1;
    } else if (dispute.concern_related_complainant == "Synopsis") {
      result[2]["value"] = result[2]["value"] + 1;
    } else if (
      dispute.concern_related_complainant == "Parental/Access Control"
    ) {
      result[3]["value"] = result[3]["value"] + 1;
    } else if (
      dispute.concern_related_complainant ==
      "Other content related concern (If you select this option, please explain the concern in detail in the next question)"
    ) {
      result[4]["value"] = result[4]["value"] + 1;
    }
  });
  return result;
};
