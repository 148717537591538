import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SingleDispute from "./SingleDisputeRv";
import { getDispute } from "slices/disputeSlice";
import { getCase } from "slices/casesSlice";
import { useParams } from "react-router-dom";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import isEmpty from "lodash/isEmpty";
export default function SingleDisputeRvContainer() {
  let { disputeId } = useParams();

  const {
    profile: { profile: profile },
    disputes: { loading: disputeLoading, activeDispute: activeDispute },
    cases: { loading: caseLoading, activeCase: activeCase },
  } = useSelector((state) => state, shallowEqual);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getDispute(disputeId));
    dispatch(getCase(disputeId));
  }, []);

  const passObjects = {
    profile,
    activeDispute,
    activeCase,
  };
  const loading =
    caseLoading ||
    disputeLoading ||
    isEmpty(activeDispute) ||
    isEmpty(activeCase);

  if (profile.profile_type && !loading)
    return <SingleDispute {...passObjects} />;
  else return <CircularCenteredLoader />;
}
