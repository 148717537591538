import { Box, Grid, Button, Paper, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import FormattedMessage from "features/common/TranslatedMessage";
import { getWindowDimension } from "utils/responsiveUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DisputeDetails from "./DisputeDetails/DisputeDetails";
import DetailsOfParties from "./DetailsOfParties/DetailsOfParties";
import EvidenceSubmissions from "./EvidenceSubmissions/EvidenceSubmissions";
import DecisionByDPCGC from "./DecisionByDPCGC/DecisionByDPCGC";
import CaseNotes from "./Case notes/CaseNotes";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDispute } from "slices/disputeSlice";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    // height: "95vh",
    // height:"100%",
    borderRadius: "1rem",
    // height: '-webkit-fill-available',
    maxHeight: "100%",
    // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
    [theme.breakpoints.down("sm")]: {
      // height:"100%",
    },
  },
  sidebar: {
    background: "white",
    // marginTop: "2px",
    width: "27rem",
    // height: "55rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sidebarItem: {
    fontSize: "16px",
    padding: "0.5rem",
  },
  selected: {
    background: "#F2F2F2",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  body2: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "0",
    overflow: "hidden",
    width: "100%",
    textAlign: "center",
  },
  secretariatName: {
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
}));

export default function DisputeDisplay() {
  let { disputeId } = useParams();
  const dispatch = useDispatch();

  const [relaod, setRelaod] = React.useState();
  const [loading, setLoading] = useState(true);

  const {
    disputes: { loading: disputeLoading, activeDispute: activeDispute },
  } = useSelector((state) => state, shallowEqual);
  React.useEffect(() => {
    dispatch(getDispute(disputeId)).then(() => {
      setLoading(false);
    });
  }, [relaod]);

  const { height, width } = getWindowDimension();
  const [screenWidth, setWidth] = useState(width);
  const handleResize = () => {
    const { height, width } = getWindowDimension();
    setWidth(width);
  };
  const isSmallScreen = (screenWidth) => {
    return screenWidth < 960;
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    console.log("dispute display render");
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let t = localStorage.getItem("whichTabDisputeDisplay");
  // console.log("t = ",t);

  const classes = useStyles();
  // const [selected, setSelected] = useState(isSmallScreen? 0 :t ? t : 1);
  const [selected, setSelected] = useState(t ? t : 1);
  const [step, setStep] = useState(isSmallScreen(screenWidth) ? 0 : 1);
  let history = useHistory();

  console.log("selected", selected);
  console.log("step", step);

  // console.log(disputeLoading);
  // console.log(activeDispute);
  // console.log(activeCase);
  // console.log(companyFlows);

  const MobileView = ({ step }) => {
    switch (step) {
      case 0:
        return <Sidebar />;
      case 1:
        return <Body />;
      default:
        return null;
    }
  };

  const RenderOptions = () => {
    if (selected == 1 || selected == 0) {
      return (
        <DisputeDetails
          activeDispute={activeDispute}
          step={1}
          setStep={setStep}
        />
      );
    }
    if (selected == 2) {
      return <DetailsOfParties activeDispute={activeDispute} />;
    }
    if (selected == 3) {
      return <EvidenceSubmissions activeDispute={activeDispute} />;
    }
    if (selected == 4) {
      return <CaseNotes activeDispute={activeDispute} />;
    }
    if (selected == 5) {
      return <DecisionByDPCGC activeDispute={activeDispute} />;
    }
    if (selected == 6) {
      return (
        <DisputeDetails
          activeDispute={activeDispute}
          step={3}
          setStep={setStep}
        />
      );
    }
  };

  const Sidebar = () => {
    return (
      <Box className={classes.sidebar}>
        {!isSmallScreen(screenWidth) && <Box height="4rem"></Box>}
        <List style={{ paddingTop: "0" }}>
          <Divider />
          {selected == 1 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Grievance Details
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(1);
                setStep(1);
                setRelaod(Math.random());
              }}
            >
              <Typography className={classes.sidebarItem}>
                Grievance Details
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 2 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Details of parties
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(2);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Details of parties
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 3 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Evidence submissions
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(3);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Evidence submissions
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 4 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(4);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Case Notes
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(4);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Case Notes
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 5 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(5);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Decision by GRB
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(5);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Decision by GRB
              </Typography>
            </ListItem>
          )}
          <Divider />
          {selected == 6 ? (
            <ListItem
              className={classes.selected}
              button
              onClick={() => {
                setSelected(6);
                setStep(1);
              }}
            >
              <Typography className={classes.sidebarItem}>
                Close grievance
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => {
                setSelected(6);
                setStep(1);
                setRelaod(Math.random());
              }}
            >
              <Typography className={classes.sidebarItem}>
                Close grievance
              </Typography>
            </ListItem>
          )}
          <Divider />
        </List>
      </Box>
    );
  };

  const Body = () => {
    return (
      <Box className={classes.body2}>
        <Hidden mdUp>
          <Paper
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              display: "flex",
              padding: "20px 30px",
            }}
          >
            <Button
              onClick={() => {
                // history.push("/disputes");
                console.log("set step clicked");
                setStep(0);
              }}
              size="large"
              startIcon={<ArrowBackIosIcon />}
            >
              <FormattedMessage id="buttons.back" />
            </Button>
          </Paper>
        </Hidden>
        <RenderOptions />
      </Box>
    );
  };

  if (loading) return <CircularCenteredLoader />;
  return (
    <React.Fragment>
      <Box className={classes.root}>
        {isSmallScreen(screenWidth) ? (
          <MobileView step={step} />
        ) : (
          <>
            <Sidebar />
            <Body />
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
