import React, { Fragment } from "react";
import Profile from "./Profile";
import { useSelector, shallowEqual } from "react-redux";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import isEmpty from "lodash/isEmpty";

export default function ProfileContainer() {
  const { profile, loading } = useSelector(
    (state) => state.profile,
    shallowEqual
  );
  console.log(profile);
  return (
    <Fragment>
      {!loading && !isEmpty(profile) ? (
        <Profile profile={profile} />
      ) : (
        <CircularCenteredLoader />
      )}
    </Fragment>
  );
}
