import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";
import { User } from "./groupSlice";

export const initialState = {
  loading: false,
  hasErrors: false,
  disputes: [],
  disputesall: [],
  activeDispute: {},
  respondent: { active: [], resolved: [] },
  claimant: { active: [], resolved: [] },
  userFiles: [],
  recordings: [],
};

const disputeSlice = createSlice({
  name: "disputes",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    stopProgress: (state) => {
      state.loading = false;
    },
    setSucessfullAll: (state, { payload }) => {
      state.disputesall = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setSucessfull: (state, { payload }) => {
      state.disputes = payload.results;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setSucessfullCreate: (state, { payload }) => {
      state.disputes.push(payload);
      state.activeDispute = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setSucessfullGet: (state, { payload }) => {
      const index = state.disputes.findIndex((disp) => disp.id === payload.id);
      if (index !== -1) {
        state.disputes[index] = payload;
      }
      state.activeDispute = payload;

      state.loading = false;
      state.hasErrors = false;
    },
    setRespondentAndClaimant: (state, { payload }) => {
      state.respondent = payload.respondent;
      state.claimant = payload.claimant;
    },
    setUserFiles: (state, { payload }) => {
      console.log("in payload ", payload);
      state.userFiles = payload;
    },
    setRecordings: (state, { payload }) => {
      state.recordings = payload;
    },
  },
});

export const {
  setProgress,
  setSucessfull,
  setSucessfullAll,
  setError,
  setSucessfullCreate,
  setSucessfullGet,
  setRespondentAndClaimant,
  setUserFiles,
  setRecordings,
} = disputeSlice.actions;

export default disputeSlice.reducer;

export function getDisputesForPagination(pageNo, disputesType) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: `/api/dispute/?page=${pageNo}&type=${
        disputesType == "active" ? "open" : "closed"
      }`,
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getDisputesAll(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/disputeall/",
      params: params,
    }).then(
      (response) => {
        console.log("response.data:", response.data);
        dispatch(setSucessfullAll(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getDisputes(params = {}) {
  return async (dispatch) => {
    dispatch(setProgress());
    return await instance({
      method: "get",
      url: "/api/dispute/",
      params: params,
    }).then(
      (response) => {
        // console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);

        dispatch(setError());
      }
    );
  };
}

export function getDispute(id) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "get",
        url: "/api/dispute/",
        params: {
          id: id,
        },
      });

      dispatch(setSucessfullGet(response.data.results[0]));

      return response.data;
    } catch (error) {
      console.log(error);
      // console.log(error.request.status);
      // console.log(error.message);
      // console.log(error.request.response);

      dispatch(setError());
    }
  };
}

export function createDispute(disputeBody) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/api/dispute/",
        data: disputeBody,
      });
      dispatch(setSucessfullCreate(response.data));

      return response.data.id;
    } catch (error) {
      console.log(error);
      dispatch(setError());
      throw error;
    }
  };
}

export function updateDispute(disputePayload) {
  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "patch",
        url: "/api/dispute/" + disputePayload.id + "/",
        data: disputePayload,
      });
      dispatch(setSucessfullGet(response.data));

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.request.status);
      console.log(error.message);
      console.log(error.request.response);
      dispatch(setError());
    }
  };
}

export function uploadDispute(files, activeDispute) {
  return async (dispatch) => {
    dispatch(setProgress());
    const data = new FormData();
    files.forEach((file) => {
      data.append("file", file);
    });
    try {
      const response = await instance.put(
        "/uploaddispute/" + activeDispute.id + "/",
        data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch(setSucessfullGet(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function uploadCaseNote(files, activeDispute) {
  return async (dispatch) => {
    dispatch(setProgress());
    const data = new FormData();
    files.forEach((file) => {
      data.append("file", file);
    });
    try {
      const response = await instance.put(
        "/uploadcasenote/" + activeDispute.id + "/",
        data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch(setSucessfullGet(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function uploadEvidence(files, activeDispute, str) {
  return async (dispatch) => {
    dispatch(setProgress());
    const data = new FormData();
    files.forEach((file) => {
      data.append("file", file);
    });
    data.append("sent_by", str);
    try {
      const response = await instance.put(
        "/uploadevidence/" + activeDispute.id + "/",
        data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch(setSucessfullGet(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function getUserFiles(disputeId) {
  return async (dispatch) => {
    // fetch files
    try {
      const response = await instance({
        method: "get",
        url: "/api/rvdocument/",
        params: {
          dispute__id: disputeId,
        },
      });
      dispatch(setUserFiles(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function addUserFile(fileBody) {
  return async (dispatch) => {
    // put file to backend
    const data = new FormData();
    Object.keys(fileBody).forEach((key) => {
      data.append(key, fileBody[key]);
    });
    console.log("data", data);
    try {
      const response = await instance({
        method: "post",
        url: "/api/rvdocument/",
        data: data,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      await dispatch(getUserFiles(fileBody.dispute));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function deleteUserFile(id, disputeId) {
  return async (dispatch) => {
    // delete file using id
    try {
      const response = await instance({
        method: "delete",
        url: `/api/rvdocument/${id}/`,
      });
      await dispatch(getUserFiles(disputeId));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export function renameUserFile(id, disputeId, newPath) {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "patch",
        url: `/api/rvdocument/${id}/`,
        data: { id: id, path: newPath },
      });
      await dispatch(getUserFiles(disputeId));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
}

export async function downloadFile(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/rvdocurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadLegalDoc(id) {
  
  try {
    const response = await instance({
      method: "get",
      url: `/api/legaldocurl/${id}/`,
    });
    
    // window.open(response.data.url, '_blank');
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadCaseNote(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/casenoteurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
    
  } catch (error) {
    console.log(error);
  }
}

export async function downloadEvidence(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/evidencedocurl/${id}/`,
    });

    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadRecording(id) {
  try {
    const response = await instance({
      method: "get",
      url: `/api/recordingurl/${id}/`,
    });
    console.log("response.data: ", response.data);
    // window.open(response.data.url, "_blank");
    window.location.href = response.data.url;
  } catch (error) {
    console.log(error);
  }
}

export const getRecordings = (disputeId) => {
  console.log("Here");
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: "/api/recordings/" + disputeId + "/",
      });
      console.log("response.data.data: ", response.data.data);
      dispatch(setRecordings(response.data.data));
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};

export const getDisputesCount = () => {
  return async (dispatch) => {
    try {
      const response = await instance({
        method: "get",
        url: "/api/countdisputes/",
      });
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(setError());
    }
  };
};
