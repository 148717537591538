import React from "react";
import { CLAIMANT, RESPONDENT, GENERAL_RV } from "app/constants";
import ClaimantStepper from "./ClaimantStepper";
import RespondentStepper from "./RespondentStepper";
import DisputeDisplayRv from "features/singleDisputeRv/DisputeDisplayRv";

export default function SingleDisputeRv({
  activeDispute,
  profile,
  activeCase,
}) {
  const childProps = { activeDispute, profile, activeCase };

  const { profile_type } = profile;

  if (profile_type === GENERAL_RV) {
    if (activeCase.type === CLAIMANT)
      return <ClaimantStepper {...childProps} />;
    else if (activeCase.type === RESPONDENT)
      return <RespondentStepper {...childProps} />;
  } else {
    return <DisputeDisplayRv activeDispute={activeDispute} profile={profile} />;
  }
}
