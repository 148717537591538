import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import { CLAIMANT } from "app/constants";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DisputeRvSchema } from "schemas/DisputeRvSchema";
import { createCase } from "slices/casesSlice";
import { createDispute } from "slices/disputeSlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    padding: "2rem",
  },
}));

const IntroForm = ({ profile, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseLoading = useSelector((state) => state.cases.loading);
  const disputeLoading = useSelector((state) => state.disputes.loading);

  let history = useHistory();

  // debounced submit handler for general user as claimant
  const debouncedOnSubmit = debounce((values) => {
    let name = user.first_name + " " + user.last_name + " vs ";
    let description = name;
    let disputeBody = {
      name: name,
      description: description,
    };

    let createCaseBody = {
      summary: description,
      description: description,
      profile: profile.id,
      type: CLAIMANT,
    };
    dispatch(createDispute(disputeBody))
      .then((response) => {
        createCaseBody.dispute = response;
        return dispatch(createCase(createCaseBody));
      })
      .then((response) => {
        history.push(`/disputes/${response.dispute}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000);

  // handler for vendor as claimant

  const formik = useFormik({
    initialValues: {
      email: user.email,
      phone: profile.phone_number,
      affirmation: false,
    },
    onSubmit: debouncedOnSubmit,
    validationSchema: DisputeRvSchema,
  });
  return (
    <Box p="2rem 0">
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid item xs={10} md={6}>
          <Paper className={classes.formContainer}>
            {" "}
            <Box>
              <Typography variant="h4" gutterBottom align="center">
                <FormattedMessage id="pages.intro.introText" />
              </Typography>
              <Box height="1rem"></Box>
              <Box p="0rem 2rem">
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    required
                    id="email"
                    label={<FormattedMessage id="user.email" />}
                    variant="outlined"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                  <Box height="1rem"></Box>
                  <TextField
                    required
                    id="phone"
                    label={<FormattedMessage id="user.phoneNumber" />}
                    variant="outlined"
                    type="phone"
                    fullWidth={true}
                    className={classes.item}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.errors.phone ? (
                    <div>{formik.errors.phone}</div>
                  ) : null}
                  <Box height="1rem"></Box>

                  <Box height="1rem"></Box>

                  <Box height="2rem"></Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.affirmation}
                        onChange={formik.handleChange}
                        name="affirmation"
                        color="primary"
                      />
                    }
                    label="Please confirm that you would like to proceed to the  virtual hearing platform"
                  />
                  {formik.errors.affirmation ? (
                    <Alert severity="error">{formik.errors.affirmation}</Alert>
                  ) : null}
                  <Box height="1rem"></Box>

                  <Box display="flex" justifyContent="center">
                    {caseLoading || disputeLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={caseLoading || disputeLoading}
                      >
                        <FormattedMessage id="buttons.submit" />
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs />
      </Grid>
    </Box>
  );
};

export default IntroForm;
