import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DebouncedButton from "features/common/DebouncedButton";
import FormattedMessage from "features/common/TranslatedMessage";
import Question from "features/singleDispute/Question";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createAnswers as createAnswersAndUpdateCase } from "slices/answerSlice";
import { DocGenerator } from "utils";
import { useStyles } from "./styles";
import isEmpty from "lodash/isEmpty";
import { format, parseISO } from "date-fns";
import { updateDispute } from "slices/disputeSlice";

const Questions = ({
  questions,
  activeDispute,
  activeCase,
  answerLoading,
  profile,
}) => {
  let dispatch = useDispatch();

  console.log("questions", questions);

  let history = useHistory();
  const classes = useStyles();
  const answerValues = new Map();

  function checkMendatoryAnswer(questionId) {
    for (var entry of answerValues.entries()) {
      if (entry[0] === questionId) {
        return entry[1];
      }
    }
  }

  function checkMendatoryBoolean(questionId) {
    for (var entry of answerValues.entries()) {
      if (entry[0] === questionId) {
        return entry[1];
      }
    }
  }

  const onSubmit = (e) => {
    console.log("questions:", questions);
    console.log("answerValues:", answerValues);

    questions.map((question, index) => {
      if (
        !question.optional &&
        question.type !== "boolean" &&
        question.type !== "date"
      ) {
        var returned_ans = checkMendatoryAnswer(question.id);
        if (typeof returned_ans === "undefined") {
          alert(
            "Please fill mandatory field => " +
              question.name.substr(0, 40) +
              "...."
          );
          console.log(
            "returned_ans: " +
              returned_ans +
              " question.id: " +
              question.id +
              " type: " +
              typeof returned_ans
          );
        } else if (returned_ans === "") {
          alert(
            "Please fill mandatory field => " +
              question.name.substr(0, 40) +
              "...."
          );
          console.log(
            "returned_ans: " +
              returned_ans +
              " question.id: " +
              question.id +
              " type: " +
              typeof returned_ans
          );
          answerValues.delete(question.id);
        }
      }

      if (
        !question.optional &&
        question.mandatory_boolean &&
        question.type === "boolean"
      ) {
        var returned_ans = checkMendatoryBoolean(question.id);
        if (!returned_ans) {
          alert("Please Agree with: " + question.name.substr(0, 40) + "....");
          console.log(
            "returned_ans_bool => " +
              returned_ans +
              " question.id: " +
              question.id +
              " type: " +
              typeof returned_ans
          );
          answerValues.delete(question.id);
        }
      }

      if (!question.optional && question.type === "time") {
        let arr = answerValues.get(question.id).split(":");
        console.log("arr:::::", arr);
        if (arr[0] > 5 || arr[1] > 59 || arr[2] > 59) {
          console.log("returned ans: ", answerValues.get(question.id));
          alert("Please enter time less than 5:59:59");
          answerValues.delete(question.id);
        }
      }
    });

    console.log("answerValues.size outside: ", answerValues.size);

    if (answerValues.size === questions.length) {
      let createAnswerPayload = [];
      for (var entry of answerValues.entries()) {
        createAnswerPayload.push({
          question: entry[0],
          value: entry[1] !== null ? entry[1].toString() : null,
          case: activeCase.id,
        });
      }

      let casePayload = {
        id: activeCase.id,
        case_status: "questions_complete",
      };

      console.log("answerValues: ", answerValues);

      // alert("Yay! filled all");
      if (profile.profile_type == "general") {
        let nature_grievance_complainant = answerValues.get(1630);
        if (!nature_grievance_complainant)
          nature_grievance_complainant = answerValues.get(1633);
        let concern_related_complainant = answerValues.get(1471);
        if (!concern_related_complainant)
          concern_related_complainant = answerValues.get(1637);
        console.log(
          "nature_grievance_complainant : ",
          nature_grievance_complainant
        );
        console.log(
          "concern_related_complainant : ",
          concern_related_complainant
        );
        dispatch(
          updateDispute({
            id: activeDispute.id,
            nature_grievance_complainant: nature_grievance_complainant,
            concern_related_complainant: concern_related_complainant,
          })
        );
      }
      dispatch(
        createAnswersAndUpdateCase({ createAnswerPayload, casePayload })
      );
    }
  };

  return (
    <React.Fragment>
      <Box textAlign="center" m="0 auto">
        <Grid container>
          {/* <Grid item xs sm md={2}></Grid> */}

          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            data-testid={`Claimant-arbitration-questions`}
            style={{marginLeft:"auto"}} 
          >
            <Box height="1rem"></Box>
            {questions.map((question, index) => {
              if (question.optional) {
                if (question.type === "boolean") {
                  answerValues.set(question.id, false);
                } else if (question.pre_filled) {
                  answerValues.set(
                    question.id,
                    "Not aware to the best of my knowledge"
                  );
                } else if (question.type === "date") {
                  answerValues.set(question.id, null);
                } else if (question.type === "simple") {
                  answerValues.set(question.id, null);
                }
              } else if (
                question.mandatory_boolean &&
                question.type === "boolean"
              ) {
                answerValues.set(question.id, false);
              } else if (!question.optional && question.type === "boolean") {
                answerValues.set(question.id, false);
              }

              // if(question.type == "date"){
              //   console.log("here1:", question.id, question.name, " ", answerValues)
              // }
              return (
                <Paper key={question.id} className={classes.paper}>
                  <Question
                    index={index}
                    question={question}
                    answerValues={answerValues}
                  />
                </Paper>
              );
            })}
          </Grid>
          <Grid item xs sm md={2}></Grid>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="center" p="1rem 0">
        {questions[0].for_profile_type === "respondent" && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              DocGenerator(activeDispute.id, "claimant");
            }}
          >
            Download Case file
          </Button>
        )}

        <Box width="2rem" />
        <DebouncedButton
          style={{fontSize:"14px"}}
          variant="outlined"
          color="primary"
          className={classes.button}
          onClickHandler={onSubmit}
          disabled={answerLoading}
          loading={answerLoading}
          value={<FormattedMessage id="buttons.submit" />}
          debounceTime={1000}
        />
      </Box>
    </React.Fragment>
  );
};

export default Questions;
