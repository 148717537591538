import React from "react";
// import Upload from "./Upload";
import Upload from "./NewUpload";
import UploadOtt from "./UploadOtt";
import { useSelector } from "react-redux";

export default function UploadContainer() {
  const activeCase = useSelector((state) => state.cases.activeCase);
  const activeDispute = useSelector((state) => state.disputes.activeDispute);
  const profile = useSelector((state) => state.profile.profile);

  // if(activeDispute.name.split(" ")[3] == "Shemaroo" && activeCase.type == "claimant"){
  //   console.log("activeDispute:", activeDispute.name.split(" ")[3]);
  //   console.log("activeCase.type:", activeCase.type);
  // }

  let temp = false;
  // if(temp){
  if (activeCase.type == "claimant") {
    return (
      <UploadOtt
        activeCase={activeCase}
        activeDispute={activeDispute}
        profile={profile}
      />
    );
  } else if (activeCase.type == "respondent") {
    return (
      <>
        <Upload
          activeCase={activeCase}
          activeDispute={activeDispute}
          profile={profile}
        />
      </>
    );
  }
}
