import React from "react";

import MicRecorder from "mic-recorder-to-mp3";
import useStateWithPromise from "./useStateWithPromise";

const recorder = new MicRecorder({
  bitRate: 128,
});

export default function useAudioRecorder() {
  const [isRecording, setIsRecording] = useStateWithPromise(false);
  const [isBlocked, setIsBlocked] = useStateWithPromise(false);
  const [blobURL, setblobURL] = useStateWithPromise("");
  const [audioFile, setAudioFile] = useStateWithPromise({});

  const startRecording = () => {
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
        console.log("Recording started");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const stopRecording = async () => {
    let [buffer, blob] = await recorder.stop().getMp3();
    const blobURL = URL.createObjectURL(blob);
    setblobURL(blobURL);
    setAudioFile(blob);
    setIsRecording(false);
    return blob;
  };

  return [isRecording, blobURL, audioFile, startRecording, stopRecording];
}
