import { isAfter, getDate, getYear, getMonth } from "date-fns";

export const stringToTime = (time, date = new Date()) => {
  const timeArray = time.split(":");
  const day = getDate(date);
  const year = getYear(date);
  const month = getMonth(date);
  const parsableTime = new Date(
    year,
    month,
    day,
    timeArray[0],
    timeArray[1],
    timeArray[2]
  );
  return parsableTime;
};

export const filterPastMeetings = (meetings) => {
  const currentDate = new Date();

  return meetings.filter((meeting) => {
    const meetingEnd = Date.parse(meeting.end);

    if (isAfter(currentDate, meetingEnd)) {
      return false;
    }
    return true;
  });
};
