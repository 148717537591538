import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";

export const initialState = {
  loading: false,
  hasErrors: false,
  agreement: {},
};

const agreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.agreement = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setError: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    setSucessfullAgreeement: (state, { payload }) => {
      state.agreement = payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export const {
  setProgress,
  setSucessfull,
  setError,
  setSucessfullAgreeement,
} = agreementSlice.actions;

export default agreementSlice.reducer;

export function getAgreement(dispute__id) {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/caseagreement/" + dispute__id + "/",
    }).then(
      (response) => {
        console.log(response.data);
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        dispatch(setError());
      }
    );
  };
}
