import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCompanies } from "slices/companySlice";
import Intro from "./intro";

export const introFormContext = React.createContext();

export default function IntroContainer() {
  const { user } = useSelector((state) => state.user, shallowEqual);
  const { profile } = useSelector((state) => state.profile, shallowEqual);
  const { companies } = useSelector((state) => state.companies, shallowEqual);
  const [claimantFormValues, setClaimantFormValues] = React.useState({});
  const [formStep, setFormStep] = React.useState(1);

  const dispatch = useDispatch();
  const loading = !profile.user || !user.email || companies.length === 0;

  const IntroFormContextProvider = introFormContext.Provider;

  var companiesSorted = JSON.parse(JSON.stringify(companies));

  companiesSorted.sort(function (a, b) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <IntroFormContextProvider
      value={{
        isVendor: profile.profile_type === "vendor" ? true : false,
        claimantFormValues,
        setClaimantFormValues,
        setFormStep,
      }}
    >
      <Intro
        loading={loading}
        user={user}
        profile={profile}
        companies={companiesSorted}
        formStep={formStep}
      />
    </IntroFormContextProvider>
  );
}
