import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { MEDIATOR } from "app/constants";
import classNames from "classnames";
import FormattedMessage from "features/common/TranslatedMessage";
import React from "react";
import { LegalDocGet } from "utils";

const useStyles = makeStyles((theme) => ({
  heading: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#000",
  },
  iconContainer: {
    background: "rgba(112, 124, 151, 0.15)",
    display: "inline-block",
    borderRadius: "0.3rem",
    padding: "1rem",
  },
  icon: {
    color: "rgba(112, 124, 151, 0.7)",
    fontSize: "6rem",
  },
  greenText: {
    color: theme.palette.common.green,
  },
}));

export default function SecretariatReviewStatus({ status, dispute, profile }) {
  const classes = useStyles();
  const reviewComplete = status !== "wait";

  let award_type = "Arbitral Award";
  if (profile.profile_type === MEDIATOR) {
    award_type = "Settlement Agreement";
  }
  return (
    <Box display="flex" p="2rem 0" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Box className={classes.iconContainer}>
          <DescriptionOutlinedIcon className={classes.icon} />
        </Box>
        <Box
          bgcolor="primary.main"
          color="#fff"
          textAlign="center"
          p="0.5rem 0"
          fontSize="60%"
        >
          {award_type}
        </Box>
        {reviewComplete && (
          <Box p="0.2rem 0" textAlign="center">
            <Button
              color="primary"
              onClick={() => {
                LegalDocGet(dispute.id);
              }}
            >
              <FormattedMessage id="buttons.download" />
            </Button>
          </Box>
        )}
      </Box>
      <Box width="2rem"></Box>
      <Typography
        variant="h5"
        className={classNames(classes.heading, {
          [classes.greenText]: reviewComplete,
        })}
      >
        {reviewComplete ? (
          <FormattedMessage id="pages.arbitrator.reviewComplete" />
        ) : (
          <FormattedMessage id="pages.arbitrator.secreteriatReview" />
        )}
      </Typography>
    </Box>
  );
}
