import { Box, CircularProgress, Container } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormattedMessage from "features/common/TranslatedMessage";
import DetailsReview from "features/singleDispute/DetailsReview";
import Questions from "features/singleDispute/Questions";
import Upload from "features/singleDispute/Upload";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "utils";
import ClaimantWaiting from "../ClaimantFlowSteps/ClaimantWaiting";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps(is3steps) {
  if (is3steps) {
    return [
      <FormattedMessage id="pages.questions.questions" />,
      <FormattedMessage id="pages.upload.upload" />,
      <FormattedMessage id="pages.review.detailsReview" />,
      // <FormattedMessage id="pages.status.status" />,
    ];
  }
  return [
    <FormattedMessage id="pages.questions.questions" />,
    <FormattedMessage id="pages.upload.upload" />,
    <FormattedMessage id="pages.review.detailsReview" />,
    <FormattedMessage id="pages.status.status" />,
  ];
}

function getStepContent(step, dispute, profile) {
  switch (step) {
    case 0:
      return <Questions profile={profile} />; // Questions
    case 1:
      return <Upload />;
    case 2:
      return <DetailsReview profile={profile} />;
    case 3:
      return <ClaimantWaiting />;
    default:
      return "Unknown step";
  }
}

function getStep(status) {
  if (status === "new" || status === "in_progress") return 0;
  if (status === "questions_complete") return 1;
  if (status === "upload_complete") return 2;
  if (status === "review_complete") return 3;
  return 0;
}

export default function SingleDisputeDetails({ is3steps, profile }) {
  const classes = useStyles();
  let { disputeId } = useParams();

  // const [loading, setLoading] = useState(true);
  console.log(profile);

  const { activeDispute } = useSelector(
    (state) => state.disputes,
    shallowEqual
  );

  const { activeCase } = useSelector((state) => state.cases, shallowEqual);

  let activeStep = getStep(activeCase.case_status);

  const steps = getSteps(is3steps);

  if (isEmpty(activeCase) || isEmpty(activeDispute)) {
    return (
      <Box m="4rem 0 0 0" display="flex" justifyContent="center">
        <CircularProgress disableShrink />
      </Box>
    );
  }
  return (
    <Container maxWidth="lg">
      <Box>
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          className={classes.dispute_heading}
        >
          {activeDispute.name.split(" ")[3] == "Shemaroo"
            ? activeDispute.name.replace("vs", "&")
            : activeDispute.name}
        </Typography>
        <Typography
          className={classes.date_text}
          variant="subtitle1"
          color="textSecondary"
          component="p"
        >
          {/* Created on {activeDispute.created.split("T")[0]} */}
          Created on {formatDate(activeDispute.created)}
        </Typography>
      </Box>

      <Box height="1.5rem"></Box>

      <div className={classes.root}>
        <Hidden smDown>
          <Box display="flex" style={{ padding: "10px" }}>
            <Box
              style={
                {
                  // width: "-webkit-fill-available",
                  // wordWrap: "normal"
                }
              }
            >
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                style={{ padding: 0, paddingTop: "10px", background: "none" }}
              >
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box height="2rem"></Box>

            <Box flexGrow={1}>
              {getStepContent(activeStep, activeDispute, profile)}
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Stepper
            activeStep={activeStep}
            orientation="horizontal"
            alternativeLabel
            style={{ padding: 0, background: "none" }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box height="2rem"></Box>

          {getStepContent(activeStep, activeDispute, profile)}
        </Hidden>

        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
          </Paper>
        )}
      </div>
      <Box height="2rem"></Box>

      {/* <Box display="flex" justifyContent="flex-start">
        {activeCase.case_status === "review_complete" ? (
          <>
            <Box width="1rem"></Box>
            <Link to={`/disputes/${disputeId}/chat`}>
              <Button variant="contained" color="primary" size="large">
                <FormattedMessage id="common.hearingRooms" />
              </Button>
            </Link>
          </>
        ) : null}
      </Box> */}
    </Container>
  );
}
