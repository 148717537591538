import { backgrounds } from "contexts/BackgroundContext";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { useBodyBackgroundChange } from "hooks";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCase } from "slices/casesSlice";
import { getDispute, getUserFiles } from "slices/disputeSlice";
import Files from "./Files";

export default function FilesContainer() {
  let { disputeId } = useParams();

  useBodyBackgroundChange(backgrounds.WHITE);

  const {
    profile: { profile: profile },
    disputes: {
      loading: disputeLoading,
      activeDispute: activeDispute,
      userFiles: files,
    },
    cases: { loading: caseLoading, activeCase: activeCase },
  } = useSelector((state) => state, shallowEqual);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserFiles(disputeId));
    dispatch(getDispute(disputeId));
    dispatch(getCase(disputeId));
  }, []);

  const passObjects = {
    profile,
    activeDispute,
    activeCase,
    files,
  };
  const loading =
    caseLoading ||
    disputeLoading ||
    isEmpty(activeDispute) ||
    isEmpty(activeCase);

  if (loading) return <CircularCenteredLoader />;

  console.log("printing files", files);
  return <Files {...passObjects} />;
}
