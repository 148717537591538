import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  makeStyles,
  useTheme,
  Icon,
  Grid,
} from "@material-ui/core";
import { sendMessage } from "utils/chatUtils";
import { containerContext } from "features/Chat/ChatContainer";

import { DropzoneDialog } from "material-ui-dropzone";
import { typingIndicator } from "utils/chatUtils";
import { useDispatch } from "react-redux";
import { uploadFile } from "slices/groupSlice";
import { useSelector } from "react-redux";
import MicIcon from "@material-ui/icons/Mic";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UtilityButton from "features/Chat/UtilityButton";
import AttachmentIcon from "@material-ui/icons/Attachment";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "2.5rem",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    background: theme.palette.secondPrimary,
  },

  container: {
    // borderTop: `0.2rem solid ${theme.palette.border}`,
    // paddingTop: "1.4rem",
  },
  root: {
    background: "#fff",
    padding: "1rem .5rem",
  },
  underline: {
    "&:before": {
      borderBottom: "2px solid green",
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: "2px solid blue",
    },
    "&:after": {
      borderBottom: "3px solid purple",
    },
  },
}));

const actions = [
  { icon: <AttachmentIcon />, name: "File" },
  { icon: <MicIcon />, name: "Voice" },
];

export default function InputBox({ groupId, handleStartRecording }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState("");
  const { ws } = useContext(containerContext);

  const username = useSelector((state) => state.user.user.first_name);
  const classes = useStyles();
  const theme = useTheme();

  let timer,
    timeoutVal = 1000;

  const onKeyUp = () => {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    timer = window.setTimeout(() => {
      typingIndicator(ws, groupId, username, false);
    }, timeoutVal);
  };

  const onKeyPress = () => {
    window.clearTimeout(timer);
    typingIndicator(ws, groupId, username, true);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSendClick();
    }
  };

  const onUploadClick = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    setMessage(e.target.value);
  };
  const onSendClick = () => {
    message.trim().length > 0 && sendMessage(ws, message, groupId);
    setMessage("");
  };

  const handlers = [onUploadClick, handleStartRecording];

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item sm={1} xs={2} style={{ position: "relative" }}>
          <UtilityButton actions={actions} handlers={handlers} />
        </Grid>
        <Grid item sm={10} xs={8}>
          <TextField
            style={{ borderRadius: "1rem" }}
            fullWidth
            id="filled-basic"
            variant="filled"
            placeholder="Type a message here"
            inputProps={{ className: classes.root }}
            value={message}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item sm={1} xs={2}>
          <IconButton onClick={onSendClick}>
            <Icon className={classes.icon}>send_Circle</Icon>
          </IconButton>
        </Grid>
      </Grid>
      <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={2.6e+7}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={async (files) => {
          console.log("Files:", files);
          dispatch(uploadFile({ file: files[0], groupId }));
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit={1}
        showFileNamesInPreview={true}
        acceptedFiles={[
          "image/jpeg", "image/png", "image/jpg",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
      />

      {/* <DropzoneDialog
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          console.log("Files:", files);
          files.forEach((file) => {
            addFileToServer(file, file.name);
          });
          setOpen(false);
        }}
        showPreviews={true}
        filesLimit="1"
        showFileNamesInPreview={true}
      /> */}

    </React.Fragment>
  );
}
