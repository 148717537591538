import React, { useContext } from "react";
import { IntlProvider } from "react-intl";
import bnTranslation from "translations/bn.json";
import enTranslation from "translations/en.json";
import hiTranslation from "translations/hi.json";
import teTranslation from "translations/te.json";
import tmTranslation from "translations/tm.json";
// import mlTranslation from "translations/ml.json";
// import asTranslation from "translations/as.json";
// import maTranslation from "translations/ma.json";
// import odTranslation from "translations/od.json";
// import puTranslation from "translations/pu.json";
import { flattenJson } from "utils";

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    this.switchToEnglish = () =>
      this.setState({ locale: "en", messages: flattenJson(enTranslation) });

    this.switchToHindi = () =>
      this.setState({ locale: "hi", messages: flattenJson(hiTranslation) });

    this.switchToTamil = () =>
      this.setState({ locale: "tm", messages: flattenJson(tmTranslation) });

    this.switchToTelugu = () =>
      this.setState({ locale: "te", messages: flattenJson(teTranslation) });

    this.switchToBengali = () =>
      this.setState({ locale: "bn", messages: flattenJson(bnTranslation) });

    // this.switchToMalayalam = () =>
    //   this.setState({ locale: "ml", messages: flattenJson(mlTranslation) });

    // this.switchToAssamese = () =>
    //   this.setState({ locale: "as", messages: flattenJson(asTranslation) });

    // this.switchToMarathi = () =>
    //   this.setState({ locale: "ma", messages: flattenJson(maTranslation) });

    // this.switchToOdia = () =>
    // this.setState({ locale: "od", messages: flattenJson(odTranslation) });

    // this.switchToPunjabi = () =>
    //   this.setState({ locale: "pu", messages: flattenJson(puTranslation) });

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: "en",
      messages: flattenJson(enTranslation),
      switchToEnglish: this.switchToEnglish,
      switchToHindi: this.switchToHindi,
      switchToTamil: this.switchToTamil,
      switchToTelugu: this.switchToTelugu,
      switchToBengali: this.switchToBengali,
      // switchToMalayalam: this.switchToMalayalam,
      // switchToAssamese: this.switchToAssamese,
      // switchToMarathi: this.switchToMarathi,
      // switchToOdia: this.switchToOdia,
      // switchToPunjabi: this.switchToPunjabi,
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

const useIntlContext = () => {
  return useContext(Context);
};

export { IntlProviderWrapper, Context as IntlContext, useIntlContext };
